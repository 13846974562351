import {
  Button,
  Divider,
  notification,
  Radio,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import AppUpload from "../AppUpload/app-upload";
import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

const UploadDocument: React.FunctionComponent<any> = (props) => {
  const {
    selectedLander,
    documentSetId,
    individualType,
    callBack,
    selectedType,
    loanProductId,
    formatDocument,
    parentDocId,
    childDocId,
    isCustomUI,
    maxSize,
    isLenderDocs,
    docOrder,
    docPage,
    actionOnDelete,
    selectedDocument,
    documentTypeId,
    filteredList
  } = props;

  const [applicantTypes, setApplicantTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [selectApplicantTypes, setSelectApplicantTypes] = useState("");
  const [selectEmploymentTypes, setSelectEmploymentTypes] = useState("");
  const [isSelectEmploymentTypesLead, setIsSelectEmploymentTypesLead] =
    useState(false);
  const [documentTypesParent, setDocumentTypesParent] = useState(null as any);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null as any);
  const [fileList, setFileList] = useState([] as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [hierarchyList, setHierarchyList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [loading, setLoading] = useState(null as any);
  const [filePayload, setFilePayload] = useState([] as any);
  const [isSelectMultipleFile, setIsSelectMultipleFile] = useState(false);
  const [allowedAttechmentTypeZip] = useState([
    "application/x-zip-compressed",
    "application/zip",
  ]);
  const [documentSubtypeList, setDocumentSubTypeList] = useState([]);
  const [isSelectedLenderDoc, setIsSelectedLenderDoc] = useState(false);
  const [fileSize, setFileSize] = useState(null as any);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null as any);
  const { appColors } = useSelector((state: RootState) => state.common);
  const documentType = () => {
    console.log("formatDocument: ", formatDocument);
    let fileFormat = formatDocument.toLocaleString();
    fileFormat = fileFormat.replace("jpg;", "jpeg");
    fileFormat = fileFormat.replace("jpg", "jpeg");
    fileFormat = fileFormat.replace("jpeg", "image/jpeg");
    fileFormat = fileFormat.replace("png", "image/png");
    fileFormat = fileFormat.replace("svg", "image/svg+xml");
    fileFormat = fileFormat.replace("pdf", "application/pdf");
    fileFormat = fileFormat.replace(
      "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    fileFormat = fileFormat.replace("xls", "application/vnd.ms-excel");
    return fileFormat;
  };
  console.log('filteredList', filteredList);

  useEffect(() => {
    if ((selectedType?.length ?? 0) === 0) {
      setEnums();
    }
    if (individualType === "Salaried") {
      setSelectEmploymentTypes("SALARIED");
      getDocumentHierarchyList("APPLICANT", "SALARIED");
    } else {
      setSelectEmploymentTypes("SELF_EMPLOYED");
      getDocumentHierarchyList("APPLICANT", "SELF_EMPLOYED");
    }
    console.log("::individualType", individualType);
    if (isCustomUI) {
      setSelectApplicantTypes("APPLICANT");
    }
    const tmpSubType = selectedType?.split(",");
    setDocumentSubTypeList(tmpSubType);
    if (tmpSubType?.length == 1) {
      const docTypeTmp = tmpSubType?.at(0) as any;
      setIsSelectedLenderDoc(true);
      setSelectedDocumentType(docTypeTmp?.split("~").at(0));
    }
    if (!isLenderDocs) {
      setIsSelectedLenderDoc(true);
    }
  }, []);

  const updateSelectedSubtype = (e: any) => {
    console.log("e>>", e);
    setIsSelectedLenderDoc(true);
    setSelectedDocumentType(e.split("~")?.at(0));
    setSelectedDocumentName(e);
  };

  const checkDocPage = (selectedDocType) => {
    const filteredLists = filteredList?.filter((item) => item?.documentType === selectedDocType);
    return filteredLists?.length;
  }

  const setEnums = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);

      const type = enumValues.find(
        (item: any) => item.propertyName === "DOCUMENT_APPLICANT_TYPE"
      );
      setApplicantTypes(type.keyAndDisplayValueList);
      if ((type?.keyAndDisplayValueList?.length ?? 0) > 0) {
        setSelectApplicantTypes(type.keyAndDisplayValueList[0].key);
      }

      let employmentType = "Salaried";
      const typeEmp = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      setEmploymentTypes(typeEmp.keyAndDisplayValueList);
      if ((typeEmp?.keyAndDisplayValueList?.length ?? 0) > 0) {
        employmentType = typeEmp.keyAndDisplayValueList[0].key;
        setSelectEmploymentTypes(typeEmp.keyAndDisplayValueList[0].key);
      }

      const filteredEmp = typeEmp?.keyAndDisplayValueList?.filter(
        (data) => data.value === individualType
      );
      if ((filteredEmp?.length ?? 0) > 0) {
        employmentType = filteredEmp[0].key;
        setSelectEmploymentTypes(filteredEmp[0].key);
        setIsSelectEmploymentTypesLead(true);
      }

      // getDocumentHierarchyList("APPLICANT", employmentType);
    }
  };

  const getDocumentHierarchyList = (applicationType, employmentType) => {
    if (!isLenderDocs) {
      const params = {
        applicationType,
        employmentType,
        loanProductId,
      };
      setLoading(true);
      API_SERVICE.getDocumentHierarchyList(params)
        .then(({ data }) => {
          if (data) {
            let uploadObject = {
              documentCategoryKey: "ZIP",
              documentCategoryName: "Zip",
            };
            let dataObject = data?.payload;
            dataObject.push(uploadObject);
            setHierarchyList(dataObject);
            // setHierarchyList(data.payload);
            if ((data?.payload?.length ?? 0) > 0) {
              setDocumentTypesParent(data?.payload[0]?.documentCategoryKey);
            }
          }
          setLoading(false);
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        });
    }
  };

  const uploadDocumentFile = () => {
    if (selectedDocument?.documentDetails) {
      actionOnDelete(selectedDocument, false, false);
    }
    // const allowedAttechmentTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
    const allowedAttechmentTypes = formatDocument
      ? documentType()?.split(",")
      : ["application/pdf", "image/jpeg", "image/jpg", "image/png"];

    if (documentTypesParent === "ZIP") {
      if (allowedAttechmentTypeZip.indexOf(uploadedFile.type) === -1) {
        notification.error({ message: "Please attach only ZIP file." });
        setFileList([]);
        return;
      }
    } else {
      if (allowedAttechmentTypes.indexOf(uploadedFile?.type) === -1) {
        // notification.error({ message: "Please attach only image(jpg/jpeg/png) or pdf file." });
        notification.error({
          message: `Please attach only ${
            formatDocument ? documentType() : "image(jpg/jpeg/png) or pdf"
          } file.`,
        });
        return;
      }
    }

    let type = selectedDocumentType; // (selectedType?.length ?? 0) != 0 ? selectedType : null;
    const types = type?.split("~");
    // type = types?.at(0)?.trim();
    let subType = selectedDocumentType;
    if (type == null) {
      type = subType;
    }
    if ((subType?.length ?? 0) == 0) {
      subType = type;
      // let t = types?.at(-1)?.trim();
      // if((types?.length ?? 0) >= 3) {
      //     t = types?.at(1)?.trim() + "~"+ types?.at(-1)?.trim();
      // }
      // subType = (t?.length ?? 0) == 0 ? type : t;
    }

    const documentSubType =
      selectedLander?.lenderName?.toLowerCase()?.includes("lending") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("bajaj")
        ? subType?.split("(")[0]
        : subType;

    const payload = {
      documentURL: imageUrl,
      documentName: uploadedFile.name,
      documentType: type,
      documentSubType: documentSubType,
      documentApplicantType: selectApplicantTypes,
      lenderId: selectedLander?.lenderInfoId,
      docOrder: docOrder ?? 0,
      // docPage: selectedDocumentName
      //   ? selectedDocumentName.split("~")?.at(1)
      //   : docPage,
      docPage: checkDocPage(documentSubType),
      fileSize: fileSize,
      documentTypeId: documentTypeId,
    };
    API_SERVICE.addDocument(payload, documentSetId)
      .then(({ data }) => {
        if (data) {
          setFileList([]);
          if (parentDocId && childDocId) {
            callBack(true, {
              ...data.payload,
              parentDocId: parentDocId,
              childDocId: childDocId,
            });
          } else {
            callBack(true, data.payload);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const generatDocumentType = (documentSubTypeList) => {
    console.log("::documentSubTypeList", documentSubTypeList);
    return (
      <div>
        {documentSubTypeList && (
          <Select
            className="custom-select"
            placeholder="Document Type"
            onChange={(e) => setSelectedDocumentType(e)}
          >
            {documentSubTypeList
              ?.sort(function (a: any, b: any) {
                if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                return 0;
              })
              ?.map((item: any, i: number) => {
                return (
                  <Select.Option key={i} value={item.key}>
                    <Tooltip title={item.value}>{item.value}</Tooltip>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </div>
    );
  };
  console.log(
    "Disable",
    (selectedType?.length ?? 0) == 0
      ? !(
          selectedDocumentType &&
          uploadedFile &&
          imageUrl &&
          isSelectedLenderDoc
        )
      : !uploadedFile && !imageUrl && !isSelectedLenderDoc
  );
  const customRadioPrimary: any = { '--borderColor': appColors?.appPrimaryColor ?? "" };
  const customBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
  const customBtnStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  return (
    <>
      <div className="upload-document-modal">
        {(selectedType?.length ?? 0) === 0 && (
          <>
            <div className="main-view">
              <Title level={5}>Select Applicant Type</Title>

              <Radio.Group
                name="applicantType"
                className="dynamic-radio"
                style={customRadioPrimary}
                onChange={(ev) => {
                  setSelectApplicantTypes(ev.target.value);
                  getDocumentHierarchyList(
                    ev.target.value,
                    selectEmploymentTypes
                  );
                }}
                value={selectApplicantTypes}
              >
                {applicantTypes?.map((type: any) => {
                  return <Radio value={type?.key}>{type?.value}</Radio>;
                })}
              </Radio.Group>
            </div>

            {!isSelectEmploymentTypesLead && (
              <div className="main-view">
                <Title level={5}>Select Employment Type</Title>

                <Radio.Group
                  name="employmentType"
                  className="dynamic-radio"
                  style={customRadioPrimary}
                  onChange={(ev) => {
                    console.log("::ev.target.value", ev.target.value);
                    setSelectEmploymentTypes(ev.target.value);
                    getDocumentHierarchyList(
                      selectApplicantTypes,
                      ev.target.value
                    );
                  }}
                  value={selectEmploymentTypes}
                >
                  {employmentTypes?.map((type: any) => {
                    return <Radio value={type?.key}>{type?.value}</Radio>;
                  })}
                </Radio.Group>
              </div>
            )}
          </>
        )}

        <div className="main-view">
          {(selectedType?.length ?? 0) === 0 && (
            <>
              <Title level={5}>Type of Document</Title>

              <Radio.Group
                className="types-document dynamic-radio"
                style={customRadioPrimary}
                name="document"
                onChange={(ev) => {
                  setSelectedDocumentType(null);
                  setUploadedFile(null);
                  if (fileList?.length > 0) {
                    setFileList([]);
                    setFilePayload([]);
                  }
                  if (ev.target.value === "ZIP") {
                    setSelectedDocumentType("ZIP");
                    if (!isSelectMultipleFile) {
                      setIsSelectMultipleFile(true);
                    }
                  } else {
                    if (isSelectMultipleFile) {
                      setIsSelectMultipleFile(false);
                    }
                  }
                  setDocumentTypesParent(ev.target.value);
                }}
                value={documentTypesParent}
              >
                {hierarchyList?.map((documentType: any) => {
                  const value = documentType?.documentCategoryKey;
                  return (
                    <>
                      {value === "ZIP" && (
                        <Divider orientation="center">Or</Divider>
                      )}
                      <Radio value={value}>
                        {documentType?.documentCategoryName}
                      </Radio>
                      {documentTypesParent === value &&
                        generatDocumentType(documentType?.documentList)}
                    </>
                  );
                })}
              </Radio.Group>
            </>
          )}

          <AppUpload
            acceptType={
              formatDocument ??
              (documentTypesParent === "ZIP" ? ["zip"] : ["image", "pdf"])
            }
            showUploadList={uploadedFile != null}
            setUploadedFile={setUploadedFile}
            multiple={isSelectMultipleFile}
            setIsSelectedLenderDoc={setIsSelectedLenderDoc}
            maxSize={maxSize}
            setFileSize={setFileSize}
            callBack={(response) => {
              if (response?.data && response?.file) {
                setUploadedFile(response?.file);
                setImageUrl(response?.data);
                if (!isSelectedLenderDoc) {
                  const tmpSubType = selectedType?.split(",");
                  if (tmpSubType?.length == 1 || !isLenderDocs) {
                    setIsSelectedLenderDoc(true);
                  }
                }
              }
            }}
          />
          {isLenderDocs && documentSubtypeList?.length > 1 && (
            <Select
              className="custom-select"
              placeholder="Document Type"
              onChange={(e) => updateSelectedSubtype(e)}
            >
              {documentSubtypeList?.map((item: any, i: number) => {
                // TODO: if change anything in following value then change inside useeffect setSelectedDocumentType() also
                return (
                  <Select.Option key={i} value={item}>
                    <Tooltip title={item.split("~").at(0)}>
                      {item.split("~").at(0)}
                    </Tooltip>
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </div>

        <div className="upload-btns">
          <Button
            type="default"
            className="upload-btn-submit dynamic-btn-default"
            style={customBtnStyle}
            onClick={() => {
              setFileList([]);
              callBack(false);
            }}
          >
            Cancel
          </Button>

          <Button
            className="upload-btn-submit dynamic-btn-primary"
            style={customBtnPrimary}
            type="primary"
            disabled={
              (selectedType?.length ?? 0) == 0
                ? !(selectedDocumentType && uploadedFile && imageUrl) ||
                  !isSelectedLenderDoc
                : (!uploadedFile && !imageUrl) || !isSelectedLenderDoc
            }
            onClick={uploadDocumentFile}
          >
            Upload
          </Button>
        </div>
      </div>

      {loading && (
        <div className="loader-view">
          <Spin />
        </div>
      )}
    </>
  );
};

export default UploadDocument;
