import {
  Row,
  Select,
  Typography,
  Input,
  Col,
  Form,
  Collapse,
  InputNumber,
  DatePicker,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { DOUBLE_REGEX } from "shared/constants/AppConstants";
import { numberWithCommas } from "shared/services/Utility";
import iconAction from "../../../../assets/images/action.svg";
import "./LenderDecision.scss";
import LenderDisbursedMain from "../LenderDisbursed/LenderDisbursedMain";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Title, Text } = Typography;

const LenderDecision: React.FunctionComponent<any> = ({
  selectedLenderList,
  leadData,
  faircentStatus,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
  lenderDisbursedHandler,
  isShowDisbursed,
  setIsShowDisbursed,
  setIsLoading,
}) => {
  const [selectedLenderData, setSelectedLenderData] = useState([] as any);
  const [rejectReasons, setRejectReasons] = useState([] as any);
  const [pendencyReasons, setPendencyReasons] = useState([] as any);
  const [withdrawnReasons, setWithdrawnReasons] = useState([] as any);
  const [dicisionForm] = Form.useForm();
  const [selectedLenderName, setSelectedLenderName] = useState("");
  const { appColors }: any = useSelector((state: RootState) => state.common);
   const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );


  useEffect(() => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const rejectedReasonList = enumValues.find(
        (item) => item.propertyName === "REJECTION_REASON"
      );
      setRejectReasons(rejectedReasonList.keyAndDisplayValueList);
      const pendencyReasonList = enumValues?.find(
        (item) => item?.propertyName === "PENDENCY_REASON"
      );
      setPendencyReasons(pendencyReasonList?.keyAndDisplayValueList);
      const withdrawnReasonList = enumValues?.find(
        (item) => item?.propertyName === "WITHDRAWN_REASON"
      );
      setWithdrawnReasons(withdrawnReasonList?.keyAndDisplayValueList);
    }
    let sanctionedAmount = 0;
    let lenderDecisionType = [] as any;
    const tmpAssignedLenders =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.filter(
        (item) =>
          (item.status === "LOGGED" && item?.submissionType != "API") ||
          (item?.submissionType == "API" && item?.allDocumentUploaded) ||
          item.status == "PENDENCY" ||
          item.status === "SANCTIONED" ||
          item.status === "DISBURSED" ||
          item.status === "REJECTED" ||
          item.status === "WITHDRAWN"
      );
    tmpAssignedLenders.map(
      (item, index) => (
        (item.leanderDecision = {
          selectedType: getSelectedType(item),
          sanctionedData: {
            interestRate: item.interestRate,
            loanAmount: item.sanctionedAmount,
            sanctionedDate:item.sanctionedDate,
          },
          rejectedData: { rejectReason0: item.rejectedReason },
          pendencyData: { [`comment${index}`]: item?.comments },
          withdrawnData: { [`comment${index}`]: item?.comments },
          interestRate: item.interestRate,
        }),
        
        lenderDecisionType.push({
          selectedType: getSelectedType(item),
        }),
        (sanctionedAmount += item.sanctionedAmount ?? 0)
      )
    );
    setSelectedLenderData(tmpAssignedLenders);
    console.log("lenderdata", selectedLenderData);
    const setData = {} as any;
    tmpAssignedLenders.map((lender: any, index: number) => {
      const selectedType =
        lender?.status == "DISBURSED"
          ? "Disbursed"
          : lender?.leanderDecision?.selectedType ?? undefined;
      setData[`selectedType${index}`] = selectedType;
      if (selectedType == 1) {
        const sanctionedData = {
          ...lender?.leanderDecision?.sanctionedData,
          sanctionedDate: lender?.leanderDecision?.sanctionedData
            ?.sanctionedDate
            ? moment(lender?.leanderDecision?.sanctionedData?.sanctionedDate)
            : "",
            
        };
        console.log('sanctionedata',sanctionedData)
        setData[`${index}`] = sanctionedData;
      } else if (selectedType == 2) {
        setData[`rejectReason${index}`] =
          lender?.leanderDecision?.rejectedData?.rejectReason ?? undefined;
      } else if (selectedType == 3) {
        setData[`comment${index}`] =
          lender?.leanderDecision?.withdrawnData[`comment${index}`] ??
          undefined;
      } else if (selectedType == 4) {
        setData[`comment${index}`] =
          lender?.leanderDecision?.pendencyData[`comment${index}`] ?? undefined;
      } else {
        const sanctionedData = {
          ...lender?.leanderDecision?.sanctionedData,
          disbursedAmount: calcTotalDisbursedAmount(
            lender?.disbursalLenderInfoDetails
          ),
        };
        setData[`${index}`] = sanctionedData;
      }
    });
    const loanAmount = leadData.caseDetails?.loanDetails?.loanAmount || 0;
    dicisionForm.setFieldsValue({
      ...setData,
      approvedAmount: sanctionedAmount,
      requestedAmount: loanAmount,
    });
  }, []);

  const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
    let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.insuranceAmount)
    );
    const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
    const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
    const total = totalDisbursed + totalInsurance;
    return isNaN(total) ? 0 : total;
  };

  const getSelectedType = (item) => {
    if (
      faircentStatus?.toUpperCase() === "SANCTIONED" ||
      item.status == "SANCTIONED"
    ) {
      return 1;
    } else if (faircentStatus === "Rejected" || item.status == "REJECTED") {
      return 2;
    } else if (item.status == "WITHDRAWN") {
      return 3;
    } else if (item.status == "PENDENCY") {
      return 4;
    }
    return null;
  };

  const renderDisbursed = () => (
    <LenderDisbursedMain
      setIsLoading={setIsLoading}
      leadData={leadData}
      selectedLenderList={selectedLenderList}
      lenderDisbursedHandler={lenderDisbursedHandler}
      getRedirectURLLink={getRedirectURLLink}
      getDownloadLetterForICICI={getDownloadLetterForICICI}
      loadingUpdate={loadingUpdate}
      selectedLenderName={selectedLenderName}
    />
  );

  const renderLenderDecision = () => (
    <div>
      <Form form={dicisionForm} layout="vertical">
        <div className="disbursal-main-div">
          <div className="" style={{ padding: "10px", rowGap: "0px" }}>
            <div>
              <Row gutter={[8, 8]} style={{ marginLeft: "10px" }}>
                <Col span={6}>
                  <Title level={5}>Disbursal Details:</Title>
                </Col>
                <Col span={8}>
                  <Text style={{ fontSize: "14px" }}>
                    Total Tentative Loan Amount:
                  </Text>
                  <InputNumber
                    readOnly
                    className="custom-input-number"
                    name="tentativeLoanAmount"
                    value={leadData?.caseDetails?.loanDetails?.loanAmount || 0}
                    formatter={(value) => numberWithCommas(value)}
                    placeholder="Total Tentative Loan Amount"
                  />
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="approvedAmount"
                    className="inline-label"
                    label="Total Sanctioned Loan Amount"
                  >
                    <InputNumber
                      className="custom-input-number"
                      name="approvedAmount"
                      readOnly
                      formatter={(value) => numberWithCommas(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div>
          <ul style={{ listStyle: "none", padding: "0px" }}>
            {selectedLenderData?.map((item: any, i: any) => {
              return (
                <li key={i}>
                  <div className="lender-detail1-main-div">
                    <Row gutter={[6, 6]} style={{ marginLeft: " ", flex: "1" }}>
                      <Col span={4}>
                        <span className="lenderName-decision">
                          {item.lenderName}
                        </span>
                        <div>
                          {item?.submissionType?.includes("API") &&
                            (item?.lenderApplicationId?.length ?? 0) > 0 && (
                              <div className="lenderId-div">
                                <span
                                  style={{ fontSize: "9px" }}
                                >{`Loan ID: ${item.lenderApplicationId}`}</span>
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col span={5} xs={10} xl={5}>
                        <div>
                          <Form.Item
                            className="decision-item"
                            label="Loan Status"
                            name={`selectedType${i}`}
                          >
                            <Select
                              className="custom-select"
                              disabled
                              defaultValue={
                                item?.status == "DISBURSED"
                                  ? "Disbursed"
                                  : item.leanderDecision.selectedType
                              }
                            >
                              <Select.Option value={1}>
                                Sanctioned
                              </Select.Option>
                              <Select.Option value={2}>Rejected</Select.Option>
                              <Select.Option value={3}>WithDrawn</Select.Option>
                              <Select.Option value={4}>Pendency</Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {item.leanderDecision.selectedType === 1 ||
                      item?.status == "DISBURSED" ? (
                        <Form.List name={i}>
                          {(fields) => (
                            <>
                              <Col span={5} xs={10} xl={5}>
                                <Form.Item
                                  label="Interest rate %"
                                  name="interestRate"
                                  className="decision-item"
                                  initialValue={
                                    item?.leanderDecision?.interestRate || 0
                                  }
                                >
                                  <Input
                                    className="custom-input"
                                    name="interestRate"
                                    disabled
                                    value={item.leanderDecision.interestRate}
                                    placeholder="Interest rate %"
                                  />
                                </Form.Item>
                              </Col>
                              {isMobile && <Col span={4}></Col> }
                              <Col span={5} xs={10} xl={5} >
                                <Form.Item
                                  name="loanAmount"
                                  className="decision-item"
                                  label={
                                    item?.status == "DISBURSED"
                                      ? "Sanctioned Amount"
                                      : "Amount"
                                  }
                                  initialValue={item?.sanctionedAmount ?? 0}
                                >
                                  <InputNumber
                                    className="custom-input-number"
                                    disabled
                                    formatter={(value) =>
                                      numberWithCommas(value)
                                    }
                                    placeholder="Amount"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={5} xs={10} xl={5} >
                                {item?.status == "DISBURSED" ? (
                                  <Form.Item
                                    className="decision-item"
                                    name="disbursedAmount"
                                    label="Disbursed Amount"  
                                    initialValue={
                                      item?.disbursalLenderInfoDetails?.at(0)
                                        ?.lenderAmount.disbursedAmount
                                    }
                                  >
                                    <InputNumber
                                      className="custom-input-number"
                                      disabled
                                      formatter={(value) =>
                                        numberWithCommas(value)
                                      }
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name="sanctionedDate"
                                    className="decision-item"
                                    label="Sanctioned Date"
                                    initialValue={
                                      item?.leanderDecision?.sanctionedData?.sanctionedDate
                                        ? moment(item?.leanderDecision?.sanctionedData?.sanctionedDate)
                                        : ""
                                    }
                                    
                                  >
                                    <DatePicker
                                      format="YYYY-MM-DD"
                                      className="custom-input"
                                      placeholder="Select Date"
                                      disabled
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </>
                          )}
                        </Form.List>
                      ) : (
                        ""
                      )}
                      {item.leanderDecision.selectedType === 2 && (
                        <Col span={10}>
                          <Text className="fields-text ">Rejection Reason</Text>
                          <div className="rejected-wrapper">
                            <Form.Item
                              name={`rejectReason${i}`}
                              initialValue={item?.leanderDecision?.rejectedData?.rejectReason0}
                            >
                              <Select
                                className="custom-select"
                                placeholder="Select Reason"
                                disabled
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {rejectReasons?.map((item) => (
                                  <Select.Option
                                    key={item.key}
                                    value={item.key}
                                  >
                                    {item.value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      )}
                      {item.leanderDecision.selectedType === 3 && (
                        <Col span={10}>
                          <Text className="fields-text">Withdrawn Reason</Text>
                          <div className="rejected-wrapper">
                            <Form.Item
                              name={`comment${i}`}
                              // initialValue={item.rejectedReason}
                            >
                              <Select
                                className="custom-select"
                                showSearch
                                disabled
                                placeholder="Select Reason"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {withdrawnReasons?.map((item) => (
                                  <Select.Option
                                    key={item?.key}
                                    value={item?.key}
                                  >
                                    {item?.value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      )}
                      {item.leanderDecision.selectedType === 4 && (
                        <Col span={10}>
                          <Text className="fields-text">Pendency Reason</Text>
                          <div className="rejected-wrapper">
                            <Form.Item
                              name={`comment${i}`}
                              // initialValue={item.rejectedReason}
                            >
                              <Select
                                className="custom-select"
                                showSearch
                                disabled
                                placeholder="Select Reason"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {pendencyReasons?.map((item) => (
                                  <Select.Option
                                    key={item?.key}
                                    value={item?.key}
                                  >
                                    {item?.value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {item?.status == "DISBURSED" ? (
                      <>
                        <div>
                          <Text className="fields-text">Action</Text>
                          <Form.Item name={`action${i}`}>
                            <Button
                              type="text"
                              htmlType="submit"
                              style={{ border: "none" }}
                              icon={<img src={iconAction}/>}
                              onClick={() => {
                                setSelectedLenderName(item?.lenderName);
                                setIsShowDisbursed(true);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    ) : (
                      <div style={{ padding: "17px" }} />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Form>
    </div>
  );

  return isShowDisbursed ? renderDisbursed() : renderLenderDecision();
};

export default LenderDecision;
