import { Col, Row } from "antd";
import oneflow from "assets/images/oneflo.svg";
import oneflowFlyingPlane from "assets/images/oneflo-flying-plane.svg";
import "./authentication.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IAuthentication {
  title: string;
  children: JSX.Element;
}

const Authentication: React.FunctionComponent<IAuthentication> = (
  props: IAuthentication
) => {
  const { title, children } = props;
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);

  return (
    <div className="authentication-main-view">
      {!isMobile &&
        <Row>
          <Col xs={0} sm={0} md={0} lg={6} className="header-logo-details">
            <h3 className="page-header">{title}</h3>
            <img className="logo-details" src={oneflow} width={120} />
            {/* <p className="header-content">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p> */}
          </Col>
          <Col xs={0} sm={0} md={0} lg={9} className="flying-plane text-center">
            <img src={oneflowFlyingPlane} width={330} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={9}
            className="authentication-container"
          >
            {children}
          </Col>
        </Row>}
      {isMobile &&
        <Row>
          <Col xs={24} sm={24} md={0} lg={6} className="header-logo-details" style={{ display: "flex", justifyContent: "center" }}>
            <img className="logo-details" src={oneflow} width={180} />
          </Col>
          <Col xs={0} sm={0} md={0} lg={9} className="flying-plane text-center">
            <img src={oneflowFlyingPlane} width={330} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={9}
            className="authentication-container"
          >
            {children}
          </Col>
        </Row>}
    </div>
  );
};
export default Authentication;
