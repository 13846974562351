import { SetStateAction, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthConsumer } from "shared/contexts/AuthContext";
import Authentication from "../authentication";
import SignUpReferral from "./sign-up-referral";
import SignUpType from "./sign-up-type";
import SignUpYourDetail from "./sign-up-your-detail";
import VerifyDetails from "../VerifyDetails";
import SetPassword from "../SetPassword";
import SignUpBusinessDetails from "./sign-up-business-details";
import SignUpSignatoryDetails from "./sign-up-signatory-details";
import "../authentication.scss";
import SignUpReferralCode from "./sign-up-referral-code";

export type signUpStages =
  | "referralCode"
  | "referral"
  | "selectType"
  | "yourDetail"
  | "verifyDetail"
  | "businessDetail"
  | "authSignatory"
  | "setPassword";

const SignUp = () => {
  const { from } = { from: { pathname: "/dashboard" } };

  const [selectedSignUpStage, setSelectedSignUpStage] = useState(
    "referralCode" as signUpStages
  );
  const [businessType, setBusinessType] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [userDetails, setUserDetails] = useState({});

  const saveUserData = (data: any) => {
    console.log("UserData: ", { ...userDetails, ...data });
    setUserDetails({ ...userDetails, ...data });
  };


  useEffect(() => {
    const urlParams = {} as any;
    let searchParams = new URL(window.location.href).searchParams as any;

    if (searchParams?.size > 0) {
      searchParams?.forEach((value, key) => {
        urlParams[key] = value;
      });
    }

    if (urlParams?.connID || urlParams?.salesID || urlParams?.dsaBusinessID) {
      saveUserData({
        referralId: urlParams?.connID,
        managerId: urlParams?.salesID,
        slateEmployeeId: "",
        referralSource: urlParams?.referralSource,
        dsaBusinessId: urlParams?.dsaBusinessID
      });
      setSelectedSignUpStage("selectType")
    }

  }, []);

  const renderStage = () => {
    if (selectedSignUpStage === "referralCode") {
      return (
        <SignUpReferralCode
          setSelectedSignUpStage={setSelectedSignUpStage}
          saveUserData={saveUserData} />
      );
    } else if (selectedSignUpStage === "referral") {
      return (
        <SignUpReferral
          setSelectedSignUpStage={setSelectedSignUpStage}
          saveUserData={saveUserData}
        />
      );
    } else if (selectedSignUpStage === "selectType") {
      return (
        <SignUpType
          setSelectedSignUpStage={setSelectedSignUpStage}
          setBusinessType={setBusinessType}
        />
      );
    } else if (selectedSignUpStage === "yourDetail") {
      return (
        <SignUpYourDetail
          businessType={businessType}
          setSelectedSignUpStage={setSelectedSignUpStage}
          setMobileNo={setMobileNo}
          setVerificationId={setVerificationId}
          saveUserData={saveUserData}
          userDetails={userDetails}
        />
      );
    } else if (selectedSignUpStage === "verifyDetail") {
      return (
        <VerifyDetails
          setSelectedSignUpStage={setSelectedSignUpStage}
          mobileNo={mobileNo}
          verificationId={verificationId}
          businessType={businessType}
        />
      );
    } else if (selectedSignUpStage === "businessDetail") {
      return (
        <SignUpBusinessDetails
          setSelectedSignUpStage={setSelectedSignUpStage}
          saveUserData={saveUserData}
          userDetails={userDetails}
        />
      );
    } else if (selectedSignUpStage === "authSignatory") {
      return (
        <SignUpSignatoryDetails
          userDetails={userDetails}
          setSelectedSignUpStage={setSelectedSignUpStage}
        />
      );
    } else if (selectedSignUpStage === "setPassword") {
      return <SetPassword mobileNo={mobileNo} />;
    }
    return <></>;
  };

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, permissions }) => {
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          <Authentication title="Sign Up to">{renderStage()}</Authentication>
        );
      }}
    </AuthConsumer>
  );
};

export default SignUp;
