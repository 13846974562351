import { notification } from "antd";
import "./FormInput.scss";
import { useEffect, useState } from "react";
import { PAN_REGAX } from "shared/constants/AppConstants";
import FormInput from "./FormInput";
import API_SERVICE from "shared/services/api-service";

interface IFormInputPan {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  showVerification?: boolean;
  callBack: (panData?: any) => void;
}

const FormInputPan = (props: IFormInputPan) => {
  const { name, label, placeholder, showVerification, value, callBack } = props;

  const [panVerified, setPanVerified] = useState(false);

  useEffect(() => {
    if ((value?.length ?? 0) > 0) {
      getPanDetail(value);
    }
  }, [value]);

  const getPanDetail = (pan) => {
    const data = {
      consent: "Y",
      dob: "",
      name: "",
      pan: pan
    }
    API_SERVICE.panVerify(data).then(({ data }) => {
      if (data?.payload?.result?.name) {
        setPanVerified(true);
      }
      callBack(data);
    }).catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    })
  };

  const checkPan = (_: any, value: any) => {
    if (value == null || (value?.length ?? 0) == 0) {
      return Promise.resolve();
    }

    if (PAN_REGAX.test(value)) {
      getPanDetail(value);
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Please enter valid pan number`));
    }
  };

  return (
    <FormInput
      name={name}
      label={label}
      placeholder={placeholder}
      onChange={(e: any) => {
        setPanVerified(false);
      }}
      //isVerified={panVerified}
      //showVerification={showVerification}
      isUpperCase
      rules={[
        {
          required: true,
          message: "Please enter pan number",
        },
        { validator: checkPan },
      ]}
    />
  );
};

export default FormInputPan;
