import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IScreenHeader {
  backScreenTitle?: string;
  backScreenPath?: string;
  screenTitle?: string;
}

const screenHeader: IScreenHeader = {
  backScreenTitle: "",
  backScreenPath: "",
  screenTitle: "",
};

export const commonSlice = createSlice({
  name: "CommonReducer",
  
  initialState: { screenHeader, lenderRequiredFields: [], isMobile: false, showProfileVerificaton: false, isAppHeaderShow: true, isAppFooterShow: false, activeTab: 0, appColors: {} as any },
  reducers: {
    setScreenHeader: (state, action: PayloadAction<IScreenHeader>) => {
      state.screenHeader.screenTitle = action?.payload?.screenTitle;
      state.screenHeader.backScreenPath = action?.payload?.backScreenPath;
      state.screenHeader.backScreenTitle = action?.payload?.backScreenTitle;
    },
    setLenderRequiredField: (state, action: PayloadAction<any>) => {
      state.lenderRequiredFields = action?.payload;
    },
   
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setShowProfielVerification: (state, action: PayloadAction<boolean>) => {
      state.showProfileVerificaton = action.payload;
    },
    setIsAppHeaderShow: (state, action: PayloadAction<boolean>) => {
      state.isAppHeaderShow = action.payload;
    },
    setIsAppFooterShow: (state, action: PayloadAction<boolean>) => {
      state.isAppFooterShow = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setAppColors: (state, action: PayloadAction<Object>) => {
      state.appColors = action.payload
    }

  }
});

// Action creators are generated for each case reducer function
export const { setIsMobile, setShowProfielVerification, setIsAppHeaderShow, setIsAppFooterShow, setActiveTab, setAppColors, setScreenHeader } = commonSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default commonSlice.reducer;