import { Col, Form, Row } from "antd";
import RadioButton from "shared/components/RadioButton/RadioButton";
import { useEffect } from "react";
import FormInput from "shared/components/FormInput/FormInput";
import { getEnumList, getGenderKey } from "shared/services/Utility";
import { PHONE_NUMBER_REGEX, STRING_ONLY } from "shared/constants/AppConstants";
import FormButton from "shared/components/FormButton/FormButton";
import "./profile.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IEditBusiness {
  setOpenEditBusinessDetail: any;
  updateConnector: any;
  basicDetails: any
}

const EditBusinessDetail: React.FC<IEditBusiness> = (props: IEditBusiness) => {
  const { setOpenEditBusinessDetail, updateConnector, basicDetails } = props as any;
  const [form] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    form.setFieldsValue({
      businessName: basicDetails?.businessName,
      authorisedSignatoryName: basicDetails?.authorizedSignatory?.name,
      authorisedSignatoryPhone: basicDetails?.authorizedSignatory?.phoneNumber,
      authorisedSignatoryEmail: basicDetails?.authorizedSignatory?.email,
      businessType: basicDetails?.businessType,
    });
  }, []);

  const onOpenBusiness = () => {
    setOpenEditBusinessDetail(false);
  };

  const onBusinessDetail = (value) => {
    const tmpBasicDetails = {
      ...basicDetails,
      gender: getGenderKey(basicDetails?.gender),
      businessType: value?.businessType,
      businessName: value?.businessName,
      authorizedSignatory: {
        name: value?.authorisedSignatoryName,
        phoneNumber: value?.authorisedSignatoryPhone,
        email: value?.authorisedSignatoryEmail,
      }
    };
    updateConnector({ basicDetails: tmpBasicDetails });
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        size="small"
        onFinish={onBusinessDetail}
      >
        <RadioButton
          label="Select Business"
          name="businessType"
          radioList={getEnumList("BUSINESS_TYPE")}
          defaultValue={basicDetails?.businessType}
          className="custom-radio profession-btns"
          style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
          isRequired={false}
        />
        <FormInput
          name="businessName"
          label="Business Name"
          placeholder="Enter Your Business Name"
          isBorder
        />
        <FormInput
          name="authorisedSignatoryName"
          label="Authorised Name"
          placeholder="Enter Authorised Name"
          isBorder
          rules={[
            {
              pattern: STRING_ONLY,
              message: "Please enter valid Authorized Name",
            },
          ]}
        />
        <FormInput
          name="authorisedSignatoryPhone"
          label="Authorised Phone"
          placeholder="Enter Authorised Phone"
          isBorder
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid Authorized Phone Number",
            },
          ]}
        />
        <FormInput
          name="authorisedSignatoryEmail"
          label="Authorized Email"
          inputMode="email"
          type="email"
          placeholder="Enter Your Email Id"
          isBorder
        />
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item>
              <FormButton
                label="Cancel"
                onclick={onOpenBusiness}
                className="dynamic-btn-default"
                style={{'--borderColor': appColors?.appSecondaryColor ?? ""} as any}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormButton
              htmlType="submit"
              type="primary"
              label="Save"
              className="dynamic-btn-primary"
              style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default EditBusinessDetail;
