import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LeadDetails from "screens/Lead/LeadDetails";
import { AuthConsumer } from "shared/contexts/AuthContext";
import Dashboard from "../Dashboard/Dashboard";
import Employees from "screens/Employees/Employees";
import notices from "../Notices/notices";
import viewNotice from "../Notices/viewNotice";
import viewOffer from "../Offers/viewOffer";
import NotificationBar from "../noification-bar/notification-bar";
import ViewProfile from "../Profile/ViewProfile";
import Reports from "../Reports/reports";
import { getBasicDetails, hasUserRole } from "shared/services/Utility";
import Offers from "../Offers/offers";
import Help from "../help/Help";
import ImportBulUpload from "../Lead/bulkUpload/Import_bulkUpload";
import ImportBulUploadStep from "../Lead/bulkUpload/bulk_upload_start";
import AddEmployee from "screens/Employees/AddEmployee/AddEmployee";
import Lead from "screens/Lead/Lead";
import PayoutRequest from "screens/PayoutRequest/payout_request";
import Partners from "screens/Partners/Partners";
import Profile from "screens/Profile/profile";
import Tools from "screens/Tools/Tools";
import Designation from "screens/Designation/Designation";
import AddDesignation from "screens/Designation/AddDesignation";
import AppLayout from "./AppLayout";
import AddLeadNew from "screens/AddLead/AddLeadNew";
import AddLead from "screens/AddLead/AddLead";

type Props = {};

const Layout: React.FunctionComponent<Props> = () => {
  
  const basicDetails = getBasicDetails();
  const isAdmin = (basicDetails?.connectorRole ?? "") == "CONNECTOR_ADMIN";
  const canAddEmployee = basicDetails?.canAddEmployee

  return (
    <AuthConsumer>
      {({ isAuth, apiCreds }) => {
        return isAuth ? (
          <AppLayout>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/lead" component={Lead} />
              <Route exact path={"/lead/add-lead"} component={AddLeadNew} />
              <Route exact path={"/lead/details/:id"} component={LeadDetails} />
              <Route
                exact
                path="/lead/bulkUpload"
                component={ImportBulUpload}
              />
              <Route exact path={"/lead/edit/:id"} component={AddLead} />
              <Route
                exact
                path="/lead/bulkUpload/import-bulkupload"
                component={ImportBulUploadStep}
              />
              <Route exact path="/payout-request" component={PayoutRequest} />
              <Route exact path="/tools" component={Tools} />
              <Route exact path="/offers" component={Offers} />
              <Route
                exact
                path={"/offers/view-offer/:id"}
                component={viewOffer}
              />
              <Route exact path="/notices" component={notices} />

              <Route
                exact
                path={"/notices/view-notice/:id"}
                component={viewNotice}
              />

              <Route exact path={"/partners"} component={Partners} />

              {(isAdmin || canAddEmployee) && (
                <Route exact path="/employees" component={Employees} />
              )}
              {(isAdmin || canAddEmployee) && (
                <Route
                  exact
                  path={"/employees/add-employee"}
                  component={AddEmployee}
                />
              )}
              {(isAdmin || canAddEmployee) && (
                <Route exact path={"/employees/:id"} component={AddEmployee} />
              )}
              {(isAdmin || canAddEmployee) && (
                <Route
                  exact
                  path={"/employees/edit/:id"}
                  component={AddEmployee}
                />
              )}

              {isAdmin && (
                <Route exact path="/designation" component={Designation} />
              )}
              {isAdmin && (
                <Route
                  exact
                  path={"/designation/add-designation"}
                  component={AddDesignation}
                />
              )}
              {isAdmin && (
                <Route
                  exact
                  path={"/designation/edit/:id"}
                  component={AddDesignation}
                />
              )}

              <Route exact path={"/report"} component={Reports} />
              <Route
                exact
                path="/notificationbar"
                component={NotificationBar}
              />
              <Route exact path={"/view-profile"} component={Profile} />
              <Route exact path={"/help"} component={Help} />
              <Redirect to={{ pathname: "/dashboard" }} />
            </Switch>
          </AppLayout>
        ) : (
            <Redirect
              to={{
                pathname: "/sign-in",
              }}
            />
        );
      }}
    </AuthConsumer>
  );
};

export default Layout;
