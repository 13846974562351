import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "NotificationReducer",
  initialState: { pageNo: 1, pageSize: 10,  status: "ALL" },
  reducers: {
    setNotificationPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setNotificationPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setNotificationStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    }

  }
});

// Action creators are generated for each case reducer function
export const { setNotificationPageNo, setNotificationPageSize,setNotificationStatus } = notificationSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default notificationSlice.reducer;