import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import "./FormInput.scss";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { PASSWORD_REGEX } from "shared/constants/AppConstants";

interface IFormInputPassword {
  name: string;
  label?: string;
  classNameForm?: string;
  placeholder?: string;
  isRequired?: boolean;
  requiredLabel?: string;
  isDisabled?: boolean;
  isBorder?: boolean;
  rules?: Rule[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
}

const FormInputPassword = (props: IFormInputPassword) => {
  const {
    name,
    label,
    classNameForm,
    isRequired,
    requiredLabel,
    isDisabled,
    isBorder,
    placeholder,
    rules,
    onChange,
    onBlur,
  } = props;

  return (
    <>
      <Form.Item
        className={`field-bg ${isBorder ? "field-border" : ""} ` + classNameForm}
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: `Please Enter your ${requiredLabel}`,
          },
          ...(rules ?? []),
        ]}
      >
        <Input.Password
          className="custom-input"
          size="middle"
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    </>
  );
};

export default FormInputPassword;
