import React, { useEffect, useState } from "react";
import { Table, notification, Typography, Button } from "antd";
import API_SERVICE from "shared/services/api-service";
import { ColumnsType } from "antd/lib/table";

const { Title } = Typography;

interface IListLeadDetails {
  leadData: any;
  showDecisioning?: any;
  setShowDecisioning?: any;
  setLeadViewType?:any;
}

const ListLeadDetails: React.FC<IListLeadDetails> = (
  props: IListLeadDetails
) => {
  const { leadData, showDecisioning, setShowDecisioning,setLeadViewType } = props;
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    listDecisioning();
  }, []);

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPageNo(current);
    setPageSize(pageSize);
  };

  const listDecisioning = () => {
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false)
      .then(({ data }) => {
        setListDescisioningDetail(data?.payload);
        setTotalCount(data?.payload?.length ?? 0);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      key: "maxLoanAmount",
    },
    {
      title: "Total EMI",
      dataIndex: "totalEmi",
      key: "totalEmi",
    },
    {
      title: "Tenure",
      dataIndex: "tenureInMonths",
      key: "tenureInMonths",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
  ];

  return (
    <>
      <div className="">
        {!showDecisioning && (
          <div className="heading" style={{ marginLeft: "1.1rem" }}>
            <Title level={5} style={{ marginTop: "15px" }}>
              Decisioning Output
            </Title>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Table
            columns={columns}
            dataSource={descisioningDetail}
            size="small"
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              current: pageNo,
              pageSize: pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
        <Button
          type="default"
          className="case-btn"
          style={{
            marginBottom: "15px",
            marginLeft: "15px",
            borderRadius: "5px",
          }}
          size="large"
          onClick={() => setLeadViewType("LeadDetailNewTabs")}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
export default ListLeadDetails;
