import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Drawer,
  Dropdown,
  Layout,
  Space,
  Typography,
  notification,
} from "antd";
import {ReactComponent as IconMenu} from "../../assets/images/menu.svg";
import headerNotificationIcon from "../../assets/images/headerNotificationIcon.svg";
import SideBar from "./SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { AuthConsumer } from "shared/contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { setNotificationCount } from "shared/redux/notificationCount-reducer";
import { setAppTitle } from "shared/services/Utility";
import { ReactComponent as IconUser } from "../../assets/images/user.svg";
import { FaBars } from "react-icons/fa";
import "./AppLayout.scss";
import { AppImages } from "shared/constants/AppImages";
import { DownOutlined } from "@ant-design/icons";
import { setAppColors } from "shared/redux/common-reducer";
import { setTenantInfo } from "shared/redux/tenant-reducer";
import { setPostLenderCase, setPreLenderCase } from "shared/redux/lead-reducer";

const { Content, Sider } = Layout;
const { Title } = Typography;

export default function AppLayout({ children }: any) {

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [collapsedSlider, setCollapsedSider] = useState(false);
  const [showSiderMobile, setShowSiderMobile] = useState(false);
  const [userData, setUserData] = React.useState(null as any);
  const [businessDocumentId, setBusinessDocumentId] = useState("");

  const { appColors } = useSelector((state: RootState) => state.common);
  const { notificationCount } = useSelector(
    (state: RootState) => state.notificationCount
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    }

    setAppTitle();
    getTenantDetails();
    getUnreadNotificationCnt();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getUnreadNotificationCnt = () => {
    API_SERVICE.getUnreadNotificationCnt()
      .then(({ data }) => {
        dispatch(setNotificationCount(data?.payload?.unReadCount));
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getTenantDetails = () => {
    API_SERVICE.getTenantDocumentDetails()
      .then(({ data }) => {

        const payload = {
          ...data?.payload
        };

        let obj = {} as any;
        payload?.tenantColorConfig?.map((item: any) => {
          obj[item.element] = item.hexCode;
        })

        const tmpObj = {
          appPrimaryColor: obj?.primary_color,
          appSecondaryColor: obj?.secondary_color,
          appSecondaryLightColor: obj?.secondary_color ? `${obj?.secondary_color}33` : "",
          appPrimaryLightColor: obj?.primary_color ? `${obj?.primary_color}33` : "",
        }
        dispatch(setAppColors(tmpObj));
        dispatch(setTenantInfo(payload));
        if (payload?.businessProductType === "INSURANCE") {
          dispatch(setPostLenderCase("LOGGED"));
        }

        if (payload?.tenantLogoDocumentDetail?.businessDocumentId) {
          setBusinessDocumentId(payload?.tenantLogoDocumentDetail?.businessDocumentId)
        } else {
          localStorage.setItem("getlogoDetail", "{}");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderHeader = () => {
    if(userData?.hideHeader) return null;

    return (
      <div className={isMobile ? "app-header mview" : "app-header"}>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Button
              type="text"
              size={"middle"}
              onClick={() => setShowSiderMobile(true)}
              icon={<FaBars />}
            />
          ) : (
            <></>
            // <img
            //   src={iconMenu}
            //   alt=""
            //   className={
            //     "sider-menu-icon" + (collapsedSlider ? " collapsed" : "")
            //   }
            //   onClick={() => setCollapsedSider(!collapsedSlider)}
            // />
          )}
          <Title
            level={4}
            style={{
              marginBottom: "0px",
              marginLeft: "15px",
              fontWeight: "normal",
            }}
          >
            {/* {(backScreenTitle?.length ?? 0) > 0 && (
              <>
                <Link className="link" to={backScreenPath ?? ""}>
                  {backScreenTitle}
                </Link>
                &nbsp; &gt; &nbsp;
              </>
            )}
            {screenTitle} */}
          </Title>
        </div>
        <div className="header">
          <Badge count={notificationCount} className="notification-bell">
            <img
              src={headerNotificationIcon}
              style={{ width: "25px", height: "25px" }}
              onClick={() => history.push("/notificationbar")}
            />
          </Badge>
          <Dropdown menu={{ items, onClick }} placement="bottomRight" arrow>
            <Space>
              <IconUser
                className="user-icon"
                height={30}
                width={35}
                style={{ "--appPrimary": appColors?.appPrimaryColor ?? "" } as any} />
              {/* <img
                src={"/image/UserIcon.png"}
                alt={"name"}
                width="30px"
                height="30px"
              /> */}
              {userData?.userDetails?.firstName
                ? userData?.userDetails?.firstName
                : "Welcome"}
              <DownOutlined
                style={{
                  marginRight: "5px",
                  marginBottom: "4px",
                }}
              />
            </Space>
          </Dropdown>
        </div>
      </div>
    );
  };

  const onClick = ({ key }) => {
    if (key === "profile") {
      history.push("/view-profile");
    } else if (key === "logout") {
      localStorage.clear();
      window.location.reload();
    }
  };

  const items = [
    {
      key: "profile",
      label: "My Account",
      icon: <img src={AppImages.userIcon} height={20} />,
    },
    {
      key: "logout",
      label: "Logout",
      icon: (
        <img
          src={AppImages.logoutIcon}
          height={20}
          style={{ paddingLeft: "2px" }}
        />
      ),
    },
  ];

  const renderBody = () => {
    return (
      <>
        <Content>{children}</Content>
      </>
    );
  };
  const customStyles :any = {'--bgColor': appColors?.appPrimaryColor ?? ""}
  return (
    <AuthConsumer>
      {({ isAuth, apiCreds }) => {
        return (
          <>
            <Layout style={{ minHeight: "100vh" }} className={`desktop-layout`}>
              {userData?.hideSidebar ? null : 
              isMobile ? (
                <Drawer
                  footer={false}
                  visible={showSiderMobile}
                  placement="left"
                  closable={false}
                  width={230}
                  className="navbar-box"
                  onClose={() => setShowSiderMobile(false)}
                >
                  <div style={{ width: "220px", marginLeft: "0px" }}>
                    <SideBar key={2} closeNavHandler={setShowSiderMobile} businessDocumentId={businessDocumentId}/>
                  </div>
                </Drawer>
              ) : (
                <Sider collapsed={collapsedSlider}>
                  <>
                    <SideBar
                      key={2}
                      closeNavHandler={setShowSiderMobile}
                      collapsedSlider={collapsedSlider}
                      businessDocumentId={businessDocumentId}
                    />
                   <IconMenu style={customStyles} height={24} width={24}  className="sider-icon-collapse"  onClick={() => setCollapsedSider(!collapsedSlider)} />
                  </>
                </Sider>
              )}

              <Layout>
                {renderHeader()}
                <div className="content-wrapper">
                  <div className="body1">{renderBody()}</div>
                </div>
              </Layout>
            </Layout>
          </>
        );
      }}
    </AuthConsumer>
  );
}
