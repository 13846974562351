import {
  Typography,
  Input,
  Button,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { TextArea } = Input;
const { Title } = Typography;

const LenderLogin: React.FunctionComponent<any> = ({
  assignedLenders,
  lenderLoginHandler,
  loading,
  loginComment
}) => {
  const [comment, setComment] = useState(null as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    setComment(loginComment);
  }, []);

  const setupLender = () => {
    lenderLoginHandler({ comment });
  };

  return (
    <>
      <Title level={5}>Login</Title>
      <div className="assigned-lender-list">
        <Space direction="vertical">
          {assignedLenders?.map((item, i) => {
            return (
              item.status != "UNASSIGNED" && (
                <div className="assigned-list" key={i}>
                  <div className="checkbox-text-div">
                    <span className="checkbox-text">{item.lenderName}</span>
                  </div>
                  {(item.openInput || item.status == "LOGGED") && (
                    <Input
                      className="custom-input"
                      value={item.rmName}
                      placeholder="RM name"
                      disabled
                    />
                  )}
                </div>
              )
            );
          })}
        </Space>
      </div>

      <div className="comment-box">
        <TextArea
          placeholder="Comment"
          showCount
          value={comment}
          maxLength={500}
          className="custom-textarea"
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <Button
        type="primary"
        loading={loading}
        className="flex next-btn dynamic-btn-primary"
        style={{ '--btnColor': appColors?.appPrimaryColor ?? "" } as any}
        onClick={setupLender}
      >
        Submit
      </Button>
    </>
  );
};

export default LenderLogin;
