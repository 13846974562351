import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  Input,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { downloadDocument } from "shared/services/Utility";
import "./index.scss";
import "./doc-category.scss";
import bankList from "./banklist.json";

const { Panel } = Collapse;

interface IDocCategory {
  // documentCategoryList: any;
  // callbackUpdateDoc: any;
  // actionOnDelete: any;
  // actionOnUpload: any;
  // actionAdd: any;
  // actionRemove: any;
  // actionReplaceDoc?: any;
  // actionRenameDoc?: any;
  // onChangeCheckbox?: any;
  // checkedDocuments?: any;
  // setIsConflictClick?: any;
  updateBankDetails?: any;
  doc?: any;
  index?: any;
}

const BankStatementFields: React.FunctionComponent<IDocCategory> = (
  props: IDocCategory
) => {
  const {
    //   documentCategoryList,
    //   callbackUpdateDoc,
    //   actionOnDelete,
    //   actionOnUpload,
    //   actionAdd,
    //   actionRemove,
    //   actionReplaceDoc,
    //   actionRenameDoc,
    //   onChangeCheckbox,
    //   checkedDocuments,
    //   setIsConflictClick,
    updateBankDetails,
    index,
    doc,
  } = props;
  const [backName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [backPassword, setBankPassword] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const getDisableBtn = () => {
    let isDisable: any = false;
    isDisable = !backName && !accountNumber && !backPassword;
    return isDisable;
  };
  const setAccountNumber1 = (val)=> {
    const regex = /^[0-9]*$/;
    if (regex.test(val)) {
      setAccountNumber(val);
    } else {
    }
  }
  useEffect(() => {
    console.log("doc", doc);
    if (doc?.documentDetails) {
      const data = doc?.documentDetails?.additionalInfo;

      updateData(JSON.parse(data));
    }
  }, [doc]);
  const getBankNameFromList = (bankName) => {
    let fullBankName = "";
    bankList &&
      bankList.filter((items) => {
        if (items.value === bankName) {
          fullBankName = items.label;
        }
      });
    return fullBankName;
  };
  console.log(":::doc", doc);
  const updateData = (req) => {
    console.log('req', req);
    
    setBankName(req?.bankName);
    setAccountNumber(req?.accountNumber);
    setBankPassword(req?.filePassword);
    if (!req?.bankName && !req?.accountNumber && !req?.filePassword) {
      setIsEditable(false);  
    } else {
      setIsEditable(true);
    }
  };
  const onClickOnSave = () => {
    const payload = {
      filePassword: backPassword,
      bankName: backName,
      accountNumber: accountNumber,
    };
    const documentBusinessID = doc?.documentDetails?.businessDocumentId;
    updateBankDetails(
      documentBusinessID,
      { additionalInfo: JSON.stringify(payload) },
      updateData
    );
  };

  return (
    <>
      {/* bankStatement details */}
      {isEditable ? (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
          >
            <Col span={1} className="doc-checkbox"></Col>
            <Col
              span={6}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
              }}
            >
              <Tooltip title="Bank Name">
                <span style={{ verticalAlign: 'sub' }}>{getBankNameFromList(backName)}</span>
              </Tooltip>
            </Col>
            <Col
              span={6}
              style={{
                padding: "2px",
              }}
            >
              <Tooltip title="Bank Account Number">
                <span style={{ verticalAlign: 'sub' }}>{accountNumber}</span>
              </Tooltip>
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
              }}
            >
              <Tooltip title="File Password">
                <span style={{ verticalAlign: 'sub' }}>{backPassword}</span>
              </Tooltip>
            </Col>
            <Col
              span={3}
              style={{
                padding: "2px",
              }}
            >
              <Button
                size="large"
                className="button-submit"
                type="primary"
                htmlType="submit"
                onClick={() => setIsEditable(false)}
              >
                Edit
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      ) : (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
          >
            <Col span={1} className="doc-checkbox"></Col>
            <Col
              span={6}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
              }}
            >
              <Tooltip title="Bank Name">
                <Select
                  placeholder="Search Or Select Bank Name"
                  className="custom-select"
                  options={bankList}
                  showSearch
                  value={backName}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    console.log("::e", e);
                    setBankName(e);
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={6}
              style={{
                padding: "2px",
              }}
            >
              <Tooltip title="Bank Account Number">
                <Input
                  className="custom-input"
                  max={100}
                  type="text"
                  min={0}
                  value={accountNumber}
                  onChange={(e) => setAccountNumber1(e?.target?.value)}
                  placeholder="Bank Account No."
                />
              </Tooltip>
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
              }}
            >
              <Tooltip title="File Password">
                <Input
                  className="custom-input"
                  max={100}
                  min={0}
                  value={backPassword}
                  onChange={(e) => setBankPassword(e?.target?.value)}
                  placeholder="Password"
                />
              </Tooltip>
            </Col>
            <Col
              span={3}
              style={{
                padding: "2px",
              }}
            >
              <Button
                size="large"
                className="button-submit"
                // loading={loading}
                type="primary"
                htmlType="submit"
                disabled={getDisableBtn()}
                onClick={onClickOnSave}
              >
                Save
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BankStatementFields;
