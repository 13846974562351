import { Card, Typography } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import "./help.scss";
type Props = {};
const { Title, Paragraph } = Typography;

const PrivacyPolicy: React.FunctionComponent<Props> = (props) => {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = () => {
    API_SERVICE.privacyPolicy().then((data) => {
      if (data?.data?.payload?.length > 0) {
        setPrivacyPolicy(data.data.payload);
      }
    });
  };
  
  return (
    <>
      <Card className="custom-app-card">
        <div style={{ padding: "5px", height: "62vh",overflow:'auto'}}>
          <Title level={5}>PRIVACY POLICY</Title>
          {privacyPolicy.map((data: any) => (
            <Paragraph key={data?.busCommId}>
              <pre style={{wordBreak:"break-word",backgroundColor:"transparent",border:"none"}}>{data.description}</pre>
            </Paragraph>
          ))}
        </div>
      </Card>
    </>
  );
};

export default PrivacyPolicy;
