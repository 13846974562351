import { useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined, RightOutlined } from "@ant-design/icons";
import "./profile.scss";
import {
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "shared/components/FormInput/FormInput";
import {
  PAN_REGEX,
  STRING_ONLY,
} from "shared/components/constants/AppConstants";
import { RootState } from "shared/redux/store";
import FormIcons from "shared/components/FormIcons/FormIcons";
import API_SERVICE from "shared/services/api-service";
import PanVerification from "./pan-verification";
import {
  addDocumentCommon,
  genericDeleteDoc,
  getUserDetails,
} from "shared/services/Utility";
import { getEnumList } from "shared/services/Utility";
import FormSelect from "shared/components/FormSelect/FormSelect";
import AppUpload from "./app-upload";
import DocListItem from "shared/components/DocListItem/DocListItem";
import FormButton from "shared/components/FormButton/FormButton";
import { renderStatusARI } from "shared/components/common";

const { Text } = Typography;
const { Panel } = Collapse;

interface IKycTab {
  kycData: any;
  updateConnector: any;
}

const KYCTab: React.FC<IKycTab> = (props: IKycTab) => {
  const { kycData, updateConnector } = props;
  const userDetails = getUserDetails();
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  const [form] = Form.useForm();
  const [formAddress] = Form.useForm();
  const [showPanVerification, setShowPanVerification] = useState(false);
  const [isPanNameMatch, setIsPanNameMatch] = useState(false);
  const [panDetails, setPanDetails] = useState({}) as any;
  const [isVerified, setIsVerified] = useState(true);
  const [showUploadList, setShowUploadList] = useState(true);
  const [fileData, setfileData] = useState([] as any);
  const [selectDocType, setSelectDocType] = useState("");
  const { appColors } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    form.setFieldsValue({
      panCard: kycData?.pan,
      panName: kycData?.panName,
    });
    setIsVerified(kycData?.panVerified);
    const panNameCondition =
      kycData?.panVerified &&
      form.getFieldValue("panCard") != "" &&
      panDetails?.name == form.getFieldValue("panName");
    setIsPanNameMatch(panNameCondition);
  }, []);

  const handleClickHere = () => {
    setShowPanVerification(true);
  };

  const checkPanDetails = (e: any) => {
    setIsPanNameMatch(false);
    setIsVerified(false);
    e.persist();
    const { value: panNumber } = e.target;
    const params = {
      panNumber: panNumber,
    };
    if (e?.target?.value.length == 10) {
      API_SERVICE.checkPanDetails(params).then(({ data }) => {
        if (data) {
          setPanDetails(data?.payload);
          const result =
            data?.payload?.fullName?.trim() ==
            form.getFieldValue("panName").trim();
          setIsVerified(result);
          setIsPanNameMatch(!result);
        }
      });
    }
  };

  const actionPanContinue = () => {
    console.log("panDetails: ", panDetails);
    form.setFieldValue("panName", panDetails?.fullName);
    setShowPanVerification(false);
    setIsPanNameMatch(false);
    setIsVerified(true);
  };

  const onPanVerification = (value: any) => {
    const tmpKycDetails = {
      ...kycData,
      pan: value?.panCard,
      panName: value?.panName,
    };
    updateConnector({ kycDetails: tmpKycDetails });
  };

  const uploadKycDetails = async (value) => {
    if ((fileData?.file?.name?.length ?? 0) == 0) {
      notification.error({ message: "Please Upload Address Proof" });
      return;
    }

    const documentSetId = userDetails?.documentSetId;
    const response = await addDocumentCommon(
      documentSetId,
      fileData.data,
      fileData.file.name,
      selectDocType,
      ""
    );

    if (response?.payload) {
      setShowUploadList(false);
      setfileData([]);
      formAddress.resetFields();
      const tmpKycAddressProof = {
        ...kycData,
        addressProofDoc: response?.payload,
      };
      updateConnector({ kycDetails: tmpKycAddressProof });
    }
    return;
  };

  const onSelectDocType = (value) => {
    setSelectDocType(value);
  };

  const onDelete = async (doc: any) => {
    const response = await genericDeleteDoc(doc);
    if (response?.payload) {
      const tmpKycAddressProof = {
        ...kycData,
        addressProofDoc: null,
      };
      updateConnector({ kycDetails: tmpKycAddressProof });
    }
    return;
  };

  const iconPrimaryColor = appColors?.appPrimaryColor ?? "";

  return (
    <>
      <Card
        className="lead-details"
        style={{
          height: "calc(250% - 116px)",
          width: "100%",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "60%",
            height: "798px",
            padding: "2px",
          }}
        >
          {renderStatusARI(kycData?.status)}
          <Collapse
            defaultActiveKey={["1"]}
            className="lead-detail-box1"
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <RightOutlined rotate={isActive ? 270 : 90} />
            )}
          >
            <Panel header="Pan Verification" key="1">
              <Form layout="vertical" form={form} onFinish={onPanVerification}>
                <div className="field-bg field-border">
                  <span style={{ float: "right" }}>
                    {isVerified ? (
                      <label style={{ color: "green" }}>
                        <CheckOutlined style={{ marginRight: "5px" }} />
                        Verified
                      </label>
                    ) : (
                      <label style={{ color: "red" }}>
                        <CloseOutlined /> Unverified
                      </label>
                    )}
                  </span>
                  <Form.Item
                    label="PAN"
                    name="panCard"
                    rules={[
                      {
                        required: true,
                        message: "Please enter PAN",
                      },
                      {
                        pattern: PAN_REGEX,
                        message: "Please enter valid PAN",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Enter Your PAN Card"
                      onChange={checkPanDetails}
                    />
                  </Form.Item>
                </div>

                <FormInput
                  label="Name"
                  name="panName"
                  classNameForm="field-bg custom-input"
                  placeholder="Name(auto Check)"
                  isDisabled
                  isBorder
                  rules={[
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid PAN Name",
                    },
                  ]}
                />
                {!isPanNameMatch ? null : (
                  <p style={{ color: "red" }}>
                    PAN name does'nt matched with your profle name.
                    <Text
                      onClick={handleClickHere}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      Click here
                    </Text>
                    to correct your profile name.
                  </p>
                )}
                <FormButton type="primary" label="Save" htmlType="submit"
                  className="dynamic-btn-primary"
                  style={
                    { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                />
              </Form>
            </Panel>
          </Collapse>
          <Collapse
            className="lead-detail-box1"
            style={{ marginTop: "2%" }}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <RightOutlined rotate={isActive ? 270 : 90} />
            )}
          >
            <Panel header="Address Proof" key="2">
              <Form
                form={formAddress}
                layout="vertical"
                onFinish={uploadKycDetails}
              >
                <Form.Item>
                  <AppUpload
                    className="upload-wrapper"
                    acceptType={["image", "pdf", "xls", "xlsx"]}
                    showUploadList={showUploadList}
                    callBack={(value: any) => {
                      setShowUploadList(true);
                      setfileData(value);
                    }}
                  >
                    <FormIcons type="addFileData"/>
                    Upload Address Proof
                  </AppUpload>
                </Form.Item>

                <FormSelect
                  label="Document Type"
                  name="docType"
                  placeholder="Enter document type"
                  options={getEnumList("ADDRESS_TYPE")}
                  onChange={onSelectDocType}
                  isRequired
                  isBorder
                />
                <span style={{ fontSize: "12px" }}>
                  Only One Address Proof Document Requried such as Aadhar Card,
                  Electricity Bill, Water Bill, Society Bill
                </span>

                <FormButton type="primary" label="Save" htmlType="submit"
                  className="dynamic-btn-primary"
                  style={
                    { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                />
              </Form>
              {(kycData?.addressProofDoc?.documentName?.length ?? 0) != 0 && (
                <>
                  <Divider orientation="left" orientationMargin="0">
                    Uploaded Files
                  </Divider>
                  <DocListItem
                    document={kycData?.addressProofDoc}
                    hideDelete
                    callbackDelet={() => onDelete(kycData?.addressProofDoc)}
                  />
                </>
              )}
            </Panel>
          </Collapse>
        </div>
        <Modal
          title="Confirm Your Name"
          centered
          width={400}
          onCancel={() => setShowPanVerification(false)}
          footer={null}
          open={showPanVerification}
        >
          <PanVerification
            panDetails={panDetails}
            contactDetails={kycData}
            actionPanContinue={actionPanContinue}
          />
        </Modal>
      </Card>
    </>
  );
};
export default KYCTab;
