import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    notification,
    Row,
    Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import {ReactComponent as IconFileBorder} from "../../assets/images/file-border.svg";
import { Link, useHistory, useParams } from 'react-router-dom';
import API_SERVICE from 'shared/services/api-service';
import { base64ToArrayBuffer, downloadDocument, saveByteArray } from 'shared/services/Utility';
import './index.scss';
import { AppImages } from "shared/constants/AppImages";
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'shared/redux/store';
import { setIsAppHeaderShow } from 'shared/redux/common-reducer';

const { Title, Text } = Typography;

const ViewNotice: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [leadData, setLeadData] = useState(null as any);
    const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);

    let { id } = useParams() as any;

    if (!id) {
        history.goBack();
    }

    useEffect(() => {

        getLeadById();
        dispatch(setIsAppHeaderShow(false));
    }, []);
    const [noticeDocumentDetails, setNoticeDocumentDetails] = useState(false);
    const [title, setTitle] = useState('');
    const [time, setTime] = useState('');
    const [discrption, setDescrption] = useState('');
    const { appColors } = useSelector((state: RootState) => state.common);

    const [form] = Form.useForm();
    const getLeadById = () => {
        API_SERVICE.getBusinessid(id)
            .then(({ data }) => {
                if (data?.payload) {
                    setLeadData(data.payload);
                    if (data.payload) {
                        setTitle(data.payload.title);
                        setDescrption(data.payload.description);
                        setTime(moment(data.payload.lastUpdatedDate).locale("en").format("MMM, DD, YYYY HH:mm"));

                    }
                    if (data.payload.documentDetails.fileExtn !== "") {
                        setNoticeDocumentDetails(true)
                    }

                    if (data?.payload?.caseDetails?.caseHistoryHolder) {
                        // setLeadHistory(data.payload.caseDetails.caseHistoryHolder)
                    }
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
            });
    };

    // const downloadDocument = (item: any) => {

    //     downloadDocument(item)

    //     API_SERVICE.downloadDocument(
    //         item.businessId,
    //         item.businessDocumentId
    //     )
    //         .then(({ data }) => {
    //             let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
    //             saveByteArray([bytesData], data.payload.documentName);
    //         })
    //         .catch((e: any) => {
    //             notification.error({ message: API_SERVICE.handleErrors(e) });
    //         });
    // };

    const document = (leadData?.documentDetails?.length ?? 0) > 0 ? leadData.documentDetails[0] : {};

    return (
        <>

            {isMobile ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px', paddingBottom: '10px' }}>
                        <Button
                            style={{ border: 'none' }}
                            size="small"
                            onClick={() => history.goBack()}
                            icon={<img src={AppImages.back} />}
                        />
                        <Text style={{ marginTop: '5px' }}>Notice</Text>
                        <div style={{ width: '25px' }} />
                    </div>
                    <Row style={{ "marginTop": "7%" }}>
                        <Col span={24}>
                            <h4>{title}</h4>
                            <p style={{ color: "grey" }}>{time}</p>
                            <p style={{ width: "90vw" }}>{discrption}</p>
                        </Col>
                    </Row>
                    <Divider />
                    <span style={{ fontSize: '12px' }}>
                        <FaFileAlt style={{ color: "#2a3791" }} />&nbsp;
                        {document?.documentName}

                        <Button
                            size='small'
                            onClick={() => downloadDocument(document)}
                            icon={<DownloadOutlined />}
                            className='downloadBtn'
                        >
                            Download
                        </Button>
                    </span>

                </>

            ) : (

                <div className="content-box">
                    <Title level={3}><Link className='link' to={"/notices"} style={{'--color': appColors?.appPrimaryColor ?? ""} as any}>Notices</Link> &nbsp; &gt; &nbsp; Notice Detail</Title>
                    <Row>
                        <Col span={6} style={{ marginTop: '5%' }}>
                            {noticeDocumentDetails &&
                                <>
                                    <div className="doc-detail">
                                        <IconFileBorder onClick={() => downloadDocument(document)} width={40} height={40} style={{"--borderColor": appColors?.appPrimaryColor ?? ""}as any}/>
                                        <span style={{ fontSize: '15px' }}>
                                            {document?.documentName}
                                            <br />
                                            <span className="type" style={{ fontSize: '15px' }}>
                                                {document?.documentType}
                                            </span>
                                        </span>
                                    </div>
                                </>
                            }
                        </Col>

                    </Row>

                    <Row gutter={[16, 16]} style={{ "marginTop": "3%" }}>
                        <Col span={16}>
                            <h4>{title}</h4>
                            <p style={{ color: "grey" }}>{time}</p>
                            <p className=''>{discrption}</p>
                        </Col>
                    </Row>


                </div>
            )}
        </>
    );
};

export default ViewNotice;


