export const SHORT_DATE_FORMAT = 'LL';
export const FORM_DATE_FORMAT = 'DD-MM-YYYY';
export const GST_REGEX = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/;
export const PHONE_NUMBER_REGEX = /^[6789]\d{9}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const PIN_CODE = /^[1-9][0-9]{5}$/;
export const PAN_CARD = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const DOUBLE_REGEX = /^\d{0,2}(\.\d{0,2}){0,1}$/;

export const STRING_ONLY = /^[A-Za-z\\s ]+$/;
export const AADHAR_CARD_NUMBER_REGEX =  /^\d{12,12}/;
export const AADHAR_CARD_NUMBER_REGEX_EDIT =  /^\d{4}\s\d{4}\s\d{4}$/;
export const AADHAR_CARD_NUMBER_REGEX_HIDE =  /^[0-9,*]+$/;
export const CONNECTOR_ID_REGEX  =  /^([CONN]{4})[0-9]{10}$/;
export const MANAGER_ID_REGEX  = /^([US]{2})[0-9]{10}$/;
export const PASSWORD_REGEX  = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/;
export const GREATERTHEN_ZERO_REGEX =/^[1-9][0-9]*$/

export const EXT_TO_MIMES = [
    {name: 'jpg', value: 'image/jpg'},
    {name: 'jpeg', value: 'image/jpeg'},
    {name: 'png', value: 'image/png'},
    {name: 'pdf', value: 'application/pdf'},
];

export const extToMimes = {
    'jpg': 'image/jpg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'pdf': 'application/pdf',
};
export const PAN_REGAX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const PINCODE_REGAX = /^[1-9][0-9]{5}$/;
export const POSITIVE_VALUE_REGEX =/^[0-9][0-9]*$/

