import { Col, Row, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import TermsNConditions from "./termsNConditions";
import PrivacyPolicy from "./privacyPolicy";
import FAQ from "./faq";
import About from "./about";
import "./help.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAppFooterShow,
  setIsAppHeaderShow,
} from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { TabPane } = Tabs;
const { Title } = Typography;

type Props = {};

const Help: React.FunctionComponent<Props> = (props) => {
  const [activeKey, setActiveKey] = useState("1");

  const dispatch = useDispatch();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(true));
  }, []);

  const onTabChange = (key: any) => {
    setActiveKey(key);
  };

  const tabStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      <Title level={3}>Help</Title>
      <Row style={{ marginTop: "5px" }}>
        <Col className="btn-box" span={24} style={tabStyles}>
          <div className="help-tab-box">
            <Tabs
              activeKey={activeKey}
              onChange={onTabChange}
              type="card"
              className="custom-app-tab"
            >
              <TabPane tab="Terms & Conditions" key="1">
                <TermsNConditions />
                {/* <Card className="custom-app-card">{termAndConditions()}</Card> */}
              </TabPane>
              <TabPane tab="Privacy Policy" key="2">
                <PrivacyPolicy />
                {/* <Card className="custom-app-card">{PrivacyPolicy()}</Card> */}
              </TabPane>
              <TabPane tab="FAQ" key="3">
                <FAQ />
                {/* <Card className="custom-app-card">{Faq()}</Card> */}
              </TabPane>
              <TabPane tab="About" key="4">
                <About />
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Help;
