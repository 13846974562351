import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const { Text } = Typography;

export const renderStatusARI = (status) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Text
        style={{
          color: status == "APPROVED" ? "green" : "red",
        }}
      >
        {status == "APPROVED" ? <CheckOutlined /> : <CloseOutlined />}
        {status == "APPROVED"
          ? "Approved"
          : status == "REJECTED"
          ? "Rejected"
          : "In Process"}
      </Text>
    </div>
  );
};
