import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export const payoutSlice = createSlice({
    name: 'payoutReducer',
    initialState: { pageNo: 1, pageSize: 10, searchParam: null, payoutStatus: "DISBURSED" },
    reducers: {
        setPayoutPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setPayoutPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setPayoutSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        },
        setPayoutStatus: (state, action: PayloadAction<any>) => {
            state.payoutStatus = action.payload;
        }
    }
});

export const { setPayoutPageNo, setPayoutPageSize, setPayoutSearchParam, setPayoutStatus } = payoutSlice.actions;
export default payoutSlice.reducer;