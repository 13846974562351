export const API_CONFIG = {
    login: 'free/user/login',
    getOTP: 'free/get/otp?phoneNumber=',
    referralCode: 'free/validate/referral-code?tenantReferralCode=',
    checkStateUser: 'free/check/slate/user/',
    submitReferralCode: 'free/validate/referral-and-srm-ids',
    checkEmail: 'free/get/otp/email/',
    resetPassword: 'free/reset/password',
    verifyOtp: 'free/verify/otp',
    tenantDocDetails: 'tenant/short-details',
    enumValues: 'enum-values?loggedInFrom=',
    enumValuesFree: 'enum-values/free/',
    subConnectorList: 'list/sub-connector/connector/',
    payoutsOfConnectorDisbursedList: 'list/cases/disbursed',
    payoutsOfConnectorPayoutList: 'payout/list',
    createPayoutReq: 'payout/create',
    approvePayoutInfo: 'update/payout/',
    searchCustomers: 'search/customers?searchParam=',
    connectorById: 'get/connector',
    panDetails: 'external/v1/get/pan/verification',
    panDetailsForProfile: 'get/pan-profile/details',
    pinDetails: 'free/get/pin-code-details',
    addLeadBusiness: 'add/business-case',
    addLeadIndividual: 'add/individual-case',
    editLead: '/update/case/',
    leadList: 'list/cases',
    leadListbyFilter: 'filter/connector/case',
    heirarchyUsers: 'list/heirarchy/user/',
    leadListById: 'get/case/',
    updateConnector: 'update/connector',
    fileUploadfree: 'free/fileupload',
    fileUpload: 'fileupload',
    addDocument: "document-upload/add/",
    getDocument: "document-upload/get/",
    addDocumentfree: "document-upload/free/add/documentset/",
    documentList: 'document-upload/documentset/',
    documentListfree: 'document-upload/free/documentset/',
    deleteDoc: 'document-upload/delete/',
    documentHierarchyListfree: "document-upload/free/document-hierarchy/list",
    documentHierarchyList: "document-upload/document-hierarchy/list",
    caseDetailByShareLinkId: "free/shareLink/",
    basicOffersByCaseId: "offers/case/",
    listOffer: 'business-communication/list',
    helpTermsAndConditions: "business-communication/get/free/app_content/",
    helpFaq: "business-communication/get/free/app_content/",
    helpPrivacyPolicy: "business-communication/get/free/app_content/",
    getBusinessid: 'business-communication/get/',
    updateOffers: 'business-communication/update/',
    designation: 'connector/designation',
    verify: 'verify/user',
    updateCase: 'update/case/',
    user: "get/user/",
    statusChange: "update/user/status/",
    document: "document/",
    userPermission: 'fetch/user/',
    listHierarchy: "list/hierarchy/connector",
    userSignUp: 'free/user/connector/signup',
    updatePassword: 'free/verify-and-update/password',
    generateReportByReportType: "connector/report?",
    bulkUploadStart: "bulk/upload",
    bulkUploadMap: "bulk/upload/",
    bulkUploadExecute: "bulk/upload/",
    bulkUploadDelete: "bulk/upload/",
    bulkUploadList: "bulk/upload/list",
    downloadFormate: "bulk/upload/downloadFormat",
    getShareLinkId: "shareLink/case/",
    unreadNotificationCnt: 'notification/get/unread-count',
    notificationList: 'notification/list?',
    updateNotificationStatus: 'notification/update',
    clearAllNotifications: 'notification/clear-all',
    usersByDepartment: 'list/users/by-department',
    connectorLists: 'list/connector',
    usersWithRole: 'list/users?role=ROLE_EMPLOYEE',
    getLeadAdditional: 'get/lead-additional/pan/',
    checkCaptchaRequired:'free/check/re-captcha-is-required',
};
