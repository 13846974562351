import {
    Row,
    Typography,
    Col,
} from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import DisbursedGroup from "./DisbursedGroup";

import "./index.scss";

const { Title } = Typography;

const Disbursed: React.FunctionComponent<any> = ({
    lender,
    lenderIndex,
    onActionSubmit,
    leadData,
}) => {
    const [finalLender, setFinalLander] = useState({} as any);
    const [totalDisbursedAmount, setTotalDisbursedAmount] = useState(0);

    useEffect(() => {
        lender?.disbursalLenderInfoDetails?.map((amount) => {
            amount.lenderAmount.isDisabled = amount.lenderAmount.disbursedAmount > 0;
        });
        setFinalLander(lender);
        calcTotalDisbursedAmount(lender?.disbursalLenderInfoDetails);
    }, [lender]);


    const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
        let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
            Number(item.lenderAmount.disbursedAmount)
        );
        let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
            Number(item.lenderAmount.insuranceAmount)
        );
        const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
        const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
        const total = totalDisbursed + totalInsurance;
        setTotalDisbursedAmount(isNaN(total) ? 0 : total);
    }

    return (
        <div style={{ marginTop: '16px' }}>
            {finalLender?.disbursalLenderInfoDetails?.map((payment, index) => {
                const isHidden = index !== 0;

                return (
                    <>
                        {!isHidden &&
                            <>
                                <Row className="disbursed-lenders-header">
                                    <Col span={6} className="disbursed-heading">
                                        Lender
                                    </Col>
                                    <Col span={8} className="disbursed-heading">
                                        Sanctioned Amount
                                    </Col>
                                    <Col span={8} className="disbursed-heading">
                                        Disbursed Amount
                                    </Col>
                                    <Col span={2}></Col>
                                </Row>
                                <Row className="disbursed-lenders-header">
                                    <Col span={8} className="disbursed-heading">
                                        <Title level={5}>{lender?.lenderName}</Title>
                                    </Col>
                                    <Col span={8} className="disbursed-heading">
                                        <Title level={5}>
                                            {lender?.sanctionedAmount &&
                                                numberWithCommas(lender?.sanctionedAmount)
                                            }
                                        </Title>
                                    </Col>
                                    <Col span={8} className="disbursed-heading">
                                        <Title level={5}>
                                            {totalDisbursedAmount &&
                                                numberWithCommas(totalDisbursedAmount)
                                            }
                                        </Title>
                                    </Col>
                                </Row>
                            </>
                        }

                        <DisbursedGroup
                            lender={finalLender}
                            leadData={leadData}
                            lenderIndex={lenderIndex}
                            payment={payment}
                            index={index}
                            payoutDetailIndex={index}
                            onActionSubmit={(comment) => {
                                onActionSubmit(comment);
                            }}
                        />
                    </>
                );
            })
            }
        </div>
    );
};

export default Disbursed;
