import {
  Row,
  Typography,
  Input,
  Button,
  Space,
  Col,
  Form,
  Collapse,
} from "antd";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { getEnumValue } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Title } = Typography;

const LenderDecision: React.FunctionComponent<any> = ({
  assignedLenders,
  lenderDecisionHandler,
  leadData,
}) => {
  const [selectedLenderData, setSelectedLenderData] = useState([] as any);
  const [dicisionForm] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    let sanctionedAmount = 0;
    const tmpAssignedLenders = assignedLenders.filter(
      (item) =>
        (item.status === "LOGGED" && item?.submissionType != "API") ||
        (item?.submissionType == "API" && item?.allDocumentUploaded) ||
        item.status == "PENDENCY" ||
        item.status === "SANCTIONED" ||
        item.status === "DISBURSED" ||
        item.status === "REJECTED" ||
        item.status === "WITHDRAWN"
    );
    setSelectedLenderData(tmpAssignedLenders);
    const loanAmount = leadData.caseDetails?.loanDetails?.loanAmount || 0;
    dicisionForm.setFieldsValue({
      approvedAmount: sanctionedAmount,
      requestedAmount: loanAmount,
      comment:
        leadData?.caseDetails?.caseHistoryHolder?.lenderDecisionState?.at(0)
          ?.comments,
    });
  }, []);

  const lenderSanctionedHandler = (value: any) => {
    const data = {
      comment: value?.comment ?? "",
    };
    lenderDecisionHandler(data);
  };

  const getHeader = (item) => (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>{item.lenderName}</h4>
      </div>
      <span style={{ fontSize: "11px" }}>{item?.status}</span>
    </>
  );

  return (
    <div>
      <Title level={5}>Lender Decision</Title>
      <Form form={dicisionForm} onFinish={lenderSanctionedHandler}>
        <Space className="lender-space" direction="vertical">
          {selectedLenderData?.map((item: any, i: any) => {
            console.log("item", item);
            return (
              <div key={i}>
                <Collapse
                  className="payout-collapse"
                  collapsible="header"
                  defaultActiveKey={[i === 0 ? "1" : ""]}
                  expandIconPosition="end"
                >
                  <Panel header={getHeader(item)} key="1">
                    <div
                      className="assigned-lender-list"
                      style={{ paddingInline: "16px" }}
                    >
                      {(item?.status === "SANCTIONED" ||
                        item?.status === "DISBURSED") && (
                        <Row>
                          <Col span={12} style={{ textAlign: "center" }}>
                            <label>Interest rate %</label> <br />
                            {item?.interestRate}
                          </Col>
                          <Col span={12} style={{ textAlign: "center" }}>
                            {" "}
                            <label>SanctionAmount</label> <br />
                            {item?.sanctionedAmount}/-
                          </Col>
                        </Row>
                      )}

                      {item?.status === "REJECTED" && (
                        <>
                          <label>Reason</label>
                          <br />{" "}
                          {getEnumValue(
                            "REJECTION_REASON",
                            item?.rejectedReason
                          )}
                        </>
                      )}

                      {item?.status === "WITHDRAWN" && (
                        <>
                          <label>Withdrawn Reason</label>
                          <br />
                          {getEnumValue(
                            "WITHDRAWN_REASON",
                            item?.comments ?? "--"
                          )}
                        </>
                      )}
                      {item?.status === "PENDENCY" && (
                        <>
                          <label>Pendency Reason</label>
                          <br />
                          {getEnumValue(
                            "PENDENCY_REASON",
                            item?.comments ?? "--"
                          )}
                        </>
                      )}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            );
          })}
        </Space>
        <Col span={24}>
          <Form.Item className="comment-box" name="comment">
            <TextArea
              name="comment"
              placeholder="Comment"
              showCount
              maxLength={500}
              className="custom-textarea"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Button type="primary" htmlType="submit" className="next-btn dynamic-btn-primary"
          style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}>
            Submit
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default LenderDecision;
