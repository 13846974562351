import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { notification, Modal, Button } from "antd";
import parameters from "shared/parameters";
import ProductSteps from "./ProductSteps";
import AppLoading from "shared/components/AppLoading/AppLoading";


const MobileRunTime = () => {
  const [openFlowablePath, setOpenFlowablePath] = useState(false);
  const [leadData, setLeadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getTaskData, setGetTaskData] = useState({} as any);
  const [json, setJson] = useState({} as any);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const caseId = params.get("caseId");
  const stepKey = params.get("stepKey");
  const clientType = params.get("clientType");
  const [isConnectorEditform, setIsConnectorEditform] = useState(false)
  const [submitPayload, setSubmitPayload] = useState({} as any);
  const [submitCalling, setSubmitCalling] = useState(false);
  const [isStepReadOnly, setIsStepReadOnly] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [conflictMessage, setConflictMessage] = useState("");
  
  

  useEffect(() => {
    getCaseDetails(caseId);
  }, []);

  const callbackForMobile = (actionType: string) => {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ actionType: actionType })
      );
  };

  useEffect(() => {
    console.log("submitCalling: ", submitCalling);
    if (submitCalling) {
      console.log("loading: ", loading);
      setLoading(true);
      callbackForMobile("LOADING_TRUE");
      connectorEditFormStatus('submit')    
    }
  }, [submitCalling]);

  const submitTaskAPI=() =>{
    API_SERVICE.productSubmitTask(submitPayload)
    .then(() => {
      callbackForMobile("SUBMIT_TASK");
    })
    .catch((e: any) => {
      if (e?.response?.status == 409) {
        callbackForMobile("REFRESH_PAGE");
      } else {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      }
    }) .finally(() => {
      callbackForMobile("LOADING_FALSE");
      setLoading(false);
    });
  }

  const connectorEditFormStatus =(type:string)=>{
    API_SERVICE.getConnectorEditStatus(caseId)
    .then(({ data }) => {
      if (data?.payload) {
        setIsConnectorEditform(data?.payload?.canConnectorEditForm)
      }
      if (type == 'submit')
      {
     
      if (!data?.payload?.canConnectorEditForm) {
        setShowConflict(true);
        setConflictMessage(data?.payload?.message);
        callbackForMobile("LOADING_FALSE");
        setLoading(false);
  
      } 
      else{
        submitTaskAPI();
      }
    }
   })
   .catch((e: any) => {
      callbackForMobile("LOADING_FALSE");
      setLoading(false);
      notification.error({ message: API_SERVICE?.handleErrors(e) });
      setShowConflict(false)
      ;
    }
    //  setIsLoading(false);
  )
  }

  const getCaseDetails = (caseId: any) => {
    setLoading(true);
    API_SERVICE.getLeadsById(caseId)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data?.payload);
         // setIsConnectorEditForm(data?.payload?.canConnectorEditForm)
          handleGetTask(
            {
              processInstanceId: data?.payload?.processInstanceId,
              clientType: clientType,
            },
            data?.payload
          );
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleGetTask = (payload: any, leadDataTmp: any = leadData) => {
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        setGetTaskData(data?.payload ?? {});
        fetchProductWorkflowById(leadDataTmp);
        setIsStepReadOnly(
          stepKey !== data?.payload?.productFormMetadata?.stepKey ||
          data?.payload?.taskDefinitionKey == "vas_user_task"
        );
        connectorEditFormStatus('gettask')
      })
      .catch((e: any) => {});
  };

  const fetchProductWorkflowById = (leadData: any) => {
    API_SERVICE.fetchProductWorkflowById(leadData?.masterProductId)
      .then(({ data }) => {
        const stage = data?.payload?.productWorkflowConfig?.stages?.find(
          (stage: any) => stage?.key == "prospectStage"
        );
        const step = stage?.steps?.find((step: any) => step?.key == stepKey);
        setJson(step?.form?.definition ?? {});
        setOpenFlowablePath(true);
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitTask = (payload: any) => {
    setSubmitPayload(payload);
    setSubmitCalling(true);
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    try {
      str = str?.replaceAll(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replaceAll(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replaceAll(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    } catch (error) {
      str = str?.replace(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replace(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replace(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    }
    return JSON.parse(str);
  };
  const renderConfictMessage = () => (
    <Modal
      centered
      width={400}
      // onCancel={() => setIsFilterVisible(false)}
      closable={false}
      footer={null}
      visible={showConflict}
    >
      <div style={{ padding: "15px", textAlign: "center" }}>
        <p style={{ fontSize: "18px" }}>{conflictMessage}</p>
        <Button
          type="primary"
          onClick={() =>
            setShowConflict(false)
          }
          style={{
            width: "120px",
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );


  return (
    <div style={{ padding: "15px", minHeight: "150px" }}>
      {openFlowablePath && (
        <ProductSteps
          
          getTaskData={getTaskData}
          submitTask={submitTask}
          leadData={leadData}
          getJSONSchema={getJSONSchema}
          formJsonSchema={JSON.stringify(json)}
          isStepReadOnly={isStepReadOnly}
          currentSteps={stepKey}
          getNextTask={handleGetTask}
          isConnectorEditform={isConnectorEditform}
          setGetTaskData={setGetTaskData}
        />
      )}
      {loading && <AppLoading />}
      {showConflict && renderConfictMessage()}
    </div>
  );
};
export default MobileRunTime;
