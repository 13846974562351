import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const employeesSlice = createSlice({
  name: "EmployeesReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setEmployeesPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setEmployeesPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setEmployeesSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEmployeesPageNo, setEmployeesPageSize, setEmployeesSearchParam } = employeesSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default employeesSlice.reducer;