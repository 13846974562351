import { Card, Col, Divider, Form, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FormButton from "shared/components/FormButton/FormButton";
import FormDOB from "shared/components/FormDOB/FormDOB";
import FormInput from "shared/components/FormInput/FormInput";
import RadioButton from "shared/components/RadioButton/RadioButton";
import { NUMBER_REGEX } from "shared/constants/AppConstants";
import { RootState } from "shared/redux/store";
import { getEnumList, getGenderKey } from "shared/services/Utility";

interface IEditProfile {
  setOpenEditProfileDetail: any;
  updateConnector: any;
  basicDetails: any;
}

const EditProfile: React.FC<IEditProfile> = (props: IEditProfile) => {
  const { setOpenEditProfileDetail, updateConnector, basicDetails } =
    props as any;
  const [form] = Form.useForm();
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  console.log(basicDetails);

  useEffect(() => {
    form.setFieldsValue({
      gender: getGenderKey(basicDetails?.gender),
      dateOfBirth:
        basicDetails?.dateOfBirth && moment(basicDetails?.dateOfBirth),
      pinCode: basicDetails?.address?.pinCode,
      address: basicDetails?.address?.address,
      city: basicDetails?.address?.city,
      state: basicDetails?.address?.state,
    });
  }, []);

  const onEditDetail = (value: any) => {
    const dateOfBirth = moment(value?.dateOfBirth).isValid() ? moment(value?.dateOfBirth).format("YYYY-MM-DD") : ""
    const tmpBasicDetails = {
      ...basicDetails,
      address: {
        address: value?.address,
        city: value?.city,
        pinCode: value?.pinCode,
        state: value?.state,
      },
      dateOfBirth: dateOfBirth,
      gender: value?.gender,
    };

    updateConnector({ basicDetails: tmpBasicDetails });
  };

  const onOpenProfile = () => {
    setOpenEditProfileDetail(false);
  };
 
  return (
    <>
      <Card
        className="lead-details"
        style={{ height: "840px", width: "100%", marginTop: "5px" }}
      >
        <Card
          style={{
            width: isMobile ? "100%" : "65%",
            height: "40%",
            padding: "2px",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            style={{ padding: "2%" }}
            onFinish={onEditDetail}
          >
            <h1 style={{ fontSize: "25px" }}> Edit Details</h1>
            <Divider />

            <RadioButton
              label="Select Gender"
              name="gender"
              radioList={getEnumList("GENDER")}
              defaultValue={basicDetails?.gender}
              className="custom-radio profession-btns"
              style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            />
            <FormDOB
              form={form}
              name="dateOfBirth"
              label="Date Of Birth"
              placeholder="Date of Birth"
              isBorder
            />
            <FormInput
              name="pinCode"
              label="Pin Code"
              placeholder="Enter Your Pincode"
              isBorder
              rules={[
                {
                  pattern: NUMBER_REGEX,
                  message: "Please enter valid Pincode",
                },
              ]}
            />
            <FormInput
              name="address"
              label="Address"
              placeholder="Enter Your Address"
              isBorder
            />
            <FormInput
              name="city"
              label="City"
              placeholder="Enter Your City"
              isBorder
            />
            <FormInput
              name="state"
              label="State"
              placeholder="Enter Your State"
              isBorder
            />
            <Row gutter={10}>
              <Col span={12}>
                <FormButton
                  label="Cancel"
                  onclick={onOpenProfile}
                  className="dynamic-btn-default"
                  style={
                    { "--borderColor": appColors?.appSecondaryColor ?? "" } as any
                  }
                />
              </Col>
              <Col span={12}>
                <FormButton
                  htmlType="submit"
                  type="primary" label="Save"
                  className="dynamic-btn-primary"
                  style={
                    { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                  } />
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </>
  );
};

export default EditProfile;
