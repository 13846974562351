import { Popconfirm, Space } from "antd";
import FormIcons from "../FormIcons/FormIcons";
import {ReactComponent as FileBorder} from "../../../assets/images/file-border.svg";
import {
  downloadDocument,
  downloadDocumentWith,
  genericDeleteDoc,
  getEnumValue,
} from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IDocListItem {
  document: any;
  callbackDelet: any;
  hideDelete?: boolean;
}

const DocListItem = (props: IDocListItem) => {
  const { document, callbackDelet, hideDelete } = props;
  const { appColors }: any = useSelector((state: RootState) => state.common);
  
  const onDelete = async () => {
    const result = await genericDeleteDoc(document);
    callbackDelet(result?.payload);
  };

  const iconPrimaryColor = appColors?.appPrimaryColor ?? "";
  const iconSecondaryColor = appColors?.appSecondaryColor ?? "#C71C8E";

  return (
    <div style={{ display: "flex", gap: "8px", marginBottom: "12px" }}>
       <FileBorder  style={{'--fileIconColor': iconPrimaryColor ?? ""} as any} className="icon-border"/>
      <span style={{ width: "100%" }}>
        {document?.documentName}
        <br />
        <span className="type">
          {getEnumValue(
            "DOCUMENT_LIST",
            document?.documentSubType ?? document?.documentType
          )}
        </span>
      </span>
      <Space size={8}>
        <span
          className="remove-border"
          onClick={() => downloadDocument(document)}
        >
          <FormIcons type="download" color={iconSecondaryColor} isDynamicIcon/>
        </span>
        {!hideDelete && (
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to delete this document?"
            onConfirm={() => onDelete()}
            okText="Yes"
            cancelText="No"
            className="remove"
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
          >
            <span className="remove">
              <FormIcons type="delete" />
            </span>
          </Popconfirm>
        )}
      </Space>
    </div>
  );
};

export default DocListItem;
