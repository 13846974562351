import { Checkbox, Form, Input, Space, Typography } from "antd";
import { Rule } from "antd/es/form";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface IFormCheckbox {
  name: string;
  text?: string;
  textElement?: JSX.Element;
  isRequired?: boolean;
  msgRequired?: string;
  onChange?: ((e: CheckboxChangeEvent) => void) | undefined;
}

const FormCheckbox = (props: IFormCheckbox) => {
  const { name, text, textElement, isRequired, msgRequired, onChange } = props;

  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value || !isRequired
              ? Promise.resolve()
              : Promise.reject(new Error(` `)),
        },
      ]}
    >
      <Checkbox onChange={onChange}>{textElement ?? text}</Checkbox>
    </Form.Item>
  );
};

export default FormCheckbox;
