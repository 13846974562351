import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { downloadDocument } from "shared/services/Utility";
import iconUpload from "../../../assets/images/upload.svg";
import iconEdit from "../../../assets/images/editIcon.svg";
import iconFileEdit from "../../../assets/images/file_edit.svg";
import iconFileEditDisable from "../../../assets/images/file_edit_disable.svg";
import iconPlus from "../../../assets/images/add_round.svg";
import iconPlusDisable from "../../../assets/images/add_round_disable.svg";
import iconMinus from "../../../../assets/images/minus_round.svg";
import iconDelete from "../../../assets/images/delete.svg";
import iconDeleteDisable from "../../../assets/images/delete_disable.svg";
import iconDownload from "../../../assets/images//download.svg";
import iconApproveFill from "../../../assets/images/approve_fill.svg";
import iconError from "../../../assets/images/error.svg";
import iconWarning from "../../../../assets/images/warning.svg";
import BankStatementFields from "./BankStatementFields";
import "./index.scss";
import "./doc-category.scss";
import ErrorPopup from './ErrorPopup';
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;

interface IDocCategory {
  documentCategoryList: any;
  callbackUpdateDoc: any;
  actionOnDelete: any;
  actionOnUpload: any;
  actionAdd: any;
  actionRemove: any;
  actionReplaceDoc?: any;
  actionRenameDoc?: any;
  onChangeCheckbox?: any;
  checkedDocuments?: any;
  setIsConflictClick?: any;
  updateBankDetails?: any;
  selectedLander?: any
  indexCat?: any;
  isFlowable?: any
}

const DocCategory: React.FunctionComponent<IDocCategory> = (
  props: IDocCategory
) => {
  const {
    documentCategoryList,
    callbackUpdateDoc,
    actionOnDelete,
    actionOnUpload,
    actionAdd,
    actionRemove,
    actionReplaceDoc,
    actionRenameDoc,
    onChangeCheckbox,
    checkedDocuments,
    setIsConflictClick,
    updateBankDetails,
    selectedLander,
    indexCat,
    isFlowable
  } = props;
  const [backName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [backPassword, setBankPassword] = useState('');
  const [isShowErrorModel, setShowErrorModel] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const { appColors } = useSelector((state: RootState) => state.common);

  const renderButton = (icon, onClick, tooltip, disabled) => {
    return (
      <Tooltip title={tooltip}>
        <Button
          type="text"
          onClick={onClick}
          disabled={disabled}
          icon={<img src={icon} width="20px" height="20px" alt="" />}
        />
      </Tooltip>
    );
  };

  const renderActions = (doc: any, index: any) => {
    const isDocUploaded = doc?.lenderDocumentStatus == "UPLOADED";
    return (
      <div className="action-btns" style={{ display: "flex", justifyContent: "center", backgroundColor: 'transparent' }}>
        {doc?.lenderDocumentStatus == "FAILED" && doc?.error && (
          <Tooltip title={doc?.errorMessage}>
            <img
              src={iconError}
              width="20px"
              height="20px"
              alt=""
              onClick={() => onShowErrorPopup(doc)}
              style={{ marginTop: "8px" }}
            />
          </Tooltip>
        )}

        {doc?.documentDetails && doc?.maxSize <= doc?.documentDetails?.fileSize && (
          <Tooltip
            title={`The document size is not valid, Document size must be less then ${doc?.maxSize} MB `}
          >
            <Button
              type="text"
              icon={
                <img
                  src={iconError}
                  width="20px"
                  height="20px"
                  alt=""
                />
              }
            />
          </Tooltip>
        )}

        {isDocUploaded && (
          <Tooltip title={"Document uploaded successfully"}>
            <img
              src={iconApproveFill}
              width="20px"
              height="20px"
              alt=""
              style={{ marginTop: "8px" }}
            />
          </Tooltip>
        )}

        {renderButton(
          isDocUploaded ? iconFileEditDisable : iconFileEdit,
          () => {
            setIsConflictClick(false);
            actionReplaceDoc(doc);
          },
          "Upload a new document to replace the document shown here",
          isDocUploaded
        )}

        {!doc?.documentDetails?.documentName &&
          renderButton(
            iconUpload,
            () => actionOnUpload(doc),
            "Upload document",
            false
          )}

        {doc?.documentDetails &&
          renderButton(
            iconDownload,
            () => downloadDocument(doc.documentDetails),
            "Download the document",
            false
          )}

        {(doc?.documentDetails != null ||
          (doc?.isUpdatable && doc?.isRemovable)) && (
          <Popconfirm
            placement="topRight"
            title={
              doc?.documentDetails
                ? "Sure to delete document?"
                : "Sure to delete record?"
            }
            onConfirm={(event) => {
              const types = doc?.documentType?.split("~");
              const position = types?.at(-1) ?? 0;
              if (
                (doc?.isUpdatable &&
                  doc?.isRemovable &&
                  position > 0 &&
                  !doc?.documentDetails) ||
                !doc?.documentDetails?.businessId
              ) {
                actionRemove(doc);
              } else if (
                (!doc?.isUpdatable &&
                doc?.isRemovable &&
                position > 0) ||
                (doc?.lenderDocumentValues.includes("~") && position > 0)
              ) {
                actionOnDelete(doc, true);
                onChangeCheckbox(null, doc, true);
              } else {
                actionOnDelete(doc);
                onChangeCheckbox(null, doc, true);
              }
            }}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
            disabled={isDocUploaded}
          >
            {renderButton(
              isDocUploaded ? iconDeleteDisable : iconDelete,
              () => {},
              "Delete the document",
              isDocUploaded
            )}
          </Popconfirm>
        )}

        {doc?.isUpdatable && (
          <>
            {renderButton(
              doc?.multiPageDisable ? iconPlusDisable : iconPlus,
              () => {
                if (doc?.documentDetails) {
                  actionAdd(doc, index);
                } else {
                  notification.error({ message: "Please Upload Document." });
                }
              },
              doc?.multiPageDisable ? `Only one document allowed for ${selectedLander?.lenderName}` : "Add another file for the same document",
              doc?.multiPageDisable ?? false
            )}
            {/* {doc?.isRemovable && (
              <Popconfirm
                title="Sure to remove?"
                onConfirm={(event) => actionRemove(doc)}
              >
                {renderButton(iconMinus, () => {}, "Remove document", false)}
              </Popconfirm>
            )} */}
          </>
        )}
      </div>
    );
  };

  const renderItem = (doc: any, index: any) => {
    const docType = doc?.documentOrgType ?? doc?.documentType;
    const docName = doc?.lenderDocumentLabel ?? docType?.split("~").at(-1);
    const fileName = doc?.documentDetails?.fileName ? `${doc?.documentDetails?.fileName}.${doc?.documentDetails?.fileExtn}` : '';
    const isDocUploaded = doc?.lenderDocumentStatus == "UPLOADED";
    console.log(
      "doc?.documentDetails?.fileSize",
      doc?.documentDetails?.fileSize
    );
    const disableCheckbox = (doc) => {
      if (!doc?.documentDetails && checkedDocuments?.includes(
        doc?.identifier
      )) {
        onChangeCheckbox(null, doc);
      }
      return doc?.documentDetails == null || isDocUploaded
    }
    return (
      <>
        <Row
          key={`${doc?.documentType}_${index}`}
          className={(doc?.lenderDocumentStatus == "FAILED" || doc?.documentDetails && doc?.maxSize <= doc?.documentDetails?.fileSize) ? "error" : ""}
        >
          <Col span={2} className="doc-checkbox">
            <span style={{ color: "red" }}>
              {doc?.require ? "* " :  <>&nbsp;&nbsp;&nbsp;</> }
              <Checkbox
                checked={
                  !isDocUploaded &&
                  checkedDocuments?.includes(
                    doc?.identifier
                  )
                }
                disabled={disableCheckbox(doc)}
                onChange={(value) => {
                  console.log("value: ", value?.target?.checked);
                  onChangeCheckbox(value, doc);
                }}
              />
            </span>
          </Col>
          <Col
            span={6}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            {(doc?.conflict && !doc?.documentDetails) ? (
              <>
                {doc?.conflict && !doc?.documentDetails && (
                  <Tooltip
                    title={
                      "There are more than one documents for this category. Please select the correct document."
                    }
                  >
                    <Button
                      type="text"
                      onClick={() => {
                        setIsConflictClick(true);
                        actionReplaceDoc(doc);
                      }}
                      icon={
                        <img
                          src={iconError}
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      }
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              <div className="action-btns">
                <span style={{ lineBreak: 'anywhere' }}>{fileName}</span>
                {(fileName?.length ?? 0) > 0 && (
                  <Tooltip title={"Rename the document"}>
                    <Button
                      type="text"
                      onClick={() => actionRenameDoc(doc)}
                      icon={
                        <img src={iconEdit} width="20px" height="20px" alt="" />
                      }
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </Col>
          <Col
            span={5}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            <Tooltip title={docName}>
              <span>{docName}</span>
            </Tooltip>
          </Col>
          <Col span={3} style={{display:'flex', justifyContent:'center'}}>
            <Tooltip title={doc?.format ? doc?.format?.join("/") : "-"}>
              <span>{doc?.format ? doc?.format?.at(0) + ", ..." : "-"}</span>
            </Tooltip>
          </Col>
          <Col span={2}>{doc?.maxSize ? doc?.maxSize + " mb" : "-"}</Col>
          <Col span={6}>{renderActions(doc, index)}</Col>
        </Row>
        {console.log('::selectedLander', selectedLander)}
        {/* bankStatement details */}
        {doc?.documentType.includes('BANK_STATEMENT') && selectedLander?.lenderName?.toLowerCase().includes('lending kart') && doc?.documentDetails &&(
        <BankStatementFields doc={doc} index={index} updateBankDetails={updateBankDetails}/>
        )}
        {/* bankStatement details for display */}
      </>
    );
  };

  const getHeader = () => {
    const reqSign = (
      <span style={{ color: "red" }}>
        {documentCategoryList?.categoryRequire ? "* " : "  "}
      </span>
    );

    const tmpTotalM = documentCategoryList?.mandatory === 2 ? 1 : 0
    const totalM = documentCategoryList?.totalDocs == 0 ? tmpTotalM : documentCategoryList?.totalDocs

    const uploade = documentCategoryList?.uploadedDocs
    const tmpUploadM = documentCategoryList?.mandatory === 2 ? (uploade == 0 ? 0 : 1) : 0
    const uploadM = documentCategoryList?.mandatoryCount == 0 ? tmpUploadM : documentCategoryList?.mandatoryCount

    return (
      <>
        {reqSign}
        {`${documentCategoryList?.category} ${
          documentCategoryList?.mandatory === 2
            ? " (Select one or many)"
            : documentCategoryList?.mandatory === 0
            ? "(optional)"
            : `(*required)`
        }`}
        {<span className="doc-category-tags">Mandatory: {uploadM}/{totalM}</span>}
        {<span className="doc-category-tags">Uploaded: {uploade}</span>}
      </>
    );
  };
  const onShowErrorPopup = (doc) => {
    console.log('::doc?.error', doc?.error);
    setErrorMsg(doc?.error);
    setShowErrorModel(true);
  }

  return (
    <>
    {<ErrorPopup
        setShowErrorModel={setShowErrorModel}
        errorMsg={errorMsg}
        isShowErrorModel={isShowErrorModel}
    />}
      {console.log("documentCategoryList: ", documentCategoryList)}
      <Collapse
        className="doc-collapse"
        expandIconPosition="end"
        key={`${documentCategoryList?.category}`}
      >
        <Panel header={getHeader()} key={"1"}>
          {documentCategoryList?.docs?.map((item, index) => {
            return renderItem(item, index);
          })}
        </Panel>
      </Collapse>
    </>
  );
};

export default DocCategory;
