import React, { useEffect, useState } from "react";
import { Menu, MenuProps, notification } from "antd";
import { ReactComponent as Dashboard } from "../../../assets/images/sidebar/Dashboard.svg";
import { ReactComponent as Lead } from "../../../assets/images/sidebar/Lead.svg";
import { ReactComponent as Offers } from "../../../assets/images/sidebar/offers.svg";
import { ReactComponent as Notices } from "../../../assets/images/sidebar/Notices.svg";
import { ReactComponent as IconHelp } from "../../../assets/images/sidebar/Help.svg";
import { ReactComponent as Employee } from "../../../assets/images/sidebar/Employee.svg";
import { ReactComponent as Designation } from "../../../assets/images/Designation.svg";
import { ReactComponent as Reports } from "../../../assets/images/sidebar/Reports.svg";
import { ReactComponent as Payouts } from "../../../assets/images/sidebar/Payouts.svg";
import { useDispatch, useSelector } from "react-redux";
import API_SERVICE from "shared/services/api-service";
import { getBasicDetails } from "shared/services/Utility";
import {
  DASHBOARD_ROUTE,
  DESIGNATION,
  EMPLOYEES,
  HELP,
  LEAD,
  NOTICES,
  OFFERS,
  PAYOUT_REQUEST,
  REPORT,
} from "shared/constants/RouteConstants";
import { useHistory } from "react-router-dom";
import "./SideBar.scss";
import { setAppColors } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

interface IDesktopNavbar {
  closeNavHandler?: any;
  collapsedSlider?: boolean;
  businessDocumentId: string;
}

type MenuItem = Required<MenuProps>["items"][number];

const basicDetails = getBasicDetails();
const isAdmin = (basicDetails?.connectorRole ?? "") == "CONNECTOR_ADMIN";
const canAddEmployee = basicDetails?.canAddEmployee;
const isdsaBusinessid = (basicDetails?.dsaBusinessId?.length ?? 0) > 0;

export default function SideBar(props: IDesktopNavbar) {
  const { closeNavHandler, collapsedSlider, businessDocumentId } = props;

  const [selectedKeys, setSelectedKeys] = useState(DASHBOARD_ROUTE);
  const [documentData, setDocumentData] = useState(null as any);
  const [docType, setDocType] = useState(null as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { appColors } = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);

  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    setSelectedKeys(path);
  }, [window.location.href]);

  useEffect(() => {
    if((businessDocumentId?.length ?? 0) > 0){ 
      getDocUpload(businessDocumentId);
    }
  }, [businessDocumentId]);

  const getDocUpload = (id: any) => {
    API_SERVICE.getDocument(id)
      .then(({ data }) => {
        setDocumentData(data?.payload?.documentDetails);
        setDocType(data?.payload?.fileExtn);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return <Icon height={18} width={18} className={strokeFill ? "stroke-fill" : ""} />;
  };

  const handelOnClick = (path: string) => {
    closeNavHandler && closeNavHandler();
    history.push(path);
  };

  const dashboard = {
    key: DASHBOARD_ROUTE,
    label: "Home",
    icon: setIcon(Dashboard),
    onClick: () => handelOnClick(DASHBOARD_ROUTE),
  };

  const lead = {
    key: LEAD,
    label: "Lead",
    icon: setIcon(Lead),
    style: isdsaBusinessid ? {} : { display: "none" },
    onClick: () => handelOnClick(LEAD),
  };

  const payouts = {
    key: PAYOUT_REQUEST,
    label: "Payouts",
    icon: setIcon(Payouts, true),
    style: basicDetails?.connectorAdditionalInfo?.canAddBankInfo && tenantInfo?.businessProductType !== "INSURANCE"
      ? {}
      : { display: "none" },
    onClick: () => handelOnClick(PAYOUT_REQUEST),
  };

  const employeeManagement = {
    key: EMPLOYEES,
    label: "Employee Management",
    icon: setIcon(Employee),
    style:
      isdsaBusinessid && (isAdmin || canAddEmployee) ? {} : { display: "none" },
    onClick: () => handelOnClick(EMPLOYEES),
  };

  const designation = {
    key: DESIGNATION,
    label: "Designation",
    icon: setIcon(Designation),
    style: isAdmin && isdsaBusinessid ? {} : { display: "none" },
    onClick: () => handelOnClick(DESIGNATION),
  };

  const offers = {
    key: OFFERS,
    label: "Offers",
    icon: setIcon(Offers),
    style: isdsaBusinessid ? {} : { display: "none" },
    onClick: () => handelOnClick(OFFERS),
  };

  const notices = {
    key: NOTICES,
    label: "Notices",
    icon: setIcon(Notices, true),
    style: isdsaBusinessid ? {} : { display: "none" },
    onClick: () => handelOnClick(NOTICES),
  };

  const report = {
    key: REPORT,
    label: "Reports",
    // icon: setIcon(iconReport),
    icon: setIcon(Reports, true),
    style: isdsaBusinessid ? {} : { display: "none" },
    onClick: () => handelOnClick(REPORT),
  };

  const Help = {
    key: HELP,
    label: "Help",
    icon: setIcon(IconHelp),
    onClick: () => handelOnClick(HELP),
  };

  const items: MenuItem[] = [
    dashboard,
    lead,
    payouts,
    employeeManagement,
    designation,
    offers,
    notices,
    report,
    Help,
  ];

  const setTitles = () => {
    return (
      <Menu
        className="sidebar"
        style={{ background: "#fbfcfd" }}
        theme="light"
        selectedKeys={[selectedKeys]}
        mode="inline"
        items={items}
      />
    );
  };
  const customStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };

  return (
    <>
      {documentData ? (
        <div className="logo-sidebar">
          <img
            src={"data:" + docType + ";base64," + documentData}
            alt=""
            style={{ objectFit: "contain" }}
          />
        </div>
      ) : (
        <p style={{ color: "transparent", height: "50px" }}></p>
      )}

      <div className="nav-panel" style={{'--bgColor': appColors?.appPrimaryLightColor ?? '' ,'--iconColor': appColors?.appPrimaryColor ?? ''}as any}>{setTitles()}</div>
    </>
  );
}
