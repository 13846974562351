import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";

interface IBankStatement {
  setIsFinBox: any;
  leadData: any;
  submitTask: any;
  getTaskData: any;
}

const BankStatement: React.FC<IBankStatement> = (props: IBankStatement) => {
  const { setIsFinBox, leadData, submitTask, getTaskData } = props;
  const [finBoxURL, setFinBoxURL] = useState("");

  useEffect(() => {
    getFinboxURL();
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event: any) => {
    if (event?.data?.type === "finbox-bankconnect") {
      console.log("Event -> ", event);
      if (event?.data?.status === "exit") {
        setFinBoxURL("");
        setIsFinBox(false);
      } else if (event?.data?.status === "success") {
        setFinBoxURL("");
        submitTask({ taskId: getTaskData?.taskId });
        setIsFinBox(false);
        // const entityId = event?.data?.payload?.entityId;
        // setFinBoxEntityId(event?.data?.payload?.entityId);
        // const getAnalysis = localStorage.getItem("getAnalysis");
        // if (getAnalysis !== "true") {
        //   localStorage.setItem("getAnalysis", "true");
        //   setLoader(true);
        //   API_SERVICE.getAnalysis(
        //     entityId,
        //     leadDetail?.caseDetails?.caseId ?? ""
        //   )
        //     .then(({ data }) => {
        //       updateDropOfStage("BANK_STATEMENT_UPLOADED");
        //       getDocumentList();
        //       updateCaseStatus();
        //     })
        //     .catch((e: any) => {
        //       notification.error({ message: API_SERVICE.handleErrors(e) });
        //     })
        //     .finally(() => {
        //       setLoader(false);
        //     });
        // }
      }
    }
  };

  const getFinboxURL = () => {
    localStorage.setItem("getAnalysis", "false");
    API_SERVICE.getFinboxURL(leadData?.caseDetails?.caseId ?? "caseId")
      .then(({ data }) => {
        if (data) {
          setFinBoxURL(data?.redirect_url);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  return (
    <>
      <Modal
        // title="Upload New Document:"
        centered
        // width={400}
        onCancel={() => setFinBoxURL("")}
        closable={false}
        footer={null}
        visible={finBoxURL.length > 0}
        className="modal-finbox"
      >
        <iframe
          aria-hidden="false"
          frameBorder="0"
          style={{ height: "500px", width: "100%" }}
          src={finBoxURL}
          onDragExit={() => {
            console.log("On exit");
          }}
        />
      </Modal>
    </>
  );
};

export default BankStatement;
