import { useMedia } from "react-use";
import API_SERVICE from "./api-service";
import { notification } from "antd";
import LogoOneFlo from "../../assets/images/oneflo.svg";
import LogoAmazin from "../../assets/images/LOGO-AMAZIN.png";
import LogoAll from "../../assets/images/logo-all.png";
import axios from "axios";

export const isUserVerified = () => {
  return localStorage.getItem("isUserVerified") === "true";
};

export const useLayoutMedia = (layoutsToVerify: string[]) => {
  let layoutMode: "Mobile" | "TabletPortrait" | "TabletLandscape" | "Desktop" =
    "Desktop";
  if (useMedia("(max-width: 1024px)")) {
    layoutMode = "TabletLandscape";
  }
  if (useMedia("(max-width: 768px)")) {
    layoutMode = "TabletPortrait";
  }
  if (useMedia("(max-width: 576px)")) {
    layoutMode = "Mobile";
  }
  return layoutsToVerify.indexOf(layoutMode) > -1;
};

export const INRFormatter = (str: any, showSymbol = true) => {
  let s = str + "";
  if (!str) {
    return "₹ 0";
  }
  // TODO: Optimize this one
  let lastThree = s.substring(s.length - 3);
  const otherNumbers = s.substring(0, s.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  s = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  if (showSymbol) {
    s = "₹ " + s;
  }
  return s;
};
export const getBusinessType = () => {
  return "ADMIN";
};
export const filteredList = (searchKey: string, items: any[]) => {
  if (!searchKey) {
    return items;
  }
  return items.filter((c: any) => {
    let passed = false;
    for (const key in c) {
      const value = c[key];
      if (
        value &&
        typeof value === "string" &&
        value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      ) {
        passed = true;
      }
    }
    return passed;
  });
};

function parseDotNotation(str: string, val: any, obj: any) {
  let currentObj = obj,
    keys = str.split("."),
    i,
    l = Math.max(1, keys.length - 1),
    key;

  for (i = 0; i < l; ++i) {
    key = keys[i];
    currentObj[key] = currentObj[key] || {};
    currentObj = currentObj[key];
  }

  currentObj[keys[i]] = val;
  delete obj[str];
}

export function dotToObject(obj: any) {
  for (let key in obj) {
    if (key.indexOf(".") !== -1) {
      parseDotNotation(key, obj[key], obj);
    }
  }
  return obj;
}

export const camelToNormal = (text: string) => {
  if (!text) {
    return text;
  }

  let result = text.replace(/([A-Z])/g, " $1");
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function objectToDot(obj: any) {
  let res: any = {};
  (function recurse(obj, current) {
    for (let key in obj) {
      let value = obj[key];
      let newKey = current ? current + "." + key : key;
      if (value && typeof value === "object") {
        // @ts-ignore
        recurse(value, newKey);
      } else {
        res[newKey] = value;
      }
    }
  })(obj);
  return res;
}

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (function () {
  var a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, name) {
    var blob = new Blob(data, { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const hasUserRole = () => {
  const userPermissions = JSON.parse(
    localStorage.getItem("userPermission") || "[]"
  );
  const permissions = userPermissions.reduce((result, item) => {
    result[item.roleName.replace(/ /g, "")] = item.selected;
    return result;
  }, {});
  return permissions;
};

export const numberWithCommas = (x: any) => {
  if(x == null) {
    return 0;
  }
  
  return x?.toString().split(".")[0].length > 3
    ? x
        ?.toString()
        .substring(0, x?.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x?.toString().substring(x?.toString().split(".")[0].length - 3)
    : x?.toString();
};

export const getUser = () => {
  let user = localStorage.getItem("user") as any;
  if (user) {
    return JSON.parse(user);
  }
  return {};
};

export const getUserDetails = () => {
  return getUser()?.userDetails;
};

export const getBasicDetails = () => {
  let basicDetails = JSON.parse(
    localStorage.getItem("basicDetails") ?? "{}"
  ) as any;
  return basicDetails;
};

export const getEnumList = (propertyName: string) => {
  let enumValues = localStorage.getItem("enumValues") as any;
  if (enumValues) {
    enumValues = JSON.parse(enumValues);
    const enumValue = enumValues.find(
      (item) => item.propertyName === propertyName
    );
    return enumValue?.keyAndDisplayValueList;
  }
  return [];
};

export const getEnumValue = (propertyName: string, key?: string) => {
  const value = getEnumList(propertyName)?.find((item: any) => {
    return item?.key === key;
  });
  return value?.value ? value?.value : key ?? "NA";
};

export const getAppLogo = () => {
  let logo = LogoAll
  // if(getBasicDetails()?.dsaBusinessName?.includes("AMAZIN")) {
  //   logo = LogoAmazin
  // } else if(getBasicDetails()?.dsaBusinessName?.includes("Fineoteric")) {
  //   logo = LogoOneFlo
  // }
  return logo;
}

export const getGenderKey = (strGender) => {
  return strGender === "Male" ? "M" : strGender === "Female" ? "F" : "O";
};

export const setAppTitle = () => {
  document.title = getUser()?.businessName ?? "1Flo";
}

export const downloadDocumentWith = (
  businessId: String,
  documentId: String
) => {
  API_SERVICE.downloadDocument(businessId, documentId)
    .then(({ data }) => {
      console.log(data);
      let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
      saveByteArray([bytesData], data.payload.documentName);
    })
    .catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
};

export const fileUploadCommon = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file?.originFileObj ?? file);
  try {
    const { data } = await API_SERVICE.fileUpload(formData);
    return { file, data, index: null };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { file, data: null, index: null };
  }
};

export const addDocumentCommon = async (
  documentSetId: string,
  url: string,
  name: string,
  type: string,
  subtype: string
) => {
  const payload = {
    documentURL: url ?? "",
    documentName: name ?? "",
    documentType: type ?? "",
    documentSubType: subtype ?? "",
  };

  try {
    const { data } = await API_SERVICE.addDocument(payload, documentSetId);
    return { payload: data?.payload };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { payload: null };
  }
};

export const genericDeleteDoc = async (document: any) => {
  try {
    const { data } = await API_SERVICE.deleteDocument(
      document?.businessId,
      document?.businessDocumentId
    );
    return { payload: data?.payload };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { payload: null };
  }
};

export const getImageWidthHeight = (file) => {
  const _URL = window.URL || window.webkitURL;
  let img = new Image();
  let objectUrl = _URL.createObjectURL(file);
  img.onload = function (f) {
    console.log("f1", img.width + " " + img.height);
    _URL.revokeObjectURL(objectUrl);
  };
  img.src = objectUrl;
};


export const saveBase64File = (function () {
  var a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, name) {
    // a.href = "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg";
    // a.href = "https://d3jy31tj1gt845.cloudfront.net/reactdigitalgarden/json/menu.json";
    // a.href = {data};
    // a.download = name;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    window.open(data, '_blank');
  };
})();

export const downloadDocument = (doc: any) => {

  let isFromWebView = false;
  const userAgent = window.navigator.userAgent as any
  if (userAgent != null) {
    if (userAgent.includes("wv") || 
        userAgent.includes("Mobile Safari") || 
        (userAgent.includes("Chrome") && userAgent.includes("Mobile"))) {
          isFromWebView = true;
    }
  }

  API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId, {isFromWebView})
    .then(({ data }) => {
      if(isFromWebView) {
        saveBase64File(data.payload.preSignedUrl, data.payload.documentName)
      } else {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      }
    })
    .catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
};

export const disableDate = (current) => {
  let eighteenYearsAgo = new Date();
  let eightAgo = eighteenYearsAgo.setFullYear(
    eighteenYearsAgo.getFullYear() - 18
  );
  return current && current > new Date(eightAgo);
};

export const getEnumKey = (propertyName: string, value?: string) => {
  const key = getEnumList(propertyName)?.find((item: any) => {
    return item?.value === value;
  });
  return key?.key ? key?.key : value ?? "NA";
};