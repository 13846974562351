import React, { useEffect, useState } from "react";
import { Divider, Tag } from "antd";
import { ReactComponent as ArrowRightNew } from "../../../../assets/images/arrowRightNew.svg";
import moment from "moment";
import "../../../LeadNew/LeadDetail/History/History.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IHistory {
  historyDetails?: any;
}

const History: React.FC<IHistory> = (props: IHistory) => {
  const { historyDetails } = props;

  const [leadHistory, setLeadHistory] = useState(null as any);
  const { appColors } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    setSortingHistory(historyDetails);
  }, [historyDetails]);

  const setSortingHistory = (historyDetails) => {
    let history: any = [];
    for (let key in historyDetails) {
      history = [...history, ...historyDetails[key]];
    }
    const sortedHistory = history.sort((a, b) =>
      a.statusChangedOn < b.statusChangedOn ? 1 : -1
    );
    setLeadHistory(sortedHistory);
  };
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  const tagPrimaryLightStyle : any = {'--tagBgColor': appColors?.appPrimaryLightColor ?? ""};
  return (
    <>
      <div>
        <Divider style={{ marginBottom: "5px", marginTop: "10px" }} />
        <div className="timeline">
          {leadHistory?.map((item: any, i: number) => (
            <>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <div>
                  <p className="history-logo" style={customStyles}>
                    {item.changedByUser.fullName.charAt(0) +
                      item.changedByUser.fullName.charAt(1).toUpperCase()}
                  </p>
                </div>
                <div
                  style={{ display: "grid", width: "100%", marginLeft: "10px" }}
                >
                  <div className="history-header-name">
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginBottom: "0px",
                        }}
                      >
                        {item.changedByUser.fullName
                          ? item.changedByUser.fullName
                          : ""}{" "}
                        {item?.dispositions?.[`${"Disposition 1"}`] || item?.dispositions?.[`${"Disposition 2"}`] ? (
                          <span> added a comment </span>
                        ) : (
                          <>
                            changed the Status:{" "}
                            <span style={{ color: appColors?.appSecondaryColor ?? "#C71C8E" }}>
                              {item.currentStatus ? item.currentStatus : ""}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      {item.statusChangedOn && (
                        <span className="history-date">
                          {moment(item.statusChangedOn)
                            .locale("en")
                            .format("DD MMM YYYY hh:mm A")}
                        </span>
                      )}
                    </div>
                  </div>

                  <div style={{ display: "grid" }}>
                    <div>
                      {item?.previousStage?.name && (
                        <Tag className="history-tag" style={tagPrimaryLightStyle}>
                          {item?.previousStage?.name}
                          {item?.previousStage?.step?.name &&
                            ('/ ') + item?.previousStage?.step?.name}
                        </Tag>
                      )}
                      {item?.previousStage?.name &&
                        item?.currentStage?.name && <ArrowRightNew />}
                      {item?.currentStage?.name && (
                        <Tag className="history-tag" style={tagPrimaryLightStyle}>
                          {item?.currentStage?.name}
                          {item?.currentStage?.step?.name &&
                            ('/ ') + item?.currentStage?.step?.name}
                        </Tag>
                      )}
                    </div>

                    {item?.dispositions?.[`${"Disposition 1"}`] && (
                      <span className="history-sub-details">
                        Disposition 1:{" "}
                        {item?.dispositions?.[`${"Disposition 1"}`]}
                      </span>
                    )}
                    {item?.dispositions?.[`${"Disposition 2"}`] && (
                      <span className="history-sub-details">
                        Disposition 2:{" "}
                        {item?.dispositions?.[`${"Disposition 2"}`]}
                      </span>
                    )}
                    {item?.comments && (
                      <span className="history-sub-details">
                        Message : {item?.comments}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
export default History;
