/* eslint-disable @typescript-eslint/no-unused-vars */
import { Row, Typography, Input, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
const { TextArea } = Input;
const { Title } = Typography;

const LenderSelection: React.FunctionComponent<any> = ({
  leadData,
  lenderNextHandler,
}) => {
  const [comment, setComment] = useState(null as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const lenderActivityDetails =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;

  useEffect(() => {
    setComment(
      leadData?.caseDetails?.caseHistoryHolder?.lenderSelectionState?.at(0)
        ?.comments
    );
  }, []);

  const updateLender = () => {
    lenderNextHandler({ comment });
  };

  return (
    <>
      <Row>
        <Title level={5}> Lender Selection</Title>
        <div className="assigned-lender-list" style={{ width: "100%" }}>
          <Space direction="vertical">
            {lenderActivityDetails?.map((item, i) => {
              const lenderName =
                (item?.lenderName?.length ?? 0) === 0
                  ? item.branchName
                  : item?.lenderName;
              return (
                <div className="assigned-list" key={i}>
                  <div className="checkbox-text-div">
                    <span className="checkbox-text">{lenderName}</span>
                  </div>
                </div>
              );
            })}
          </Space>
        </div>
        <div className="comment-box">
          <TextArea
            placeholder="Comment"
            showCount
            value={comment}
            maxLength={500}
            className="custom-textarea"
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <Button type="primary" className="next-btn dynamic-btn-primary" style={{ '--btnColor': appColors?.appPrimaryColor ?? "" } as any} onClick={updateLender}>
          Submit
        </Button>
      </Row>
    </>
  );
};

export default LenderSelection;
