import { Radio, Form, Space } from "antd";

const RadioButton = (props) => {
  const {
    onChange,
    radioList,
    className,
    name,
    defaultValue,
    rules,
    label,
    isRequired,
    isDisabled,
    requiredLabel,
    style,
  } = props;

  const renderRadioGroup = () => {
    return (
      <Radio.Group
        value={defaultValue}
        onChange={onChange}
        className={className}
        disabled={isDisabled}
        style={style}
      >
        <Space wrap>
          {radioList?.map((obj: any, i: number) => {
            return (
              <Radio.Button key={i} value={obj.key}>
                {obj.value}
              </Radio.Button>
            );
          })}
        </Space>
      </Radio.Group>
    );
  };

  const renderDiv = () => {
    return <div>{renderRadioGroup()}</div>
  }

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: `Please select your ${requiredLabel ?? label}.`,
          },
          ...(rules ?? []),
        ]}
      >
        {name.length == 0 ? renderDiv() : renderRadioGroup()}
      </Form.Item>
    </>
  );
};

export default RadioButton;
