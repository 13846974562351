import {
  Row,
  Typography,
  Input,
  Col,
  Form,
  Collapse,
} from "antd";
import moment from "moment";
import React from "react";
import "./index.scss";
const { Panel } = Collapse;
const { TextArea } = Input;
const { Text } = Typography;

const DisbursedGroup: React.FunctionComponent<any> = ({
  lender,
  lenderIndex,
  payment,
  index,
  payoutDetailIndex,
  onActionSubmit,
}) => {
  const [disbursedForm] = Form.useForm();

  const actionSubmit = (value: any) => {
    onActionSubmit(value?.comment);
  };

  const getHeader = () => (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {`${lender.lenderName} - Disbursal #${payoutDetailIndex + 1}`}
      </div>
      {payment?.disbursalId?.length > 0 ? <span style={{ fontSize: '11px' }}>Disbursal ID: {payment?.disbursalId}</span> : ""}
    </>
  );

  const renderValue = (value) => {
    return (
      <div className="cutom-input-text">
        <Text>{value}</Text>
      </div>
    );
  };

  const keyCol = `${lenderIndex}-${payoutDetailIndex}`;
  return (
    <div key={payoutDetailIndex} style={{ marginBottom: "8px" }}>
      <Form form={disbursedForm} onFinish={actionSubmit}>
        <Collapse
          className="payout-collapse"
          collapsible="header"
          expandIconPosition="end"
        >
          <Panel
            header={getHeader()}
            key={keyCol}
          >
            <div className="bg-title">
              <Text>Disbursal Details</Text>
            </div>
            <div style={{ paddingInline: "16px" }}>
              <Row
                className="disbursed-lenders-header"
                style={{ paddingTop: "10px" }}
              >
                <Col span={12} className="disbursed-heading">
                  Insurance Amount
                </Col>
                <Col span={12} className="disbursed-heading">
                  Disbursed Amount
                </Col>
              </Row>
              <Form.List name={`${lenderIndex}-${index}`}>
                {(fields) => (
                  <Row className="selected-lender-row" gutter={[6, 6]}>
                    <Col span={12} className="disbursed-row">
                      {renderValue(payment?.lenderAmount?.insuranceAmount !== "" ? payment?.lenderAmount?.insuranceAmount : 0)}
                    </Col>
                    <Col span={12} className="disbursed-row">
                      {renderValue(payment?.lenderAmount?.disbursedAmount !== "" ? payment?.lenderAmount?.disbursedAmount : 0)}
                    </Col>
                  </Row>
                )}
              </Form.List>
            </div>
            <br />
            <Row style={{ marginLeft: "13px", width: '92%' }} gutter={[6, 6]}>
              <Col span={12} className="disbursed-heading">
                Disbursal Date:
                {renderValue(
                  payment?.lenderAmount?.disbursalDate
                    ? moment(payment?.lenderAmount?.disbursalDate)?.format("YYYY-MM-DD")
                    : "Disbursal Date (YYYY-MM-DD)"
                )}
              </Col>
              <Col span={12}>
                Payout%:
                {renderValue(payment?.lenderAmount?.payoutPercentage !== "" ? payment?.lenderAmount?.payoutPercentage : 0)}
              </Col>
            </Row>
            <br />
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default DisbursedGroup;
