import {
  Button,
  Col,
  Row,
  Typography,
  Divider,
  notification,
  Tabs,
} from "antd";
import "./profile.scss";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import ProfileTab from "./profile-tab";
import KYCTab from "./kyc-tab";
import PayoutPrimaryAC from "./primary-acc-tab";
import { TiCamera } from "react-icons/ti";
import { getBasicDetails, getUserDetails } from "shared/services/Utility";
import {ReactComponent as IconUser} from "../../assets/images/user.svg";
import {ReactComponent as IconProfileEdit} from "../../assets/images/profileEdit.svg";
import { ReactComponent as IconArrowShare } from "../../assets/images/shareProfile.svg";
import EditBusinessDetail from "./edit-business-details";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import FormIcons, { iconType } from "shared/components/FormIcons/FormIcons";
import AppUpload from "./app-upload";
import FormButton from "shared/components/FormButton/FormButton";
import { getEnumValue } from "shared/services/Utility";

const { Title } = Typography;
const { Text } = Typography;
const { TabPane } = Tabs;

const Profile: React.FC<any> = () => {
  const userDetails = getUserDetails();
  const [kycData, setKycData] = React.useState(null as any);
  const [profileData, setProfileData] = useState(null as any);
  const [profileImage, setProfileImage] = useState(null as any);
  const [accountData, setAccountData] = useState(null as any);
  const [openEditBusinessDetail, setOpenEditBusinessDetail] = useState(false);
  const [openEditProfileDetail, setOpenEditProfileDetail] = useState(false);
  const [basicDetails, setBasicDetails] = useState(null as any);
  const [otherDetails, setOtherDetails] = useState(null as any);
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );

  useEffect(() => {
    getConnector();
  }, []);

  const OpenEditBusinessData = () => {
    setOpenEditBusinessDetail(true);
  };

  const setConnectorDetails = (payload) => {
    setProfileData(payload?.profilePicture);
    setBasicDetails(payload?.basicDetails);
    setOtherDetails(payload?.otherDetails);
    setKycData(payload?.kycDetails);
    if ((payload?.accounts?.length ?? 0) > 0) {
      setAccountData(payload?.accounts[0]);
    }
    if ((payload?.profilePicture?.businessDocumentId?.length ?? 0) > 0) {
      let businessDocumentId = payload?.profilePicture?.businessDocumentId;
      getDocument(businessDocumentId);
    }
  };

  const getConnector = () => {
    if (userDetails !== null) {
      const id = userDetails?.userId;
      API_SERVICE.getConnectorById(id)
        .then(({ data }) => {
          setConnectorDetails(data?.payload);
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const getDocument = (businessDocumentId: string) => {
    console.log("businessDocumentId: ", businessDocumentId);
    API_SERVICE.getDocument(businessDocumentId)
      .then(({ data }) => {
        setProfileImage(data?.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateConnector = (updatedDetail: any) => {
    const payload = {
      accounts: null,
      basicDetails: null,
      currentStatus: null,
      kycDetails: null,
      otherDetails: null,
      profilePicture: null,
      ...updatedDetail,
    };

    API_SERVICE.updateConnector(userDetails?.userId, payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setOpenEditBusinessDetail(false);
        setOpenEditProfileDetail(false);
        getConnector();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const generatDunamicLink = async () => {
    const connID = userDetails?.userId ?? ""
    const salesID = getBasicDetails()?.srmId ?? getUserDetails().managerDetails?.id ?? "";
    const dsaBusinessId = getBasicDetails()?.dsaBusinessId;
    const { data } = await API_SERVICE.generatDunamicLink(connID, salesID, dsaBusinessId);
    navigator.clipboard.writeText(data?.shortLink)
  }

  const renderText = (title, value) => {
    return (
      <div style={{ display: "grid", marginBottom: "10px" }}>
        <Text style={{ fontWeight: "350" }}>{title}</Text>
        <Text style={{ fontWeight: "350" }}>{value}</Text>
      </div>
    );
  };

  const renderTextReferral = (title, name, number) => {
    if ((name?.length ?? 0) == 0) {
      return null;
    }
    return (
      <div style={{ display: "grid", paddingBottom: "10px" }}>
        <Text style={{ fontWeight: "350" }}>{title}</Text>
        <div>
          <Text style={{ fontWeight: "350" }}>{name}</Text>
          <Text style={{ fontWeight: "350", float: "right" }}>
            <FormIcons type={"phoneIcon"} color={appColors?.appPrimaryColor ?? ""} isDynamicIcon/>
            {number}
          </Text>
        </div>
      </div>
    );
  };

  const renderBusinessDetailTab = () => {
    return (
      <>
        {openEditBusinessDetail ? (
          <EditBusinessDetail
            setOpenEditBusinessDetail={setOpenEditBusinessDetail}
            updateConnector={updateConnector}
            basicDetails={basicDetails}
          />
        ) : (
          <>
            <Row>
              <Col span={20}>
                {renderText(
                  "Business Type",
                  getEnumValue("BUSINESS_TYPE", basicDetails?.businessType)
                )}
              </Col>

              {basicDetails?.connectorRole === "CONNECTOR_ADMIN" && (
                <Col style={{ marginTop: "-8px" }}>
                  <FormButton
                    className="remove-border custome-btn-icon-play"
                    style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                    icon={<IconProfileEdit style={{width:"20px", height:"20px"}}/>}
                    onclick={OpenEditBusinessData}
                  />
                </Col>
              )}
            </Row>
            {renderText("Business Name", basicDetails?.businessName)}
            {renderText(
              "Authorised Signatory Name",
              basicDetails?.authorizedSignatory?.name
            )}
            {renderText(
              "Authorised Signatory Phone",
              basicDetails?.authorizedSignatory?.phoneNumber
            )}
            {renderText(
              "Authorised Signatory Email",
              basicDetails?.authorizedSignatory?.email
            )}
          </>
        )}
      </>
    );
  };

  const renderReferralDetails = () => {
    return (
      <>
        {renderTextReferral(
          "Referral Connector Detail",
          basicDetails?.connectorName,
          basicDetails?.connectorMobileNumber
        )}
        {renderTextReferral(
          "Referral Sales Relationship Manager Detail",
          basicDetails?.srmName,
          basicDetails?.srmMobileNumber
        )}
        {renderTextReferral(
          "Channel Manager Detail",
          basicDetails?.channelManagerName,
          basicDetails?.channelManagerMobileNumber
        )}
      </>
    );
  };
  const customPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
  return (
    <>
      <Title level={4}>My Profile</Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={8} span={8}>
          <div className="lead-detail-box1">
            <div
              className="lead-details"
              style={{ height: isMobile ? "" : "900px" }}
            >
              <Row gutter={[16, 16]} className="profile-custom-row">
                <Col>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    {!profileImage?.fileExtn ? (
                      <IconUser
                        className="user-icon"
                        height={130}
                        width={130}
                        style={{"--appPrimary": appColors?.appPrimaryColor ?? ""} as any}
                      />
                    ) : (
                      <img
                        id="borderimg"
                        src={
                          "data:image/" +
                          profileImage?.fileExtn +
                          ";base64," +
                          profileImage?.documentDetails
                        }
                        style={{"--color": appColors?.appSecondaryColor ?? ""} as any}
                        width="130px"
                        height="130px"
                        alt=""
                      />
                    )}
                    <AppUpload
                      acceptType={["image", "pdf"]}
                      documentSetId={userDetails?.documentSetId ?? ""}
                      callBack={(value: any) => {
                        updateConnector({ profilePicture: value });
                      }}
                    >
                      <Button
                        size="middle"
                        shape="circle"
                        icon={<TiCamera />}
                        type="primary"
                        className="img-upload dynamic-btn-primary-default"
                        style={customPrimary}
                      />
                    </AppUpload>
                  </Row>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <h1
                      style={{
                        fontSize: "150%",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {basicDetails?.name}
                    </h1>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <div className="container">
                      <span style={{ display: "flex", justifyContent: "center" }}>
                        {userDetails?.userId}
                        <span style={{ marginTop: "-5px" }}>
                          <FormButton
                            className="remove-border"
                            icon={<FormIcons type="copy" color=''/>}
                            size="small"
                            onclick={() =>
                              navigator.clipboard.writeText(
                                userDetails?.userId
                              )
                            }
                          />
                        </span>
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  span={
                    (otherDetails?.selectConnectorLevel?.length ?? 0) > 0
                      ? 8
                      : 12
                  }
                  className="profilIcons"
                >
                  {otherDetails?.status == "APPROVED" ? (
                    <FormIcons type="apporvedFill" />
                  ) : (
                    <FormIcons type="cancelClose" />
                  )}
                  <br />
                  <Text
                    style={{
                      color:
                        otherDetails?.status == "APPROVED" ? "green" : "red",
                    }}
                  >
                    {otherDetails?.status == "APPROVED"
                      ? "Approved"
                      : otherDetails?.status == "REJECTED"
                        ? "Rejected"
                        : "In Process"}
                  </Text>
                </Col>
                {(otherDetails?.selectConnectorLevel?.length ?? 0) > 0 && (
                  <Col span={8} className="profilIcons">
                    <>
                      <FormIcons type="silver" />
                      <br />
                      <Text style={{ textTransform: "capitalize" }}>
                        {otherDetails?.selectConnectorLevel.toLowerCase()}
                      </Text>
                    </>
                  </Col>
                )}
                <Col
                  span={
                    (otherDetails?.selectConnectorLevel?.length ?? 0) > 0
                      ? 8
                      : 12
                  }
                >
                  {(basicDetails?.dsaBusinessId?.length ?? 0) > 0 ? (
                    <FormButton
                      label="Share"
                      icon={<IconArrowShare style={{width:"20px", height:"20px"}}/>}
                      iconPositon="top"
                      className="remove-border user-icon"
                      onclick={generatDunamicLink}
                      style={{marginTop:"5px", color:appColors?.appPrimaryColor ?? "", '--appPrimary': appColors?.appSecondaryColor ?? "#C71C8E" }}
                    />
                  ) : null}
                </Col>
              </Row>
              {!isMobile && (
                <div className="tab-details dynamic-tabs" style={customPrimary}>
                  <Divider />
                  <Tabs defaultActiveKey="1">
                    {basicDetails?.connectorType === "BUSINESS" && (
                      <Tabs.TabPane tab="Business Details" key="1">
                        {renderBusinessDetailTab()}
                      </Tabs.TabPane>
                    )}
                    <Tabs.TabPane tab="Relationship Mapping" key="2">
                      {renderReferralDetails()}
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} xl={16} span={16}>
          <Row>
            <Col span={24}>
              <div className="card-container">
                <Tabs defaultValue={1} type="card">
                  <TabPane tab="Profile" key="1">
                    <ProfileTab
                      getConnector={getConnector}
                      updateConnector={updateConnector}
                      basicDetails={basicDetails}
                      setOpenEditProfileDetail={setOpenEditProfileDetail}
                      openEditProfileDetail={openEditProfileDetail}
                      setOpenEditBusinessDetail={setOpenEditBusinessDetail}
                      openEditBusinessDetail={openEditBusinessDetail}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        KYC
                        {kycData?.status != "APPROVED" && (
                          <div className="tab-dot" />
                        )}
                      </span>
                    }
                    key="2"
                  >
                    <KYCTab
                      kycData={kycData}
                      updateConnector={updateConnector}
                    />
                  </TabPane>
                  {basicDetails?.connectorAdditionalInfo?.canAddBankInfo &&
                    <TabPane
                      tab={
                        <span>
                          Payout-Primary A/C{" "}
                          {accountData?.status != "APPROVED" && (
                            <div className="tab-dot" />
                          )}
                        </span>
                      }
                      key="3"
                    >
                      <PayoutPrimaryAC
                        accountData={accountData}
                        updateConnector={updateConnector}
                      />
                    </TabPane>
                  }
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
