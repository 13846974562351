import { Row, Typography, Col, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import Disbursed from "./Disbursed";
import "./index.scss";

const { Title, Text } = Typography;

const LenderDisbursed: React.FunctionComponent<any> = ({
  leadData,
  assignedLenders,
  lenderDisbursedHandler,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
  selectedLenderName
}) => {
  const [selectedLenders, setSelectedLenders] = useState([] as any);
  const [totalSanctionedAmount, setTotalSanctionedAmount] = useState(
    0 as number
  );
  const [payoutType, setPayoutType] = useState("");
  const findSelected =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
      (item) => item?.lenderName == selectedLenderName
    );

  useEffect(() => {
    const type = leadData?.caseDetails?.connectorDetails?.payoutType ?? "";
    setPayoutType(type);
  }, []);

  useEffect(() => {
    let sanctionedAmount = 0;
    const tmpAssignedLenders = assignedLenders.filter(
      (item) =>
        item.status != "REJECTED" &&
        item.status != "ASSIGNED" &&
        item.status != "LOGGED" &&
        item.sanctionedAmount > 0
    );

    const lenders = tmpAssignedLenders?.map((lender) => {
      sanctionedAmount = sanctionedAmount + lender?.sanctionedAmount;
      if (
        lender.disbursalLenderInfoDetails == null ||
        (lender.disbursalLenderInfoDetails ?? 0) == 0
      ) {
        lender.disbursalLenderInfoDetails = [
          {
            disbursedId: null,
            lenderAmount: {
              disbursedAmount: null,
              insuranceAmount: 0,
              payoutPercentage:
                leadData?.caseDetails?.connectorDetails?.payoutType == "FIXED"
                  ? leadData?.caseDetails?.connectorDetails?.payoutPercentage
                  : leadData?.sourceOfCase == "CONNECTOR"
                  ? leadData?.caseDetails?.loanDetails?.expectedInterestRate
                  : null,
            },
          },
        ];
      }
      return lender;
    });

    setTotalSanctionedAmount(sanctionedAmount);
    setSelectedLenders(lenders);
  }, [assignedLenders]);

  return (
    <div className="lender-disbursed">
      <div>
        <Row gutter={[8, 8]}>
          <Col span={7} style={{paddingInline:'15px'}}>
            <Title level={5}>Disbursal Details:</Title>
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: "14px" }}>
              Total Tentative Loan Amount:
            </Text>
            <InputNumber
              readOnly
              className="custom-input-number"
              name="tentativeLoanAmount"
              value={leadData?.caseDetails?.loanDetails?.loanAmount || 0}
              formatter={(value) => numberWithCommas(value)}
              placeholder="Total Tentative Loan Amount"
            />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: "14px" }}>
              Total Sanctioned Loan Amount:
            </Text>
            <InputNumber
              readOnly
              className="custom-input-number"
              name="tentativePayoutAmount"
              value={totalSanctionedAmount}
              formatter={(value) => numberWithCommas(value)}
              placeholder="Total Sanctioned Loan Amount"
            />
          </Col>

          <Col span={24}></Col>
        </Row>

        {/* {selectedLenders?.map((lender, lenderIndex) => {
          return ( */}
            <Disbursed
              lender={findSelected}
              leadData={leadData}
              getRedirectURLLink={getRedirectURLLink}
              lenderIndex={0}
              payoutType={payoutType}
              loadingUpdate={loadingUpdate}
              getDownloadLetterForICICI={getDownloadLetterForICICI}
              onActionSubmit={(newLander, comment) => {
                lenderDisbursedHandler({
                  comment: comment,
                  lenders: [newLander],
                });
              }}
            />
          {/* );
        })} */}
      </div>
    </div>
  );
};

export default LenderDisbursed;
