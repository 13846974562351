import { Button, Card, Form, notification, Progress, Typography, Space } from "antd";
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from "react";
import Authentication from "./authentication";
import API_SERVICE from "shared/services/api-service";
import {
  NUMBER_REGEX,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
} from "shared/constants/AppConstants";
import FormInput from "shared/components/FormInput/FormInput";
import "./authentication.scss";
import { useHistory, useLocation } from "react-router-dom";
import FormInputPassword from "shared/components/FormInput/FormInputPassword";
import { getUser, getUserDetails } from "shared/services/Utility";
import checkedGreenCircle from "assets/images/Checked-Green.svg";
import checkedGreyCircle from "assets/images/Checked-Grey.svg";
import { FaSleigh } from "react-icons/fa";

interface IForgetPassword { }

const ForgetPassword: React.FunctionComponent<IForgetPassword> = (
  props: IForgetPassword
) => {
  const time = 60 * 2 - 1;
  const history = useHistory();

  const [userMobileNumber, setUserMobileNumber] = useState<string>("");
  const [mobileVerifyData, setMobileVerifyData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [otpscreen, setOtpscreen] = useState(false);
  const [mobilescreen, setMobilescreen] = useState(false);
  const [passwordscreen, setPasswordscreen] = useState(false);
  const [verifyOtpData, setVerifyOtpData] = useState();
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [countDown, setCountDown] = React.useState(time);
  const [runTimer, setRunTimer] = React.useState(true);
  const [ForgetPasswordform] = Form.useForm();
  const { state, pathname } = useLocation() as any;
  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordDigit, setPasswordDigit] = useState(false)
  const [passwordUppercase, setPasswordUppercase] = useState(false)
  const [passwordLowercase, setPasswordLowercase] = useState(false)
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState(false)
  const [reCaptchaToken, setReCaptchaToken] = useState("") as any
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  
  useEffect(() => {
    if (pathname === "/change-password") {
      if (!state?.data) {
        history.push("/dashboard");
      }
      setPasswordscreen(true);
      setOtpscreen(false);
      setMobilescreen(false);
      setUserMobileNumber(getUserDetails()?.phoneNumber);
    } else {
      setMobilescreen(true)
    }
  }, []);

  const submitHandler = async () => {
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else{
      setLoading(true);
      API_SERVICE.getOTPmobile(userMobileNumber)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            setMobileVerifyData(data.payload);
            setMobilescreen(false);
            setOtpscreen(true);
            handleOTPCaptcha(userMobileNumber)
            setReCaptchaToken("")
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => {
          setLoading(false);
        });
      }
  };

  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      setRunTimer(false);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  useEffect(() => {
    if (countDown === 0) {
      setIsTimeStoped(true);
    }
  });

  const actionResend = () => {
    if(isTimeStoped){
    handleOTPCaptcha(userMobileNumber)
  }
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else
    {    
      if (countDown === 0) {
        setReCaptchaToken("")
        setShowRecaptcha(false)
      API_SERVICE.getOTPmobile(userMobileNumber)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data?.message });
            setCountDown(time);
            setIsTimeStoped(false);
            setRunTimer(true);
          } else {
            notification.error({ message: "no data found" });
          }
      })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
      });
    } 
   }
  };

  const submitOtpHandler = async (values) => {
    if (values.otp == "") {
         notification.error({
        message: "Please Enter OTP",
        
      });
    } 
   else {
      verifyOtp(values.otp);
    }
  };

  const verifyOtp = (otp) => {
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else{
    const data = {
      mobileOtp: otp,
      mobileNumber: userMobileNumber,
      verificationId: mobileVerifyData?.id ?? "",
    };
    setLoading(true);
    API_SERVICE.verifyOtp(data)
      .then(({ data }) => {
        if (data) {
          setVerifyOtpData(data);
          setOtpscreen(false);
          setPasswordscreen(true);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
        setReCaptchaToken("")
        setShowRecaptcha(false)
        handleOTPCaptcha(userMobileNumber)
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  const handleProgressBar = (e:any) =>{
    let password = new Array();
    password.push("[$@$!%*#?&]");
    password.push("[A-Z]"); 
    password.push("[0-9]"); 
    password.push("[a-z]"); 

  var progress = 0;
  for (var i = 0; i < password.length; i++) {
      if (new RegExp(password[i]).test(e.target.value)) {
        progress++;
      }
  }
  if(progress > 2 && e.target.value.length > 7){
    progress++;
  }
  
  switch (progress) {
      case 0:
      setProgressPercent(0);
      break;
      case 1: 
      setProgressPercent(20);
      break;
      case 2:
        setProgressPercent(40);
        break; 
      case 3:
        setProgressPercent(60);
          break;
      case 4:
        setProgressPercent(80);
          break;
      case 5:
        setProgressPercent(100);
          break;
    }
  }

  const handlePasswordPolicy = (e:any) =>{
    setProgressStatus(e.target.value.length > 0 ? true  : false)
    setPasswordLength(e.target.value.length > 7 ? true  : false)
    setPasswordDigit(/\d/.test(e.target.value))
    setPasswordUppercase(/(?=[A-Z])[A-Z]/.test(e.target.value))
    setPasswordLowercase(/(?=[a-z])[a-z]/.test(e.target.value))
    setPasswordSpecialChar(/[$@$!%*#?&]/.test(e.target.value))   
    handleProgressBar(e)
}

const renderReCaptcha = () => {
  return (
       <div className="g-recaptcha">
    <ReCAPTCHA
      sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
      onChange={(token:any)=>setReCaptchaToken(token)}
    />
    </div>
  )
}

 const handlePassword = async (values) => {
    const data = {
      mobileNumber: userMobileNumber,
      password: values.password,
    };
    if (passwordLength && passwordDigit && passwordUppercase && passwordLowercase && passwordSpecialChar)
    {
      setLoading(true);
       API_SERVICE.updatePassword(data)
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            notification.success({ message: data.message });
            if (pathname === "/change-password") {
                let userData = getUser();
                userData.userDetails.changePasswordNextLogin = false;
                localStorage.setItem("user", JSON.stringify(userData));
                history.push("/dashboard");
                window.location.reload();
              } else {
                history.push("/sign-in");
                setPasswordscreen(false);
              }
            }
          })
        .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
   };
 }

  async function handleCaptcha(e:any){
    setUserMobileNumber(e.target.value)
    if(e.target.value.length === 10)
    {
       setLoading(true)
      const payload = {
        mobileNumber:e.target.value,
        password:"",
        reCaptchaScreenName:"OTP"
      }
      const { data } = await API_SERVICE.checkCaptchaRequired(payload);
      if(data)
        {
          setShowRecaptcha(data?.payload?.captchaRequired)
         
        }
        setLoading(false)
    }
}

async function handleOTPCaptcha(mobileNumber:any){
  setLoading(true)
  if(mobileNumber.length === 10)
  {
    const payload = {
      mobileNumber:mobileNumber,
      password:"",
      reCaptchaScreenName:"OTP"
    }
    const { data } = await API_SERVICE.checkCaptchaRequired(payload);
    if(data)
      {
        setShowRecaptcha(data?.payload?.captchaRequired)
      }
      setLoading(false)
  }
}

const renderForgotPassword = () => {
    return (
      <Card className="authentication-box">
        <h3 className="welcome-to-oneflo">
          Welcome to
          <span className="oneflo">ONEFLO</span>
        </h3>
        <h3 className="authentication-header">Forgot Password</h3>
        <Form
          layout="vertical"
          className="authentication-form"
          size="large"
          form={ForgetPasswordform}
          onFinish={submitHandler}
          requiredMark={false}
        >
        <FormInput
            classNameForm="oneflo oneflo-bottom-space"
            label="Confirm Your Mobile Number "
            name="mobileNumber"
            isBorder
            isRequired
            placeholder="Mobile number"
            requiredLabel="Mobile Number"
            prefix="+91"
            onChange={handleCaptcha}
            rules={[
              {
                pattern: PHONE_NUMBER_REGEX,
                message: "Please enter valid Mobile Number",
              },
            ]}         
          />
          {showRecaptcha && renderReCaptcha()}
         <Form.Item className="oneflo oneflo-bottom-space">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={loading}
              className="oneflo-full-btn"
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  const renderOtp = () => {
    return (
      <Card className="authentication-box">
        <h3 className="authentication-header">Verify Details</h3>
        <p>Enter the OTP sent to +91 {userMobileNumber}</p>
        <Form
          layout="vertical"
          className="authentication-form"
          onFinish={submitOtpHandler}
        >
          <FormInputPassword
            name="otp"
            classNameForm="oneflo-bottom-space"
            isBorder
            isRequired
            requiredLabel="otp"
            // type="password"
            rules={[
              {
                pattern: NUMBER_REGEX,
                message: "Please enter valid otp",
              },
            ]}
          />
           {showRecaptcha && renderReCaptcha()}
          <Form.Item
            style={{ display: "flex", justifyContent: "center", color: "grey" }}
          >
            Didn't receive the OTP? &nbsp;
            <a onClick={actionResend} style={{ color: "#C71C8E" }}>
              {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}
              <br />
            </a>
          </Form.Item>
          <Form.Item className="oneflo oneflo-bottom-space">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="oneflo-full-btn"
              loading={loading}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  const renderSetPassword = () => {
    return (
      <Card className="authentication-box">
        <h3 className="authentication-header">Set Your Password</h3>
        <Form
          layout="vertical"
          className="authentication-form"
          onFinish={handlePassword}
        >
          <FormInputPassword
            classNameForm="oneflo-bottom-space"
            name={"password"}
            label="Enter Your Password"
            isBorder
            isRequired
            requiredLabel="Password"
            placeholder="Enter Your Password"
            onChange={(e)=>handlePasswordPolicy(e)}
            rules={[
              {
                pattern: PASSWORD_REGEX,
                message: "Please enter valid Password",
              },
            ]}
          />
          <div className="passwordPolicy">
            <div>
                <img src = {passwordLength ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Length should be greater than 8
              </div>
              <div>
              <img src = {passwordDigit ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimum 1 digit in password
              </div>
              <div>
              <img src = {passwordUppercase ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimun 1 uppercase letter in password
              </div>
              <div>
              <img src = {passwordLowercase ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimun 1 lowercase letter in password
              </div>
              <div>
              <img src = {passwordSpecialChar ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                    Minimun 1 special character in password like @,#,$
              </div>
              </div>
          {progressStatus &&
          <Space>
          <Typography.Title level = {5}>Password Strength</Typography.Title>
          <Typography.Title level = {5} style={{
            marginLeft:"250px",
            display:"inline-block",
            color : (progressPercent === 100 )?"rgba(52,168,83,1)":"rgba(189,0,0,1"
          }}> {(progressPercent === 100) ? "Strong" : "Weak" }</Typography.Title>
          </Space>
        }
         {progressStatus &&
          <Progress 
            percent={progressPercent} 
            showInfo={false}
            strokeColor={progressPercent === 100 ? "rgba(52,168,83,1)" : "rgba(189,0,0,1"}
            style={{
              marginBottom:"10px",
            }}>
          </Progress>
         }
          <FormInputPassword
            classNameForm="oneflo-bottom-space"
            name="confirm"
            label="Confirm Your Password"
            isBorder
            isRequired
            requiredLabel="Confirm Password"
            placeholder="Confirm Your Password"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          />

          <Form.Item className="oneflo oneflo-bottom-space">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="oneflo-full-btn"
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  return (
    <Authentication
      title={
        mobilescreen
          ? "Forgot Password"
          : otpscreen
            ? "Verify Details"
            : "Set Your Password"
      }
    >
      <>
        {mobilescreen && renderForgotPassword()}
        {otpscreen && renderOtp()}
        {passwordscreen && renderSetPassword()}
      </>
    </Authentication>
  );
};

export default ForgetPassword;
