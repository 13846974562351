import { Button, Card, Divider, Form, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import ProfileOTP from "./ProfileOTP";
import FormInput from "shared/components/FormInput/FormInput";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
const { Title } = Typography;

interface IVerifyEmail {
  setOpenEmail: any;
  getConnector: any;
}

const VerifyEmail: React.FC<IVerifyEmail> = (props: IVerifyEmail) => {
  const { setOpenEmail, getConnector } = props;
  const getEmailId = JSON.parse(
    localStorage.getItem("basicDetails") ?? ""
  )?.emailId;
  const [form] = Form.useForm();
  const [openOtp, setOpenOtp] = useState(false);
  const [verificationId, serVerificationId] = useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    form.setFieldsValue({
      emailId: getEmailId,
    });
  }, []);

  const onSubmit = async () => {
    API_SERVICE.checkEmail(getEmailId)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setOpenOtp(true);
          serVerificationId(data?.payload?.id);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  return (
    <>
      {!openOtp && (
        <>
          <Card
            className="lead-details"
            style={{ height: "840px", width: "100%", marginTop: "5px" }}
          >
            <Card style={{ width: "50%", height: "45%", padding: "2px" }}>
              <Form layout="vertical" form={form} onFinish={onSubmit}>
                <Title level={3}>Verify Email </Title>
                <Divider />
                <FormInput
                  inputMode="email"
                  type="email"
                  name="emailId"
                  label="Email Id"
                  classNameForm="field-bg custom-input"
                  placeholder="Enter Your Email Id"
                  isDisabled
                />
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="request-Otp-btn dynamic-btn-primary"
                    style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                  >
                    {" "}
                    Request OTP{" "}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Card>
        </>
      )}
      {openOtp && (
        <ProfileOTP
          verificationId={verificationId}
          setOpenEmail={setOpenEmail}
          getConnector={getConnector}
        />
      )}
    </>
  );
};
export default VerifyEmail;
