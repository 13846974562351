import { Row, Typography, Button, Col, Tooltip, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import add from "../../../../assets/images/add.svg";
import copy from "../../../../assets/images/copy.svg";
import DisbursedGroup from "./DisbursedGroup";
import "./index.scss";

const { Title } = Typography;
const { Panel } = Collapse;

const Disbursed: React.FunctionComponent<any> = ({
  lender,
  lenderIndex,
  onActionSubmit,
  leadData,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
}) => {
  const [accountTypes, setAccountTypes] = useState([] as any);
  const [finalLender, setFinalLander] = useState({} as any);
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState(0);

  useEffect(() => {
    setEnumValues();
  }, []);

  useEffect(() => {
    lender?.disbursalLenderInfoDetails?.map((amount) => {
      amount.lenderAmount.isDisabled = amount.lenderAmount.disbursedAmount > 0;
    });
    setFinalLander(lender);
    calcTotalDisbursedAmount(lender?.disbursalLenderInfoDetails);
  }, [lender]);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let accountType = enumValues.find(
        (item: any) => item.propertyName == "ACCOUNT_TYPE"
      );
      if (accountType) {
        setAccountTypes(accountType.keyAndDisplayValueList);
      }
    }
  };

  const isAddEneble = (item) => {
    let amountDisbursed = item.disbursalLenderInfoDetails.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    let amountInsurance = item.disbursalLenderInfoDetails.map((item) =>
      Number(item.lenderAmount.insuranceAmount)
    );
    const totalDisbursed = amountDisbursed.reduce((a, b) => a + b, 0);
    const totalInsurance = amountInsurance.reduce((a, b) => a + b, 0);
    const total = totalDisbursed + totalInsurance;
    return total < item.sanctionedAmount;
  };

  const setAddButton = (isHidden, lender, lenderIndex, index) => {
    return (
      <Tooltip
        title={isAddEneble(lender) ? "" : "You disbursed all sanctioned amount"}
      >
        <Button
          className="btn-add"
          style={{ marginTop: "15px" }}
          hidden={isHidden}
          onClick={(event) => {
            event.stopPropagation();
            if (!isAddEneble(lender)) {
              return;
            }
            const lenderInfo = {
              disbursedId: null,
              lenderAmount: {
                disbursedAmount: null,
                insuranceAmount: 0,
                payoutPercentage:
                  leadData?.caseDetails?.connectorDetails?.payoutType == "FIXED"
                    ? leadData?.caseDetails?.connectorDetails?.payoutPercentage
                    : leadData?.sourceOfCase == "CONNECTOR"
                    ? leadData?.caseDetails?.loanDetails?.expectedInterestRate
                    : null,
              },
            };
            const tmpLender = { ...finalLender };
            const disburselLenderInfo = [
              ...tmpLender.disbursalLenderInfoDetails,
            ];
            disburselLenderInfo.push(lenderInfo);
            tmpLender.disbursalLenderInfoDetails = disburselLenderInfo;

            if ((tmpLender?.payoutDetails?.length ?? 0) > 0) {
              const tmpPayoutDetails = tmpLender.payoutDetails?.map(
                (payoutDetail) => {
                  const tmpPayoutDetail = payoutDetail?.map((detail) => {
                    const tmpDetail = { ...detail };
                    tmpDetail.disbursalGroup = `${index + 1}`;
                    tmpDetail.accountType = "";
                    tmpDetail.paymentType = null;
                    tmpDetail.payoutAmount = "";
                    tmpDetail.payoutId = null;
                    return tmpDetail;
                  });
                  return tmpPayoutDetail;
                }
              );
              const payoutDetails = [...tmpLender.payoutDetails];
              if (tmpPayoutDetails[0]) {
                payoutDetails.push(tmpPayoutDetails[0]);
              }
              tmpLender.payoutDetails = payoutDetails;
            }
            setFinalLander(tmpLender);
          }}
        >
          <img src={add} alt="" width={20} />
        </Button>
      </Tooltip>
    );
  };

  const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
    let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.insuranceAmount)
    );
    const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
    const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
    const total = totalDisbursed + totalInsurance;
    setTotalDisbursedAmount(isNaN(total) ? 0 : total);
  };

  const renderHeader = (isHidden?: any, index?: number) => (
    <>
      {/* {!isHidden && ( */}
      <Row className="disbursed-lenders-header">
        <Col span={5} className="disbursed-heading">
          <Title level={5}>Loan Details</Title>
          {/* <Title level={5}>{lender?.lenderName}</Title>
          {lender?.submissionType?.includes("API") &&
            (lender?.lenderApplicationId?.length ?? 0) > 0 && (
              <span style={{ fontSize: "11px", float: "left" }}>
                {`Lender loan ID: ${lender.lenderApplicationId}`}{" "}
                <img
                  src={copy}
                  alt=""
                  width={11}
                  onClick={() =>
                    navigator.clipboard.writeText(lender.lenderApplicationId)
                  }
                />
              </span>
            )} */}
        </Col>
        <Col span={6} className="disbursed-heading disbursed-detail-div">
          Sanctioned Amount
          <Title level={5}>
            {lender?.sanctionedAmount &&
              numberWithCommas(lender?.sanctionedAmount)}
          </Title>
        </Col>
        <Col span={6} className="disbursed-heading disbursed-detail-div">
          Disbursed Amount
          <Title level={5}>
            {totalDisbursedAmount && numberWithCommas(totalDisbursedAmount)}
          </Title>
        </Col>
        <Col span={4} className="disbursed-heading disbursed-detail-div">
          Interest Rate
          <Title level={5}>{lender?.interestRate}%</Title>
        </Col>
      </Row>
      {/* )} */}
    </>
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <Collapse
        className="payout-collapse"
        collapsible="header"
        expandIconPosition="end"
        activeKey={"index"}
      >
        <Panel showArrow={false} key={"index"} header={renderHeader(false, 1)}>
          {finalLender?.disbursalLenderInfoDetails?.map((payment, index) => {
            const isHidden = index !== 0;
            return (
              <DisbursedGroup
                lender={finalLender}
                leadData={leadData}
                lenderIndex={lenderIndex}
                getRedirectURLLink={getRedirectURLLink}
                payment={payment}
                index={index}
                payoutDetailIndex={index}
                getDownloadLetterForICICI={getDownloadLetterForICICI}
              />
            );
          })}
        </Panel>
      </Collapse>
    </div>
  );
};

export default Disbursed;
