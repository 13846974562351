import { Button, Card, Col, Row, Space, notification } from "antd";
import React, { useEffect, useRef } from "react";
import { FaCopy, FaShare } from "react-icons/fa";
import moment from "moment";
import { toBlob } from "html-to-image";
import "./help.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

type Props = {};

const About: React.FunctionComponent<Props> = () => {
  //   const osversion = window.navigator.appVersion;
  const osversion = "" + parseFloat(navigator.appVersion);
  const deviceName = window.navigator.platform;
  const [userData, setUserData] = React.useState(null as any);
  const exportRef = useRef<HTMLDivElement | null>(null);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    }
  }, []);

  const copyToClipboard = async (pngBlob) => {
    try {
      await navigator.clipboard.write([new ClipboardItem({ ["image/png"]: pngBlob, })]);
      notification.success({ message: "Copied Successfully!" });
    } catch (error) {
      console.error(error);
    }
  };

  const copy = async (el) => {
    const newFile = await toBlob(el);
    copyToClipboard(newFile);
  };

  /*const urlToObject = async (el) => {
    const blob = await toBlob(el);
    const file = new File([blob!], "image.jpg", { type: "image/png" });
    return file;
  };*/

  const share = async (el) => {
    const newFile = await toBlob(el);
    const data = {
      files: [
        new File([newFile!], "image.png", {
          type: "image/png",
        }),
      ],
      //title: "Image",
      //text: "image",
    };

    try {
      if (!window.navigator.canShare(data)) {
        console.error("Can't share");
      }
      await window.navigator.share(data);
    } catch (err) {
      console.error(err);
    }

  }

  const renderItem = (label: string, text: string, isLast: boolean = false) => {
    return <Row gutter={24} className={isLast ? "" : "detail-divider"}>
            <Col span={12} className="detail-label">{label}</Col>
            <Col span={12} className="detail-text">{text}</Col>
          </Row>
  }

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  return (
    <>
      <Card className="about-content custom-app-card">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={18} lg={13}>
            <Card className="about-content-details" ref={exportRef}>
              {renderItem("App Version", "1.0")}
              {renderItem("Os Version", osversion)}
              {renderItem("Last Update", moment().format("DD-MM-YYYY"))}
              {renderItem("Device Name", deviceName)}
              {renderItem(
                "Mobile Number",
                userData?.userDetails?.phoneNumber ?? ""
              )}
              {renderItem("User Id", userData?.userDetails?.userId ?? "", true)}
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={12}>
          <Col xs={24} sm={24} md={18} lg={13} className="text-center">
            <Space>
              <Button
                type="primary"
                className="copy-btn w-100 dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={() => copy(exportRef.current)}
              >
                <FaCopy />
                &nbsp;Copy
              </Button>
              <Button
                type="primary"
                className="export-btn w-100 dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={() => share(exportRef.current)}
              >
                <FaShare />
                &nbsp;Share
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default About;
