import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../LenderPrivacyPolicy/LenderPrivacyPolicy.scss";
import { getEnumList, numberWithCommas } from "shared/services/Utility";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

interface IPrivacyPolicyPanel {
  lender: any;
  lenderIndex?: any;
}

const PrivacyPolicyPanel: React.FC<IPrivacyPolicyPanel> = (
  props: IPrivacyPolicyPanel
) => {
  const { lender, lenderIndex } = props;
  const [policyStatusKey, setPolicyStatusKey] = useState();
  const [formPrivacyPolicy] = Form.useForm();

  const withdrawnReasons = getEnumList("INSURANCE_WITHDRAWN_REASON");
  const dateFormat = "DD/MM/YYYY";

  const policyStatusList = [
    {
      key: "POLICY_ISSUED",
      value: "Policy Issued",
    },
    {
      key: "UNDERWRITING_REJECT",
      value: "Rejected",
    },
    {
      key: "COUNTER_OFFER",
      value: "Counter Offer",
    },
    {
      key: "WITHDRAWN",
      value: "Withdrawn",
    },
  ];

  const frequencyPaymentList = [
    {
      key: "MONTHLY",
      value: "Monthly",
    },
    {
      key: "QUARTERLY",
      value: "Quaterly",
    },
    {
      key: "HALF_YEARLY",
      value: "Half Yearly",
    },
    {
      key: "ANNUALLY",
      value: "Annually",
    },
  ];

  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    if (["LOGGED"].includes(lender?.status)) {
      return;
    }

    const insuranceDetails = lender?.insuranceDetails;
    if (!insuranceDetails) {
      return;
    }

    const iDate = insuranceDetails.policyIssuedDate;
    const eDate = insuranceDetails.policyEndDate;

    formPrivacyPolicy.setFieldsValue({
      productPlan: insuranceDetails.productPlanName,
      policyIssuedDate: iDate && moment(iDate, dateFormat),
      policyEndDate: eDate && moment(eDate, dateFormat),
      grossPremiumAmount: insuranceDetails.grossPremiumAmount,
      frequencyOfPayment: insuranceDetails.frequencyOfPayment,
      premiumTenureYear: insuranceDetails.premiumTenure?.years,
      premiumTenureMonth: insuranceDetails.premiumTenure?.months,
      maturitySumInsured: insuranceDetails.maturitySumInsured,
      coverTenureYear: insuranceDetails.coverTenure?.years,
      coverTenureMonth: insuranceDetails.coverTenure?.months,
      counterOfferPremium: insuranceDetails.counterOfferPremium,
      policyNumber: insuranceDetails.policyNumber,
      proposalApplicationNumber: insuranceDetails.proposalApplicationNumber,
      policyStatus: insuranceDetails.policyStatus,
      policyStatusComments: insuranceDetails.policyStatusComments,
    });
    setPolicyStatusKey(insuranceDetails?.policyStatus);
  };

  const renderPrivacyPolicySelectFields = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyNumber`}
            className="decision-item privacy-policy-label"
            label="Policy No."
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <span className="field-lable privacy-policy-label custome-lable">
            <span className="star">*</span> Cover Tenure(In Years-Months)
          </span>
          <div style={{ display: "flex", gap: "6px" }}>
            <Form.Item
              name={`coverTenureYear`}
              className="decision-item privacy-policy-label"
              style={{ flex: 1 }}
            >
              <InputNumber className="custom-input-number" disabled />
            </Form.Item>
            <Form.Item
              className="decision-item privacy-policy-label"
              name={`coverTenureMonth`}
              style={{ flex: 1 }}
            >
              <InputNumber className="custom-input-number" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyIssuedDate`}
            label="Policy Issue Date"
            className="decision-item privacy-policy-label"
          >
            <DatePicker
              style={{
                width: "98%",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              format={dateFormat}
              className="custom-input"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyEndDate`}
            label="Policy End Date"
            className="decision-item privacy-policy-label"
          >
            <DatePicker
              style={{
                width: "98%",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              format={dateFormat}
              className="custom-input"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`grossPremiumAmount`}
            className="decision-item privacy-policy-label"
            label="Gross Premium Amount"
            rules={[{ required: true }]}
          >
            <InputNumber
              className="custom-input-number"
              formatter={(value) => numberWithCommas(value)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Frequency Of Payment"
            name={`frequencyOfPayment`}
          >
            <Select className="custom-select" disabled>
              {frequencyPaymentList?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <div className="field-lable privacy-policy-label custome-lable">
            Premium Tenure(In Years-Months)
          </div>
          <div style={{ display: "flex", gap: "6px" }}>
            <Form.Item
              name={`premiumTenureYear`}
              className="decision-item privacy-policy-label"
              style={{ flex: 1 }}
            >
              <InputNumber className="custom-input-number" disabled />
            </Form.Item>
            <Form.Item
              className="decision-item privacy-policy-label"
              name={`premiumTenureMonth`}
              style={{ flex: 1 }}
            >
              <InputNumber className="custom-input-number" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`maturitySumInsured`}
            className="decision-item privacy-policy-label"
            label="Maturity/Sum Insured"
          >
            <InputNumber
              className="custom-input-number"
              name="maturitySumInsured"
              formatter={(value) => numberWithCommas(value)}
              disabled
            />
          </Form.Item>
        </Col>
      </>
    );
  };

  const renderRejectedView = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} xl={18}>
          <div className="rejected-wrapper">
            <Form.Item
              name={`policyStatusComments`}
              label="Rejection Reason"
              className="privacy-policy-form-item"
            >
              <TextArea className="custom-textarea" rows={1} disabled />
            </Form.Item>
          </div>
        </Col>
      </>
    );
  };

  const renderWithdraw = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </Col>
        <Col span={6} xs={24} xl={6}>
          <div className="rejected-wrapper">
            <Form.Item
              name={`policyStatusComments`}
              label="Withdrawn Reason"
              className="privacy-policy-form-item"
            >
              <Select
                className="custom-select"
                showSearch
                placeholder="Select Reason"
                optionFilterProp="children"
                disabled
              >
                {withdrawnReasons?.map((item) => (
                  <Select.Option key={item?.key} value={item?.key}>
                    {item?.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </>
    );
  };

  const renderCounterOffer = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" disabled />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Frequency Of Payment"
            name={`frequencyOfPayment`}
          >
            <Select className="custom-select" disabled>
              {frequencyPaymentList?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Counter Offer Premium"
            name={`counterOfferPremium`}
          >
            <InputNumber
              className="custom-input-number"
              name="counterOfferPremium"
              formatter={(value) => numberWithCommas(value)}
              disabled
            />
          </Form.Item>
        </Col>
      </>
    );
  };

  const renderPrivacyPanel = () => {
    return (
      <li key={lenderIndex} style={{ marginTop: "-0px" }}>
        <Form form={formPrivacyPolicy} layout="vertical">
          <div className="lender-privacy-policy-header">
            {lender?.lenderName}
          </div>
          <div className="lender-privacy-policy-main-div">
            <Row gutter={[6, 6]} style={{ rowGap: "1px" }}>
              <Col span={6} xs={12} xl={6}>
                <Form.Item
                  className="decision-item privacy-policy-label"
                  label="Product Plan"
                  name={`productPlan`}
                >
                  <Input className="custom-input" name="productPlan" disabled />
                </Form.Item>
              </Col>
              <Col span={6} xs={12} xl={6}>
                <Form.Item
                  className="decision-item privacy-policy-label"
                  label="Policy Status"
                  name={`policyStatus`}
                >
                  <Select
                    className="custom-select"
                    onSelect={(ev: any) => setPolicyStatusKey(ev)}
                    disabled
                  >
                    {policyStatusList?.map((item) => (
                      <Select.Option key={item?.key} value={item?.key}>
                        {item?.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {policyStatusKey === "POLICY_ISSUED" &&
                renderPrivacyPolicySelectFields()}
              {policyStatusKey === "UNDERWRITING_REJECT" &&
                renderRejectedView()}
              {policyStatusKey === "WITHDRAWN" && renderWithdraw()}
              {policyStatusKey === "COUNTER_OFFER" && renderCounterOffer()}
            </Row>
          </div>
        </Form>
      </li>
    );
  };

  return <>{renderPrivacyPanel()}</>;
};
export default PrivacyPolicyPanel;
