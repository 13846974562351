import { ReactComponent as IconUpload } from "../../../assets/images/upload.svg";
import iconUpload from "../../../assets/images/upload.svg";
import { ReactComponent as IconEye } from "../../../assets/images/eye.svg";
import iconEye from "../../../assets/images/eye.svg";
import { ReactComponent as IconDelete } from "../../../assets/images/delete.svg";
import iconDelete from "../../../assets/images/delete.svg";
import { ReactComponent as Iconbell } from "../../../assets/images/bell-icon.svg";
import iconBell from "../../../assets/images/bell-icon.svg";
import { ReactComponent as IconCopy } from "../../../assets/images/copy.svg";
import iconCopy from "../../../assets/images/copy.svg";
import { ReactComponent as IconFile } from "../../../assets/images/file.svg";
import iconFile from "../../../assets/images/file.svg";
import { ReactComponent as IconCloudDownload } from "../../../assets/images/cloudDownload.svg";
import iconCloudDownload from "../../../assets/images/cloudDownload.svg";
import { ReactComponent as IconAddFile } from "../../../assets/images/add.svg";
import iconAddFile from "../../../assets/images/add.svg";
import { ReactComponent as IconEdit } from "../../../assets/images/profileEdit.svg";
import iconEdit from "../../../assets/images/profileEdit.svg";
import { ReactComponent as IconApporvedBorder } from "../../../assets/images/emptyApproved.svg";
import iconApporvedBorder from "../../../assets/images/emptyApproved.svg";
import { ReactComponent as IconApporvedFill } from "../../../assets/images/approved.svg";
import iconApporvedFill  from "../../../assets/images/approved.svg";
import { ReactComponent as IconSilver } from "../../../assets/images/silver.svg";
import iconSilver  from "../../../assets/images/silver.svg";
import { ReactComponent as IconShare } from "../../../assets/images/shareIcon.svg";
import iconShare from "../../../assets/images/shareIcon.svg";
import { ReactComponent as IconArrowShare } from "../../../assets/images/shareProfile.svg";
import iconArrowShare from "../../../assets/images/shareProfile.svg";
import { ReactComponent as IconPhone } from "../../../assets/images/phoneicon.svg";
import iconPhone  from "../../../assets/images/phoneicon.svg";
import iconAddFileData  from "../../../assets/images/addFileData.svg";
import { ReactComponent as IconCancelClose } from "../../../assets/images/cancelClose.svg";
import { ReactComponent as IconDownload } from "../../../assets/images/download.svg";
import iconDownload from "../../../assets/images/download.svg";
import iconCancelClose  from "../../../assets/images/cancelClose.svg";
import "./formIcon.scss";
import { Upload } from "antd";

export type iconType =
  | "upload"
  | "file"
  | "delete"
  | "view"
  | "download"
  | "addFile"
  | "edit"
  | "copy"
  | "cloudDownload"
  | "apporvedBorder"
  | "apporvedFill"
  | "silver"
  | "share"
  | "arrowShare"
  | "phoneIcon"
  | "addFileData"
  | "cancelClose";

interface IFormIcons {
  type: iconType;
  color?: any;
  isDynamicIcon?: boolean;
  iconWidth?:number;
  iconHeight?:number;
}

const FormIcons = (props: IFormIcons) => {
  const { type, color, isDynamicIcon, iconHeight, iconWidth } = props;

  const icons: any = {
    upload: isDynamicIcon ? IconUpload: iconUpload,
    view:isDynamicIcon ? IconEye :  iconEye,
    file:isDynamicIcon ? IconFile : iconFile,
    addFileData: iconAddFileData,
    delete:isDynamicIcon ? IconDelete: iconDelete,
    cloudDownload:isDynamicIcon ? IconCloudDownload: iconCloudDownload,
    download: isDynamicIcon ? IconDownload : iconDownload,
    addFile:isDynamicIcon ? IconAddFile: iconAddFile,
    edit:isDynamicIcon? IconEdit: iconEdit,
    copy:isDynamicIcon ? IconCopy : iconCopy,
    bell:isDynamicIcon ? Iconbell: Iconbell,
    apporvedBorder:isDynamicIcon ? IconApporvedBorder : iconApporvedBorder,
    apporvedFill:isDynamicIcon ? IconApporvedFill: iconApporvedFill,
    silver: isDynamicIcon ? IconSilver: iconSilver,
    share:isDynamicIcon ? IconShare: iconShare,
    arrowShare:isDynamicIcon ? IconArrowShare: iconArrowShare,
    phoneIcon: isDynamicIcon ? IconPhone: iconPhone,
    cancelClose:isDynamicIcon ? IconCancelClose: iconCancelClose,
  };

  const setIcon = (Icon: any) => {
    return <Icon height={iconHeight ?? 20} width={iconWidth ?? 20} style={{ "--color": color ?? "" } as any} className='iconColors' />;
  };

  return  (
    <>
      {isDynamicIcon ? setIcon(icons[`${type}`]) : (
        <img src={icons[`${type}`]} width={iconWidth ?? 20} height={iconHeight ?? 20} alt="" />
      )}
    </>
  )
};

export default FormIcons;
