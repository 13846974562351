import React, { useEffect } from "react";
import "moment/locale/zh-cn";
import {
    Typography,
    Row,
    Col,
    Form,
    notification,
} from "antd";
import debounce from "lodash/debounce";
import API_SERVICE from "shared/services/api-service";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import FormInput from "shared/components/FormInput/FormInput";
import FormButton from "shared/components/FormButton/FormButton";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

interface IDesignation {
}

const AddDesignation: React.FunctionComponent<IDesignation> = (
    props: IDesignation
) => {
    const { } = props;
    const location = useLocation() as any;
    const history = useHistory();
    const [designationForm] = Form.useForm();
    let { id } = useParams() as any;
    const { appColors } = useSelector((state: RootState) => state.common);

    useEffect(() => {
        designationForm.setFieldsValue({
            designation: location?.state?.name,
        })
    }, []);

    const onFinishForm = (values: any) => {
        if (id) {
            const payload = {
                id: id,
                name: values.designation,
                status: true
            }
            API_SERVICE.editDesignation(payload)
                .then(({ data }) => {
                    if (data) {
                        notification.success({ message: data.message });
                        history.push(`/designation`);
                    }
                })
                .catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                });
        }
        else {
            const payload = {
                id: "",
                name: values.designation,
                status: true
            }
            API_SERVICE.addDesignation(payload)
                .then(({ data }) => {
                    if (data) {
                        notification.success({ message: data.message });
                        history.push("/designation");
                    }
                })
                .catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                });
        }
    }

    return (
        <div className="content-box">
            <Title level={3}>
                <Link className="link" to={"/designation"} style={{'--color': appColors?.appPrimaryColor ?? ""} as any}>
                    Designation
                </Link>{" "}
                &nbsp; &gt; &nbsp;Add New Designation
            </Title>

            <Form
                layout="vertical"
                autoComplete="off"
                name="designationForm"
                form={designationForm}
                onFinish={onFinishForm}
            >

                <Row gutter={[16, 16]}>
                    <Col xs={24} xl={8} span={8}>
                        <FormInput
                            label="Designation"
                            name="designation"
                            placeholder="Enter your designation"
                            isRequired
                        />

                        <Row gutter={[24, 24]} style={{ justifyContent: "center" }}>
                            <Col span={12}>
                                <Form.Item>
                                    <FormButton
                                        label="Cancel"
                                        className="dynamic-btn-default"
                                        style={
                                            { "--borderColor": appColors?.appSecondaryColor ?? "" } as any
                                        }
                                        onclick={() => history.goBack()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <FormButton
                                        htmlType="submit"
                                        type="primary"
                                        label="Submit"
                                        className="dynamic-btn-primary"
                                        style={
                                        { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AddDesignation;
