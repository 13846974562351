import { Upload, UploadProps, notification } from "antd";
import React, { useCallback, useState } from "react";
import { addDocumentCommon } from "shared/services/Utility";
import { fileUploadCommon } from "shared/services/Utility";

type fileType = "image" | "pdf" | "xls" | "xlsx" | "svg";

interface IAppUpload {
  acceptType?: fileType[];
  documentSetId?: string; // If available then upload document API calling here
  callBack: any;
  className?: string;
  multiple?:boolean;
  showUploadList?: boolean;
  children: React.ReactNode;
}

const AppUpload: React.FC<IAppUpload> = (props: IAppUpload) => {
  const { acceptType, documentSetId, callBack, className, multiple, showUploadList, children } = props;

  const [fileList, setFileList] = useState([] as any);

  const getAccept = () => {
    let fileFormat: string[] = [];
    if (acceptType?.includes("pdf")) {
      fileFormat.push("application/pdf");
    }
    if (acceptType?.includes("xls") || acceptType?.includes("xlsx")) {
      fileFormat.push("application/vnd.ms-excel");
      fileFormat.push(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
    if (acceptType?.includes("svg")) {
      fileFormat.push("image/svg+xml");
    }
    if ((acceptType?.length ?? 0) == 0 || acceptType?.includes("image")) {
      fileFormat.push("image/jpg");
      fileFormat.push("image/jpeg");
      fileFormat.push("image/png");
    }
    return fileFormat;
  };

  const uploadProps: UploadProps = {
    accept: getAccept().join(", "),
    listType: "text",
    showUploadList: showUploadList ?? false,
    multiple: multiple,
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onUpload = useCallback((acceptedFile: any) => {
    if (acceptedFile?.file?.status !== "removed") {
      if (acceptedFile?.file?.size > 9437184) {
        notification.error({ message: "Please attach less than 10MB file" });
        setFileList([]);
        return;
      } else {
        const allowedAttechmentTypes = getAccept();
        console.log("allowedAttechmentTypes: ", allowedAttechmentTypes);
        if (allowedAttechmentTypes.indexOf(acceptedFile?.file?.type) === -1) {
          const msgType = acceptType?.join(", ");
          notification.error({
            message: `Please attach only ${msgType ?? "image"} file.`,
          });
          return;
        }
        UploadDocumentHandler(acceptedFile?.file);
      }
    }
  }, []);

  const UploadDocumentHandler = async (file: any) => {
    const response = await fileUploadCommon(file);
    if (response?.data && response?.file) {
      if ((documentSetId?.length ?? 0) > 0) {
        uploadDocumtnt(response?.file, response?.data);
      } else {
        callBack(response);
      }
    }
  };

  const uploadDocumtnt = async (file: any, data: any) => {
    const response = await addDocumentCommon(
      documentSetId ?? "",
      data,
      file.name,
      "",
      ""
    );
    if (response?.payload) {
      callBack(response?.payload);
    }
  };

  return (
    <>
      <Upload className={className ?? ""} onChange={onUpload} {...uploadProps}>
        {children}
      </Upload>
    </>
  );
};

export default AppUpload;
