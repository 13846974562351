import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IScreenHeader {
  backScreenTitle?: string;
  backScreenPath?: string;
  screenTitle?: string;
}

const screenHeader: IScreenHeader = {
  backScreenTitle: "",
  backScreenPath: "",
  screenTitle: "",
};

export const commonSlice = createSlice({
  name: "CommonScreenReducer",
  initialState: { screenHeader },
  reducers: {
    setScreenHeader: (state, action: PayloadAction<IScreenHeader>) => {
      state.screenHeader.screenTitle = action?.payload?.screenTitle;
      state.screenHeader.backScreenPath = action?.payload?.backScreenPath;
      state.screenHeader.backScreenTitle = action?.payload?.backScreenTitle;
    },
  },
});

export const { setScreenHeader } = commonSlice.actions;
export default commonSlice.reducer;
