import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Form,
  notification,
  Switch,
  Popconfirm,
  List,
  Card,
} from "antd";

import { FaSearch, FaPlus } from "react-icons/fa";
import { ReactComponent as IconEyeBorder } from "../../assets/images/eye_border.svg";
import { ReactComponent as IconEditBorder } from "../../assets/images/edit_border.svg";
import { AiFillDelete } from "react-icons/ai";
import type { ColumnsType } from "antd/es/table";

import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  getBasicDetails,
  hasUserRole,
  isUserVerified,
} from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { useDispatch } from "react-redux/es/exports";
import {
  setEmployeesPageNo,
  setEmployeesPageSize,
  setEmployeesSearchParam,
} from "shared/redux/employees-reducer";
import {
  setIsAppFooterShow,
  setIsAppHeaderShow,
  setShowProfielVerification
} from "shared/redux/common-reducer";
import FormButton from "shared/components/FormButton/FormButton";
import blackRefereshIcon from "../../assets/images/blackReferesh.svg";
const { Title } = Typography;
type Props = {};

const Employees: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [designationList, setDesignationList] = useState([]);
  const [total, setTotal] = useState();
  const employees: any = useSelector((state: RootState) => state.employees);
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userVerified = () => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true))
      notification.error({ message: "Please verify your profile to proceed" });
      return false
    }
    return true
  }

  const columns: ColumnsType<any> = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Mobile No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Designation",
      dataIndex: "connectorDesignation",
      key: "connectorDesignation",
      render: (_: any, record: any) => {
        const designationData: any = designationList.find((designation: any) =>
          designation.id == record.connectorDesignation
        )
        return designationData?.name ?? "";
      },
    },
    {
      title: "Line Manager",
      dataIndex: "connectorManagerName",
      key: "connectorManagerName",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: any) => (
        <Switch
          checked={record.active}
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={{ '--bgColor': appColors?.appPrimaryColor ?? "" } as any}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => goToLeadDetails(record)}
          />
          <Button
            className="custome-btn-icon"
            style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
            onClick={() => goToEdit(record)} />

          {/* <Popconfirm
            title="Sure to delete?"
            onConfirm={(event) => handleDelete(event, record)}
          >
            <Space
              className="action-button"
              size="middle"
              style={{ marginLeft: "1%" }}
            >
              <Button
                className="pink"
                size="small"
                type="primary"
                shape="circle"
                icon={<AiFillDelete />}
              // hidden={
              //   !(
              //     userRolePermission["UpdateUser"] ||
              //     userRolePermission["Admin"]
              //   )
              // }
              />
            </Space>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(true));
    getDesignation()

    form.setFieldsValue({
      searchBox: employees.searchParam,
    });
    getAllEmployees(
      employees.pageNo,
      employees.pageSize,
      employees.searchParam
    );
  }, []);

  const onChangeStatus = (event: any, item: any) => {
    item.active = event;
    setLoading(true);
    API_SERVICE.statusChange(item.userId, item.active)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          getAllEmployees(
            employees.pageNo,
            employees.pageSize,
            employees.searchParam
          );
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleDelete = (event: any, item: any) => {
    item.active = false;
    setLoading(true);
    API_SERVICE.statusChange(item.userId, item.active)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          let page = null as any;
          if (totalCount / 10 === parseFloat(employees.pageNo - 1 + "." + 1)) {
            //setCurrentPage(1);
            dispatch(setEmployeesPageNo(1));
            page = 1;
          } else {
            //setCurrentPage(employees.pageNo);
            dispatch(setEmployeesPageNo(employees.pageNo));
            page = employees.pageNo;
          }
          getAllEmployees(page, employees.pageSize, employees.searchParam);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllEmployees = (
    pageNo: number = 1,
    pageSize: any = null,
    searchVal: any = null
  ) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal,
    };
    setLoading(true);
    API_SERVICE.getSubConnector(params)
      .then(({ data }) => {
        mapLeadsData(data);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllEmployees(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const addHandler = () => {
    if (userVerified()) {
      history.push("/employees/add-employee");
    }
  };

  const goToLeadDetails = (item: any) => {
    if (userVerified()) {
      history.push({
        pathname: `/employees/${item.userId}`,
        state: { item, type: "view" },
      });
    }
  };

  const goToEdit = (item: any) => {
    if (userVerified()) {
      history.push({
        pathname: `/employees/edit/${item.userId}`,
        state: { item, type: "edit" },
      });
    }
  };

  const handleSearch = (event: any) => {
    //setCurrentPage(1);
    //setSearchValue(event.target.value);
    dispatch(setEmployeesPageNo(1));
    dispatch(setEmployeesSearchParam(event.target.value));
    getAllEmployees(1, employees.pageSize, event.target.value);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    //setCurrentPage(page);
    //setPageSize(pagination.pageSize);
    dispatch(setEmployeesPageNo(current));
    dispatch(setEmployeesPageSize(pageSize));
    setTotal(total);
    getAllEmployees(current, pageSize, employees.searchParam);
  };

  const getDesignation = () => {
    API_SERVICE.getDesignation()
      .then(({ data }) => {
        setDesignationList(data?.payload?.content ?? [])
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  console.log("userDetail: ", getBasicDetails());

  return (
    <div className="content-box">
      <Title level={3}>Manage Employees</Title>
      <Form form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={18} md={14} lg={14}  >
            <Form.Item name="searchBox">
              <Input
                size="large"
                className="search-box"
                placeholder="Search"
                allowClear
                prefix={<FaSearch />}
                onChange={debounce(handleSearch, 1000)}
              />
            </Form.Item>
          </Col>
          <Col xs={8} sm={6} md={4} lg={4} style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: "",
                });
                dispatch(setEmployeesSearchParam(""));
                dispatch(setEmployeesPageNo(1));
                getAllEmployees(1, employees.pageSize, "");
              }}
            ><img src={blackRefereshIcon} alt="" style={{ marginLeft: '-5px' }} /></Button>
          </Col>
          <Col xs={15} sm={6} md={6} lg={5}>
            {(getBasicDetails()?.canAddEmployee || getBasicDetails()?.connectorRole == "CONNECTOR_ADMIN") && (

              <FormButton
                label="Add Employee"
                type="primary"
                className="dynamic-btn-primary"
                style={{ '--btnColor': appColors?.appPrimaryColor ?? "" } as any}
                iconPositon="left"
                icon={<FaPlus />}
                onclick={addHandler}
              />
            )}
          </Col>
        </Row>
      </Form>
      <Row gutter={24} style={{ marginTop: isMobile ? "20px" : "10px" }}>
        {isMobile ? (
          <List
            style={{ width: "100%" }}
            grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
            pagination={{
              onChange: (page, pageSize) => {
                handlePaginationChange(page, pageSize);
              },
              current: employees.pageNo,
              pageSize: employees.pageSize,
              total: totalCount,
            }}
            itemLayout="horizontal"
            dataSource={allEmployees}
            renderItem={(item: any) => (
              <List.Item style={{ padding: "0px 12px" }}>
                <Card>
                  <Row gutter={24}>
                    <Col span={12}>
                      <label className="leadlist-title">Full Name</label>
                      <br />
                      {item?.fullName}
                    </Col>
                    <Col span={12} className="text-right">
                      <Space>
                        <Switch
                          checked={item.active}
                          onChange={(event) => onChangeStatus(event, item)}
                        />
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={(event) => handleDelete(event, item)}
                          okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border: "none" } }}
                          cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
                        >
                          <Space
                            className="action-button"
                            size="middle"
                            style={{ marginLeft: "1%" }}
                          >
                            <Button
                              className="pink"
                              size="small"
                              type="primary"
                              shape="circle"
                              icon={<AiFillDelete />}
                              hidden={
                                !(
                                  userRolePermission["UpdateUser"] ||
                                  userRolePermission["Admin"]
                                )
                              }
                            />
                          </Space>
                        </Popconfirm>
                      </Space>
                    </Col>
                  </Row>
                  <Row className="title-space">
                    <Col span={11}>
                      <label className="leadlist-title"> Email</label>
                      <br />
                      <span style={{ overflowWrap: "break-word" }}>
                        {" "}
                        {item?.loginName}
                      </span>
                    </Col>
                    <Col span={7}>
                      <label className="leadlist-title">Mobile No</label>
                      <br />
                      {item?.phoneNumber}
                    </Col>
                    <Col span={6} className="text-right">
                      <label className="leadlist-title">Boss</label>
                      <br />
                      {""}
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <Col span={24} >
            <Table
              className="dynamic-pagination-checkbox"
              style={{ '--bgColor': appColors?.appPrimaryColor ?? "" } as any}
              loading={loading}
              scroll={{ x: "max-content" }}
              columns={columns}
              dataSource={allEmployees}
              size="small"
              onChange={(pagination: any) => {
                handlePaginationChange(pagination.current, pagination.pageSize);
              }}
              pagination={{
                current: employees.pageNo,
                pageSize: employees.pageSize,
                total: totalCount, // total count returned from backend
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Employees;
