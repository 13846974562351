import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import iconInfo from "../../../assets/images/info.svg";
import "./FormInput.scss";

interface IFormInput {
  name: string;
  type?: string;
  label?: string;
 classNameForm?: string;
  inputMode?:
    | "url"
    | "email"
    | "text"
    | "search"
    | "none"
    | "tel"
    | "numeric"
    | "decimal";
  tooltip?: string;
  placeholder?: string;
  prefix?: string;
  isRequired?: boolean;
  requiredLabel?: string;
  isDisabled?: boolean;
  isBorder?: boolean;
  isUpperCase?: boolean;
  rules?: Rule[] | undefined;
  defaultValue?: string;
  max?:BigInteger;
  min?:number;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  removeDefaultCss?:boolean;
}

const FormInput = (props: IFormInput) => {
  const {
    name,
    type,
    inputMode,
    label,
    tooltip,
    classNameForm,
    isRequired,
    requiredLabel,
    prefix,
    isBorder,
    isDisabled,
    isUpperCase,
    placeholder,
    rules,
    defaultValue,
    onChange,
    onBlur,
    min,
    removeDefaultCss
  } = props;

  const toInputUppercase = (e: any) => {
    if (isUpperCase) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  //const typeTmp = type == "number" ? "text" : type;
  return (
    <>
      <Form.Item
       className={removeDefaultCss ? '' : "field-bg"}
       label={label}
       name={name}
       tooltip={!tooltip ? "" : { title: tooltip, icon: <img src={iconInfo} height={20} width={20} /> }}
       rules={[
        {
          required: isRequired,
          message: `Please enter your ${requiredLabel ?? label ?? placeholder}`
          // message: `Please Enter ${label ?? placeholder}`,
        },
            ...(rules ?? []),
        ]}
      >
        <Input
          className="custom-input"
          size="large"
          type={type ?? "text"}
          placeholder={placeholder}
          onInput={toInputUppercase}
          inputMode={inputMode ?? "text"}
          disabled={isDisabled}
          prefix={prefix}
           min={min}
          // className="form-input"
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Form.Item>
    </>
  );
};

export default FormInput;
