import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Row,
  Col,
  Form,
  Button,
  Table,
  Space,
  notification,
  AutoComplete,
  Select,
  Tooltip,
  Modal,
} from "antd";
import {
  FaSearch,
  FaFilter,
  FaPlus,
  FaEye,
  FaPen,
  FaInfo,
} from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { GrCircleInformation } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { hasUserRole, numberWithCommas } from "shared/services/Utility";
import CheckableTag from "antd/lib/tag/CheckableTag";
import type { ColumnsType } from "antd/es/table";
import iconError from "../../assets/images/error.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAppFooterShow,
  setIsAppHeaderShow,
} from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import blackRefereshIcon from "../../assets/images/blackReferesh.svg";
import {
  setPayoutPageNo,
  setPayoutPageSize,
  setPayoutSearchParam,
  setPayoutStatus,
} from "shared/redux/payout-reducer";

const { Title, Text } = Typography;
type Props = {};

const PayoutRequest: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allConnectors, setAllConnectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isKycAgreement, setIsKycAgreement] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState(null as any);
  const payoutReq: any = useSelector((state: RootState) => state.payoutReq);
  const { appColors } = useSelector((state: RootState) => state.common);
  
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [listCase, setListCase] = useState([
    {
      key: "DISBURSED",
      value: "Case Disbursed",
    },
    {
      key: "REQUESTED",
      value: "Payout Requested",
    },
    {
      key: "APPROVED",
      value: "Payout Approved",
    },
    {
      key: "COMPLETED",
      value: "Payout Completed",
    },
  ]);

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(false));
    // setPages(1);
  }, []);

  const colCaseId: any = {
    title: "Case Id",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "caseId"
        : ["payoutLenderDetails", "caseId"],
    key: "caseId",
    fixed: "left",
  };

  const colDisbursalId: any = {
    title: "Disbursal ID",
    dataIndex: "disbursalId",
    key: "disbursalId",
    fixed: "left",
  };

  const colPayoutId: any = {
    title: "Payout ID",
    dataIndex: "payoutId",
    key: "payoutId",
  };

  const colConnectorName: any = {
    title: "Connector Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? ["connectorName"]
        : ["payoutLenderDetails", "connectorName"],
    key: "connectorName",
  };

  const colBorrowerName: any = {
    title: "Borrower Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "borrowerName"
        : ["payoutLenderDetails", "borrowerName"],
    key: "borrowerName",
  };

  const colLenderName: any = {
    title: "Lender Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "lenderName"
        : ["payoutLenderDetails", "lenderName"],
    key: "lenderName",
  };

  const colProduct: any = {
    title: "Product",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "loanProductName"
        : ["payoutLenderDetails", "loanProductName"],
    key: "product",
  };

  const colDisbursalDate: any = {
    title: "Disbursed Date",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "disbursalDate"
        : ["payoutLenderDetails", "disbursedDate"],
    key: "disbursalDate",
  };

  const colPayoutReqDate: any = {
    title: "Payout Req Date",
    dataIndex: "requestedDate",
    key: "payoutReqDate",
  };

  const colApprovalDate: any = {
    title: "Payout Approval Date",
    dataIndex: "approvalDate",
    key: "approvalDate",
  };

  const colCompletedDate: any = {
    title: "Payment Date",
    dataIndex: "transferAmountDate",
    key: "completedDate",
  };

  const colDisbursedAmount: any = {
    title: "Disbursed Amount",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "disbursedAmount"
        : ["payoutLenderDetails", "disbursedAmount"],
    key: "disbursedAmount",
  };

  const colInsuranceAmount: any = {
    title: "Insurance Amount",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "insuranceAmount"
        : ["payoutLenderDetails", "insuranceAmount"],
    key: "insuranceAmount",
  };

  const colTentativePayoutPercentage: any = {
    title: "Payout %",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "tentativePayoutPercentage"
        : ["monetaryDetails", "payoutPercentage"],
    key: "tentativePayoutPercentage",
  };

  const colAdjustments: any = {
    title: "Adjustments",
    dataIndex: ["monetaryDetails", "adjustmentAmount"],
    key: "adjustments",
  };

  const colTentativeTaxableValue: any = {
    title: ["APPROVED", "COMPLETED"].includes(payoutReq.payoutStatus)
      ? "Taxable Value"
      : "Tentative Taxable Value",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "tentativeTaxableValue"
        : ["monetaryDetails", "grossAmount"],
    key: "tentativeTaxableValue",
  };

  const colTds: any = {
    title: "TDS",
    dataIndex: ["monetaryDetails", "tdsAmount"],
    key: "tds",
  };

  const colGst: any = {
    title: "GST",
    dataIndex: ["monetaryDetails", "gstAmount"],
    key: "gst",
  };

  const colNetAmount: any = {
    title: "Net Amount",
    dataIndex: ["monetaryDetails", "netPayoutAmount"],
    key: "netAmount",
    render: (_: any, record: any) => {
      return (
        <>
          {record?.monetaryDetails?.netPayoutAmount < 0 ? (
            <span style={{ color: "red" }}>
              {record?.monetaryDetails?.netPayoutAmount}
            </span>
          ) : (
            <span>{record?.monetaryDetails?.netPayoutAmount}</span>
          )}
        </>
      );
    },
  };

  const colComment: any = {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  };

  const colRectify: any = {
    title: "Rectify",
    dataIndex: "rectify",
    key: "rectify",
    align: "center",
    className: ["APPROVED", "COMPLETED"].includes(payoutReq.payoutStatus)
      ? "hide-columns"
      : "",
    render: (_: any, record: any) =>
      record?.errorDescription?.length > 0 && (
        <Tooltip placement="topRight" title={record?.errorDescription}>
          <img src={iconError} width="20px" height="20px" alt="" />
        </Tooltip>
      ),
  };

  const columnsDisbursed: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProduct,
    colDisbursalDate,
    colDisbursedAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colTentativeTaxableValue,
    colRectify,
  ];

  const columnsRequested: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProduct,
    colDisbursalDate,
    colPayoutReqDate,
    colDisbursedAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colTentativeTaxableValue,
  ];

  const columnsApproved: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProduct,
    colDisbursalDate,
    colPayoutReqDate,
    colApprovalDate,
    colDisbursedAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colAdjustments,
    colTentativeTaxableValue,
    colTds,
    colGst,
    colNetAmount,
    colComment,
  ];

  const columnsCompleted: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProduct,
    colDisbursalDate,
    colPayoutReqDate,
    colApprovalDate,
    colCompletedDate,
    colDisbursedAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colAdjustments,
    colTentativeTaxableValue,
    colTds,
    colGst,
    colNetAmount,
    colComment,
  ];

  const filterColumns = () => {
    if ("DISBURSED" == payoutReq.payoutStatus) {
      return columnsDisbursed;
    } else if ("REQUESTED" == payoutReq.payoutStatus) {
      return columnsRequested;
    } else if ("APPROVED" == payoutReq.payoutStatus) {
      return columnsApproved;
    } else if ("COMPLETED" == payoutReq.payoutStatus) {
      return columnsCompleted;
    }
  };

  const handlePaginationChange = (pagination) => {
    let page =
      pagination?.pageSize != payoutReq.pageSize ? 1 : pagination.current;
    dispatch(setPayoutPageNo(page));
    dispatch(setPayoutPageSize(pagination.pageSize));
    getPayoutAllList(page, pagination.pageSize, payoutReq.searchParam);
  };

  const getPayoutAllList = (
    pageNo: number = 1,
    pageSize: number = 10,
    searchVal: any = null
  ) => {
    if (payoutReq.payoutStatus == "DISBURSED") {
      getPayoutCaseDisbursed(pageNo, pageSize, searchVal);
    } else {
      getPayoutList(pageNo, pageSize, searchVal);
    }
  };

  const getPayoutCaseDisbursed = (pageNo, pageSize, searchVal) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal,
    };
    setLoading(true);
    API_SERVICE.getPayoutsOfConnectorsDisbursed(params)
      .then(({ data }: any) => {
        setAllConnectors(data?.payload?.content);
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map((connector: any) => {
            connector.key = connector.disbursalId;
          });
        }
        setTotalCount(data?.payload?.totalElements);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPayoutList = (pageNo, pageSize, searchVal) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal,
      payoutStatus: payoutReq.payoutStatus,
    };
    setLoading(true);
    API_SERVICE.getPayoutOfConnectorsPayout(params)
      .then(({ data }) => {
        setAllConnectors(data?.payload?.content);
        setTotalCount(data?.payload?.totalElements);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (event: any) => {
    dispatch(setPayoutPageNo(1));
    dispatch(setPayoutSearchParam(event.target.value));
    getPayoutAllList(1, payoutReq.pageSize, event.target.value);
  };

  const handleConnectorTagChange = (i: number, value: string) => {
    dispatch(setPayoutPageNo(1));
    dispatch(setPayoutStatus(value));
  };

  useEffect(() => {
    getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
  }, [payoutReq.payoutStatus]);

  const handlePayoutReq = () => {
    const param: any = [];
    selectedRowKeys.map((id: any) => {
      const tmpObj = { disbursalId: id };
      param.push(tmpObj);
      return tmpObj;
    });
    if (selectedRowKeys?.length > 0) {
      API_SERVICE.createPayoutReq(param)
        .then(({ data }) => {
          notification.success({ message: data?.message });
          setIsKycAgreement(false);
          getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    } else {
      notification.error({ message: "Please select at least 1 payout!" });
      setIsKycAgreement(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: () => ({
      disabled: payoutReq.payoutStatus !== "DISBURSED",
    }),
  };
  
  const customBtnStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const ModalBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
  const customBtnPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? "",height: "35px", borderRadius: "8px"  };

  return (
    <>
      <div className="content-box">
        <Title level={3}>Connector Payout Requests</Title>
        <Form form={form}>
          <Row gutter={24} className="data-filter-bar">
            <Col xs={24} sm={14} md={16} lg={18} className="pd-lr-10">
              <Form.Item name="searchBox">
                <Input
                  size="large"
                  className="search-box"
                  onChange={debounce(handleSearch, 1000)}
                  placeholder="Search"
                  allowClear
                  prefix={<FaSearch />}
                />
              </Form.Item>
            </Col>
            <Col xs={9} sm={5} md={4} lg={3} className="pd-lr-10">
              {/* <Tooltip title="Filter">
                <Button type="primary" className="filter-btn" icon={<FaFilter />} onClick={() => { form.setFieldsValue({ searchBox: "" }) }}></Button>
              </Tooltip> */}
              <Tooltip title="Refresh">
                <Button
                  type="primary"
                  className="refresh-button"
                  onClick={() => {
                    form.setFieldsValue({ searchBox: "" });
                    setSearchValue("");
                    getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
                  }}
                 
                ><img src={blackRefereshIcon} alt="" style={{ marginLeft: '-5px' }} /></Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        <Row className="payout-tabs">
          <Col>
            {listCase.map((item: any, i: number) => (
              <CheckableTag
                className="custom-tag"
                key={i}
                checked={item.key === payoutReq.payoutStatus}
                onChange={(checked) => handleConnectorTagChange(i, item.key)}
              >
                {item.value}
              </CheckableTag>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className={
                (payoutReq.payoutStatus === "DISBURSED" ? "" : "hide-selection") + " dynamic-pagination-checkbox"
              }
              style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              loading={loading}
              columns={filterColumns()}
              dataSource={allConnectors}
              onChange={handlePaginationChange}
              pagination={{
                current: payoutReq.pageNo,
                pageSize: payoutReq.pageSize,
                total: totalCount, // total count returned from backend
              }}
              size="small"
            />
            {payoutReq.payoutStatus == "DISBURSED" ? (
              <div style={{ padding: "5px", marginTop: "-30px" }}>
                {allConnectors?.length > 0 && (
                  <Button
                    type="primary"
                    className="dynamic-btn-primary-default"
                    style={customBtnPrimary}
                    onClick={() => setIsKycAgreement(true)}
                  >
                    Request Payout
                  </Button>
                )}
                <div style={{ marginTop: "20px" }}>
                  <li>
                    The payout displayed is subject to adjustments related to
                    subventions, advance payments etc. which are calculated post
                    payout request.
                  </li>
                  <li>
                    {" "}
                    The Tentative Taxable value of payout does not include taxes
                    like GST
                  </li>
                  <li>
                    {" "}
                    For any queries please contact your relationship manager
                  </li>
                </div>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <Modal
        width={350}
        visible={isKycAgreement}
        style={{ marginBottom: 230 }}
        centered
        closable={false}
        footer={false}
      >
        <p style={{ marginTop: "30px", textAlign: "center" }}>
          Please ensure that your agreement, KYC and bank details have been
          shared to ensure seamless payout.
        </p>
        <Space
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            className="agreement-btn dynamic-btn-primary"
            style={ModalBtnPrimary}
            type="primary"
            onClick={handlePayoutReq}
          >
            OK
          </Button>
          <Button
            onClick={() => setIsKycAgreement(false)}
            className="agreement-btn dynamic-btn-default"
            style={customBtnStyle}
          >
            Back
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default PayoutRequest;
