import {
  Button,
  Form,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import FormInput from "../../shared/components/FormInput/FormInput";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Text } = Typography;

interface IPanVerification {
  panDetails: any;
  contactDetails: any;
  actionPanContinue: Function;

}

const PanVerification: React.FunctionComponent<IPanVerification> = (
  props: IPanVerification
) => {
  const { panDetails, contactDetails, actionPanContinue } = props;

  const [form] = Form.useForm() as any;
  const { appColors } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    const arr = panDetails?.fullName?.split(" ")
    const name = contactDetails?.panName?.split(" ");
    name?.map((item: any) => {
      if (arr?.includes(item?.toUpperCase())) {
        form.setFieldsValue({
          panNameConfirm: panDetails?.fullName,
        });
      }
    })
  }, [form.resetFields()]);

  const checkPanName = (_: any, value: any) => {
    if (value == "") {
      return Promise.resolve();
    }
    if (value == panDetails?.fullName) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Name as per PAN Card does not match`));
    }
  };

  return (
    <div style={{ display: "grid" }}>
      <Text style={{ marginTop: "20px", color: "red", fontSize: "16px" }}>
        PAN and profile name mismatch. Please enter your name as per your PAN
        card.
      </Text>

      <Form
        layout="vertical"
        autoComplete="off"
        name="leadForm"
        style={{ paddingBlock: "20px", marginInline: "0px" }}
        form={form}
        onFinish={() => actionPanContinue()}

      >
        <FormInput
          name="panNameConfirm"
          label={"Name as per PAN Card"}
          isRequired
          requiredLabel={"Name as per PAN Card"}
          isUpperCase
          rules={[{ validator: checkPanName }]}
        />

        <Button type="primary" htmlType="submit"
          className="dynamic-btn-primary"
          style={
            { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
          }
          size="large">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default PanVerification;
