import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  Space,
  notification,
  Divider,
  InputNumber,
  Select,
  DatePicker,
} from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";

import debounce from "lodash/debounce";

import API_SERVICE from "shared/services/api-service";

//import * as _ from "lodash";
//import "./index.scss";
import {
  GREATERTHEN_ZERO_REGEX,
  STRING_ONLY,
} from "shared/constants/AppConstants";
import moment from "moment";
import FormDOB from "shared/components/FormDOB/FormDOB";
import { numberWithCommas } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IAddLeadV1 {
  setViewType?: any;
  userData?: any;
}

const AddLeadV1: React.FunctionComponent<IAddLeadV1> = (props: IAddLeadV1) => {
  const { setViewType, userData } = props;

  const history = useHistory();
  const location = useLocation() as any;
  const [loading, setLoading] = useState(false);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [genders, setGenders] = useState([]);
  const [selectedApplicationType, setSelectedApplicationType] =
    useState("INDIVIDUAL");
  const [leadForm] = Form.useForm() as any;
  const [bureauScoreStatus, setBureauScoreStatus] = useState("");
  let { id } = useParams() as any;
  const [editData, setEditData] = useState({} as any);
  const [isPersonalLoan, setIsPersonalLoan] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState("");
  const [hideBusinessDetails, setHideBusinessDetails] = useState(false);
  const [backOfficerUserId, setBackOfficerUserId] = useState(null as any);
  const [businessProfile, setBusinessProfile] = useState([] as any);
  const [placeOfStudys, setPlaceOfStudys] = useState([] as any);
  const [admissionStatus, setAdmissionStatus] = useState([] as any);
  const [propertyOwnership, setPropertyOwnership] = useState([] as any);
  const [isDisableBusinessData, setIsDisableBusinessData] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  useEffect(() => {
    leadForm.setFieldsValue({
      loanType: location?.state?.radioValue || "",
    });
    getAllTypes();
    setEnumValues();

    if (id || userData?.caseDetails?.caseId) {
      getLeadById();
    }
  }, []);


  const getLeadById = () => {
    let user = localStorage.getItem("user") as any;
    if (user) {
      user = JSON.parse(user);
    }
    API_SERVICE.getLeadsById(id || userData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          setEditData(data.payload);
          const payoutType =
            data.payload.caseDetails.connectorDetails.payoutType;
          if (data?.payload?.applicationType === null) {
            if (isDisableBusinessData === false) {
              setIsDisableBusinessData(true);
            }
          }
          prepareDataForEdit(
            data.payload,
            data.payload.caseDetails.assignedToUserDetails
          );
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const prepareDataForEdit = (userData: any, assignTo) => {
    let enumValues = localStorage.getItem("enumValues") as any;
    let aType: any = null;
    let iType: any = null;
    let bType: any = null;

    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let businessTypes = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );

      aType = filteredApplicationType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationType
      ) as any;
      iType = filteredIndivType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
      bType = businessTypes.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
    }

    let filteredLoanType = enumValues?.find(
      (item: any) => item.propertyName === "LOAN_TYPE"
    );

    let loanType = "";
    const loanTypesFiltered = filteredLoanType?.keyAndDisplayValueList?.filter(
      (item) => item.key === userData?.loanProductId
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const tmpIsPersonalLoan = loanType === "Personal Loan";
    const tmpIsEL = loanType === "Education Loan";
    setIsPersonalLoan(tmpIsPersonalLoan);
    setSelectedLoanType(loanType);
    setIsDisableBusinessData(false);

    const tmpIndiType = tmpIsPersonalLoan ? "SALARIED" : iType?.key;
    if (loanType === "Home loan" && tmpIndiType == "SALARIED") {
      setHideBusinessDetails(true);
    } else {
      setHideBusinessDetails(false);
    }

    const timeOfStudy =
      userData.caseDetails.loanDetails?.educationLoanDetails?.timeOfStudy;

    setSelectedApplicationType(
      tmpIsPersonalLoan || tmpIsEL ? "INDIVIDUAL" : aType?.key
    );
    setBackOfficerUserId(userData?.caseDetails?.backOfficeUserDetails?.userId);
    leadForm.setFieldsValue({
      connector:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData?.caseDetails?.connectorDetails?.userDetails?.fullName
          : "",
      tentativePayout:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData.caseDetails.loanDetails.expectedInterestRate
          : "",
      applicantType:
        tmpIsPersonalLoan || tmpIsEL ? "INDIVIDUAL" : aType?.key || "",
      individualType: tmpIsPersonalLoan ? "SALARIED" : iType?.key || "",
      businessType: bType?.key || "",
      gender: userData.contactDetails.gender,
      pan:
        userData.contactDetails.pan === "PENDING"
          ? ""
          : userData.contactDetails.pan,
      businessPan:
        userData?.contactDetails?.pan === "PENDING"
          ? ""
          : userData.contactDetails.pan,
      businessName: userData.contactDetails.firstName,
      searchCustomer: userData.contactDetails.firstName,
      phoneCode: "+91",
      mobileNumber: userData?.contactDetails?.mobileNumber?.startsWith("1")
        ? ""
        : userData?.contactDetails?.mobileNumber,
      email: userData.contactDetails.emailId,
      officePinCode: userData.officePincode,
      dateOfBirth:
        userData?.contactDetails?.dateOfBirth &&
        moment(userData.contactDetails.dateOfBirth),
      pincode: userData.address.pinCode,
      address1: userData.address.address,
      city: userData.address.city,
      state: userData.address.state,
      employername: userData.entityOrEmployerName,
      loanAmount: userData.caseDetails.loanDetails.loanAmount,
      loanTenure: userData.caseDetails.loanDetails.loanTenureInYears,
      monthlyInHandSalary:
        userData.caseDetails.loanDetails?.monthlyInHandSalary,
      currentEmis: userData.caseDetails.loanDetails?.currentEmis,
      loanType: userData?.loanProductId,
      comment: userData.caseDetails.lenderActivityHolder.comments,
      assignTo: assignTo?.fullName,
      assignToBackOffice:
        userData?.caseDetails?.backOfficeUserDetails?.fullName,
      firstName: userData?.contactDetails?.firstName,
      middleName: userData?.contactDetails?.middleName,
      lastName: userData?.contactDetails?.lastName,
      businessProfile: userData?.businessProfile,
      businessVintage: userData?.businessVintage,
      businessTurnover: userData?.businessTurnOver,
      monthlyEmi: userData?.monthlyEmi,
      propertyOwnership: userData?.caseDetails?.propertyOwnership,
      timeOfStudy: timeOfStudy ? moment(timeOfStudy) : null,
      placeOfStudy:
        userData.caseDetails.loanDetails?.educationLoanDetails?.placeOfStudy,
      admissionStatus:
        userData.caseDetails.loanDetails?.educationLoanDetails?.admissionStatus,
    });

    setBureauScoreStatus(
      userData?.leadAdditionalInfoDetails?.bureauScoreStatus ?? ""
    );
  };

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredLoanType = enumValues.find(
        (item: any) => item.propertyName === "LOAN_TYPE"
      );
      let filteredGenderType = enumValues.find(
        (item: any) => item.propertyName === "GENDER"
      );
      let businessProfileValue = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_PROFILE"
      );
      let propertyOwnershipValue = enumValues.find(
        (item: any) => item.propertyName === "PROPERTY_OWNERSHIP"
      );
      let placeStudyValue = enumValues.find(
        (item: any) => item.propertyName === "PLACE_STUDY"
      );
      let admissionStatusValue = enumValues.find(
        (item: any) => item.propertyName === "ADMISSION_STATUS"
      );

      if (businessProfileValue) {
        setBusinessProfile(businessProfileValue?.keyAndDisplayValueList);
      }
      if (filteredLoanType) {
        setLoanTypes(filteredLoanType.keyAndDisplayValueList);
      }
      if (filteredGenderType) {
        setGenders(filteredGenderType.keyAndDisplayValueList);
      }

      if (propertyOwnershipValue) {
        setPropertyOwnership(propertyOwnershipValue?.keyAndDisplayValueList);
      }
      if (placeStudyValue) {
        setPlaceOfStudys(placeStudyValue?.keyAndDisplayValueList);
      }
      if (admissionStatusValue) {
        setAdmissionStatus(admissionStatusValue?.keyAndDisplayValueList);
      }
    }
  };

  const checkPincode = (e: any) => {
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        leadForm.setFieldsValue({
          city: data.payload.city,
          state: data.payload.state,
        });
      }
    });
  };

  const createPayload = (formData: any) => {
    const isValidDob = moment(
      moment(leadForm.getFieldValue("dateOfBirth"))
        .format("YYYY-MM-DD")
        .toString()
    ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"));
    if (formData && formData.dateOfBirth && !isValidDob) {
      notification.error({
        message: "DOB should be greater than or equal to 18 yrs",
      });
      return;
    }
    const bureauScore =
      bureauScoreStatus.length !== 0
        ? bureauScoreStatus
        : null;

    const assignToIdTmp = editData?.caseDetails?.assignedToUserDetails?.userId;
    const assignToId = assignToIdTmp ?? userDetails?.userDetails?.userId;
    let backOfficeUserId = backOfficerUserId;
    const connectorIdTmp = editData?.caseDetails?.connectorDetails?.userDetails?.userId;
    const connectorId = connectorIdTmp || assignToId;
    let loanType = "";
    const loanTypesFiltered = loanTypes?.filter(
      (item) => item.key === formData.loanType
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const data = {
      loanType: userData?.loanType,
      loanProductId: userData?.loanProductId,
      sourceOfCase: userData?.sourceOfCase,
      address: {
        address: formData?.address1,
        area: formData?.city,
        city: formData?.city,
        country: userDetails?.userDetails?.address?.country,
        geoCode: userDetails?.userDetails?.address?.geoCode,
        pinCode: formData?.pincode,
        state: formData?.state,
      },
      applicationSubType:
        userData?.applicationSubTypeKey,
      applicationType: userData?.applicationTypeKey,
      officePincode: formData.officePinCode,
      comments: formData.comment,
      assignToUserId: assignToId,
      backOfficeUserId: backOfficeUserId,
      businessProfile: formData?.businessProfile,
      businessTurnOver: formData?.businessTurnover,
      monthlyEmi: formData?.monthlyEmi,
      propertyOwnership: formData?.propertyOwnership,
      businessVintage: formData?.businessVintage,
      commissionDetail: {
        connectorName: formData.firstName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: connectorId,
      customerId: userData?.customerId,
      nationality: null,
      bureauScoreStatus: bureauScore,
      contactDetails: {
        gender: formData.gender,
        firstName: formData?.firstName,
        middleName: formData?.middleName,
        lastName: formData?.lastName,
        dateOfBirth:
          formData &&
            formData.dateOfBirth &&
            moment(
              moment(leadForm.getFieldValue("dateOfBirth"))
                .format("YYYY-MM-DD")
                .toString()
            ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"))
            ? moment(formData.dateOfBirth).format("YYYY-MM-DD").toString()
            : "",
        emailId: formData.email,
        mobileNumber: formData.mobileNumber,
        pan:
          userData?.applicationTypeKey === "INDIVIDUAL"
            ? formData.pan
            : formData.businessPan,
      },
      entityOrEmployerName: formData.employername,
      loanDetails: {
        expectedInterestRate: userData?.loanDetails?.expectedInterestRate,
        loanAmount: formData.loanAmount,
        loanTenureInYears: formData.loanTenure,
        monthlyInHandSalary: formData.monthlyInHandSalary,
        currentEmis: formData.currentEmis,
        educationLoanDetails: {
          timeOfStudy: formData?.timeOfStudy
            ? moment(formData.timeOfStudy).format("YYYY-MM").toString()
            : null,
          placeOfStudy: formData?.placeOfStudy ?? null,
          admissionStatus: formData?.admissionStatus ?? null,
        },
      },
    };
    return data;
  };

  const onFinishForm = (values: any) => {
    if (id || userData?.caseDetails?.caseId) {
      const payload = createPayload(values);
      setLoading(true);
      API_SERVICE.editLead(editData.caseDetails.caseId, payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            history.push(`/lead/details/${data?.payload?.caseDetails?.caseId}`);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const payload = createPayload(values);
      setLoading(true);
      API_SERVICE.addLead(payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            history.push(`/lead/details/${data?.payload?.caseDetails?.caseId}`);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const disableDate = (current) => {
    return current && current > new Date();
  };

  const disableTimeOfStudy = (current) => {
    return current && current < new Date();
  };

  if (!userDetails) return <></>;

  const renderEducationDetails = () => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Education Details
        </Divider>

        <Form.Item
          className="field-bg"
          label="Time of Study (Starts from)"
          name="timeOfStudy"
          rules={[
            {
              required: true,
              message: "Please enter time of study",
            },
          ]}
        >
          <DatePicker
            className="custom-input"
            picker="month"
            placeholder="YYYY-MM"
            format={"yyyy-MM"}
            disabledDate={disableTimeOfStudy}
          />
        </Form.Item>

        <Form.Item
          className="field-bg"
          label="Preferred Place of Study"
          name="placeOfStudy"
          rules={[
            {
              required: true,
              message: "Please enter preferred place of study",
            },
          ]}
        >
          <Select placeholder="Please select" className="custom-select">
            {placeOfStudys &&
              placeOfStudys?.map((loan: any, i: number) => (
                <Select.Option key={i} value={loan.key}>
                  {loan.value}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="field-bg"
          label="Admission Status"
          name="admissionStatus"
          rules={[
            {
              required: true,
              message: "Please enter admission status",
            },
          ]}
        >
          <Select placeholder="Please select" className="custom-select">
            {admissionStatus &&
              admissionStatus?.map((loan: any, i: number) => (
                <Select.Option key={i} value={loan.key}>
                  {loan.value}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const customStylesBtnPrimary:any = {'--btnColor': appColors?.appPrimaryColor ?? ""};

  return (
    <>
      <Form
        style={{ marginTop: "50px" }}
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          applicantType: "INDIVIDUAL",
          gender: "M",
          assignTo: userDetails?.userDetails?.fullName,
        }}
        form={leadForm}
        onFinish={onFinishForm}
        autoComplete="off"
        name="leadForm"
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <Divider orientation="left" orientationMargin="0">
              Personal Details
            </Divider>
            <div className={"form-box"}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Please select Gender" }]}
              >
                <Radio.Group
                  className="custom-radio"
                  style={customStylesBtn}
                  name="loanTypes"
                  defaultValue={1}
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((loan: any, i: number) => (
                        <Radio.Button key={i} value={loan.key}>
                          {loan.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              {selectedApplicationType == "INDIVIDUAL" && (
              <Form.Item
                className="field-bg"
                label="Pan"
                name="pan"
                rules={[
                  {
                    required: false,
                    message: "Please enter pan number",
                  },
                  {
                    pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                    message: "Please enter valid pan number",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Pan number"
                  onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
                />
              </Form.Item>
              )}
              <Form.Item
                className="field-bg"
                label="Email"
                name="email"
                rules={[
                  { required: false, message: "Please enter email" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input className="custom-input" placeholder="Email" />
              </Form.Item>
              <FormDOB
                form={leadForm}
                disableDate={disableDate}
                label="Date of Birth (YYYY-MM-DD)"
                placeholder="Date of Birth"
                name="dateOfBirth"
                isRequired={false}
              />
            </div>
            <div>
              <Divider orientation="left" orientationMargin="0">
                Current Address
              </Divider>
              <Form.Item
                className="field-bg"
                label="Pin Code"
                name="pincode"
                rules={[
                  { required: false, message: "Please enter pin code" },
                  {
                    pattern: /^[1-9][0-9]{5}$/,
                    message: "Please enter valid pincode",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  onChange={debounce(checkPincode, 1000)}
                  placeholder="Please enter pin code"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Address Line 1"
                name="address1"
                rules={[{ required: false, message: "Please enter addredd" }]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter address"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="City"
                name="city"
                rules={[
                  { required: false, message: "Please enter city" },
                  { pattern: STRING_ONLY, message: "Please enter valid city" },
                ]}
              >
                <Input className="custom-input" placeholder="Please enter city" />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="State"
                name="state"
                rules={[
                  { required: false, message: "Please enter state" },
                  { pattern: STRING_ONLY, message: "Please enter valid state" },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter state"
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            {selectedLoanType != "Education Loan" && (
              <div>
                <Divider orientation="left" orientationMargin="0">
                  Employer Details
                </Divider>
                <Form.Item
                  className="field-bg"
                  label="Employer / Entity name"
                  name="employername"
                  rules={[
                    {
                      required: false,
                      message: "Enter employer / entity name",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Employer / entity name"
                  />
                </Form.Item>
                {(isPersonalLoan || hideBusinessDetails) && (
                  <>
                    <Form.Item
                      className="field-bg"
                      label="Monthly In-hand Salary(INR)"
                      name="monthlyInHandSalary"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Monthly In-hand Salary",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Please enter Monthly In-hand Salary"
                        formatter={(value) => numberWithCommas(value)}
                        min={1}
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Office Pin Code"
                      name="officePinCode"
                      rules={[
                        {
                          required: false,
                          message: "Please enter office pin code",
                        },
                        {
                          pattern: /^[1-9][0-9]{5}$/,
                          message: "Please enter valid pincode",
                        },
                      ]}
                    >
                      <Input
                        className="custom-input"
                        placeholder="Please enter office pin code"
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Current EMIs (INR)"
                      name="currentEmis"
                      rules={[
                        {
                          required: false,
                          message: "Please enter Current EMIs (INR)",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Current EMIs (INR)"
                        formatter={(value) => numberWithCommas(value)}
                        min={0}
                      />
                    </Form.Item>
                  </>
                )}
              </div>
            )}
            <div className={"form-box"}>
              {selectedLoanType == "Education Loan" && renderEducationDetails()}
              {!isPersonalLoan &&
                selectedLoanType != "Education Loan" &&
                !hideBusinessDetails && (
                  <>
                    <Divider orientation="left" orientationMargin="0">
                      Business Details
                    </Divider>
                    {selectedApplicationType !== "INDIVIDUAL" && (
                      <>
                        <Form.Item
                          className="field-bg"
                          label="Business Name"
                          name="employername"
                          rules={[
                            {
                              required: false,
                              message: "Please enter business name",
                            },
                          ]}
                        >
                          <Input
                            className="custom-input"
                            placeholder="Business name"
                          />
                        </Form.Item>

                        <Form.Item
                          className="field-bg"
                          label="Business Pan"
                          name="businessPan"
                          rules={[
                            {
                              required: false,
                              message: "Please enter pan number",
                            },
                            {
                              pattern:
                                /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                              message: "Please enter valid pan number",
                            },
                          ]}
                        >
                          <Input
                            className="custom-input"
                            placeholder="Business pan"
                            onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
                          />
                        </Form.Item>
                      </>
                    )}

                    <Form.Item
                      className="field-bg"
                      label="Business Profile"
                      name="businessProfile"
                    >
                      <Select
                        placeholder="Please select"
                        className="custom-select"
                      >
                        {businessProfile &&
                          businessProfile
                            ?.sort(function (a: any, b: any) {
                              if (a.key.toLowerCase() < b.key.toLowerCase())
                                return -1;
                              if (a.key.toLowerCase() > b.key.toLowerCase())
                                return 1;
                              return 0;
                            })
                            ?.map((loan: any, i: number) => (
                              <Select.Option key={i} value={loan.key}>
                                {loan.value}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="field-bg"
                      label="No of Years In Business"
                      name="businessVintage"
                      rules={[
                        {
                          required: false,
                          message: "Please enter no of years in business",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input"
                        placeholder="No of Years In Business"
                        min={1}
                      />
                    </Form.Item>

                    <Form.Item
                      className="field-bg"
                      label="Business Turnover (Yearly)"
                      name="businessTurnover"
                      rules={[
                        {
                          required: false,
                          message: "Please enter business turnover",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Business turnover"
                        formatter={(value) => numberWithCommas(value)}
                        min={1}
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Monthly Obligations (EMIs)"
                      name="monthlyEmi"
                      rules={[
                        {
                          required: false,
                          message: "Please enter monthly obligations (EMIs)",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Monthly Obligations (EMIs)"
                        formatter={(value) => numberWithCommas(value)}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Ownership"
                      name="propertyOwnership"
                    >
                      <Select
                        placeholder="Please select"
                        className="custom-select"
                      >
                        {propertyOwnership &&
                          propertyOwnership
                            ?.sort(function (a: any, b: any) {
                              if (a.key.toLowerCase() < b.key.toLowerCase())
                                return -1;
                              if (a.key.toLowerCase() > b.key.toLowerCase())
                                return 1;
                              return 0;
                            })
                            ?.map((loan: any, i: number) => (
                              <Select.Option key={i} value={loan.key}>
                                {loan.value}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </>
                )}
            </div>
            <div>
              <Divider orientation="left" orientationMargin="0">
                Loan Details
              </Divider>
              <Form.Item
                className="field-bg"
                label="Loan Amount"
                name="loanAmount"
                rules={[
                  {
                    required: true,
                    message: "Please enter loan amount",
                  },
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Please enter loan amount"
                  formatter={(value) => numberWithCommas(value)}
                  min={1}
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Loan Tenure(in Years)"
                name="loanTenure"
                rules={[
                  {
                    required: true,
                    message: "Please enter Loan Tenure",
                  },
                  {
                    pattern: GREATERTHEN_ZERO_REGEX,
                    message: "Please enter valid Loan Tenure",
                  },
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Please enter Loan Tenure"
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="">

          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ justifyContent: "flex-end" }}>
          <Divider />
          <Col>
            <Form.Item>
              <Space>
                <Button
                  size="large"
                  className="button-submit dynamic-btn-default"
                  style={customStylesBtn}
                  htmlType="button"
                  onClick={() => {
                    if(history.length <= 2) {
                      history.replace(`/lead`);
                    } else {
                      history.goBack()
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="button-submit dynamic-btn-primary"
                  style={customStylesBtnPrimary}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Continue
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddLeadV1;
