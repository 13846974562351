import Logo from "../../assets/images/oneflo.svg";
import OneflowFlyingPlane from "../../assets/images/oneflo-flying-plane.svg";
import SignTypeIndividual from "../../assets/images/sign-type-individual.svg";
import SignTypeBusiness from "../../assets/images/sign-type-business.svg";
import Bell from "../../assets/images/bell-icon.svg";
import Play from "../../assets/images/play.svg";
import Share from "../../assets/images/shareIcon.svg";
import HomeBlack from "../../assets/images/home-black.svg";
import AddFile from "../../assets/images/payouts/addFile.svg";
import Information from "../../assets/images/information.svg";
import PayoutSuccess from "../../assets/images/payouts/payoutSuccess.svg";
import Add from "../../assets/images/add.svg";
import Upload from "../../assets/images/upload.svg";
import Delete from "../../assets/images/delete.svg";
import File from "../../assets/images/file.svg";
import Eye from "../../assets/images/eye.svg";
import Edit from "../../assets/images/profileEdit.svg";
import CloudDownload from "../../assets/images/cloudDownload.svg";
import Approved from "../../assets/images/approved.svg";
import Silver from "../../assets/images/silver.svg";
import ShareProfile from "../../assets/images/shareProfile.svg";
import EmptyApproved from "../../assets/images/emptyApproved.svg";
import Copy from "../../assets/images/copy.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import Right from "../../assets/images/right.svg";
import Refresh from "../../assets/images/refresh.svg";
import RefreshPink from "../../assets/images/refreshpink.svg";
import UserIcon from "../../assets/images/userIcon.svg";
import LogoutIcon from "../../assets/images/logoutIcon.svg";
import ArrowRight from "../../assets/images/arrow_right.svg";
import Back from "../../assets/images/back.svg";

export const AppImages = {
  logo: Logo,
  oneflowFlyingPlane: OneflowFlyingPlane,
  signTypeIndividual: SignTypeIndividual,
  signTypeBusiness: SignTypeBusiness,
  bell: Bell,
  play: Play,
  share: Share,
  homeBlack: HomeBlack,
  addFile: AddFile,
  information: Information,
  payoutSuccess: PayoutSuccess,
  add: Add,
  upload: Upload,
  delete: Delete,
  file: File,
  eye: Eye,
  edit: Edit,
  cloudDownload: CloudDownload,
  approved: Approved,
  silver: Silver,
  shareProfile: ShareProfile,
  emptyApproved: EmptyApproved,
  copy: Copy,
  deleteIcon: DeleteIcon,
  right: Right,
  refreshPink: RefreshPink,
  refresh: Refresh,
  userIcon: UserIcon,
  logoutIcon: LogoutIcon,
  arrowRight: ArrowRight,
  back: Back,
};
