import React from "react";
import { Button, Card, Form, notification,  Progress, Typography, Space } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import FormInputPassword from "shared/components/FormInput/FormInputPassword";
import API_SERVICE from "shared/services/api-service";
import "./authentication.scss";
import { PASSWORD_REGEX } from "shared/constants/AppConstants";
import checkedGreenCircle from "assets/images/Checked-Green.svg";
import checkedGreyCircle from "assets/images/Checked-Grey.svg";

interface ISetPassword {
  mobileNo?: any;
}

const SetPassword: React.FunctionComponent<ISetPassword> = (
  props: ISetPassword
) => {
  const { mobileNo } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordDigit, setPasswordDigit] = useState(false)
  const [passwordUppercase, setPasswordUppercase] = useState(false)
  const [passwordLowercase, setPasswordLowercase] = useState(false)
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState(false)
  
  const handleProgressBar = (e:any) =>{
    let password = new Array();
    password.push("[$@$!%*#?&]");
    password.push("[A-Z]"); 
    password.push("[0-9]"); 
    password.push("[a-z]"); 

  var progress = 0;
  for (var i = 0; i < password.length; i++) {
      if (new RegExp(password[i]).test(e.target.value)) {
        progress++;
      }
  }
  if(progress > 2 && e.target.value.length > 7){
    progress++;
  }
  
  switch (progress) {
      case 0:
      setProgressPercent(0);
      break;
      case 1: 
      setProgressPercent(20);
      break;
      case 2:
        setProgressPercent(40);
        break; 
      case 3:
        setProgressPercent(60);
          break;
      case 4:
        setProgressPercent(80);
          break;
      case 5:
        setProgressPercent(100);
          break;
    }
  }

  const handlePasswordPolicy = (e:any) =>{
    setProgressStatus(e.target.value.length > 0 ? true  : false)
    setPasswordLength(e.target.value.length > 7 ? true  : false)
    setPasswordDigit(/\d/.test(e.target.value))
    setPasswordUppercase(/(?=[A-Z])[A-Z]/.test(e.target.value))
    setPasswordLowercase(/(?=[a-z])[a-z]/.test(e.target.value))
    setPasswordSpecialChar(/[$@$!%*#?&]/.test(e.target.value))   
    handleProgressBar(e)
}

  const handlePassword = async (values) => {
    if (passwordLength && passwordDigit && passwordUppercase && passwordLowercase && passwordSpecialChar)
    {
      setLoading(true);
      const data = {
        mobileNumber: mobileNo,
        password: values.password,
      };
      API_SERVICE.updatePassword(data)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            history.push("/sign-in");
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Card className="authentication-box">
      <h3 className="authentication-header">Set Your Password</h3>
      <Form
        layout="vertical"
        className="authentication-form"
        onFinish={handlePassword}
      >
        <FormInputPassword
          classNameForm="oneflo-bottom-space"
          name={"password"}
          label="Enter Your Password"
          isBorder
          isRequired
          requiredLabel="Password"
          placeholder="Enter Your Password"
          onChange={(e)=>handlePasswordPolicy(e)} 
          rules={[
            {
              pattern: PASSWORD_REGEX,
              message: "Please enter valid Password",
            },
          ]}
        />
        <div className="passwordPolicy">
            <div>
                <img src = {passwordLength ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Length should be greater than 8
              </div>
              <div>
              <img src = {passwordDigit ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimum 1 digit in password
              </div>
              <div>
              <img src = {passwordUppercase ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimun 1 uppercase letter in password
              </div>
              <div>
              <img src = {passwordLowercase ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                  Minimun 1 lowercase letter in password
              </div>
              <div>
              <img src = {passwordSpecialChar ? checkedGreenCircle : checkedGreyCircle} width = {20} className="imagePasswrodPolicy"/>
                    Minimun 1 special character in password like @,#,$
              </div>
              </div>
          {progressStatus &&
          <Space>
          <Typography.Title level = {5}>Password Strength</Typography.Title>
          <Typography.Title level = {5} style={{
            marginLeft:"250px",
            display:"inline-block",
            color : (progressPercent === 100 )?"rgba(52,168,83,1)":"rgba(189,0,0,1"
          }}> {(progressPercent === 100) ? "Strong" : "Weak" }</Typography.Title>
          </Space>
        }
         {progressStatus &&
          <Progress 
            percent={progressPercent} 
            showInfo={false}
            strokeColor={progressPercent === 100 ? "rgba(52,168,83,1)" : "rgba(189,0,0,1"}
            style={{
              marginBottom:"10px",
            }}>
          </Progress>
         }
          
        <FormInputPassword
          classNameForm="oneflo-bottom-space"
          name="confirm"
          label="Confirm Your Password"
          isBorder
          isRequired
          requiredLabel="Confirm Password"
          placeholder="Confirm Your Password"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        />

        <Form.Item className="oneflo oneflo-bottom-space">
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            size="large"
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SetPassword;
