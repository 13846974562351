import { RightOutlined } from "@ant-design/icons";
import {
  Card,
  Collapse,
  Divider,
  Form,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import FormInput from "shared/components/FormInput/FormInput";
import { NUMBER_REGEX, STRING_ONLY } from "shared/constants/AppConstants";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import FormIcons from "shared/components/FormIcons/FormIcons";
import {
  addDocumentCommon,
  getUserDetails,
  genericDeleteDoc,
} from "shared/services/Utility";
import DocListItem from "shared/components/DocListItem/DocListItem";
import AppUpload from "./app-upload";
import FormButton from "shared/components/FormButton/FormButton";
import { renderStatusARI } from "shared/components/common";

const { Panel } = Collapse;

interface IPayoutPrimaryAc {
  accountData: any;
  updateConnector: any;
}

const PayoutPrimaryAC: React.FC<IPayoutPrimaryAc> = (
  props: IPayoutPrimaryAc
) => {
  const { accountData, updateConnector } = props;
  const userDetails = getUserDetails();
  const [form] = Form.useForm();
  const [DocUploadForm] = Form.useForm();
  const bankDetails = accountData?.bankDetails;
  const [fileData, setfileData] = useState([] as any);
  const [showUploadList, setShowUploadList] = useState(true);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  const { appColors } = useSelector((state: RootState) => state.common);

  console.log("accountData by primary account tab", accountData);

  useEffect(() => {
    form.setFieldsValue({
      bankName: bankDetails?.bankName,
      accHolderName: bankDetails?.accHolderName,
      accNumber: bankDetails?.accNumber,
      ifscCode: bankDetails?.ifscCode,
    });
  }, []);

  const onPayoutPrimary = async (value: any) => {
    const cancelledCheque = await uploadDocumentFile();

    const tmpAccountDetails = {
      ...accountData,
      accType: "PRIMARY",
      cancelledChequeDoc: cancelledCheque ?? accountData?.cancelledChequeDoc,
      bankDetails: {
        bankName: value?.bankName,
        accHolderName: value?.accHolderName,
        bankDetailsVerified: false,
        accNumber: value?.accNumber,
        ifscCode: value?.ifscCode,
      },
    };
    updateConnector({ accounts: [tmpAccountDetails] });
  };

  const uploadDocumentFile = async () => {
    if ((fileData?.file?.name?.length ?? 0) == 0) {
      if (accountData?.cancelledChequeDoc == null) {
        notification.error({ message: "Please Uplod Cancelled Cheque" });
      }
      return;
    }

    const documentSetId = userDetails?.documentSetId;
    const response = await addDocumentCommon(
      documentSetId,
      fileData?.data,
      fileData?.file?.name,
      "",
      ""
    );
    if (response?.payload) {
      setShowUploadList(false);
      setfileData([]);
      // const tmpAccountDetails = {
      //     ...accountData,
      //     accType: "PRIMARY",
      //     cancelledChequeDoc: response?.payload
      // }
      // updateConnector({ accounts: [tmpAccountDetails] });
      return response?.payload;
    }
    return;
  };

  const onDelete = async () => {
    const result = await genericDeleteDoc(accountData?.cancelledChequeDoc);
    if (result?.payload) {
      const tmpAccountDetails = {
        ...accountData,
        cancelledChequeDoc: null,
      };
      updateConnector({ accounts: [tmpAccountDetails] });
    }
  };

  return (
    <>
      <Card
        className="lead-details"
        style={{ height: "840px", width: "100%", marginTop: "5px" }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "60%",
            height: "45%",
            padding: "2px",
          }}
        >
          {renderStatusARI(accountData?.status)}
          <Collapse
            className="lead-detail-box1"
            defaultActiveKey={["1"]}
            style={{ marginTop: "2%" }}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <RightOutlined rotate={isActive ? 270 : 90} />
            )}
          >
            <Panel header="Add Bank Account Details" key="1">
              <Form
                layout="vertical"
                form={DocUploadForm}
                onFinish={uploadDocumentFile}
              >
                <Form.Item>
                  <AppUpload
                    className="upload-wrapper"
                    acceptType={["image", "pdf"]}
                    showUploadList={showUploadList}
                    callBack={(value: any) => {
                      setShowUploadList(true);
                      setfileData(value);
                    }}
                  >
                    <FormIcons type="addFileData"/>
                    Upload Cancelled Cheque
                  </AppUpload>
                </Form.Item>
                {/* <FormButton type="primary" label="Save" htmlType="submit" /> */}
              </Form>
              <Form layout="vertical" form={form} onFinish={onPayoutPrimary}>
                {(accountData?.cancelledChequeDoc?.documentName?.length ?? 0) !=
                  0 && (
                    <>
                      {" "}
                      <Divider orientation="left" orientationMargin="0">
                        Uploaded Files
                      </Divider>
                      {
                        <DocListItem
                          document={accountData?.cancelledChequeDoc}
                          hideDelete
                          callbackDelet={() => onDelete()}
                        />
                      }
                    </>
                  )}
                <FormInput
                  label="Bank Name"
                  name="bankName"
                  placeholder="Enter Your bank name"
                  isBorder
                  rules={[
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid Bank Name",
                    },
                  ]}
                />
                <FormInput
                  label="Account Holder Name"
                  name="accHolderName"
                  placeholder="Account holder name"
                  isBorder
                  rules={[
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid Account Holder Name",
                    },
                  ]}
                />
                <FormInput
                  label="Account Number"
                  name="accNumber"
                  placeholder="Account Number"
                  isBorder
                  rules={[
                    {
                      pattern: NUMBER_REGEX,
                      message: "Please enter valid Account Holder Name",
                    },
                  ]}
                />
                <FormInput
                  label="IFSC Code"
                  name="ifscCode"
                  placeholder="IFSC Code"
                  isBorder
                />
                <FormButton type="primary" label="Save" htmlType="submit"
                  className="dynamic-btn-primary"
                  style={
                    { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                />
              </Form>
            </Panel>
          </Collapse>
        </div>
      </Card>
    </>
  );
};
export default PayoutPrimaryAC;
