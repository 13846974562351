import { Form, InputNumber } from "antd";
import { Rule } from "antd/es/form";
import "./FormInput.scss";
import { numberWithCommas } from "shared/services/Utility";

interface IFormInputNumber {
  name: string;
  label?: string;
  classNameForm?: string;
  placeholder?: string;
  isRequired?: boolean;
  requiredLabel?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isBorder?: boolean;
  min?:number;
  hidden?:any;
  rules?: Rule[] | undefined;
  onChange?: any;
}

const FormInputNumber = (props: IFormInputNumber) => {
  const {
    name,
    label,
    classNameForm,
    isRequired,
    requiredLabel,
    isDisabled,
    placeholder,
    isBorder,
    rules,
    defaultValue,
    onChange,
    min,
    hidden
  } = props;

  return (
    <>
      <Form.Item
        className={`field-bg ${isBorder ? "field-border" : ""} ` + classNameForm}
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: `Please Enter your ${requiredLabel}`,
          },
          ...(rules ?? []),
        ]}
      >
        <InputNumber
          className="custom-input-number"
          size="middle"
          placeholder={placeholder}
          disabled={isDisabled}
          defaultValue={defaultValue}
          formatter={(value) => numberWithCommas(value)}
          onChange={onChange}
          hidden={hidden}
        />
      </Form.Item>
    </>
  );
};

export default FormInputNumber;
