import { useEffect, useState } from "react";
import {
  Col,
  List,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import info from "../../../assets/images/info.svg";
import API_SERVICE from "shared/services/api-service";
import passIcon from "../../../assets/images/pass.svg";
import failIcon from "../../../assets/images/fail.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IDecisioning {
  leadData: any;
  getTaskData: any;
  setIsLoading: any;
}

const Decisioning: React.FC<IDecisioning> = (props: IDecisioning) => {
  const { leadData, getTaskData } = props;
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [decisioningnTabkey, setDecisioningTabkey] = useState(
    leadData?.journeyConfigResponseList?.at(0)?.journeyId
  );
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );

  useEffect(() => {
    listDecisioning();
  }, []);

  const listDecisioning = () => {
    //change true
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId;
    });
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, "", tmpId)
      .then(({ data }) => {
        setDecisioningTabkey(
          data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId
        );
        setListDescisioningDetail(
          data?.payload?.journeyWiseDecisioningDataList
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Gating Eligibility Passed",
      dataIndex: "gatingPassed",
      key: "gatingPassed",
      align: "center",
      render: (_: any, record: any) => {
        return <img src={record?.gatingPassed ? passIcon : failIcon} alt="" />;
      },
    },
    {
      title: "Scorecard Available",
      dataIndex: "lenderScorecardAvailable",
      key: "lenderScorecardAvailable",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <img
            src={record?.lenderScorecardAvailable ? passIcon : failIcon}
            alt=""
          />
        );
      },
    },
    {
      title: "Offer Decision Configuration",
      dataIndex: "lenderAvailableInOfferDecision",
      key: "lenderAvailableInOfferDecision",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <img
            src={record?.lenderAvailableInOfferDecision ? passIcon : failIcon}
            alt=""
          />
        );
      },
    },
    {
      title: "Offer Calculation Configuration",
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <img
            src={record?.lenderOfferExcelPublished ? passIcon : failIcon}
            alt=""
          />
        );
      },
    },
  ];

  const renderListMobile = (tmpFindData: any) => {
    return (
      <List
        style={{
          width: "97%",
          marginLeft: "6px",
          paddingTop: "10px",
          border: "1px solid gray",
          borderBottom: "none",
          borderRadius: "10px",
        }}
        grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
        pagination={{ showSizeChanger: true }}
        itemLayout="horizontal"
        dataSource={tmpFindData?.decisioningDataListResponseList ?? []}
        renderItem={(item: any) => (
          <List.Item
            style={{
              padding: "0px 12px",
              paddingBottom: "10px",
              borderBottom: "1px solid gray",
            }}
          >
            <div style={{ fontWeight: 300 }}>
              <Row gutter={6}>
                <Col span={10}>
                  <label style={{ fontSize: "12px" }}>Lender Name</label>
                  <br />
                  <label>{item.lenderName}</label>
                </Col>
                <Col span={8} style={{ paddingLeft: "5px" }}>
                  <label style={{ fontSize: "12px" }}>Purpose</label>
                  <br />
                  {item.decisioningPurpose}
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <Col span={9}>
                  <label style={{ fontSize: "12px" }}>
                    Gating Eligibility Passed
                  </label>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={item?.gatingPassed ? passIcon : failIcon}
                      alt=""
                    />
                  </div>
                </Col>
                <Col span={8} style={{ paddingLeft: "5px" }}>
                  <label style={{ fontSize: "12px" }}>
                    Scorecard Available
                  </label>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={item?.lenderScorecardAvailable ? passIcon : failIcon}
                      alt=""
                    />
                  </div>
                </Col>
                <Col span={7} style={{ paddingLeft: "5px" }}>
                  <label style={{ fontSize: "12px" }}>Offer Available</label>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={
                        (item?.lenderAvailableInOfferDecision && item?.lenderOfferExcelPublished)
                          ? passIcon
                          : failIcon
                      }
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <>
      <Tabs
        className="lender-sub-tabs"
        size="small"
        style={{ "--borderColor": appColors?.appPrimaryColor ?? "" } as any}
        // activeKey={decisioningnTabkey}
        onChange={(key) => setDecisioningTabkey(key)}
      >
        {leadData?.journeyConfigResponseList?.map((data: any) => {
          let tmpFindData = descisioningDetail?.find(
            (item: any) => item?.journeyId == data?.journeyId
          );
          return (
            <Tabs.TabPane key={data?.journeyId} tab={data?.journeyName}>
              <div className="tab-Userdetails">
                <Space className="userdetails">
                  <p
                    style={{
                      color: `${
                        tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning
                          ? "red"
                          : ""
                      }`,
                    }}
                  >
                    Date/Time: {tmpFindData?.executionTime}
                  </p>
                  {tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning && (
                    <Tooltip
                      title="Something has been changed please edit the case"
                      placement="topLeft"
                      color="#1A3175"
                    >
                      <img src={info} style={{ marginBottom: "8px" }} />
                    </Tooltip>
                  )}
                  <p>Executed By: {tmpFindData?.executedBy}</p>
                </Space>
              </div>
              {isMobile ? (
                renderListMobile(tmpFindData)
              ) : (
                <Table
                  columns={columns}
                  className="dynamic-pagination-checkbox"
                  style={
                    { "--bgColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                  dataSource={
                    tmpFindData?.decisioningDataListResponseList ?? []
                  }
                  size="small"
                  pagination={{ showSizeChanger: true }}
                  scroll={{ x: "max-content" }}
                />
              )}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default Decisioning;
