import "./index.scss";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import type { ColumnsType } from 'antd/es/table';
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import {ReactComponent as FileBorder} from "../../assets/images/file-border.svg";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import { RiSearchLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import {
  base64ToArrayBuffer,
  saveByteArray,
  hasUserRole,
  isUserVerified,
} from "shared/services/Utility";
import { RootState } from "shared/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setIsAppFooterShow, setIsAppHeaderShow, setShowProfielVerification } from "shared/redux/common-reducer";
import blackRefereshIcon from "../../assets/images/blackReferesh.svg";

const { Title } = Typography;
type Props = {};

const Notices: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const dispatch = useDispatch();
  const [allNotices, setAllNotices] = useState<"" | any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [pages, setPages] = useState();
  const [searchValue, setSearchValue] = useState(null as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);

  const columns: ColumnsType<any> = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      width: 250
    },
    {
      title: "Attachment",
      key: "attachment",
      dataIndex: ["documentDetails", "documentName"],
      render: (title: any, record: any) => {
        const customFileStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
        const document = (record?.documentDetails?.length ?? 0) > 0 ? record.documentDetails[0] : {};
        return <Space className="action-button" size="middle">
          <Button
            size="small"
            className="custome-btn-icon"
            type="text"
            icon={<FileBorder  style={customFileStyles}/>}
            onClick={(event) => downloadDocument(event, record)}
          />
          <span>{document?.documentName}</span>
        </Space>
      },
    },
    {
      title: "Created",
      key: "date",
      dataIndex: "creationDate",
      width: 135,
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("MMM DD YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: 'center',
      width: 50,
      render: (_: any, record: any) => (
        <Button
          className="custome-btn-icon"
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
          size="small"
          type="text"
          icon={<IconEyeBorder />}
          onClick={() => goToNoticeDetail(record)}
        />
      )
    }
  ];

  const downloadDocument = (doc: any, item: any) => {
    if ((item?.documentDetails?.length ?? 0) == 0) {
      return;
    }

    API_SERVICE.downloadDocument(
      item.documentDetails[0].businessId,
      item.documentDetails[0].businessDocumentId
    )
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const goToNoticeDetail = (item: any) => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true));
    } else {
      history.push(`/notices/view-notice/${item.busCommId}`);
    }
  };
  useEffect(() => {
    getAllNotices();
    dispatch(setIsAppFooterShow(false));
    dispatch(setIsAppHeaderShow(true));
  }, []);

  const getAllNotices = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getNotices(params)
      .then(({ data }: any) => {
        if (data.payload.content.length > 0) {
          mapLeadsData(data);
        } else {
          notification.error({ message: "No Notice found." });
          mapLeadsData({});
        }
      })
      .catch((error) => { })
      .finally(() => {
        setLoading(false);
       });
    if (allNotices.length !== 0) {
    }
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllNotices(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const onChangeStatus = (event: any, item: any) => {
    item.status = event;
    const id = item.busCommId;
    setLoading(true);
    API_SERVICE.updateOffers(
      {
        active: item.status,
        type: "NOTICE",
        title: item.title,
        description: item.description,
      },
      id
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          getAllNotices(currentPage, pageSize, searchValue);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
    getAllNotices(1, pageSize, event.target.value);
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTotal(pagination.total);
    getAllNotices(pagination.current, pagination.pageSize, searchValue);
    setPages(pagination.current);
  };

  return (
    <>
      <div className="content-box">
        <Title level={3}>Notices</Title>
        <Form form={form}>
          <Row gutter={24} className="data-filter-bar">
            <Col xs={24} sm={14} md={16} lg={18} className="pd-lr-10">
              <Form.Item name="searchBox">
                <Input size="large" className="search-box" placeholder="Search" allowClear prefix={<RiSearchLine />} onChange={debounce(handleSearch, 1000)} />
              </Form.Item>
            </Col>
            <Col xs={9} sm={5} md={4} lg={3} className="pd-lr-10">
              <Tooltip title="Refresh">
                <Button type="primary" className="refresh-button" onClick={() => { form.setFieldsValue({ searchBox: "" }); setSearchValue(""); getAllNotices(currentPage, pageSize); }}>
                  <img src={blackRefereshIcon} alt="" style={{ marginLeft: '-5px' }} />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        <Row gutter={24} style={{ marginTop: isMobile ? "20px" : "0px" }}>
          {isMobile ? (
            <List
              style={{ width: "100%" }}
              grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
              pagination={{
                onChange: (page) => {
                  handlePaginationChange(page);
                },
                current: currentPage,
                pageSize: pageSize,
                total: totalCount
              }}
              loading={loading}
              itemLayout="horizontal"
              dataSource={allNotices}
              renderItem={(item: any) => (
                <List.Item style={{ padding: "0px 12px" }} onClick={() => goToNoticeDetail(item)}>
                  <Card>
                    <h3>
                      {item.title}
                    </h3>
                    <h6>
                      {moment(new Date(item.creationDate))
                        .locale("en")
                        .format("DD/MM/YYYY, hh:mm A")}
                    </h6>
                  </Card>
                </List.Item>
              )}

            />
          ) : (
            <Col span={24}>
              <Table 
                className="dynamic-pagination-checkbox"
                style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                size="small" 
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                dataSource={allNotices}
                onChange={handlePaginationChange}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalCount, // total count returned from backend
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Notices;
