import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import API_SERVICE from "shared/services/api-service";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { saveByteArray } from "shared/services/Utility";
import "antd/dist/antd.css";

const { Title, Text } = Typography;
type Props = {};

const ImportBulUpload: React.FunctionComponent<Props> = () => {
  const history = useHistory();

  const [bulkUploadList, setBulkUploadList] = useState([] as any);
  const [bulkModalVisible, setBulkModalVisible] = useState(false);
  const [failedData, setFaildedData] = useState({} as any);
  const [data, setData] = useState([] as any);
  const [successData, setSuccessData] = useState([] as any);
  var c = null as any;
  var entityType = null as any;

  useEffect(() => {
    getBulkUploadList();
  }, []);

  const getBulkUploadList = () => {
    var params = {};

    API_SERVICE.getBulkUploadList(params).then(({ data }) => {
      if ((data?.payload.length ?? 0) > 0) {
        setBulkUploadList(data?.payload);
      } else {
        setBulkUploadList([]);
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Uploaded Id",
      dataIndex: "uploadId",
      key: "uploadId",
    },
    {
      title: "File Name",
      dataIndex: "uploadedFileName",
      key: "uploadedFileName",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "date",
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("DD/MM/YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "Action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="blue"
            size="small"
            type="primary"
            shape="circle"
            icon={<FaEye />}
            onClick={() => viewModel(record)}
          />
        </Space>
      ),
    },
  ];

  const viewModel = (record) => {
    setData(record?.bulkUploadReponsePayloadBean?.responsePayload?.FAILED);
    setSuccessData(
      record?.bulkUploadReponsePayloadBean?.responsePayload?.SUCCESS
    );
    setFaildedData(record);
    setBulkModalVisible(true);
  };

  useEffect(() => {}, [failedData, data]);

  const goToStartImportBulkUpload = () => {
    history.push("/lead/bulkUpload/import-bulkupload");
  };

  const handleDownload = () => {
    entityType = "NEW_LEAD_UPLOAD";
    // entityType = "UPLOAD_LENDER_STATUS";
    API_SERVICE.getDownloadFormate(entityType)
      .then(({ data }) => {
        saveByteArray([data], "upload_bulk_lead.xlsx");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <>
      <Title level={3}>
        <Link className="link" to={"/lead"}>
          Lead
        </Link>{" "}
        &nbsp; &gt; &nbsp; Upload Bulk Lead
      </Title>
      <div style={{ height: "100%", width: "100%" }} className="maindiv">
        {/* <div>
                    <div style={{ display: "flex", justifyContent: "center" }}><Text><h4>Import Leads Data</h4></Text></div>
                    <div style={{ display: "flex", justifyContent: "center" }}><Text>Import Leads information from Excel or CSV file</Text></div>

                    <div style={{ display: "flex", justifyContent: "center", margin: "20px", height: "200px", }}>
                        <Dragger
                            style={{ width: "600px", height: "400px", borderRadius: "20px" }}
                            accept=".pdf, .png, .jpg, .jpeg"
                            name="bulkUpload"
                            maxCount={1}
                            beforeUpload={() => {
                                return false;
                            }}
                        // onChange={onBankDetailsChange}
                        >
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                                    <Space>
                                        <img src={bulk} alt="" width={40} />
                                    </Space>
                                </div>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}><Text>Start An Import </Text></div>
                                </div>
                            </div>
                        </Dragger>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", }}>

                        <Button
                            className="bulk-upload-btn"
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => goToStartImportBulkUpload()}
                        >Start</Button>

                        <Button
                            className="bulk-upload-btn"
                            style={{ marginLeft: "10px" }}
                        >Cancel</Button>

                    </div>

                </div> */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>

          <Button
            className="bulk-upload-btn"
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={() => goToStartImportBulkUpload()}
          >
            START
          </Button>

          <Button
            className="bulk-upload-btn"
            style={{ marginRight: "10px" }}
            type="primary"
            icon={<ReloadOutlined />}
            onClick={() => {
              getBulkUploadList();
            }}
          ></Button>

          {/* <Button
                        icon={<DownloadOutlined />}
                        className="bulk-upload-btn"
                        type="primary"
                        onClick={handleDownload}
                        // style={{ marginLeft: "10px" }}
                    ></Button> */}

          {/* <Button
                        className="bulk-upload-btn"
                        style={{ marginLeft: "10px" }}
                    >Cancel</Button> */}
        </div>

        <div style={{ marginTop: "20px" }}>
          <div style={{}}>
            <Table
              scroll={{ x: "max-content", y: 600 }}
              columns={columns}
              dataSource={bulkUploadList}
            />
          </div>
        </div>
      </div>

      <Modal
        width={450}
        visible={bulkModalVisible}
        closable={false}
        footer={false}
      >
        <br />
        <Title level={4}>Failed Record</Title>
        {data?.map((item, index) => {
          return <h1>{item}</h1>;
        })}
        {(data?.length ?? 0) == 0 && <h1>No Record Found</h1>}

        <Divider />
        <Title level={4}>Success Record</Title>
        {successData?.map((item, index) => {
          return <h1>{item}</h1>;
        })}
        {(successData?.length ?? 0) == 0 && <h1>No Record Found</h1>}

        <Divider />
        <Text
          style={{ color: failedData?.status == "FAILED" ? "red" : "green" }}
        >
          <Text>status : </Text>
          {failedData?.status}
        </Text>
        <br />
        <Text
          style={{ color: failedData?.status == "FAILED" ? "red" : "green" }}
        >
          <Text>status description : </Text>
          {failedData?.statusDescription}
        </Text>
        <Divider />

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "-10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="bulk-upload-btn"
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={() => setBulkModalVisible(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ImportBulUpload;
