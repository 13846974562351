import { Button, Col, Collapse, Popconfirm, Row, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import { downloadDocument } from "shared/services/Utility";
import iconUpload from "../../../../assets/images/upload.svg";
import { ReactComponent as IconUpload } from "../../../../assets/images/upload-border.svg";
import { ReactComponent as IconDelete} from "../../../../assets/images/delete.svg";
import { ReactComponent as IconDeleteDisable} from "../../../../assets/images/delete_disable.svg";
import { ReactComponent as IconDownload} from "../../../../assets/images/download.svg";
import { ReactComponent as IconPlus} from "../../../../assets/images/add_round.svg";
import "../../../Lead/RequiredDocuments/doc-category.scss";
import BankStatementV2 from "./BankStatementV2";
import FileUploadComponent from "./FileUploadComponent";
import './Document.scss';
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;

interface IDocCategoryV2 {
  documentCategoryList: any;
  actionOnDelete: any;
  actionOnUpload: any;
  actionAdd: any;
  //
  loanProductId: any;
  documentSetId: any;
  individualType: any;
  getDocumentList: any;
  bankList: any;
  setPopupVisible?: any;
  popupVisible?: any;
  uploadDocumentList?: any;
  setUploadDocumentList?: any;
}

const DocCategoryV2: React.FunctionComponent<IDocCategoryV2> = (
  props: IDocCategoryV2
) => {
  const { documentCategoryList, actionOnDelete, actionOnUpload, actionAdd, getDocumentList, bankList, setPopupVisible,
    popupVisible,
    uploadDocumentList,
    setUploadDocumentList } = props;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );

  const callBackUploadDoc = (val, data, uploadDocumentList) => {
    if (uploadDocumentList?.sucess?.length > 0) {
      const isAllUpdated = uploadDocumentList?.sucess?.every((item) => item.status === 'Done');
      if(isAllUpdated) {
        getDocumentList();
      }
    }
  }

  const renderButton = (Icon, onClick, tooltip, disabled) => {
    return (
      <Tooltip title={tooltip}>
        <Button
          type="text"
          onClick={onClick}
          disabled={disabled}
          icon={<Icon/>}
        />
      </Tooltip>
    );
  };

  const renderActions = (doc: any, index: any, position: string = "center") => {
    const isDocUploaded = doc?.lenderDocumentStatus == "UPLOADED";
    return (
      <div
        className="action-btns"
        style={{
          display: "flex",
          justifyContent: position,
          backgroundColor: "transparent",
        }}
      >
        {/* {!doc?.documentDetails?.documentName &&
          renderButton(
            iconUpload,
            () => actionOnUpload(doc),
            "Upload document",
            false
          )} */}
        {!doc?.documentDetails?.documentName && (
          <Tooltip title={"Upload document"}>
            <FileUploadComponent
              key={`idex_${index}`}
              selectedType={doc?.documentTypeType ?? ""}
              documentTypeId={doc?.documentTypeId}
              formatDocument={
                doc?.appliedFileTypes?.map((v: any) =>
                  v.toLowerCase()
                ) ?? ""
              }
              maxSize={doc?.maximumSizeInMB ?? 10}
              setPopupVisible={setPopupVisible}
              popupVisible={popupVisible}
              uploadDocumentList={uploadDocumentList}
              setUploadDocumentList={setUploadDocumentList}
              doc={doc}
              callBack={callBackUploadDoc}
              {...props}
              children={
                <Button
                  className="custome-btn-icon-secondary"
                  style={{ '--bgColor': appColors?.appSecondaryColor ?? "", '--width': "20px" } as any}
                  type="text"
                  // onClick={() => actionOnUpload(doc)}
                  disabled={false}
                  icon={<IconUpload />}
                />
              }
            />
          </Tooltip>
        )}

        {doc?.documentDetails &&
          renderButton(
            IconDownload,
            () => downloadDocument(doc.documentDetails),
            "Download the document",
            false
          )}

        {doc?.documentDetails != null && (
          <Popconfirm
            placement="topRight"
            title={"Sure to delete document?"}
            onConfirm={(event) => actionOnDelete(doc)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
            disabled={isDocUploaded}
          >
            {renderButton(
              isDocUploaded ? IconDeleteDisable : IconDelete,
              () => { },
              "Delete the document",
              isDocUploaded
            )}
          </Popconfirm>
        )}
        <Tooltip title={"Add another file for the same document"}>
          <Button
            type="text"
            style={{border:"none"}}
            onClick={() => {
              if (doc?.documentDetails) {
                actionAdd(doc, index);
              } else {
                notification.error({ message: "Please Upload Document" });
              }
            }}
            icon={<IconPlus />}
          />
        </Tooltip>
      </div>
    );
  };

  const renderItem = (doc: any, index: any) => {
    const docType = doc?.documentOrgType ?? doc?.documentType;
    const docName = doc?.documentTypeName ?? docType?.split("~").at(-1);
    const fileName = doc?.documentDetails?.documentName;

    return (
      <>
        <Row
          key={`${doc?.documentType}_${index}`}
          className={
            doc?.documentDetails &&
              doc?.maximumSizeInMB <= doc?.documentDetails?.fileSize
              ? "error"
              : ""
          }
        >
          <Col
            span={7}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
              paddingLeft: "15px",
            }}
          >
            <Tooltip title={docName}>
              <span style={{ color: "red" }}>
                {doc?.mandatory ? "* " : <>&nbsp;&nbsp;&nbsp;</>}
              </span>
              <span>{docName}</span>
            </Tooltip>
          </Col>
          <Col
            span={7}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            <div className="action-btns">
              <span style={{ lineBreak: "anywhere" }}>{fileName}</span>
            </div>
          </Col>
          <Col span={4} className="h-center">
            <Tooltip
              title={
                doc?.appliedFileTypes ? doc?.appliedFileTypes?.join("/") : "-"
              }
            >
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  marginBottom: "0px",
                  paddingInline: "10px",
                }}
              >
                {doc?.appliedFileTypes
                  ? doc?.appliedFileTypes?.join(", ")
                  : "-"}
              </p>
            </Tooltip>
          </Col>
          <Col span={3} className="h-center">
            {doc?.maximumSizeInMB ? doc?.maximumSizeInMB + "MB" : "-"}
          </Col>
          <Col span={3}>{renderActions(doc, index)}</Col>
        </Row>
        {doc?.documentTypeType.includes("BANK_STATEMENT") && doc?.documentDetails && (
          <BankStatementV2 doc={doc} index={index} bankList={bankList} />
        )}
      </>
    );
  };

  const renderItemMobile = (doc: any, index: any) => {
    const docType = doc?.documentOrgType ?? doc?.documentType;
    const docName = doc?.documentTypeName ?? docType?.split("~").at(-1);
    const fileName = doc?.documentDetails?.documentName;

    return (
      <>
      <div className="doc-mobile-items">
        <div className="doc-mobile-item-main">
          <div className="doc-mobile-item">
            <div className="doc-title">
              Name{" "}
              <span style={{ color: "red" }}>
                {doc?.mandatory ? "* " : <>&nbsp;&nbsp;&nbsp;</>}
              </span>
            </div>
            <Tooltip title={docName}>
              <span>{docName}</span>
            </Tooltip>
          </div>
          <div className="doc-mobile-item">
            <div className="doc-title">File name</div>
            <span style={{ lineBreak: "anywhere" }}>{fileName}</span>
          </div>
        </div>
        <div className="doc-mobile-item-main">
          <div className="doc-mobile-item">
            <div className="doc-title">Format</div>
            <Tooltip
              title={
                doc?.appliedFileTypes ? doc?.appliedFileTypes?.join("/") : "-"
              }
            >
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  marginBottom: "0px",
                  // paddingInline: "10px",
                }}
              >
                {doc?.appliedFileTypes
                  ? doc?.appliedFileTypes?.join(", ")
                  : "-"}
              </p>
            </Tooltip>
          </div>
          <div className="doc-mobile-item">
            <div className="doc-title">Max File Size</div>
            {doc?.maximumSizeInMB ? doc?.maximumSizeInMB + "MB" : "-"}
          </div>
        </div>
        <div>
          <div className="doc-mobile-item">
            <div className="doc-title">Actions</div>
            {renderActions(doc, index, "left")}
          </div>
        </div>
      </div>
        {doc?.documentTypeType.includes("BANK_STATEMENT") &&
          doc?.documentDetails && (
            <BankStatementV2 doc={doc} index={index} bankList={bankList} />
          )}
      </>
    );
  };

  const getHeader = () => {
    let isRequired = false;
    let mandatoryCount = 0;
    documentCategoryList?.documentTypeWithUploadedDetails?.map((item) => {
      if (!isRequired && item?.mandatory) {
        isRequired = true;
      }
      if (item?.mandatory && item.documentDetails !== null) {
        mandatoryCount = mandatoryCount + 1;
      }
    });

    const reqSign = (
      <span style={{ color: "red" }}>{isRequired ? "* " : "  "}</span>
    );

    return (
      <div className="doc-mobile-items-header">
        <div>
        {reqSign}
        {`${documentCategoryList?.documentCategoryName} `}
        </div>
        <div>
        {
          <span className="doc-category-tags" style={{'--bgColor': appColors?.appPrimaryLightColor ?? "", '--color': appColors?.appSecondaryColor ?? ""} as any}>
            Mandatory: {mandatoryCount ?? 0}/
            {documentCategoryList?.totalMandatory}
          </span>
        }
        {
          <span className="doc-category-tags" style={{'--bgColor': appColors?.appPrimaryLightColor ?? "", '--color': appColors?.appSecondaryColor ?? ""} as any}>
            Uploaded: {documentCategoryList?.totalUploaded}
          </span>
        }
        </div>
      </div>
    );
  };

  return (
    <Collapse
      className="doc-collapse doc-panel-header"
      expandIconPosition="end"
      key={`${documentCategoryList?.documentCategoryId}`}
    >
      <Panel header={getHeader()} key={"1"}>
        {documentCategoryList?.documentTypeWithUploadedDetails?.map(
          (item, index) => {
            return isMobile ? renderItemMobile(item, index) : renderItem(item, index);
          }
        )}
      </Panel>
    </Collapse>
  );
};

export default DocCategoryV2;
