import React, { useEffect, useState } from "react";
import { Typography, DatePicker, Button, notification, Select, Form, Row, Col } from "antd";
import API_SERVICE from "shared/services/api-service";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { isUserVerified, saveByteArray } from "shared/services/Utility";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setIsAppFooterShow, setIsAppHeaderShow, setShowProfielVerification } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
const { RangePicker } = DatePicker;
const { Title } = Typography;
type Props = {};

const Reports: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedReportType, setSelectedReportType] = useState(null as any);
  const [reportTypes, setReportTypes] = useState([] as any);
  const [reportForm] = Form.useForm() as any;
  const { appColors } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(false));
    getAllTypes();
  }, []);

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredReportTypes = data.find(
          (item: any) => item.propertyName == "CONNECTOR_REPORT_TYPE"
        );
        setReportTypes(filteredReportTypes.keyAndDisplayValueList);
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const onFinish = (values: any) => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true))
    } else {
      let startDate = "";
      let endDate = "";
      if (values && values?.dateRange) {
        startDate = values.dateRange[0].format("YYYY-MM-DD");
        endDate = values.dateRange[1].format("YYYY-MM-DD");
      }
      setLoading(true);
      API_SERVICE.generateReportByReportType(startDate, endDate, values.reportType)
        .then(({ data }) => {
          saveByteArray([data], (values.reportType.toLowerCase() + "_report.xlsx"));
          setLoading(false);
        })
        .catch(async (e: any) => {
          const data = JSON.parse(await e.response.data.text());
          const message = data?.payload?.errorMessage ?? e;
          notification.error({ message: API_SERVICE.handleErrors(message) });
          setLoading(false);
        });
    }
  }
  return (
    <div className="content-box">
      <Title level={3}>Reports</Title>
      <Form layout="vertical" form={reportForm} initialValues={{}} onFinish={onFinish} autoComplete="off" name="reportForm">
        <Row gutter={[24, 24]}>
          <Col span={7} xl={7} lg={8} md={8} sm={10} xs={18}>
            <Form.Item name="reportType" className="field-bg" style={{ width: "100%", height: '45px', boxShadow: "0.5px 0.5px 10px 1px #b0b0b09e !important", borderColor: '#ddd' }} rules={[{ required: true, message: "Please select report type." }]}>
              <Select placeholder="Select Report Type" onChange={(e) => setSelectedReportType(e)}>
                {reportTypes && reportTypes
                  .sort(function (a: any, b: any) {
                    if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                    if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                    return 0;
                  }).map((option: any, i: number) => {
                    return (
                      <Select.Option key={i} value={option.key}>{option.value}</Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} xl={7} lg={8} md={8} sm={11} xs={18}>
            {("CONNECTOR" !== selectedReportType) ?
              <Form.Item name="dateRange" rules={[{ required: ("CONNECTOR" !== selectedReportType), message: "Please select date range" }]}>
                <RangePicker className="date-range" onChange={onChange} disabledDate={disabledDate} />
              </Form.Item>
              :
              null
            }
          </Col>
          <Col span={6} xl={5} lg={4} md={5} sm={5} xs={10}>
            <Form.Item >
              <Button 
                type="primary" 
                className="export-btn dynamic-btn-primary" 
                style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                htmlType="submit" 
                loading={loading} 
              >
                  <RiFileExcel2Fill />&nbsp;Export To Excel</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div >
  );
};

export default Reports;