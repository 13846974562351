import { Button, Col, Form, Input, Modal, notification, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import AppLoading from "shared/components/AppLoading/AppLoading";
import iconSearch from "../../../../assets/images/search.svg";
import DocCategoryV2 from "./DocCategoryV2";
import UploadDocument from "screens/Lead/UploadDocument/UploadDocument";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import imgDocument from "../../../../assets/images/document_download.svg";
import imgTickRight from "../../../../assets/images/tickRight.svg";
import imgLoading from "../../../../assets/images/loading.svg";
import wrong from "../../../../assets/images/wrong.svg";

interface IDocument {
  leadData: any;
  getTaskData: any;
  setCurrentStage: any;
  setGotoDocument: any;
  setIsLoading:any;
}

const Document: React.FunctionComponent<IDocument> = (props: IDocument) => {
  const { leadData, getTaskData, setCurrentStage, setGotoDocument } = props;

  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [filterDocumentList, setFilterDocumentList] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const { appColors } = useSelector((state: RootState) => state.common);
  const [bankList, setBankList] = useState([]);
  const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  
  useEffect(() => {
    getDocumentList();
    getDocumentBankData();
  }, []);

  const getDocumentList = (isLoading = true) => {
    if (isLoading) {
      setLoading(true);
    }
    API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          setDocumentListCat(data?.payload?.documentConfigDetails);
          setFilterDocumentList(data?.payload?.documentConfigDetails);        
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const actionOnDelete = (doc: any) => {
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        getDocumentList();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleSearch = (value: any) => {
    let documentTypeNameFilter = documentListCat?.map((item: any) => {
      const filterData = item?.documentTypeWithUploadedDetails?.filter(
        (data: any) => {
          return data?.documentTypeName
            ?.toLowerCase()
            ?.includes(value?.searchParam?.toLowerCase());
        }
      );
      return { ...item, documentTypeWithUploadedDetails: filterData };
    });
    let getDocumentCategroy = documentTypeNameFilter?.filter((item: any) => {
      return item?.documentTypeWithUploadedDetails?.length > 0;
    });
    setFilterDocumentList(getDocumentCategroy);
  };

  const actionAdd = (doc, index, cat, indexCat) => {
    const docList = cat?.documentTypeWithUploadedDetails ?? [];
    const tmoDocLists = docList.filter(
      (val) => val?.identifier === doc?.identifier
    );

    const docListTmp = [
      ...docList.slice(0, index + 1),
      { ...doc, identifier: `${doc?.identifier}~${tmoDocLists?.length - 1}`, documentDetails: null },
      ...docList.slice(index + 1),
    ];

    const tmp = [...filterDocumentList];
    tmp[indexCat] = { ...cat, documentTypeWithUploadedDetails: docListTmp };
    setFilterDocumentList(tmp);
  };

  const renderDocumentSearch = () =>{ 
    const customBtnPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
    return(
    <Form
      layout="inline"
      style={{ marginBottom: "10px" }}
      onFinish={handleSearch}
    >
      <Form.Item name="searchParam">
        <Input
          size="large"
          style={{ borderRadius: "8px" }}
          prefix={<img src={iconSearch} alt="" width={17} />}
          placeholder="Search Name"
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" className="dynamic-btn-primary-default" style={customBtnPrimary}>
          Search
        </Button>
      </Form.Item>
    </Form>
  )};

  const renderUploadDocument = () => {
    return (
      <Modal
        title="Upload New Document:"
        centered
        width={400}
        onCancel={() => setShowUploadDocument(false)}
        footer={null}
        visible={showUploadDocument}
      >
        <UploadDocument
          selectedType={selectedDocument?.documentTypeType ?? ""}
          documentTypeId={selectedDocument?.documentTypeId}
          formatDocument={
            selectedDocument?.appliedFileTypes?.map((v: any) =>
              v.toLowerCase()
            ) ?? ""
          }
          maxSize={selectedDocument?.maximumSizeInMB ?? 10}
          loanProductId={leadData?.loanProductId}
          documentSetId={leadData?.caseDetails?.documentSetId}
          individualType={leadData?.applicationSubType}
          callBack={(isUpload) => {
            setShowUploadDocument(false);
            if (isUpload) {
              getDocumentList();
            }
          }}
        />
      </Modal>
    );
  };

  const getDocumentBankData = () => {
    const masterKey = 'BANK_NAME';
    API_SERVICE.getDocumentBankList(masterKey).then(({ data }) => {
      if (data?.payload) {
        const updtedData: any = [];
        data?.payload?.filter((item: any) => {
          updtedData.push({
            label: item.value,
            value: item.id
          });
        });
        setBankList(updtedData);
      }
    }).catch((e) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    })
  }

  const downloadPopup = () => {
    console.log('::data');
    return (
      <Modal className="model-document-upload" visible={popupVisible} footer={null} onCancel={() => {
        setPopupVisible(false)
        setUploadDocumentList({sucess: [], failed: []});
        }} mask={false} >
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of {Number(uploadDocumentList.sucess.length) + Number(uploadDocumentList.failed.length)} Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
            {items.status === 'Done' ? (
              <img src={imgTickRight} height={30} width={30} />
            ) : (
              <img className="loading-img" src={imgLoading} height={30} width={30} />
            )}
            </Col>
          </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
              <img src={wrong} height={30} width={30} />
            </Col>
          </Row>
          );
        })}
      </Modal>
    );
  };

  return (
    <>
    {downloadPopup()}
      {showUploadDocument && renderUploadDocument()}
      <div style={{marginLeft:'15px'}}>
      {renderDocumentSearch()}
      </div>
      <div style={{paddingInline : '15px'}}>
        {!isMobile &&
        <Row style={{ paddingInline: "16px" }}>
          <Col span={7}>{"Name"}</Col>
          <Col span={7}>{"File Name"}</Col>
          <Col span={4} className="h-center">
            {"Format"}
          </Col>
          <Col span={3} className="h-center">
            {"Max Size"}
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            {"Actions"}
          </Col>
        </Row>
        }

        {filterDocumentList?.map((item,indexCat: number) => {
          return (
            <DocCategoryV2
              documentCategoryList={item}
              actionOnDelete={actionOnDelete}
              actionOnUpload={(doc) => {
                setSelectedDocument(doc);
                setShowUploadDocument(true);
              }}
              actionAdd={(doc, index) => {
                actionAdd(doc, index, item, indexCat);
              }}
              loanProductId={leadData?.loanProductId}
              documentSetId={leadData?.caseDetails?.documentSetId}
              individualType={leadData?.applicationSubType}
              setPopupVisible={setPopupVisible}
              popupVisible={popupVisible}
              uploadDocumentList={uploadDocumentList}
              setUploadDocumentList={setUploadDocumentList}
              getDocumentList={getDocumentList}
              bankList={bankList}
            />
          );
        })}
      </div>

      <div className="d-flex gap-10 mt-20">
        {/* <Button
          size="middle"
          className="pi-30"
          htmlType="button"
          onClick={() =>
            setCurrentStage(getTaskData?.productFormMetadata?.stageKey)
          }
        >
          Cancel
        </Button> */}
      </div>
      {loading && <AppLoading />}
    </>
  );
};

export default Document;
