import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Radio,
  Space,
  Tabs,
  notification,
  Divider,
  Select,
  RadioChangeEvent,
  AutoComplete,
  Image,
  DatePicker,
} from "antd";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import debounce from "lodash/debounce";

import API_SERVICE from "shared/services/api-service";

//import * as _ from "lodash";
import "./index.scss";
import {
  PAN_CARD,
  PHONE_NUMBER_REGEX,
  PIN_CODE,
  STRING_ONLY,
} from "shared/constants/AppConstants";
import moment from "moment";
import FormDOB from "shared/components/FormDOB/FormDOB";
import {
  getBasicDetails,
  getEnumValue,
  getUserDetails,
  numberWithCommas,
} from "shared/services/Utility";
import RadioButton from "shared/components/RadioButton/RadioButton";
import FormSelect from "shared/components/FormSelect/FormSelect";
import FormInput from "shared/components/FormInput/FormInput";
import FormInputNumber from "shared/components/FormInput/FormInputNumber";
import { AppImages } from "shared/constants/AppImages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setActiveTab,
  setIsAppFooterShow,
  setIsAppHeaderShow,
} from "shared/redux/common-reducer";
import "../LeadNew/AddLeadNew/ProductRadioStart.scss";
const { Title, Text } = Typography;
type Props = {};

const AddLead: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const location = useLocation() as any;
  const [loading, setLoading] = useState(false);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [applicantTypes, setApplicantType] = useState([]);
  const [individualTypes, setIndividualType] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(null as any);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedApplicationType, setSelectedApplicationType] =
    useState("INDIVIDUAL");
  const [leadForm] = Form.useForm() as any;
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [activeSelfOrConnector, setActiveSelfOrConnector] = useState("2");
  const [isDisableStep, setIsDisableStep] = useState(true);
  const [isDisableCustomer, setIsDisableCustomer] = useState(true);
  const [isBureauScore, setIsBureauScore] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [isCustomerIndian, setIsCustomerIndian] = useState(false);
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null as any);
  const [isSelectedCustomer, setIsSelectedCustomer] = useState(null as any);
  const [bureauScoreStatus, setBureauScoreStatus] = useState("");
  let { id } = useParams() as any;
  const [editData, setEditData] = useState({} as any);
  const [isPersonalLoan, setIsPersonalLoan] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState("");
  const [hideBusinessDetails, setHideBusinessDetails] = useState(false);
  const [isCreditCardLoan, setIsCreditCard] = useState(false);
  const [selectedBackOfficeUser, setSelectedBackOfficeUser] = useState(
    null as any
  );
  const [assignToBackOfficeData, setAssignToBackOfficeData] = useState(
    [] as any
  );
  const [businessProfile, setBusinessProfile] = useState([] as any);
  const [placeOfStudys, setPlaceOfStudys] = useState([] as any);
  const [admissionStatus, setAdmissionStatus] = useState([] as any);
  const [propertyOwnership, setPropertyOwnership] = useState([] as any);
  const [isDisableBusinessData, setIsDisableBusinessData] = useState(false);
  const [tentativePayout, setTentativePayout] = useState(null as any);
  const [isClearBackOfficer, setIsClearBackOficer] = useState(false);
  const [isLoanTypeDone, setIsLoanTypeDone] = useState(false);
  const [isCustomerDone, setIsCustomerDone] = useState(false);
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  const dispatch = useDispatch();
  const customStylesRadio: any = { '--color': appColors?.appPrimaryColor ?? "" };
  const btnRadioStyles: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };
  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  useEffect(() => {
    dispatch(setActiveTab(2));
    dispatch(setIsAppFooterShow(false));
    dispatch(setIsAppHeaderShow(false));

    leadForm.setFieldsValue({
      loanType: location?.state?.radioValue || "",
    });
    if ((location?.state?.radioValue?.length ?? 0) > 0) {
      setIsLoanTypeDone(true);
    }
    getAllTypes();
    setEnumValues();

    if (id) {
      setIsLoanTypeDone(true);
      setIsCustomerDone(true);
      getLeadById();
    }
  }, []);

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  const getLeadById = () => {
    let user = localStorage.getItem("user") as any;
    if (user) {
      user = JSON.parse(user);
    }
    API_SERVICE.getLeadsById(id)
      .then(({ data }) => {
        if (data) {
          setEditData(data.payload);
          const payoutType =
            data.payload.caseDetails.connectorDetails.payoutType;
          setTentativePayout(payoutType);
          if (data?.payload?.applicationType === null) {
            if (isDisableBusinessData === false) {
              setIsDisableBusinessData(true);
            }
          }
          prepareDataForEdit(
            data.payload,
            data.payload.caseDetails.assignedToUserDetails
          );
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const prepareDataForEdit = (userData: any, assignTo) => {
    let enumValues = localStorage.getItem("enumValues") as any;
    let aType: any = null;
    let iType: any = null;
    let bType: any = null;

    // console.log("enumvalue", enumValues)
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let businessTypes = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );

      aType = filteredApplicationType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationType
      ) as any;
      iType = filteredIndivType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
      bType = businessTypes.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
    }

    let filteredLoanType = enumValues?.find(
      (item: any) => item.propertyName === "LOAN_TYPE"
    );

    let loanType = "";
    const loanTypesFiltered = filteredLoanType?.keyAndDisplayValueList?.filter(
      (item) => item.key === userData?.loanProductId
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const tmpIsPersonalLoan = loanType === "Personal Loan";
    const tmpIsEL = loanType === "Education Loan";
    setIsPersonalLoan(tmpIsPersonalLoan);
    setSelectedLoanType(loanType);
    setIsDisableBusinessData(false);

    const tmpIndiType = tmpIsPersonalLoan ? "SALARIED" : iType?.key;
    if (loanType === "Home loan" && tmpIndiType == "SALARIED") {
      setHideBusinessDetails(true);
    } else {
      setHideBusinessDetails(false);
    }

    const timeOfStudy =
      userData.caseDetails.loanDetails?.educationLoanDetails?.timeOfStudy;

    setSelectedApplicationType(
      tmpIsPersonalLoan || tmpIsEL ? "INDIVIDUAL" : aType?.key
    );
    setActiveSelfOrConnector(
      userData?.sourceOfCase?.toUpperCase() === "SELF" ? "1" : "2"
    );
    leadForm.setFieldsValue({
      connector:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData?.caseDetails?.connectorDetails?.userDetails?.fullName
          : "",
      tentativePayout:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData.caseDetails.loanDetails.expectedInterestRate
          : "",
      applicantType:
        tmpIsPersonalLoan || tmpIsEL ? "INDIVIDUAL" : aType?.key || "",
      individualType: tmpIsPersonalLoan ? "SALARIED" : iType?.key || "",
      businessType: bType?.key || "",
      gender: userData.contactDetails.gender,
      pan:
        userData.contactDetails.pan === "PENDING"
          ? ""
          : userData.contactDetails.pan,
      businessPan:
        userData?.contactDetails?.pan === "PENDING"
          ? ""
          : userData.contactDetails.pan,
      businessName: userData.contactDetails.firstName,
      searchCustomer: userData.contactDetails.firstName,
      phoneCode: "+91",
      mobileNumber: userData?.contactDetails?.mobileNumber?.startsWith("1")
        ? ""
        : userData?.contactDetails?.mobileNumber,
      email: userData.contactDetails.emailId,
      officePinCode: userData.officePincode,
      dateOfBirth:
        userData?.contactDetails?.dateOfBirth &&
        moment(userData.contactDetails.dateOfBirth),
      pincode: userData.address.pinCode,
      address1: userData.address.address,
      city: userData.address.city,
      state: userData.address.state,
      employername: userData.entityOrEmployerName,
      loanAmount: userData.caseDetails.loanDetails.loanAmount,
      loanTenure: userData.caseDetails.loanDetails.loanTenureInYears,
      monthlyInHandSalary:
        userData.caseDetails.loanDetails?.monthlyInHandSalary,
      currentEmis: userData.caseDetails.loanDetails?.currentEmis,
      loanType: userData?.loanProductId,
      comment: userData.caseDetails.lenderActivityHolder.comments,
      assignTo: assignTo?.fullName,
      assignToBackOffice:
        userData?.caseDetails?.backOfficeUserDetails?.fullName,
      // contactName: userData?.contactDetails?.contactName,
      firstName: userData?.contactDetails?.firstName,
      middleName: userData?.contactDetails?.middleName,
      lastName: userData?.contactDetails?.lastName,
      businessProfile: userData?.businessProfile,
      businessVintage: userData?.businessVintage,
      businessTurnover: userData?.businessTurnOver,
      monthlyEmi: userData?.monthlyEmi,
      propertyOwnership: userData?.caseDetails?.propertyOwnership,
      timeOfStudy: timeOfStudy ? moment(timeOfStudy) : null,
      placeOfStudy:
        userData.caseDetails.loanDetails?.educationLoanDetails?.placeOfStudy,
      admissionStatus:
        userData.caseDetails.loanDetails?.educationLoanDetails?.admissionStatus,
    });

    setBureauScoreStatus(
      userData?.leadAdditionalInfoDetails?.bureauScoreStatus ?? ""
    );
  };

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredLoanType = enumValues.find(
        (item: any) => item.propertyName === "LOAN_TYPE"
      );
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let filteredGenderType = enumValues.find(
        (item: any) => item.propertyName === "GENDER"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let businessProfileValue = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_PROFILE"
      );
      let propertyOwnershipValue = enumValues.find(
        (item: any) => item.propertyName === "PROPERTY_OWNERSHIP"
      );
      let placeStudyValue = enumValues.find(
        (item: any) => item.propertyName === "PLACE_STUDY"
      );
      let admissionStatusValue = enumValues.find(
        (item: any) => item.propertyName === "ADMISSION_STATUS"
      );

      if (businessProfileValue) {
        setBusinessProfile(businessProfileValue?.keyAndDisplayValueList);
      }
      if (filteredLoanType) {
        setLoanTypes(filteredLoanType.keyAndDisplayValueList);
      }
      if (filteredApplicationType) {
        setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      }
      if (filteredIndivType) {
        setIndividualType(filteredIndivType.keyAndDisplayValueList);
      }
      if (filteredGenderType) {
        setGenders(filteredGenderType.keyAndDisplayValueList);
      }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
      if (propertyOwnershipValue) {
        setPropertyOwnership(propertyOwnershipValue?.keyAndDisplayValueList);
      }
      if (placeStudyValue) {
        setPlaceOfStudys(placeStudyValue?.keyAndDisplayValueList);
      }
      if (admissionStatusValue) {
        setAdmissionStatus(admissionStatusValue?.keyAndDisplayValueList);
      }
    }
  };

  const onSearchExistingCustomer = (e: any) => {
    API_SERVICE.searchCustomers(e).then(({ data }: any) => {
      setSearchedCustomers(data?.payload ?? []);
    });
  };

  const onSelectExistingCustomer = (event: any) => {
    const customers = [...searchedCustomers];
    const customer = customers.find(
      (item: any) =>
        item?.contactDetails?.firstName.toLowerCase() === event.toLowerCase()
    );
    setSelectedCustomer(customer);
  };

  // const checkPanDetails = (e: any) => {
  //   console.log("call");
  //   e.persist();
  //   const { value: panNumber } = e.target;
  //   const params = {
  //     panNumber: panNumber,
  //     consent: "Y",
  //   };
  //   if (e?.target?.value) {
  //     API_SERVICE.checkPanDetailsForProfile(params).then(({ data }) => {
  //       if (selectedApplicationType !== "INDIVIDUAL") {
  //         leadForm.setFieldsValue({
  //           employername: data?.payload?.result?.name,
  //         });
  //       } else {
  //         console.log("data else", data?.payload?.result);
  //         let date = moment(data?.payload?.result?.dob);
  //         console.log("date", date);
  //         leadForm.setFieldsValue({
  //           firstName: data?.payload?.result?.name,
  //           dateOfBirth: date,
  //         });
  //       }
  //     });
  //   }
  // };

  const checkPincode = (e: any) => {
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        leadForm.setFieldsValue({
          city: data.payload.city,
          state: data.payload.state,
        });
      }
    });
  };

  const createPayload = (formData: any) => {
    let salesRm = JSON.parse(
      localStorage.getItem("basicDetails") ?? "{}"
    )?.srmId;
    const isValidDob = moment(
      moment(leadForm.getFieldValue("dateOfBirth"))
        .format("YYYY-MM-DD")
        .toString()
    ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"));
    if (formData && formData.dateOfBirth && !isValidDob) {
      notification.error({
        message: "DOB should be greater than or equal to 18 yrs",
      });
      return;
    }
    const bureauScore =
      bureauScoreStatus.length !== 0
        ? bureauScoreStatus
        : isBureauScore
          ? "REQUEST_INITIATED"
          : null;

    const assignToId =
      editData?.caseDetails?.assignedToUserDetails?.userId ??
      getBasicDetails()?.srmId;
    let backOfficeUserId = editData?.caseDetails?.backOfficeUserDetails?.userId;
    const connectorId =
      editData?.caseDetails?.connectorDetails?.userDetails?.userId ??
      getUserDetails()?.userId;

    let loanType = "";
    const loanTypesFiltered = loanTypes?.filter(
      (item) => item.key === formData.loanType
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const data = {
      loanType: loanType,
      loanProductId: formData.loanType,
      sourceOfCase: "CONNECTOR",
      salesRm: salesRm,
      address: {
        address: formData.address1,
        area: formData.city,
        city: formData.city,
        country: userDetails?.userDetails.address.country,
        geoCode: userDetails?.userDetails.address.geoCode,
        pinCode: formData.pincode,
        state: formData.state,
      },
      applicationSubType:
        formData.applicantType === "INDIVIDUAL"
          ? formData.individualType
          : formData.businessType,
      applicationType: formData.applicantType,
      officePincode: formData.officePinCode,
      comments: formData.comment,
      assignToUserId: assignToId,
      backOfficeUserId: backOfficeUserId,
      businessProfile: formData?.businessProfile,
      businessTurnOver: formData?.businessTurnover,
      monthlyEmi: formData?.monthlyEmi,
      propertyOwnership: formData?.propertyOwnership,
      businessVintage: formData?.businessVintage,
      commissionDetail: {
        connectorName: formData.firstName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: connectorId,
      customerId: selectedCustomer?.customerId || "",
      nationality: isCustomerIndian ? "INDIAN" : null,
      bureauScoreStatus: bureauScore,
      contactDetails: {
        gender: formData.gender,
        // contactName: formData.contactName,
        firstName: formData?.firstName,
        middleName: formData?.middleName,
        lastName: formData?.lastName,
        dateOfBirth:
          formData &&
            formData.dateOfBirth &&
            moment(
              moment(leadForm.getFieldValue("dateOfBirth"))
                .format("YYYY-MM-DD")
                .toString()
            ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"))
            ? moment(formData.dateOfBirth).format("YYYY-MM-DD").toString()
            : "",
        emailId: formData.email,
        mobileNumber: formData.mobileNumber,
        pan:
          formData.applicantType === "INDIVIDUAL"
            ? formData.pan
            : formData.businessPan,
      },
      entityOrEmployerName: formData.employername,
      loanDetails: {
        expectedInterestRate:
          formData.tentativePayout ?? selectedConnector?.payoutPercentage,
        loanAmount: isCreditCardLoan ? 100000 : formData.loanAmount,
        loanTenureInYears: isCreditCardLoan ? 1 : formData.loanTenure,
        monthlyInHandSalary: formData.monthlyInHandSalary,
        currentEmis: formData.currentEmis,
        educationLoanDetails: {
          timeOfStudy: formData?.timeOfStudy
            ? moment(formData.timeOfStudy).format("YYYY-MM").toString()
            : null,
          placeOfStudy: formData?.placeOfStudy ?? null,
          admissionStatus: formData?.admissionStatus ?? null,
        },
      },
    };
    return data;
  };

  const onFinishForm = (values: any) => {
    if (!loading) {
      setLoading(true);
    }
    if (id) {
      const payload = createPayload(values);
      setLoading(true);
      console.log("payload", payload);
      API_SERVICE.editLead(editData.caseDetails.caseId, payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            history.push(`/lead/details/${id}`);
            // history.push("/lead");
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const payload = createPayload(values);
      setLoading(true);
      API_SERVICE.addLead(payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            // history.push("/lead");
            history.push(`/lead/details/${data?.payload?.caseDetails?.caseId}`);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeApplicationType = (e: RadioChangeEvent) => {
    setSelectedApplicationType(e.target.value);
    if (e.target.value) {
      if (isDisableBusinessData === true) {
        setIsDisableBusinessData(false);
      }
    }
    leadForm.resetFields(["employername", "businessPan", "pan"]);
    setIsBureauScore(false);
    if (
      leadForm?.getFieldValue("loanType") == "LP00000006" &&
      e.target.value == "INDIVIDUAL" &&
      leadForm?.getFieldValue("individualType") == "SALARIED"
    ) {
      setHideBusinessDetails(true);
    } else {
      setHideBusinessDetails(false);
    }
  };

  const disableDate = (current) => {
    // let eighteenYearsAgo = new Date();
    // let eightAgo = eighteenYearsAgo.setFullYear(
    //   eighteenYearsAgo.getFullYear()
    // );
    return current && current > new Date();
  };

  const disableTimeOfStudy = (current) => {
    return current && current < new Date();
  };

  const onRadioChange = (value: any) => {
    let loanType = "";
    const loanTypesFiltered = loanTypes?.filter((item) => item.key === value);
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }
    console.log("loanType", loanType);
    setIsLoanTypeDone(true);
    setSelectedLoanType(loanType);
    setIsPersonalLoan(loanType === "Personal Loan");
    setIsCreditCard(loanType == "Credit Card");
    setSelectedApplicationType("INDIVIDUAL");

    leadForm.setFieldsValue({
      loanType: value,
      applicantType: "INDIVIDUAL",
      individualType: "SALARIED",
    });

    if (
      leadForm?.getFieldValue("applicantType") == "INDIVIDUAL" &&
      value == "LP00000006"
    ) {
      setHideBusinessDetails(true);
    } else {
      setHideBusinessDetails(false);
    }
  };

  const setFieldsValue = () => {
    if (selectedCustomer != null) {
      const { contactDetails, address } = selectedCustomer;

      leadForm.setFieldsValue({
        firstName: contactDetails?.firstName,
        email: contactDetails?.emailId,
        pan: contactDetails?.pan === "PENDING" ? "" : contactDetails?.pan,
        gender: contactDetails?.gender,
        mobileNumber: contactDetails?.mobileNumber?.startsWith("1")
          ? ""
          : contactDetails?.mobileNumber,
        dateOfBirth: contactDetails?.dateOfBirth
          ? moment(contactDetails?.dateOfBirth)
          : "",
        city: address?.city,
        state: address?.state,
        pincode: address?.pinCode,
        address1: address?.address,
      });
      setIsSelectedCustomer(true);
      setIsCustomerDone(true);
    }
  };

  const actionContinue = () => {
    setFieldsValue();
  };

  const generateButtons = () => {
    const customBtnStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
    const customBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
    return (
      <Form.Item style={{ marginTop: "10px" }}>
        <Space className="buttons">
          <Button
            size="large"
            htmlType="button"
            className="dynamic-btn-default"
            style={customBtnStyle}
            onClick={() => setIsCustomerDone(true)}
          >
            Add New
          </Button>
          <Button
            //style={{ width: "100%", }}
            size="large"
            loading={loading}
            type="primary"
            htmlType="button"
            onClick={actionContinue}
            className="dynamic-btn-primary"
            style={customBtnPrimary}
          >
            Continue
          </Button>
        </Space>
      </Form.Item>
    );
  };

  const generateExistingCustomer = () => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Search Existing Customer
        </Divider>
        <Form.Item className="search-box" name="searchCustomer">
          <AutoComplete
            placeholder="Search with name or mobile no"
            dropdownClassName="custom-option-list"
            onSelect={onSelectExistingCustomer}
            onSearch={onSearchExistingCustomer}
          >
            {searchedCustomers?.map((customer: any) => (
              <Select.Option
                key={customer.customerId}
                {...customer}
                value={customer?.contactDetails?.firstName}
              >
                <div>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={16}>
                      <span className="name">
                        {customer?.contactDetails?.firstName}
                      </span>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={8}>
                      <span>
                        {customer?.contactDetails?.mobileNumber.replace(
                          /\d(?=\d{4})/g,
                          "*"
                        )}
                      </span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                    <Col className="right" span={16}>
                      <span className="city">{customer?.address?.city}</span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                  </Row>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    textAlign: "right",
                    justifyContent: "space-between",
                  }}
                >
                  {customer?.contactDetails?.contactName}
                  <div
                    style={{
                      display: "grid",
                      fontSize: "14px",
                      color: "grey",
                      flex: 1,
                    }}
                  >
                    <span>{customer?.contactDetails?.mobileNumber}</span>
                    <span>{customer?.address?.city}</span>
                  </div>
                </div> */}
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        {selectedCustomer && (
          <div className="search-result">
            <Space className="selected-item" direction="vertical">
              <Title level={5}>
                {selectedCustomer?.contactDetails?.firstName}
              </Title>
              <Text>{selectedCustomer?.contactDetails?.mobileNumber}</Text>
              <Text>{selectedCustomer?.address?.city}</Text>
            </Space>
          </div>
        )}
        {generateButtons()}
      </>
    );
  };
  const renderLoanTypeRadio = () => {
    const customRadioPrimary: any = { '--borderColor': appColors?.appPrimaryColor ?? "" };
    return (
      <Form.Item
        style={{ marginBottom: "20px" }}
        label="Loan Type"
        name="loanType"
        shouldUpdate={true}
        rules={[{ required: true, message: "Please select loan type." }]}
      >
        <Radio.Group
          name="loanTypes"
          className="dynamic-radio"
          style={customRadioPrimary}
          onChange={(e) => onRadioChange(e.target.value)}

        >
          <Space direction="vertical">
            {loanTypes &&
              loanTypes.map((loan: any, i: number) => (
                <Radio key={i} value={loan.key}>
                  {loan.value}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    );
  };

  const renderLoanTypeList = () => {
    return (
      <Form.Item
        style={{ marginBottom: "20px" }}
        label=""
        name="loanType"
        shouldUpdate={true}
        required
        requiredMark={"optional"}
      >
        {loanTypes &&
          loanTypes.map((loan: any, i: number) => (
            <div
              className="loan-type-list"
              key={i}
              onClick={() => {
                console.log("loan.value: ", loan.key);
                onRadioChange(loan.key);
              }}
            >
              <Text>{loan.value}</Text>
              <img src={AppImages.arrowRight} />
            </div>
          ))}
      </Form.Item>
    );
  };

  const renderExistingCustomer = () => {
    return (
      <>
        {generateExistingCustomer()}
        {/* <Divider orientation="left" orientationMargin="0">
          Add New Customer
        </Divider>
        <Form.Item>
          <Button type="primary" size="large" style={{ width: "100%" }}>
            Add New Customer
          </Button>
        </Form.Item> */}
      </>
    );
  };

  const renderEducationDetails = () => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Education Details
        </Divider>

        <Form.Item
          className="field-bg"
          label="Time of Study (Starts from)"
          name="timeOfStudy"
          rules={[
            {
              required: true,
              message: "Please enter time of study",
            },
          ]}
        >
          <DatePicker
            className="custom-input"
            picker="month"
            placeholder="YYYY-MM"
            format={"yyyy-MM"}
            disabledDate={disableTimeOfStudy}
          />
        </Form.Item>

        <Form.Item
          className="field-bg"
          label="Preferred Place of Study"
          name="placeOfStudy"
          rules={[
            {
              required: true,
              message: "Please enter preferred place of study",
            },
          ]}
        >
          <Select placeholder="Please select" className="custom-select">
            {placeOfStudys &&
              placeOfStudys?.map((loan: any, i: number) => (
                <Select.Option key={i} value={loan.key}>
                  {loan.value}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="field-bg"
          label="Admission Status"
          name="admissionStatus"
          rules={[
            {
              required: true,
              message: "Please enter admission status",
            },
          ]}
        >
          <Select placeholder="Please select" className="custom-select">
            {admissionStatus &&
              admissionStatus?.map((loan: any, i: number) => (
                <Select.Option key={i} value={loan.key}>
                  {loan.value}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  const renderOther = () => {

    return (
      <>
        <Col xs={24} xl={8} span={8} className="col-border-right">
          <div className={""}>
            <Form.Item
              label="Applicant Type"
              name="applicantType"
              rules={[{ required: true, message: "Please select loan type." }]}
            >
              <Radio.Group
                onChange={onChangeApplicationType}
                className="custom-radio"
                style={btnRadioStyles}
                name="applicantType"
              >
                <Space direction="horizontal">
                  {applicantTypes &&
                    applicantTypes.map((type: any, i: number) => {
                      if (
                        (isPersonalLoan ||
                          selectedLoanType == "Education Loan") &&
                        type.key === "BUSINESS"
                      ) {
                        return null;
                      }
                      return (
                        <Radio.Button key={i} value={type.key}>
                          {type.value}
                        </Radio.Button>
                      );
                    })}
                </Space>
              </Radio.Group>
            </Form.Item>
            <div className="application-type">
              {selectedApplicationType === "INDIVIDUAL" ? (
                <Form.Item
                  className="field-bg"
                  label="Individual Type"
                  name="individualType"
                  rules={[
                    {
                      required: true,
                      message: "Please select individual type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please select"
                    className="custom-select"
                    onChange={() => {
                      if (
                        leadForm?.getFieldValue("loanType") == "LP00000006" &&
                        leadForm?.getFieldValue("applicantType") ==
                        "INDIVIDUAL" &&
                        leadForm?.getFieldValue("individualType") == "SALARIED"
                      ) {
                        setHideBusinessDetails(true);
                      } else {
                        setHideBusinessDetails(false);
                      }
                    }}
                  >
                    {individualTypes &&
                      individualTypes.map((type: any, i: number) => {
                        if (isPersonalLoan && type.key === "SELF_EMPLOYED") {
                          return null;
                        }
                        return (
                          <Select.Option key={i} value={type.key}>
                            {type.value}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              ) : (
                <FormSelect
                  label="Business Type"
                  name="businessType"
                  isRequired
                  placeholder="Please select"
                  options={businessTypes}
                />
              )}
            </div>

            {selectedApplicationType === "INDIVIDUAL" ? (
              <Divider orientation="left" orientationMargin="0">
                Personal Details
              </Divider>
            ) : (
              <Divider orientation="left" orientationMargin="0">
                Contact Details
              </Divider>
            )}
            <RadioButton
              label="Gender"
              name="gender"
              isRequired
              defaultValue={"M"}
              requiredLabel="gender"
              radioList={genders}
              style={btnRadioStyles}
              className="custom-radio profession-btns"
            />

            {selectedApplicationType === "INDIVIDUAL" && (
              <FormInput
                label="Pan"
                name="pan"
                // onChange={debounce(checkPanDetails, 1000)}
                placeholder="Pan number"
                requiredLabel="pan"
                rules={[
                  {
                    pattern: PAN_CARD,
                    message: "Please enter valid pan number",
                  },
                ]}
              />
            )}

            {selectedApplicationType === "INDIVIDUAL" ? (
              <>
                <FormInput
                  label="Name as per PAN Card"
                  name="firstName"
                  placeholder="Name as per PAN Card"
                  isRequired
                  requiredLabel="name as per PAN card"
                  rules={[
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid name as per PAN card",
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <FormInput
                  label="Contact Name"
                  name="firstName"
                  placeholder="Contact Name"
                  isRequired
                  requiredLabel="contact name"
                  rules={[
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid name as per PAN card",
                    },
                  ]}
                />
              </>
            )}
            <FormInput
              label="Mobile Number"
              name="mobileNumber"
              prefix="+91"
              placeholder="Mobile number"
              requiredLabel="mobile number"
              rules={[
                {
                  pattern: PHONE_NUMBER_REGEX,
                  message: "Please enter valid number",
                },
              ]}
            />
            <FormInput
              label="Email"
              name="email"
              requiredLabel="email"
              placeholder="Email"
              rules={[{ type: "email", message: "Please enter valid email" }]}
            />

            {selectedApplicationType === "INDIVIDUAL" && (
              <FormDOB
                form={leadForm}
                disableDate={disableDate}
                label="Date of Birth (YYYY-MM-DD)"
                placeholder="Date of Birth"
                name="dateOfBirth"
                // isRequired={isBureauScore || bureauScoreStatus.length != 0}
                isRequired={false}
              />
            )}

            <Divider orientation="left" orientationMargin="0">
              Current Address
            </Divider>
            <FormInput
              label="Pin Code"
              name="pincode"
              requiredLabel="pin code"
              onChange={debounce(checkPincode, 1000)}
              placeholder="Please enter pin code"
              rules={[
                {
                  pattern: PIN_CODE,
                  message: "Please enter valid pincode",
                },
              ]}
            />
            <FormInput
              label="Address Line 1"
              name="address1"
              requiredLabel="addredd"
              placeholder="Please enter address"
            />
            <FormInput
              label="City"
              name="city"
              requiredLabel="city"
              placeholder="Please enter city"
              rules={[
                { pattern: STRING_ONLY, message: "Please enter valid city" },
              ]}
            />
            <FormInput
              label="State"
              name="state"
              requiredLabel="state"
              placeholder="Please enter state"
              rules={[
                { pattern: STRING_ONLY, message: "Please enter valid state" },
              ]}
            />
          </div>
          {!isCustomerDone && (
            <div
              style={{
                position: "absolute",
                inset: "0",
                backgroundColor: "#FFFFFF88",
                zIndex: "9",
              }}
            />
          )}
        </Col>
        <Col xs={24} xl={8} span={8}>
          {isDisableBusinessData && (
            <div
              style={{
                position: "absolute",
                inset: "0",
                backgroundColor: "#FFFFFF88",
                zIndex: "9",
              }}
            />
          )}
          {selectedApplicationType === "INDIVIDUAL" &&
            selectedLoanType != "Education Loan" && (
              <div>
                <Divider orientation="left" orientationMargin="0">
                  Employer Details
                </Divider>
                <FormInput
                  label="Employer / Entity name"
                  name="employername"
                  requiredLabel="employer / entity name"
                  placeholder="Employer / entity name"
                />
                {(isPersonalLoan || hideBusinessDetails) && (
                  <>
                    <FormInputNumber
                      label="Monthly In-hand Salary(INR)"
                      name="monthlyInHandSalary"
                      placeholder="Please enter Monthly In-hand Salary"
                      min={1}
                      isRequired
                      requiredLabel="Monthly In-hand Salary"
                    />
                    <FormInput
                      label="Office Pin Code"
                      name="officePinCode"
                      requiredLabel=" office pin code"
                      placeholder="Please enter office pin code"
                      rules={[
                        {
                          pattern: PIN_CODE,
                          message: "Please enter valid pincode",
                        },
                      ]}
                    />
                    <FormInputNumber
                      label="Current EMIs (INR)"
                      name="currentEmis"
                      placeholder="Current EMIs (INR)"
                      requiredLabel=" Current EMIs (INR)"
                      min={0}
                    />
                  </>
                )}
              </div>
            )}
          <div className={"form-box"}>
            {selectedLoanType == "Education Loan" && renderEducationDetails()}
            {!isPersonalLoan &&
              selectedLoanType != "Education Loan" &&
              !hideBusinessDetails && (
                <>
                  <Divider orientation="left" orientationMargin="0">
                    Business Details
                  </Divider>
                  {selectedApplicationType !== "INDIVIDUAL" && (
                    <>
                      <FormInput
                        label="Business Name"
                        name="employername"
                        requiredLabel="business name"
                        placeholder="Business name"
                      />
                      <FormInput
                        label="Business Pan"
                        name="businessPan"
                        requiredLabel="pan number"
                        // onChange={debounce(checkPanDetails, 1000)}
                        placeholder="Business pan number"
                        rules={[
                          {
                            pattern: PAN_CARD,
                            message: "Please enter valid pan number",
                          },
                        ]}
                      />
                    </>
                  )}

                  <Form.Item
                    className="field-bg"
                    label="Business Profile"
                    name="businessProfile"
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                    >
                      {businessProfile &&
                        businessProfile
                          ?.sort(function (a: any, b: any) {
                            if (a.key.toLowerCase() < b.key.toLowerCase())
                              return -1;
                            if (a.key.toLowerCase() > b.key.toLowerCase())
                              return 1;
                            return 0;
                          })
                          ?.map((loan: any, i: number) => (
                            <Select.Option key={i} value={loan.key}>
                              {loan.value}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                  <FormInputNumber
                    label="No of Years In Business"
                    name="businessVintage"
                    requiredLabel="no of years in business"
                    placeholder="No of Years In Business"
                    min={1}
                  />
                  <FormInputNumber
                    label="Business Turnover (Yearly)"
                    name="businessTurnover"
                    placeholder="Business turnover"
                    requiredLabel="business turnover"
                    min={1}
                  />
                  <FormInputNumber
                    label="Monthly Obligations (EMIs)"
                    name="monthlyEmi"
                    placeholder="Monthly Obligations (EMIs)"
                    requiredLabel="monthly obligations (EMIs)"
                    min={0}
                  />
                  <Form.Item
                    className="field-bg"
                    label="Ownership"
                    name="propertyOwnership"
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                    >
                      {propertyOwnership &&
                        propertyOwnership
                          ?.sort(function (a: any, b: any) {
                            if (a.key.toLowerCase() < b.key.toLowerCase())
                              return -1;
                            if (a.key.toLowerCase() > b.key.toLowerCase())
                              return 1;
                            return 0;
                          })
                          ?.map((loan: any, i: number) => (
                            <Select.Option key={i} value={loan.key}>
                              {loan.value}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </>
              )}

            <Divider orientation="left" orientationMargin="0">
              Loan Details
            </Divider>
            <FormInputNumber
              label="Loan Amount"
              name="loanAmount"
              hidden={isCreditCardLoan}
              rules={[
                {
                  required: !isCreditCardLoan,
                  message: "Please enter loan amount",
                },
              ]}
              placeholder="Please enter loan amount"
              requiredLabel="loan amount"
              min={1}
            />
            <FormInputNumber
              label="Loan Tenure(in Years)"
              hidden={isCreditCardLoan}
              name="loanTenure"
              rules={[
                {
                  required: !isCreditCardLoan,
                  message: "Please enter Loan Tenure",
                },
              ]}
              placeholder="Please enter Loan Tenure"
              requiredLabel="Loan Tenure"
              min={1}
            />
          </div>
          {!isCustomerDone && (
            <div
              style={{
                position: "absolute",
                inset: "0",
                backgroundColor: "#FFFFFF88",
                zIndex: "9",
              }}
            />
          )}
        </Col>
      </>
    );
  };

  const renderFooterButton = () => {
    const customBtnStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "", 'width': '150px', 'border-radius': '11px' };
    const customBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", 'width': '150px', 'border-radius': '11px' };
    return (
      <Row gutter={[24, 24]} style={{ justifyContent: "flex-end" }}>
        <Divider />
        <Col>
          <Form.Item>
            <Space>
              <Button
                size="large"
                className="dynamic-btn-default"
                htmlType="button"
                onClick={() => history.goBack()}
                style={customBtnStyle}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="dynamic-btn-primary"
                loading={loading}
                type="primary"
                style={customBtnPrimary}
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <div className="content-box">
      {isMobile ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Button
            style={{ border: "none" }}
            size="small"
            onClick={() => {
              if (isCustomerDone) {
                setIsCustomerDone(false);
              } else if (isLoanTypeDone) {
                setIsLoanTypeDone(false);
              } else {
                history.goBack();
              }
            }}
            icon={<img src={AppImages.back} />}
          />
          <Text style={{ marginTop: "5px" }}>
            {isLoanTypeDone
              ? getEnumValue("LOAN_TYPE", leadForm.getFieldValue("loanType"))
              : "Add Lead"}
          </Text>
          <div style={{ width: "25px" }} />
        </div>
      ) : (
        <Title level={3}>
          <Link className="link" to={"/lead"} style={{'--color': appColors?.appPrimaryColor ?? ""} as any}>
            Lead
          </Link>{" "}
          &nbsp; &gt; &nbsp; {id ? "Edit Lead" : "Add Lead"}
        </Title>
      )}
      <Form
        style={{ marginTop: "10px" }}
        layout="vertical"
        initialValues={{
          applicantType: "INDIVIDUAL",
          gender: "M",
          assignTo: userDetails?.userDetails?.fullName,
        }}
        form={leadForm}
        onFinish={onFinishForm}
        autoComplete="off"
        name="leadForm"
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            {isMobile ? (
              isLoanTypeDone ? (
                <></>
              ) : (
                renderLoanTypeList()
              )
            ) : (
              renderLoanTypeRadio()
            )}
            {isMobile ? (
              isLoanTypeDone && !isCustomerDone ? (
                renderExistingCustomer()
              ) : (
                <></>
              )
            ) : (
              renderExistingCustomer()
            )}
          </Col>
          {isMobile ? isCustomerDone ? renderOther() : <></> : renderOther()}
        </Row>
        {isMobile ? (
          isCustomerDone ? (
            renderFooterButton()
          ) : (
            <></>
          )
        ) : (
          renderFooterButton()
        )}
      </Form>
    </div>
  );
};

export default AddLead;
