import { Button, Card, Divider, Form, notification } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FormButton from "shared/components/FormButton/FormButton";
import FormInput from "shared/components/FormInput/FormInput";
import { NUMBER_REGEX } from "shared/constants/AppConstants";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";

interface IProfileOtp {
  verificationId: any;
  setOpenEmail: any;
  getConnector: any;
}

const ProfileOTP: React.FC<IProfileOtp> = (props: IProfileOtp) => {
  const { verificationId, setOpenEmail, getConnector } = props;
  const getEmailId = JSON.parse(
    localStorage.getItem("basicDetails") ?? ""
  )?.emailId;
  const time = 60 * 2 - 1;
  const [form] = Form.useForm();
  const [runTimer, setRunTimer] = React.useState(true);
  const [countDown, setCountDown] = React.useState(time);
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [otp, setOtp] = useState("");
  const { appColors } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      setRunTimer(false);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  useEffect(() => {
    if (countDown === 0) {
      setIsTimeStoped(true);
    }
  });

  const actionResend = () => {
    if (countDown === 0) {
      API_SERVICE.checkEmail(getEmailId)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data?.message });
            setCountDown(time);
            setIsTimeStoped(false);
            setRunTimer(true);
          } else {
            notification.error({ message: "no data found" });
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const verifyOtp = () => {
    const payload = {
      emailId: getEmailId,
      emailOtp: otp,
      verificationId: verificationId,
    };
    API_SERVICE.verifyOtp(payload)
      .then(({ data }) => {
        notification.success({ message: data.message });
        setOpenEmail(false);
        getConnector();
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <>
      <Card
        className="lead-details"
        style={{ height: "840px", width: "100%", marginTop: "5px" }}
      >
        <Card
          className={"login-form-wrapper"}
          style={{ width: "60%", height: "45%", padding: "2px" }}
        >
          <Form
            name="normal_login"
            className="login-form"
            layout="vertical"
            form={form}
            onFinish={verifyOtp}
          >
            <Title level={3}>Enter OTP Sent To Your Email</Title>
            <Divider />
            <FormInput
              name="otp"
              isRequired
              requiredLabel="Please enter your otp!"
              classNameForm="custom-input"
              placeholder="Enter OTP"
              rules={[
                {
                  required: true,
                  pattern: NUMBER_REGEX,
                  message: "Please enter valid OTP",
                },
              ]}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
            <Form.Item>
              Didn't receive the OTP? &nbsp;
              <a onClick={actionResend} style={{ color: appColors?.appPrimaryColor ??  "#C71C8E" }}>
                {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}
                <br />
              </a>
            </Form.Item>
            <FormButton type="primary" label="Verify" htmlType="submit"
                  className="dynamic-btn-primary"
                  style={
                    { "--btnColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                />
          </Form>
        </Card>
      </Card>
    </>
  );
};
export default ProfileOTP;
