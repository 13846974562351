import { Button, Modal, Select, Typography, notification, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import iconPlus from "../../../../assets/images/add_round.svg";
import "./index.scss";
import UploadDocument from "../UploadDocument/UploadDocument";
import iconDownload from "../../../assets/images/download.svg";
import { downloadDocument } from "shared/services/Utility";

interface IReplaceDocument {
  documentSetId: string;
  showReplaceDoc: boolean;
  setShowReplaceDoc: any;
  actionAdd: any;
  selectedDocument?: any;
  callbackSuccess?: any;
  isConflict?: boolean;
  leadData?: any;
  getDocPage?: any;
  selectedLander?: any;
  actionOnDelete?: any;
  callbackDocUploaded?: any;
  getAllDocumentList?: any;
  docReplaceDocs?: any
}

const ReplaceDocument: React.FunctionComponent<IReplaceDocument> = (
  props: IReplaceDocument
) => {
  const {
    documentSetId,
    showReplaceDoc,
    setShowReplaceDoc,
    actionAdd,
    selectedDocument,
    callbackSuccess,
    isConflict,
    leadData,
    getDocPage,
    selectedLander,
    actionOnDelete,
    callbackDocUploaded,
    docReplaceDocs,
    getAllDocumentList,
  } = props;

  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [docId, setDocId] = useState("");
  const [documentSubtypeList, setDocumentSubTypeList]= useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null as any)
  const [isSelectedLenderDoc, setIsSelectedLenderDoc] = useState(false);

  useEffect(() => {
    getDocumentList();
    setDocumentSubTypeList(selectedDocument?.documentType.split(','));
  }, []);
  const updateSelectedSubtype = (e: any) => {
    console.log('e>>', e);
    setIsSelectedLenderDoc(true);
    setSelectedDocumentType(e.split('~')?.at(0));
    // setSelectedDocumentName(e);
}
  const getDocumentList = () => {
    console.log("selectedDocument: ", selectedDocument);
    API_SERVICE.getReplaceDocumentList(documentSetId)
      .then(({ data }) => {
        if (data) {
          if (isConflict) {
            const filtered = data?.payload?.filter((doc) => {
              const docT = selectedDocument?.documentType;
              const docType = docT?.split("~").at(0);
              return doc?.documentType == docType;
            });
            setDocumentList(filtered);
          } else {
            console.log('::filtered', data.payload);
            setDocumentList(data.payload);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const actionOk = () => {

    setLoading(true);
      // docReplaceDocs(docId)
      console.log('::>selectedDocument', selectedDocument);
    const doc =
      selectedDocument?.documentOrgType ?? selectedDocument?.documentType;
    const docType = doc?.split("~").at(0);
    const docSubType = doc?.split("~").at(-1);

    API_SERVICE.renameDocument(docId, {
      docOrder:
        selectedDocument?.documentDetails?.docOrder ?? Number(docSubType),
      docPage: selectedDocument?.documentDetails?.docPage ?? 0,
      documentType: selectedDocument?.documentType?.split("~").at(0),
    })
      .then(({ data }) => {
        // getAllDocumentList();
        if (selectedDocument.documentDetails) {
          API_SERVICE.renameDocument(selectedDocument?.documentDetails?.businessDocumentId, {
            docOrder: -1,
            docPage: -1,
            documentType: selectedDocument?.documentType?.split("~").at(0),
          }).then(({ data }) => {
            callbackSuccess(data.payload);  
          }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
            setLoading(false);    
          })
        } else {
          callbackSuccess(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      });
  };
//   const generatDocumentType = (documentSubTypeList) => {
//     return <div>
//         {documentSubTypeList &&
//             <Select
//                 className='custom-select'
//                 placeholder="Document Type"
//                 onChange={(e) => setSelectedDocumentType(e)}
//             >
//                 {documentSubTypeList?.sort(function (a: any, b: any) {
//                     if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
//                     if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
//                     return 0;
//                 })?.map((item: any, i: number) => {
//                     return <Select.Option key={i} value={item.key}>
//                         <Tooltip title={item.value}>
//                             {item.value}
//                         </Tooltip>
//                     </Select.Option>
//                 })}
//             </Select>
//         }
//     </div>
// }

  return (
    <>
      <Modal
        title="Replace Document"
        centered
        width={300}
        onCancel={() => setShowReplaceDoc(false)}
        footer={null}
        visible={showReplaceDoc}
      >
        <div className="replace-doc">
          <span>{selectedDocument?.lenderDocumentValues}</span>
          <div className="search">
            <Select
              size="large"
              placeholder="Search"
              className={isConflict ? "width-100" : ""}
              onChange={(e) => {
                console.log("e: ", e?.split("~")?.at(-1));
                console.log("documentList: ", documentList);
                setDocId(e?.split("~")?.at(-1));
              }}
              showSearch
            >
              {documentList?.map((doc: any) => {
                return (
                  <Select.Option
                    value={
                      (isConflict ? doc?.documentName : doc?.documentName) +
                      "~" +
                      doc?.businessDocumentId
                    }
                  >
                    <div>
                      {isConflict ? doc?.documentName : doc?.documentName}
                      {doc && (
                        <div className="downloadBtn" style={{
                          position:"absolute",
                          top:'0',
                          right:'0'
                        }}>
                          <Button
                            type="text"
                            onClick={() => downloadDocument(doc)}
                            // disabled={disabled}
                            icon={
                              <img src={iconDownload} width="20px" height="20px" alt="" />
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Select.Option>
                );
              })}
            </Select>

            {/* {!isConflict && (
              <Button
                type="text"
                onClick={actionAdd}
                icon={<img src={iconPlus} width="20px" height="20px" alt="" />}
              />
            )} */}
          </div>
          {/* {documentSubtypeList?.length > 1 && (
                        <Select
                        className='custom-select'
                        placeholder="Document Type"
                        style={{ marginTop: '10px', borderRadius: '0px' }}
                        onChange={(e) => updateSelectedSubtype(e)}
                    >
                        {documentSubtypeList?.map((item: any, i: number) => {
                            // TODO: if change anything in following value then change inside useeffect setSelectedDocumentType() also
                            return <Select.Option key={i} value={item}>
                                <Tooltip title={item.split('~').at(0)}>
                                    {item.split('~').at(0)}
                                </Tooltip>
                            </Select.Option>
                        })}
                    </Select>
                    )} */}

          <Button
            type="primary"
            className="next-btn-submit"
            loading={loading}
            style={{
              width: "100%",
              marginTop: "20px",
              borderRadius: "8px",
              height: "41px",
            }}
            onClick={() => {
              actionOk();
            }}
            disabled={!docId}
          >
            Replace
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "-20px",
            }}
          >
            OR
          </div>
          <UploadDocument
            // selectedType={"SIX_MONTH_SALARY_SLIP"}
            isLenderDocs={true}
            selectedLander={selectedLander}
            formatDocument={selectedDocument?.format ?? ""}
            selectedType={selectedDocument?.documentType ?? ""}
            docOrder={
              selectedDocument?.documentDetails?.docOrder ??
              selectedDocument?.documentOrgType?.split("~")?.at(-1) ??
              0
            }
            docPage={getDocPage()}
            maxSize={selectedDocument?.maxSize ?? 5}
            documentSetId={documentSetId}
            individualType={leadData.applicationSubType}
            actionOnDelete={actionOnDelete}
            selectedDocument={selectedDocument}
            callBack={callbackDocUploaded}
          />
        </div>
      </Modal>
    </>
  );
};

export default ReplaceDocument;
