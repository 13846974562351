import "./index.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import { RiSearchLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import moment from "moment";
import type { ColumnsType } from 'antd/es/table';
import API_SERVICE from "shared/services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setIsAppFooterShow, setIsAppHeaderShow, setShowProfielVerification } from "shared/redux/common-reducer";
import { isUserVerified } from "shared/services/Utility";
import blackRefereshIcon from "../../assets/images/blackReferesh.svg";


const { Title } = Typography;
type Props = {};

const Offers: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [allOffers, setAllOffers] = useState<"" | any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchValue, setSearchValue] = useState(null as any);
  const [form] = Form.useForm();
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);
  const { appColors } = useSelector((state: RootState) => state.common);

  const dispatch = useDispatch();
  const columns: ColumnsType<any> = [
    {
      title: "Offer",
      key: "offer",
      width: 150,
      dataIndex: ["mobileOfferDocumentDetails", "documentDetails"],
      render: (documentUrl: any, record: any) => {
        const document = (record?.webOfferDocumentDetails?.length ?? 0) > 0 ? record.webOfferDocumentDetails[0] : {};
        return <Space className="action-button" size="middle">
          <img src={"data:image/" + document.fileExtn + ";base64," + document.documentDetails} width="100px" height="40px" alt="banner" />
        </Space>
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created",
      dataIndex: "creationDate",
      key: "date",
      width: 135,
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("DD/MM/YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      width: 50,
      align: "center",
      render: (_: any, record: any) => (
        <Button
          className="custome-btn-icon"
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
          size="small"
          type="text"
          icon={<IconEyeBorder />}
          onClick={() => goToOfferDetail(record)}
        />
      ),
    },
  ];

  const goToOfferDetail = (item: any) => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true));
    } else {
      history.push(`/offers/view-offer/${item.busCommId}`);
    }
  };

  useEffect(() => {
    getAllOffers();
    dispatch(setIsAppFooterShow(false));
    dispatch(setIsAppHeaderShow(true));
  }, []);

  const getAllOffers = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getOffers(params)
      .then(({ data }: any) => {
        if (data.payload.content.length > 0) {
          mapLeadsData(data);
        } else {
          notification.error({ message: "No Offer found." });
          mapLeadsData({});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllOffers(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTotal(pagination.total);
    getAllOffers(pagination.current, pagination.pageSize, searchValue);

  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
    const res = getAllOffers(1, pageSize, event.target.value);
  };
  return (
    <>
      <div className="content-box">
        <Title level={3}>Offers</Title>
        <Form form={form}>
          <Row gutter={24} className="data-filter-bar">
            <Col xs={24} sm={14} md={16} lg={18} className="pd-lr-10">
              <Form.Item name="searchBox">
                <Input size="large" className="search-box" placeholder="Search" allowClear prefix={<RiSearchLine />} onChange={debounce(handleSearch, 1000)} />
              </Form.Item>
            </Col>
            <Col xs={9} sm={5} md={4} lg={3} className="pd-lr-10">
              <Tooltip title="Refresh">
                <Button type="primary" className="refresh-button" onClick={() => { form.setFieldsValue({ searchBox: "" }); setSearchValue(""); getAllOffers(currentPage, pageSize); }}>
                <img src={blackRefereshIcon} alt="" style={{ marginLeft: '-5px' }} />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        <Row gutter={24} style={{ marginTop: isMobile ? "20px" : "0px" }}>
          {isMobile ? (
            <List
              style={{ width: "100%" }}
              grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
              pagination={{
                onChange: (page) => {
                  handlePaginationChange(page);
                },
                current: currentPage,
                pageSize: pageSize,
                total: totalCount
              }}
              loading={loading}
              itemLayout="horizontal"
              dataSource={allOffers}
              renderItem={(item: any) => (
                <List.Item style={{ padding: "0px 12px" }} onClick={() => goToOfferDetail(item)}>
                  <Card>
                    {item.mobileOfferDocumentDetails.map(image => (
                      <img
                        src={"data:image/" + image.fileExtn + ";base64," + image.documentDetails}
                        width="100%"
                        style={{ borderRadius: "15px" }}
                        alt="banner"
                      />
                    ))}&nbsp;
                    <h3>
                      {item.title}
                    </h3>
                    <h6>
                      {moment(new Date(item.creationDate))
                        .locale("en")
                        .format("DD/MM/YYYY, hh:mm A")}
                    </h6>
                  </Card>
                </List.Item>
              )}

            />
          ) : (
            <Col span={24}>
              <Table 
                className="dynamic-pagination-checkbox"
                style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                size="small" 
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                dataSource={allOffers}
                onChange={handlePaginationChange}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalCount,
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Offers;
