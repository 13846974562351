import { useState } from "react";
import { Button, Card, Form, notification } from "antd";
import API_SERVICE from "shared/services/api-service";
import FormInput from "shared/components/FormInput/FormInput";
import { PHONE_NUMBER_REGEX, PIN_CODE } from "shared/constants/AppConstants";
import RadioButton from "shared/components/RadioButton/RadioButton";
import { signUpStages } from "./sign-up";
import { getEnumList } from "shared/services/Utility";
import "../authentication.scss";

interface ISignUpYourDetail {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  setMobileNo: React.Dispatch<React.SetStateAction<string>>;
  setVerificationId: React.Dispatch<React.SetStateAction<string>>;
  saveUserData: Function;
  businessType: string;
  userDetails: any;
}

const SignUpYourDetail: React.FunctionComponent<ISignUpYourDetail> = (
  props: ISignUpYourDetail
) => {
  const {
    setSelectedSignUpStage,
    setMobileNo,
    setVerificationId,
    saveUserData,
    businessType,
    userDetails
  } = props;

  const [fields, setFields] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [formSignIn] = Form.useForm();

  const getOTP = (mobileNo) => {
    setLoading(true);
    API_SERVICE.getOTPmobile(mobileNo)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          setMobileNo(mobileNo);
          setVerificationId(data?.payload?.id);
          setSelectedSignUpStage("verifyDetail");
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (values: any) => {
    const params = {
      email: values.email,
      gender: values.gender,
      name: values.fullName,
      phoneNumber: values.mobileNo,
      pinCode: values.pinCode,
      profession: values?.profession ?? "",
      managerId: (userDetails?.managerId?.length ?? 0) == 0 ? null : userDetails?.managerId,
      referralId: (userDetails?.referralId?.length ?? 0) == 0 ? null : userDetails?.referralId,
      slateEmployeeId: (userDetails?.slateEmployeeId?.length ?? 0) == 0 ? null : userDetails?.slateEmployeeId,
    };

    ///////////////////////////////
    // saveUserData(data);
    // setMobileNo("9931313131");
    // setVerificationId("123456789");
    // setSelectedSignUpStage("verifyDetail");
    // return;
    ////////////////////////////////

    setLoading(true);
    API_SERVICE.userSignUp(
      userDetails?.referralSource,
      userDetails?.dsaBusinessId,
      params,
      businessType == "individual" ? "individual" : "business"
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          saveUserData(data?.payload);
          getOTP(values.mobileNo);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderForm = () => {
    return (
      <Form
        size="large"
        layout="vertical"
        autoComplete="off"
        form={formSignIn}
        onFieldsChange={(allFields) => {
          setFields(allFields);
        }}
        fields={fields}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
      >
        {businessType == "individual" && (
          <RadioButton
            label="Select Profession"
            name="profession"
            radioList={getEnumList("PROFESSION")}
            className="custom-radio profession-btns"
            isRequired={true}
            requiredLabel="profession"
          />
        )}

        <RadioButton
          label="Select Gender"
          id="gender"
          name="gender"
          radioList={getEnumList("GENDER")}
          className="custom-radio profession-btns"
          isRequired={true}
          requiredLabel="gender"
        />

        <FormInput
          label="Full Name (As Per PAN Card)"
          name={"fullName"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Full Name (As Per PAN Card)"}
          isRequired={true}
          requiredLabel="full name (As Per PAN Card)"
          isBorder
        />

        <FormInput
          label="Pin Code"
          name={"pinCode"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Pin Code"}
          isRequired={true}
          requiredLabel="pin Code"
          inputMode={"numeric"}
          isBorder
          rules={[
            {
              pattern: PIN_CODE,
              message: "Please enter valid pin Code",
            },
          ]}
        />

        <FormInput
          label="Email"
          name={"email"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Email"}
          isRequired={true}
          isBorder
          requiredLabel="email"
          inputMode={"email"}
          rules={[{ type: "email", message: "Please enter valid email" }]}
        />

        <FormInput
          label="Mobile No"
          name={"mobileNo"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Mobile No"}
          isRequired={true}
          isBorder
          requiredLabel="mobile no"
          inputMode={"numeric"}
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid number",
            },
          ]}
        />

        <Form.Item className="oneflo">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size={"large"}
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card className="authentication-box">
      <h3 className="welcome-to-oneflo">Enter Your Details</h3>
      <div className="authentication-form">{renderForm()}</div>
    </Card>
  );
};

export default SignUpYourDetail;
