import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Space,
  Card,
  Form,
  Col,
  Select,
  Typography,
  Divider,
  Input,
  Tree,
  Radio,
  Checkbox,
  notification,
  List,
} from "antd";
import "./index.scss";
import debounce from "lodash/debounce";
import API_SERVICE from "shared/services/api-service";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setFilterData,
  setIsShowFilter,
  setLeadPageNo,
  setLoanType,
  setRestFilterData,
} from "shared/redux/lead-reducer";
import searchIcon from "assets/images/search.svg";
import plus from "assets/images/squre-plus.svg";
import minus from "assets/images/squre-minus.svg";
import { getEnumList, getUserDetails } from "shared/services/Utility";

const { Title, Text } = Typography;

const LeadFilter: React.FunctionComponent<any> = (props: any) => {
  const lead: any = useSelector((state: RootState) => state.lead);
  const hierarchyDataId = lead?.filterData?.connectorId;
  const [hierarchyList, setHierarchyList] = useState([]) as any;
  const [hierarchyId, setHierarchyId] = useState(hierarchyDataId ?? []) as any;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const [expandedConnectorId, setExpandedConnectorId] = useState([] as any);

  useEffect(() => {
    form.setFieldsValue({
      LOAN_TYPE: lead?.loanType,
      caseIdentifier: lead?.filterData?.caseIdentifier
    });
    if (Object.keys(lead.filterListDetails).length === 0) {
    }
    getListHierarchy();
  }, []);

  const getListHierarchy = (searchParam?: string) => {
    API_SERVICE.getListHierarchy({ searchParam }).then(({ data }: any) => {
      if (data.payload) {
        if ((searchParam?.length ?? 0) > 0) {
          setHierarchyList(data?.payload?.searchList ?? []);
        } else {
          setHierarchyList([data?.payload?.connectorHierarchyDetails ?? {}]);
          if((hierarchyDataId?.length ?? 0) == 0) {
            onCheckboxTick(data?.payload?.connectorHierarchyDetails ?? {});
          }
        }
      }
    });
  };

  const clearFilter = () => {
    form.resetFields();
    setHierarchyId([]);
    onCheckboxTick(hierarchyList[0]);
    dispatch(setRestFilterData());
    // dispatch(setIsShowFilter(false));
  };

  const onFinish = (e: any) => {
    let loanTypes: any = getEnumList("LOAN_TYPE").find((data: any) => {
      return e?.LOAN_TYPE == data?.value;
    });

    let caseIdentifier;
    if(Array.isArray(e?.caseIdentifier)) {
      caseIdentifier = e?.caseIdentifier?.at(0)
      console.log("is Array")
    }

    if (
      !loanTypes?.key &&
      !caseIdentifier &&
      (hierarchyId?.length ?? 0) == 0 &&
      !e?.assignToMe
    ) {
      notification.error({ message: "Please select one value of filter" });
    } else {
      let param = {
        loanProductIds: [loanTypes?.key],
        connectorId: e?.assignToMe ? [getUserDetails().userId] : hierarchyId,
        caseIdentifier: caseIdentifier,
      };
      dispatch(setFilterData(param));
      dispatch(setLeadPageNo(1));
      dispatch(setIsShowFilter(false));
    }
  };

  const searchHierarchy = (event) => {
    console.log("event: ", event?.target?.value);
    getListHierarchy(event?.target?.value);
  };

  const onExpand = (item: any) => {
    let keys = [...expandedConnectorId];
    let availableIndex = keys?.findIndex((key) => key == item.key);
    if (availableIndex > -1) {
      keys.splice(availableIndex, 1);
    } else {
      keys.push(item.key);
    }
    setExpandedConnectorId(keys);
  };

  const onCheckboxClick = async (item) => {
    let keys = [...hierarchyId];
    let availableIndex = keys?.findIndex((key) => key == item.key);
    if (availableIndex > -1) {
      onCheckboxUnTick(item);
    } else {
      onCheckboxTick(item);
    }
  };

  const getChildKeys = (listOfItem, childKeys) => {
    listOfItem?.forEach(async (item) => {
      childKeys.push(item.key);
      if (item?.children?.length > 0) {
        let newKeys = await getChildKeys(item?.children, childKeys);
        childKeys.push(...newKeys);
      }
    });
    return childKeys;
  };

  const onCheckboxUnTick = (item: any) => {
    // setSelectAll(false);
    let keys = [...hierarchyId];
    let childKeys = [] as any;
    childKeys.push(item.key);
    if (item?.children?.length > 0) {
      childKeys = getChildKeys(item.children, childKeys);
    }
    for (let i = 0; i < childKeys?.length; i++) {
      let keySplice = childKeys[i];
      let availableIndexSplice = keys.findIndex((key) => key == keySplice);
      if (availableIndexSplice > -1) {
        keys.splice(availableIndexSplice, 1);
      }
    }
    // setSelectedConnectorId([...new Set(keys)]);
    // setSelectedConnectorId(keys);
    setHierarchyId(keys);
  };

  const onCheckboxTick = (item: any) => {
    let keys = [...hierarchyId];
    let childKeys = [] as any;
    childKeys.push(item.key);
    if (item?.children?.length > 0) {
      childKeys = getChildKeys(item.children, childKeys);
    }
    for (let i = 0; i < childKeys?.length; i++) {
      let keySplice = childKeys[i];
      let availableIndexSplice = keys.findIndex((key) => key == keySplice);
      if (availableIndexSplice > -1) {
      } else {
        keys.push(keySplice);
      }
    }
    // setSelectedConnectorId([...new Set(keys)]);
    // setSelectedConnectorId(keys);
    setHierarchyId(keys);
  };

  const checkboxStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? "",paddingRight: 5 };

  const renderList = (item, index, children) => {
    let isExpanded = expandedConnectorId?.find((key) => key == item.key);
    let isChecked = hierarchyId?.find((key) => key == item.key);
    // let isSelectedConnector = item?.isSelectedConnector;
    return (
      <div style={{ marginLeft: "20px" }}>
        <div>
          <Button
            style={{
              marginRight: "5px",
              height: "20px",
              width: "20px",
              padding: "0px",
              border: "none",
            }}
            onClick={() => onExpand(item)}
          >
            {item.children && (
              <img
                src={isExpanded ? minus : plus}
                height={20}
                width={20}
                style={{ marginTop: "-5px" }}
              />
            )}
          </Button>
          <Checkbox
            style={checkboxStyles}
            className="dynamic-pagination-checkbox"
            checked={isChecked}
            onClick={() => {
              onCheckboxClick(item);
            }}
          />
          <Text
            style={{
              textAlign: "left",
            }}
          >
            {item?.title}
          </Text>
        </div>
        {item?.children?.length > 0 &&
          isExpanded &&
          children.map((data, index) => {
            // if (!data.parent) {
            //   data.parent = item;
            // }
            return (
              <>{renderList(data, index, data?.children)}</>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <Card style={{ width: "100%", marginBottom: "30px" }}>
        <Title level={5} style={{ marginLeft: "5px" }}>
          <Row>
            <Col span={12}> Filter By:</Col>
            <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <CloseOutlined
                onClick={() => {
                  dispatch(setIsShowFilter(false));
                }}
              />
            </Col>
          </Row>
        </Title>
        <Divider />
        <Form
          form={form}
          size="large"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col xs={24} md={24} sm={3} lg={3}>
              <Form.Item name="caseIdentifier" label="Source">
                <Checkbox.Group>
                  <Space direction="vertical">
                    {getEnumList("CASE_IDENTIFIER")?.map(
                      (loan: any, i: number) => (
                        <Checkbox key={i} value={loan.key}  
                        className="dynamic-pagination-checkbox"
                        style={checkboxStyles}
                        >
                          {loan.value}
                        </Checkbox>
                      )
                    )}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} sm={8} lg={8}>
              <Form.Item name="LOAN_TYPE" label="Loan Type">
                <Select
                  className="filter-custom-select"
                  placeholder="Loan Type"
                  showSearch
                  onSelect={(e) => {
                    dispatch(setLoanType(e));
                  }}
                  options={getEnumList("LOAN_TYPE")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} sm={8} lg={8}>
              <Form.Item name="searchBox" label="Connectors">
                <Input
                  size="large"
                  className="input-search-box"
                  placeholder="Search"
                  allowClear
                  prefix={<img src={searchIcon} width={20} />}
                  onChange={debounce(searchHierarchy, 600)}
                />
              </Form.Item>
              <Form.Item name="hierarchy">
                <Card style={{ maxHeight: "270px", overflow: "auto" }}>
                  {/* <Tree
                    checkable
                    showLine
                    treeData={hierarchyList}
                    checkedKeys={hierarchyId}
                    checkStrictly
                    onCheck={(e: any) => setHierarchyId(e?.checked)}
                    defaultExpandAll={true}
                  /> */}
                  <List
                    style={{ width: "100%" }}
                    grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
                    itemLayout="horizontal"
                    dataSource={hierarchyList}
                    renderItem={(item: any, index: number) => (
                      <List.Item style={{ padding: "0px" }}>
                        {renderList(item, index, item?.children)}
                      </List.Item>
                    )}
                  />
                </Card>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Space>
                <Button size="large" htmlType="button" onClick={clearFilter} className="dynamic-btn-default" style={{'--borderColor': appColors?.appSecondaryColor ?? ""} as any}>
                  Clear
                </Button>
                {/* <Button
                  style={{ color: "#2e43b2", borderColor: "#2e43b2" }}
                  size="large"
                  htmlType="button"
                  onClick={() => {
                    form.resetFields();
                    setHierarchyId([]);
                    onFinish({ assignToMe: true });
                  }}
                >
                  Assign to me
                </Button> */}
                <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}>
                  Apply Filter
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default LeadFilter;
