import { Button, Col, Collapse, List, Row, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import LenderDisbursed from "./LenderDisbursed";
import iconDownload from "../../../../assets/images//download.svg";
import {ReactComponent as FileBorder} from "../../../../assets/images/file-border.svg";
import { FaFile } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import FormIcons from "shared/components/FormIcons/FormIcons";

const { Title } = Typography;

interface ILenderDecisionMain {
  leadData: any;
  selectedLenderList: any;
  lenderDisbursedHandler: any;
  getRedirectURLLink: any;
  getDownloadLetterForICICI: any;
  loadingUpdate: any;
  getCashETriggerSMSLink?: any;
  selectedLenderName: any;
  setIsLoading:any;
}

const LenderDisbursedMain: React.FC<ILenderDecisionMain> = (
  props: ILenderDecisionMain
) => {
  const {
    leadData,
    selectedLenderList,
    lenderDisbursedHandler,
    getRedirectURLLink,
    getDownloadLetterForICICI,
    loadingUpdate,
    getCashETriggerSMSLink,
    selectedLenderName,
    setIsLoading,
  } = props;
  const { Panel } = Collapse;
  const [lenderRequestPayload, setLenderRequestPayload] = useState([] as any);
  const [documentList, setDocumentList] = useState([]);
  
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const findSelected =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
      (item) => item?.lenderName == selectedLenderName
    );

  const downloadDocument = (item: any) => {
    setIsLoading(true);
    API_SERVICE.downloadDocument(item?.businessId, item?.documentId ?? item?.businessDocumentId)
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getLenderRequestPayload();
    getAllDocumentList();
  }, []);

  const getAllDocumentList = () => {
    const submissionDetails =
      findSelected?.documentProgressDetails?.submissionDetails;
    if ((submissionDetails?.length ?? 0) > 0) {
      setDocumentList(submissionDetails);
      return;
    }
    setIsLoading(true);
    API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          const listDoc = [] as any;
          data?.payload?.documentConfigDetails?.map((docCon: any) => {
            docCon?.documentTypeWithUploadedDetails?.map((doc: any) => {
              if (doc?.documentDetails) {
                listDoc?.push(doc?.documentDetails);
              }
            });
          });
          setDocumentList(listDoc);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const getLenderRequestPayload = () => {
    setIsLoading(true);
    API_SERVICE.getLenderRequestPayload(
      leadData?.caseDetails?.caseId,
      selectedLenderList?.at(0)?.lenderInfoId
    )
      .then(({ data }) => {
        const map = data?.payload?.map((item: any) => {
          return JSON.parse(item);
        });
        setLenderRequestPayload(map);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const renderDetails = (list: any) => {
    const listTmp = list?.map((item: any) => {
      return renderObject(item);
    });

    // console.log("listTmp: ", listTmp)

    // const tmp = [] as any;
    // listTmp?.map((item: any) => {
    //   tmp.push(...item)
    // })

    // console.log("tmp: ", tmp)

    if ((listTmp?.at(0)?.at(0)?.length ?? 0) > 0) {
      return listTmp?.at(0)?.at(0);
    } else {
      return listTmp?.at(0);
    }
  };

  const renderObject = (item: any) => {
    if (item == null) {
      return <></>;
    }

    const keys = Object.keys(item);
    return keys?.map((key: any, index: any) => {
      let res = item[key];
      if (["string", "number", "boolean"].includes(typeof res)) {
        // console.log("key --- : ", key + ": " + res);
        return <span>{`${key}: ${res}`}</span>;
      } else if (
        [
          "conditionMethodCalculationCollection",
          "evaluatedmonthlyemiresponse",
        ].includes(key)
      ) {
        return <></>;
      } else {
        return renderObject(res);
      }
    });
  };

  const leadDetails = [
    <span>Name: {leadData?.contactDetails?.firstName}</span>,
    <span>Email: {leadData?.contactDetails?.emailId}</span>,
    <span>PAN: {leadData?.contactDetails?.pan}</span>,
    <span>
      DOB:{" "}
      {moment(leadData?.contactDetails?.dateOfBirth ?? "")
        .locale("en")
        .format("MMM DD YYYY")}
    </span>,
    <span> Mob No: {leadData?.contactDetails?.mobileNumber}</span>,
  ];

  return (
    <>
     <div
          style={{
            padding: "10px",
            display: "flex",
            gap: "100px",
            background: "#bfbfbf1c",
            marginBottom:'10px',
          }}
        >
      <div style={{ display: "grid" }}>
            <Title level={5}>{findSelected?.lenderName}</Title>
            <div
              style={{
                color: "#20A74E",
                gap: "10px",
                display: "flex",
                justifyContent: "left",
              }}
            >
          </div>
          </div>
      </div>
      <div  style={{paddingInline:'15px'}}>
        <div>
          <Collapse style={{ borderRadius: "6px" }}>
            <Panel header="Lead Details" key="1">
              <List
                className="leadDisburse-Detail-scroll"
                grid={{ gutter: 7, column: 3, xs: 1 }}
                dataSource={renderDetails(lenderRequestPayload)}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            </Panel>
          </Collapse>
        </div>

        <div style={{ marginTop: "12px" }}>
          <Collapse style={{ borderRadius: "6px" }}>
            <Panel header="Document Details" key="1">
              {documentList?.map((item: any, index: number) => {
                return (
                  <div
                    className="leadDisburse-Detail-scroll"
                    key={`${item?.documentId}-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      <FileBorder  style={{'--fileIconColor': appColors?.appPrimaryColor ?? ""} as any} className="icon-border"/>
                      {item?.documentName}
                    </span>
                    <Button
                      type="text"
                      icon={<FormIcons type="cloudDownload" color={appColors?.appSecondaryColor ?? "#C71C8E"} isDynamicIcon/>}
                      style={{ border: "none" }}
                      onClick={() => downloadDocument(item)}
                    />
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <LenderDisbursed
          leadData={leadData}
          assignedLenders={selectedLenderList}
          lenderDisbursedHandler={lenderDisbursedHandler}
          getRedirectURLLink={getRedirectURLLink}
          getDownloadLetterForICICI={getDownloadLetterForICICI}
          loadingUpdate={loadingUpdate}
          getCashETriggerSMSLink={getCashETriggerSMSLink}
          selectedLenderName={selectedLenderName}
        />
        {/* <div style={{ padding: "15px", border: "0.1px solid lightgrey" }}>
          <Space>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Disbursal Details :</p>
            </div>
            <div>
              <Form layout="vertical">
                <Space>
                  <Form.Item
                    label="Total Tentative Loan Amount"
                    name="tentativeLoanAmount"
                    className=""
                  >
                    <Input className="custom-input" />
                  </Form.Item>
                  <Form.Item
                    label="Total Sanctioned Loan Amount"
                    name="totalSanctionedLoanAmount"
                    className=""
                  >
                    <Input className="custom-input" />
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </Space>
        </div> */}
      </div>
    </>
  );
};
export default LenderDisbursedMain;
