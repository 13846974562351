import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common-reducer";
import tenantReducer from "./tenant-reducer";
import leadReducer from "./lead-reducer";
import employeesReducer from "./employees-reducer";
import offerReducer from "./offer-reducer";
import noticesReducer from "./notices-reducer";
import designationReducer from "./designation-reducer";
import notificationReducer from "./notification-reducer";
import notificationCountReducer from "./notificationCount-reducer";
import payoutReducer from "./payout-reducer";
import commonScreenReducer from "./commonScreen-reducer";

const store = configureStore({
  reducer: {
    common: commonReducer,
    tenant: tenantReducer,
    lead: leadReducer,
    employees: employeesReducer,
    designation: designationReducer,
    offers: offerReducer,
    notices: noticesReducer,
    notification: notificationReducer,
    notificationCount: notificationCountReducer,
    payoutReq: payoutReducer,
    commonScreenReducer: commonScreenReducer
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;