import React, { useEffect, useState } from "react";
import FormInput from "shared/components/FormInput/FormInput";
import API_SERVICE from "shared/services/api-service";
import { Button, Card, Form, notification } from "antd";
import { NUMBER_REGEX } from "shared/constants/AppConstants";
import { signUpStages } from "./sign-up/sign-up";
import ReCAPTCHA from 'react-google-recaptcha';

import "./authentication.scss";
import FormInputPassword from "shared/components/FormInput/FormInputPassword";

interface IVerifyDetails {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  mobileNo?: string;
  verificationId?: string;
  businessType: string;
}

const VerifyDetails: React.FunctionComponent<IVerifyDetails> = (
  props: IVerifyDetails
) => {
  const { setSelectedSignUpStage, mobileNo, verificationId, businessType } =
    props;

  const time = 60 * 2 - 1;

  const [verifyOtpData, setVerifyOtpData] = useState();
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [countDown, setCountDown] = React.useState(time);
  const [runTimer, setRunTimer] = React.useState(true);
  const [resetPassWord, setResetPassWord] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("") as any
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  
  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      setRunTimer(false);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  useEffect(() => {
    if (countDown === 0) {
      setIsTimeStoped(true);
    }
  });

  async function handleOTPCaptcha(mobileNumber:any){
    setLoading(true)
    if(mobileNumber.length === 10)
    {
      const payload = {
        mobileNumber:mobileNumber,
        password:"",
        reCaptchaScreenName:"OTP"
      }
      const { data } = await API_SERVICE.checkCaptchaRequired(payload);
      console.log(data?.payload?.captchaRequired)
      if(data)
        {
          setShowRecaptcha(data?.payload?.captchaRequired)
        }
  }
  setLoading(false)
  }

  const renderReCaptcha = () => {
    return (
         <div className="g-recaptcha">
      <ReCAPTCHA
        sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
        onChange={(token:any)=>setReCaptchaToken(token)}
      />
      </div>
    )
  }

  const actionResend = () => {
    if(isTimeStoped){
      handleOTPCaptcha(mobileNo)
    }
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else
    {    
      if (countDown === 0) {
      setReCaptchaToken("")
      setShowRecaptcha(false)
      API_SERVICE.getOTPmobile(mobileNo ?? "")
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data?.message });
            setCountDown(time);
            setIsTimeStoped(false);
            setRunTimer(true);
          } else {
            notification.error({ message: "no data found" });
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        });
    }
  }
  };

  const submitOtpHandler = async (values) => {
    verifyOtp(values.otp);
  };

  const verifyOtp = (otp) => {  
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else{
     const data = {
      mobileOtp: otp,
      mobileNumber: mobileNo,
      verificationId: verificationId,
    };
    setLoading(true);
    API_SERVICE.verifyOtp(data)
    .then(({ data }) => {
      if (data) {
        setVerifyOtpData(data);
        setResetPassWord(true);
        if (businessType == "individual") {
          setSelectedSignUpStage("setPassword");
        } else {
          setSelectedSignUpStage("businessDetail");
        }
        }
      })
      .catch(function (e) {
        setReCaptchaToken("")
        setShowRecaptcha(false)
        handleOTPCaptcha(mobileNo)
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

  return (
    <Card className="authentication-box">
      <h3 className="authentication-header">Verify Details</h3>
      <p>Enter the OTP sent to +91 {mobileNo}</p>
      <Form
        layout="vertical"
        className="authentication-form"
        onFinish={submitOtpHandler}
      >
        <FormInputPassword
          name="otp"
          classNameForm="oneflo-bottom-space"
          isRequired
          requiredLabel="otp"
          placeholder="Enter OTP"
         // type="password"

          isBorder
          rules={[
            {
              pattern: NUMBER_REGEX,
              message: "Please enter valid otp",
            },
          ]}
        />
         {showRecaptcha && renderReCaptcha()}
        <Form.Item
          style={{ display: "flex", justifyContent: "center", color: "grey" }}
        >
          Didn't receive the OTP? &nbsp;
          <a onClick={actionResend} style={{ color: "#C71C8E" }}>
            {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}
            <br />
          </a>
        </Form.Item>

        <Form.Item className="oneflo oneflo-bottom-space">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={loading}
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default VerifyDetails;
