import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import FormInput from "shared/components/FormInput/FormInput";
import checkCircle from "assets/images/check-circle.svg";
import calcBox from "assets/images/calc-box.svg";
import clacReject from "assets/images/calc-reject.svg";
import "./Tools.scss";
import { NUMBER_REGEX, PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import FormInputNumber from "shared/components/FormInput/FormInputNumber";
import FormSelect from "shared/components/FormSelect/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setIsAppFooterShow, setIsAppHeaderShow } from "shared/redux/common-reducer";

const { Title } = Typography;

const Tools = () => {
  const [form] = Form.useForm();
  const [product, setProduct] = useState("");
  const [applicant, setApplicant] = useState("");
  const [age, setAge] = useState() as any;
  const [amount, setAmount] = useState() as any;
  const [profit, setProfit] = useState() as any;
  const [emi, setEMI] = useState("");
  const [tenure, setTenure] = useState("");
  const [interest, setInterest] = useState("");
  const [businessVintage, setBusinessVintage] = useState("");
  const [cop, setCop] = useState("");
  const [turnover, setTurnover] = useState("");
  const [showClac, setShowClac] = useState(false);
  const [lonerName, setLonerName] = useState("");
  const [lonerMobileNumber, setLonerMobileNumber] = useState("");
  const [show, setShow] = useState("");
  const [shownew, setShowNew] = useState("");
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);
  const dispatch = useDispatch();

  const [results, setResults] = useState({
    calculatedLoanAmount: "",
    calculatedLoanProfit: "",
    calculatedPropertyLoanAmount: "",
    calculatedPropertyLoanProfit: "",
    calculatedBusinessLoanAmount: "",
    calculatedPersonalLoanAmount: "",
    calculateCreditCardLimit: "",
    isResult: false,
  } as any);

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(false));
  }, []);

  // Form OnFinish Function:
  const onFinish = (values) => {
    calculateResults(values);
    form.resetFields();
  };

  const handleProductChange = (event) => {
    const product = event;
    setShow(product);
    setProduct(product);
  }

  const handleApplicantChange = (event) => {
    const applicant = event;
    setShowNew(applicant);
    setApplicant(applicant);
  };
  const handleProfileChange = (event) => {
    const profile = event;
  };

  // Calculation
  const calculateResults = ({
    amount,
    profit,
    interest,
    tenure,
    emi,
    cop,
    turnover,
    foir,
    profile,
    margin,
  }) => {
    const userAmount = Number(amount);
    const userEmi = Number(emi);
    const userCop = Number(cop);

    if (
      (amount > 24999 && amount < 50000) ||
      (profit > 24999 && profit < 50000)
    ) {
      foir = 50;
    } else if (
      (amount >= 50001 && amount < 75000) ||
      (amount >= 50001 && amount < 75000)
    ) {
      foir = 60;
    } else if (amount >= 75000 || profit >= 75000) {
      foir = 70;
    } else {
      foir = 0;
    }

    const Numerator = userAmount * (foir / 100) - userEmi;
    const ProfitNumerator = (profit / 12) * (foir / 100) - userEmi;
    const t = Math.min(tenure, 60 - age, 30);
    const x = Math.pow(1 + interest / 100 / 12, 12 * t);
    const xx = x - 1;
    const y = 100000 * (interest / 100 / 12) * x;
    const denominator = y / xx;
    //denomminator means PMT

    //if home loan + amount
    const calculatedLoanAmount = Numerator / denominator;
    const emiCalculate = Numerator;
    //if home loan + profit
    const calculatedLoanProfit = ProfitNumerator / denominator;
    const emiCalculateProfit = ProfitNumerator;
    //if property of cost then + amount
    const calculatedPropertyLoanAmount = Math.min(
      calculatedLoanAmount,
      userCop * 0.6
    );
    const emiCalculateProperty = denominator * calculatedPropertyLoanAmount;
    //if property of cost then + profit
    const calculatedPropertyLoanProfit = Math.min(
      ProfitNumerator / denominator,
      userCop * 0.6
    );
    const emiCalculatePropertyProfit =
      denominator * calculatedPropertyLoanProfit;

    if (profile === "Retailer" || profile === "Wholesaler") {
      margin = 0.08;
    } else if (profile === "Services") {
      margin = 0.3;
    } else if (profile === "Manufacturing") {
      margin = 0.1;
    } else {
      margin = 0;
    }

    //if Business Loan then
    const calculatedBusinessLoanAmount =
      ((turnover / 12) * margin * (70 / 100) - userEmi) / denominator;
    const emiBusinessLoan = denominator * calculatedBusinessLoanAmount;
    //if Personal Loan then
    const calculatedPersonalLoanAmount =
      (userAmount * (50 / 100) - userEmi) / denominator;
    const emiPersonalLoan = denominator * calculatedPersonalLoanAmount;
    //if Credit Card then
    const calculateCreditCardLimit = Math.min(2 * (userAmount - emi), 300000);

    setResults({
      calculatedLoanAmount: calculatedLoanAmount.toFixed(2),
      emiCalculate: emiCalculate.toFixed(2),

      calculatedLoanProfit: calculatedLoanProfit.toFixed(2),
      emiCalculateProfit: emiCalculateProfit.toFixed(2),

      calculatedPropertyLoanAmount: calculatedPropertyLoanAmount.toFixed(2),
      emiCalculateProperty: emiCalculateProperty.toFixed(0),

      calculatedPropertyLoanProfit: calculatedPropertyLoanProfit.toFixed(2),
      emiCalculatePropertyProfit: emiCalculatePropertyProfit.toFixed(0),

      calculatedBusinessLoanAmount: calculatedBusinessLoanAmount.toFixed(2),
      emiBusinessLoan: emiBusinessLoan.toFixed(0),

      calculatedPersonalLoanAmount: calculatedPersonalLoanAmount.toFixed(2),
      emiPersonalLoan: emiPersonalLoan.toFixed(0),

      calculateCreditCardLimit: calculateCreditCardLimit.toString(),

      calculateTenure: t,
      isResult: true,
    });
    return;
  };

  const clearFields = () => {
    form.resetFields();
    setProduct("");
    setApplicant("");
    setAge("");
    setAmount("");
    setProfit("");
    setEMI("");
    setTenure("");
    setInterest("");
    setBusinessVintage("");
    setCop("");
    setTurnover("");
    setShow("");
    setShowNew("");
    setResults({
      calculatedLoanAmount: "",
      calculatedLoanProfit: "",
      calculatedPropertyLoanAmount: "",
      calculatedPropertyLoanProfit: "",
      calculatedBusinessLoanAmount: "",
      calculatedPersonalLoanAmount: "",
      calculateCreditCardLimit: "",
      isResult: false,
    });
  };

  const eligibleLogo = () => {
    return (
      <>
        <div className="eligible-logo">
          <img src={checkCircle} width={120} />
        </div>
        <div className="eligible-msg">Congrats! You are eligible.</div>
      </>
    );
  }

  const eligibleValue = (amount, title,) => {
    return (
      <Col xs={24} md={24} sm={8} lg={8} className="background-img">
        <div style={{ backgroundImage: `url(${calcBox})`, height: "100px" }}>
          <div className="amount">
            {amount}
          </div>
          <Divider className="divider-clac" />
          <div className="loan-title-type">{title}</div>
        </div>
      </Col>
    );
  }

  const renderAmountEmiTenure = (isTenure, loanAmount, emi) => {
    return (
      <div>
        {eligibleLogo()}
        <Row className="eligible-value" gutter={24}>
          {eligibleValue(`Rs. ${loanAmount} Lac`, "Loan Amount")}
          <Col span={2} style={{marginTop:isMobile?"8px":""}}> </Col>
          {eligibleValue(`Rs. ${emi}`, "EMI")}
        </Row>
        {isTenure ?
          <Row className="eligible-value" gutter={24}>
            {eligibleValue(`${results.calculateTenure} Years`, "Loan Tenure")}
            <Col span={10}> </Col>
          </Row> : ""}
      </div>
    )
  }

  const onClac = (value) => {
    setLonerName(value.name)
    setLonerMobileNumber(value.number)
    setShowClac(true)
  }

  const formInputAge = () => {
    return (
      <FormInput
        name="age"
        label="Age"
        min={0}
        placeholder="Your Age"
        requiredLabel="age"
        isRequired
        classNameForm="oneflo-bottom-space"
        onChange={(e) => setAge(parseInt(e?.target?.value))}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter valid age",
          },
        ]}
      />
    )
  }

  const formInputEmi = () => {
    return (
      <FormInputNumber
        name="emi"
        label="Monthly Obligations (EMI)"
        placeholder="EMI"
        requiredLabel="monthly obligations"
        isRequired
        classNameForm="oneflo-bottom-space"
        onChange={(value) => setEMI(value)}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter valid monthly obligations",
          },
        ]}
      />
    )
  }

  const formInputNetSalary = () => {
    return (
      <FormInputNumber
        name="amount"
        label="Net Salary (Monthly)"
        classNameForm="oneflo-bottom-space"
        placeholder="Net Salary"
        requiredLabel="net salary"
        isRequired
        onChange={(value) => setAmount(parseInt(value))}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter valid net salary",
          },
        ]}
      />
    )
  }

  const formInputNetProfit = () => {
    return (
      <FormInput
        name="profit"
        label="Net Profit For The Year"
        type="number"
        min={0}
        placeholder="Net Profit"
        isRequired
        requiredLabel="net profit"
        classNameForm="oneflo-bottom-space"
        onChange={(e) => setProfit(parseInt(e.target.value))}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter net profit for the year",
          },
          {
            whitespace: true,
          },
        ]}
      />
    )
  }

  const formInputTenure = () => {
    return (
      <FormInput
        name="tenure"
        label="Tenure (Years)"
        type="number"
        min={0}
        placeholder="Tenure"
        isRequired
        requiredLabel="tenure"
        classNameForm="oneflo-bottom-space"
        onChange={(e) => setTenure(e.target.value)}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter tenure(years)",
          },
        ]}
      />
    )
  }

  const formInputBusinessVintage = () => {
    return (
      <FormInput
        name="businessVintage"
        label="Business Vintage (Years)"
        type="number"
        min={0}
        placeholder="Business Vintage"
        isRequired
        requiredLabel="business vintage"
        classNameForm="oneflo-bottom-space"
        onChange={(e) => setBusinessVintage(e.target.value)}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter business vintage",
          }
        ]}
      />
    )
  }

  const formInputRateInterest = () => {
    return (
      <FormInput
        name="interest"
        label="Rate of Interest"
        type="number"
        placeholder="Interest"
        isRequired
        requiredLabel="rate of interest"
        classNameForm="oneflo-bottom-space"
        onChange={(e) => setInterest(e.target.value)}
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter ROI",
          },
        ]}
      />
    )
  }

  const applicantType = [{ key: "Salaried", value: "Salaried" }, { key: "Self-Employed", value: "Self Employed" }] as any
  const products = [
    { key: "Home-Loan", value: "Home Loan" },
    { key: "Lap", value: "LAP" },
    { key: "Business-Loan", value: "Business Loan" },
    { key: "Personal-Loan", value: "Personal Loan" },
    { key: "Credit-Card", value: "Credit Card" }
  ] as any
  const yourProfile = [
    { key: "Retailer", value: "Retailer" },
    { key: "Wholesaler", value: "Wholesaler" },
    { key: "Services", value: "Services" },
    { key: "Manufacturing", value: "Manufacturing" }
  ] as any

  return (
    <>
      <Title level={3}>Loan Calculator</Title>
      {!showClac && <div>
        <Form
          labelCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onClac}
          layout="vertical"
          form={form}
        >
          <Row>
            <Col xs={24} md={11} sm={10} lg={10} >
              <FormInput
                name="name"
                label="Your Name"
                min={0}
                placeholder="Enter your name"
                classNameForm="oneflo-bottom-space"
                isRequired
                requiredLabel="your name"
              />
              <FormInput
                name="number"
                label=" Mobile Number"
                min={0}
                isRequired
                requiredLabel="mobile number"
                placeholder="Enter mobile number"
                classNameForm="oneflo-bottom-space"
                rules={[
                  {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please enter valid number",
                  },
                ]}
              />
              <Form.Item>
                <Button
                  className="button btn-orange"
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>}

      {showClac && <div className="back-header">
        <Row>
          <Col xs={24} md={11} sm={9} lg={8} >
            <p style={{ margin: "8px" }}>Name: {lonerName}</p>
          </Col>
          <Col xs={24} md={11} sm={9} lg={8}>
            <p style={{ margin: "8px" }}>Mobile Number: {lonerMobileNumber}</p>
          </Col>
        </Row>
      </div>}

      {showClac && <Row gutter={{ xs: 8, sm: 8, md: 24, lg: 24 }}>
        <Col xs={24} md={24} sm={24} lg={18}>
          <div>
            <Form
              labelCol={{ span: 24 }}
              autoComplete="off"
              onFinish={onFinish}
              form={form}
            >
              {!results.isResult ? (
                <div>
                  <Row>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      <FormSelect
                        name={"product"}
                        label="Product"
                        placeholder="Select Product"
                        isRequired
                        options={products}
                        onChange={handleProductChange}
                      />
                    </Col>
                    <Col span={2} ></Col>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {show !== "" &&
                        show !== "Business-Loan" &&
                        show !== "Personal-Loan" &&
                        show !== "Credit-Card" ? (
                        <FormSelect
                          name={"applicant"}
                          label="Applicant Type"
                          placeholder="Select Applicant type"
                          isRequired
                          options={applicantType}
                          onChange={handleApplicantChange}
                        />
                      ) : (
                        ""
                      )}
                      {show === "Business-Loan" && (
                        <FormInput
                          name="turnover"
                          label="Gross Annual Turnover"
                          type="number"
                          min={0}
                          placeholder="Gross Annual Turnover"
                          classNameForm="oneflo-bottom-space"
                          onChange={(e) => setTurnover(e.target.value)}
                          rules={[
                            {
                              required: true,
                              message: "Please enter gross annual turnover",
                            },
                          ]}
                        />
                      )}
                      {
                        show === "Personal-Loan" ||
                          show === "Credit-Card" ? (
                          formInputAge()
                        ) : (
                          ""
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {(show === "Home-Loan") && (shownew === "Self-Employed" || shownew === "Salaried") ? (
                        formInputAge()
                      ) : (
                        ""
                      )}
                      {show === "Lap" && (shownew === "Self-Employed" || shownew === "Salaried") && (
                        <FormInputNumber
                          name="cop"
                          label="Market Value of Property (INR)"
                          placeholder="Market Value of Property"
                          onChange={(value) => setCop(value)}
                          classNameForm="oneflo-bottom-space"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter market value of property",
                            },
                          ]}
                        />
                      )}
                      {show === "Business-Loan" && (
                        formInputEmi()
                      )}
                      {
                        show === "Personal-Loan" ||
                          show === "Credit-Card" ? (
                          formInputNetSalary()
                        ) : (
                          ""
                        )}
                    </Col>
                    <Col span={2} ></Col>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {((show === "Home-Loan") &&
                        shownew === "Salaried") ? (
                        formInputNetSalary()
                      ) : (
                        ""
                      )}
                      {(show === "Home-Loan") &&
                        shownew === "Self-Employed" ? (
                        formInputNetProfit()
                      ) : (
                        ""
                      )}
                      {show === "Lap" && (shownew === "Self-Employed" || shownew === "Salaried") ? (
                        formInputAge()
                      ) : (
                        ""
                      )}
                      {show === "Business-Loan" ? (
                        formInputTenure()
                      ) : (
                        ""
                      )}
                      {(show === "Personal-Loan" || show === "Credit-Card") && (
                        formInputEmi()
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {(show === "Home-Loan") && (shownew === "Self-Employed" || shownew === "Salaried") && (
                        formInputEmi()
                      )}
                      {(show === "Lap") && shownew === "Salaried"
                        ? (
                          formInputNetSalary()
                        ) : (
                          ""
                        )}
                      {show === "Business-Loan" ? (
                        formInputRateInterest()
                      ) : (
                        ""
                      )}
                      {show === "Personal-Loan" ? (
                        formInputTenure()
                      ) : (
                        ""
                      )}
                      {(show === "Lap") &&
                        shownew === "Self-Employed" ? (
                        formInputNetProfit()
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col span={2}></Col>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {(shownew !== "" && (show === "Home-Loan")) ? (
                        formInputTenure()
                      ) : (
                        ""
                      )}
                      {(shownew !== "" &&
                        show === "Lap") && (
                          formInputEmi()
                        )}
                      {show === "Business-Loan" ? (
                        <FormSelect
                          name={"profile"}
                          label="Your Profile"
                          placeholder="Select Your Profile"
                          isRequired
                          options={yourProfile}
                          onChange={handleProfileChange}
                        />
                      ) : (
                        ""
                      )}
                      {(shownew !== "") && show === "Personal-Loan"
                        ? (
                          formInputRateInterest()
                        ) : (
                          ""
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {(shownew !== "" &&
                        (show === "Home-Loan")) ? (
                        formInputRateInterest()
                      ) : (
                        ""
                      )}
                      {(shownew !== "" &&
                        (show === "Lap")) ? (
                        formInputTenure()
                      ) : (
                        ""
                      )}
                      {(show === "Business-Loan") ? (
                        formInputBusinessVintage()
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col span={2} ></Col>
                    <Col xs={24} md={11} sm={9} lg={9}>
                      {(show === "Home-Loan" &&
                        shownew === "Self-Employed")
                        ? (
                          formInputBusinessVintage()
                        ) : (
                          ""
                        )}
                      {(shownew !== "" &&
                        (show === "Lap")) ? (
                        formInputRateInterest()
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      className="button btn-orange"
                      type="primary"
                      htmlType="submit"
                      style={{ width: isMobile ? "50%" : "25%" }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              ) : (
                //   Form to display the results to the user
                <div className="form-items">
                  {/* <h4 style={{fontFamily:'poppins-medium'}}>
                          <div>Product: {product}</div>
                          <div>{ (applicant === '' || applicant === 'undefined') ? '' : `Applicant type: ${applicant}`} </div>
                          <div>{ age === 0 || age === '' ? '' : `Age: ${age} years`} </div>
                          <div>{ amount === 0 || amount === '' ? '' : `Income (INR): ${amount}`}</div>
                          <div>{ emi === '' ? '' : `Monthly obligations / EMI (INR): ${emi}`} </div>
                          <div>{ tenure === '' ? '' : `Tenure: ${tenure} years`} </div>
                          <div>{ interest === '' ? '' : `Expected interest rate: ${interest}%`} </div>
                          <div>{ businessVintage === '' || businessVintage === undefined ? '' :`Business Vintage: ${businessVintage} years` } </div>
                          <div>{ cop === '' || cop === undefined ? '' :`Cost of property (INR Lakhs): ${cop}`} </div>
                          <div>{ (turnover === '' || turnover === 'undefined') ? '' : `Turnover: ${turnover}`}</div>     
                        </h4> */}
                  {/* <div style={{marginBottom:'10px'}}>
                          <label id='label'>Eligibility:</label>
                          { (age >= 21 || age === 0 || age === '') && (amount >= 24999 || amount === 0 || amount === '') ?
                            <Input type='text' value='Eligible' disabled />
                          : <Input type='text' value='Not Eligible' disabled /> }
                        </div> */}

                  {/* Home Loan calculation visible */}
                  {((age >= 21 && age <= 59) ||
                    age === 0 ||
                    age === null) &&
                    (amount >= 25000 || amount === 0 || amount === null) &&
                    product === "Home-Loan" &&
                    applicant === "Salaried" &&
                    parseInt(results.calculatedLoanAmount) > 0 ? (
                    <>
                      {renderAmountEmiTenure(true, results.calculatedLoanAmount, results.emiCalculate)}
                    </>
                  ) : ((age >= 21 && age <= 59) ||
                    age === 0 ||
                    age === null) &&
                    (profit >= 25000 ||
                      profit === 0 ||
                      profit === null) &&
                    product === "Home-Loan" &&
                    applicant === "Self-Employed" &&
                    parseInt(results.calculatedLoanProfit) > 0 ? (
                    <>
                      {renderAmountEmiTenure(true, results.calculatedLoanProfit, results.emiCalculateProfit)}
                    </>
                  ) : (age >= 21 || age === 0 || age === null) &&
                    (amount >= 24999 ||
                      amount === 0 ||
                      amount === null) &&
                    product === "Lap" &&
                    applicant === "Salaried" &&
                    parseInt(results.calculatedPropertyLoanAmount) > 0 ? (
                    <>
                      {renderAmountEmiTenure(true, results.calculatedPropertyLoanAmount, results.emiCalculateProperty)}
                    </>
                  ) : ((age >= 21 && age <= 59) ||
                    age === 0 ||
                    age === null) &&
                    (profit >= 25000 ||
                      profit === 0 ||
                      profit === null) &&
                    product === "Lap" &&
                    applicant === "Self-Employed" &&
                    parseInt(results.calculatedPropertyLoanProfit) > 0 ? (
                    <>
                      {renderAmountEmiTenure(true, results.calculatedPropertyLoanProfit, results.emiCalculatePropertyProfit)}
                    </>
                  ) : parseInt(businessVintage) >= 1 &&
                    (amount >= 24999 ||
                      amount === 0 ||
                      amount === null) &&
                    product === "Business-Loan" &&
                    parseInt(results.calculatedBusinessLoanAmount) > 0 ? (
                    <>
                      {renderAmountEmiTenure(false, results.calculatedBusinessLoanAmount, results.emiBusinessLoan)}
                    </>
                  ) : (age >= 21 || age === 0 || age === null) &&
                    (amount >= 24999 ||
                      amount === 0 ||
                      amount === null) &&
                    product === "Personal-Loan" &&
                    parseInt(results.calculatedPersonalLoanAmount) > 0 ? (
                    <>
                      {renderAmountEmiTenure(false, results.calculatedPersonalLoanAmount, results.emiPersonalLoan)}
                    </>
                  ) : (age >= 18 || age === 0 || age === null) &&
                    (amount >= 24999 ||
                      amount === 0 ||
                      amount === null) &&
                    product === "Credit-Card" &&
                    parseInt(results.calculateCreditCardLimit) > 0 ? (
                    <div>
                      {eligibleLogo()}
                      <Row className="eligible-value">
                        {eligibleValue(25.89, "Limit")}
                        <Col span={10}> </Col>
                      </Row>
                    </div>
                  ) : (
                    <Row>
                      <Col xs={24} md={24} sm={24} lg={24}>
                      <div className="reject-logo">
                        <img src={clacReject} width={120} />
                      </div>
                      <div className="reject-mag">
                        You seem to be ineligible, <br /> Please contact your
                        relationship manager.
                      </div>
                      </Col>
                    </Row>
                  )}
                  {/* Button to clear fields */}
                  <div className="calculate-button">
                    <Button
                      type="primary"
                      size="large"
                      style={{ width: isMobile?"100%":"30%" }}
                      onClick={clearFields}
                    >
                      Calculate again
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </Col>
      </Row>}
    </>
  );
};

export default Tools;
