import React, { useEffect, useState } from "react";
import {
  Button,
  notification,
  Input,
  Row,
  Modal,
  Select,
  Typography,
} from "antd";
import API_SERVICE from "shared/services/api-service";
import { FaFileMedical } from "react-icons/fa";
import UploadDocument from "../Lead/UploadDocument/UploadDocument";
import "./index.scss";
import "././index.scss";
import RadioButton from "shared/components/RadioButton/RadioButton";
import { getEnumList, genericDeleteDoc } from "shared/services/Utility";
import DocListItem from "shared/components/DocListItem/DocListItem";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Text } = Typography;
const { TextArea } = Input;

const DocumentationList: React.FunctionComponent<any> = ({
  leadData,
  assignToSalesUser,
  assignedToUserName,
  userSameAsAssigned,
  lenderDocumentHandler,
  isNextStage,
  lenderDocumentSubmitHadler,
}) => {
  const [uploadDocument, setUploadDocument] = useState(false);
  const [documentList, setDocumentList] = useState([] as any);
  const [userComment, setUserComment] = useState(null as any);
  const [documentSubTypeList, setDocumentSubTypeList] = useState([] as any);
  const [filteredList, setFilteredList] = useState([]);
  const { Option } = Select;
  const documentTypes = [
    { key: "FINANCE_DOCUMENT", value: "Finance Document" },
    { key: "BANKING_DOCUMENT", value: "Banking Document" },
    { key: "KYC_DOCUMENT", value: "KYC Document" },
    { key: "OTHER_DOCUMENT", value: "Other Document" },
    { key: "ZIP", value: "ZIP" },
  ];
  const [selectedKey, setSelectedKey] = useState("OTHER_DOCUMENT");
  const { appColors } = useSelector((state: RootState) => state.common);
  const customPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
  const customSecondry: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };

  let basicDetails = JSON.parse(
    localStorage.getItem("basicDetails") ?? "{}"
  ) as any;

  useEffect(() => {
    setEnums();
    getDocumentList(selectedKey);
  }, []);

  const setEnums = () => {
    const docList = getEnumList("DOCUMENT_LIST");
    setDocumentSubTypeList(docList);
  };

  const getDocumentList = (selectedDocType) => {
    const documentSetId = leadData?.caseDetails?.documentSetId;
    API_SERVICE.getDocumentList(documentSetId)
      .then(({ data }) => {
        if (data) {
          setDocumentList(data?.payload);
          if (selectedDocType == "OTHER_DOCUMENT") {
            let tmpType = [
              "FINANCE_DOCUMENT",
              "BANKING_DOCUMENT",
              "KYC_DOCUMENT",
              "ZIP",
            ];
            const getOtherDoc = data?.payload?.filter(
              (item: any) => !tmpType?.includes(item?.documentType)
            );
            setFilteredList(getOtherDoc);
          } else {
            const filteredList = documentList?.filter(
              (data) => selectedDocType === data?.documentType
            );
            setFilteredList(filteredList);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onChangeRadio = (documentType) => {
    setSelectedKey(documentType);
    if (documentType == "OTHER_DOCUMENT") {
      let tmpType = [
        "FINANCE_DOCUMENT",
        "BANKING_DOCUMENT",
        "KYC_DOCUMENT",
        "ZIP",
      ];
      const getOtherDoc = documentList?.filter(
        (item: any) => !tmpType?.includes(item?.documentType)
      );
      setFilteredList(getOtherDoc);
    } else {
      const filteredList = documentList?.filter(
        (data) => documentType === data?.documentType
      );
      setFilteredList(filteredList);
    }
  };

  const showDocList = () => {
    return (
      <div className="doc-list">
        {documentList?.map((doc: any, i: number) => (
          <DocListItem
            key={i}
            document={doc}
            callbackDelet={(payload: any) => {
              if (payload) {
                getDocumentList(selectedKey);
              }
            }}
          />
        ))}
      </div>
    );
  };

  const renderAssignToSalesUser = () => {
    return (
      <div className="assign-to-sales">
        <Text style={{ alignSelf: "center" }}>Assign To Sales User:</Text>
        {userSameAsAssigned ? (
          <>
            <Select
              placeholder="Select Sales User"
              className="custom-select"
              defaultValue={assignedToUserName}
            >
              <Option value={assignedToUserName}>{assignedToUserName}</Option>
              <Option value={basicDetails?.srmName}>
                {basicDetails?.srmName}
              </Option>
            </Select>
          </>
        ) : (
          assignToSalesUser
        )}
      </div>
    );
  };

  return (
    <div className="upload-document-wrapper">
      {uploadDocument && (
        <Modal
          title="Upload New Document"
          width={400}
          onCancel={() => {
            setUploadDocument(false);
          }}
          footer={null}
          visible={uploadDocument}
        >
          <UploadDocument
            loanProductId={leadData?.loanProductId}
            documentSetId={leadData?.caseDetails?.documentSetId}
            individualType={leadData?.applicationSubType}
            filteredList={filteredList}
            selectedKey={selectedKey}
            callBack={(isUpload, documentTypesParent) => {
              onChangeRadio(documentTypesParent);
              setUploadDocument(false);
              if (isUpload) {
                getDocumentList(documentTypesParent);
              }
            }}
          />
        </Modal>
      )}
      <Row>
        {/* {renderAssignToSalesUser()} <br /> */}
        {/* <RadioButton
          label=""
          name=""
          radioList={documentTypes}
          defaultValue={selectedKey}
          style={customSecondry}
          className="custom-radio profession-btns"
          onChange={(e) => onChangeRadio(e?.target?.value)}
        /> */}
        {showDocList()}
        {/* {selectedKey !== "" ? showDocList() : ""} */}
        <Button
          type="text"
          className="upload-btn"
          onClick={() => setUploadDocument(true)}
          icon={<FaFileMedical />}
        >
          Upload New document
        </Button>
        {/* <TextArea
          placeholder="Comment"
          showCount
          maxLength={500}
          className="custom-textarea"
          rows={3}
          onChange={(e) => setUserComment(e.target.value)}
        />
        <Button
          type="primary"
          className="next-btn"
          onClick={() => {
            // isNextStage   ? 
              lenderDocumentHandler(userComment)
              // : lenderDocumentSubmitHadler(userComment);
          }}
        >
          {isNextStage ? "Next Step" : "Submit"}
        </Button> */}
      </Row>
    </div>
  );
};

export default DocumentationList;
