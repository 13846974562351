import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  notification,
  Input,
  Row,
  Space,
  Timeline,
  Typography,
  Form,
  Tabs,
  Select,
  Collapse,
  Modal
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import DocumentationList from "../Documentation/Documentation";
import LenderSelection from "./LenderSelection/LenderSelection";
import LenderLogin from "./LenderLogin/LenderLogin";

import LenderDecision from "./LenderDecision/LenderDecision";
import LenderDisbursed from "./LenderDisbursed/LenderDisbursed";
import { Link } from "react-router-dom";
import { numberWithCommas } from "shared/services/Utility";
import shareIcon from "../../assets/images/shareIcon.svg";
import { AppImages } from "shared/constants/AppImages";
import mobileShareIcon from "../../assets/images/mobileShareIcon.svg";
import whatsappIcon from "../../assets/images/whatsappIcon.svg";
import homeBlack from "../../assets/images/home-black.svg";
import { getUserDetails, getEnumValue } from "shared/services/Utility";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setIsAppFooterShow,
  setIsAppHeaderShow,
} from "shared/redux/common-reducer";
import LeadDetailNew from "screens/LeadNew/LeadDetail/LeadDetailNew";
import { setScreenHeader } from "shared/redux/commonScreen-reducer";
import AppLoading from "shared/components/AppLoading/AppLoading";

const { TextArea } = Input;
const { Text } = Typography;
const { Title } = Typography;
const { Panel } = Collapse;

type Props = {};

const LeadDetails: React.FunctionComponent<Props> = () => {
  let { id } = useParams() as any;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState(null as any);
  const [basicOfferDetails, setBasicOfferDetails] = useState({} as any);
  const [leadHistory, setLeadHistory] = useState(null as any);
  const [userComment, setUserComment] = useState(null as any);
  const [userProspectComment, setUserProspectComment] = useState(null as any);
  const [documentComment, setDocumentComment] = useState(null as any);
  const [lenderStatus, setLenderStatus] = useState([] as any);
  const [caseStatus, setCaseStatus] = useState([] as any);
  const [selectedLenderList, setSelectedLenderList] = useState([] as any);
  const [activeLogin, setActiveLogin] = useState(false);
  const [lenderSelectionActive, setLenderSelectionActive] = useState(false);
  const [lenderSelection, setLenderSelection] = useState(false);
  const [landerDecisionActive, setLanderDecisionActive] = useState(false);
  const [disbursedActive, setDisbursedActive] = useState(false);
  const [currentStageIndex, setCurrentStageIndex] = useState("prospect");
  const [activePanel, setActivePanel] = useState("1");
  const [tentativePayout, setTentativePayout] = useState(0);
  const [payoutType, setPayoutType] = useState("");
  const [lenderDetailFibe, setLenderDetailFibe] = useState([] as any);
  const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedCurrentStage, setSelectedCurrentStage] = useState({} as any);
  const [propertyOwnership, setPropertyOwnership] = useState([] as any);
  const [businessProfile, setBusinessProfile] = useState([] as any);
  const [isNextStage, setIsNextStage] = useState(false);
  const { Option } = Select;
  const [assignedToUserName, setAssignToUserName] = useState("");
  const assignedToUserid = leadData?.caseDetails?.assignedToUserDetails?.id;
  
  const [isLoading, setIsLoading] = useState(false)
  const [currentStage, setCurrentStage] = useState({
    visit: false,
    documentation: false,
    lenderSelection: false,
    login: false,
    lenderDecision: false,
    disbursed: false,
    prospect: true,
  });
  const [assignToSalesUser, setAssignToSales] = useState("");
  const [isGoTo, setIsGoTo] = useState(false);
  const [userSameAsAssigned, setUserSameAsAssigned] = useState(false);
  const [showV2LeadDetails, setShowV2LeadDetails] = useState(false);
  const { appColors, isMobile } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  
  let basicDetails = JSON.parse(
    localStorage.getItem("basicDetails") ?? "{}"
  ) as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lead",
        backScreenPath: "/lead",
        screenTitle: "View Lead",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setIsAppFooterShow(false));
    dispatch(setIsAppHeaderShow(false));

    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const lenderStatusList = enumValues.find(
        (item) => item.propertyName === "LENDER_STATUS"
      );
      const caseStatusList = enumValues.find(
        (item) => item.propertyName === "CASE_STATUS"
      );

      const propertyOwnership = enumValues.find(
        (item) => item.propertyName === "PROPERTY_OWNERSHIP"
      );
      const businessProfileValue = enumValues.find(
        (item: any) => item.propertyName == "BUSINESS_PROFILE"
      );
      setLenderStatus(lenderStatusList);
      setCaseStatus(caseStatusList);
      setPropertyOwnership(propertyOwnership?.keyAndDisplayValueList);
      setBusinessProfile(businessProfileValue?.keyAndDisplayValueList);
    }

    getLeadById(true);
    getBasicOfferByCaseId();
  }, []);

  useEffect(() => { }, [userComment, userProspectComment]);

  const setSortingHistory = (leadHistory) => {
    let history: any = [];
    for (let key in leadHistory) {
      history = [...history, ...leadHistory[key]];
    }
    const tmpSortedHistory = history.sort((a, b) =>
      a.statusChangedOn < b.statusChangedOn ? 1 : -1
    );
    setLeadHistory(tmpSortedHistory);
  };

  const getBasicOfferByCaseId = () => {
    setIsLoading(true);
    API_SERVICE.getBasicOfferByCaseId(id)
      .then(({ data }) => {
        if (data?.payload?.basicLoanOffer)
          setBasicOfferDetails(data?.payload?.basicLoanOffer);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLeadById = (setStatus?) => {
    setIsLoading(true);
    API_SERVICE.getLeadsById(id)
      .then(({ data }) => {
        if (data?.payload) {
          setUserProspectComment(
            data?.payload?.caseDetails?.caseHistoryHolder?.prospectState[0]
              ?.comments
          );
          setDocumentComment(
            data?.payload?.caseDetails?.caseHistoryHolder?.documentationState?.at(
              0
            )?.comments
          );
          setAssignToUserName(
            data?.payload?.caseDetails?.assignedToUserDetails?.fullName
          );
         // setIsConnectorEditForm(data?.payload?.canConnectorEditForm)
          let caseDetails = data?.payload?.caseDetails;
          let isUserIdSameAsAssignedId =
            caseDetails?.assignedToUserDetails?.userId ===
            getUserDetails().userId;
          setUserSameAsAssigned(isUserIdSameAsAssignedId);
          let status = caseDetails?.caseStatus;
          let stages = ["Prospect", "Lead", "Visit", "Documentation"];
          if (isUserIdSameAsAssignedId && stages.includes(status)) {
            setIsNextStage(true);
          } else {
            setIsNextStage(false);
          }

          setIsGoTo(isUserIdSameAsAssignedId);

          if (stages.includes(status)) {
            setAssignToSales(caseDetails?.assignedToUserDetails?.fullName);
          }
          // setUserComment(data?.payload?.caseDetails?.caseHistoryHolder?.prospectState[0]?.comments);
        }
        if (data?.payload) {
          setLeadData(data.payload);
          if (
            data.payload.caseDetails?.documentDetails?.length > 0 &&
            data.payload.caseDetails?.lenderActivityHolder
              ?.lenderActivityDetails?.length > 0
          ) {
            setActiveLogin(true);
            // setLenderSelectionActive(true);
            // setLanderDecisionActive(true);
          }
          if (data?.payload?.caseDetails?.caseHistoryHolder) {
            setSortingHistory(data.payload.caseDetails.caseHistoryHolder);
          }
          if (
            data.payload.caseDetails.lenderActivityHolder.lenderActivityDetails
              .length > 0
          ) {
            data.payload.caseDetails.lenderActivityHolder.lenderActivityDetails.map(
              (item) => {
                item.rmName = item?.rmName ? item.rmName : "";
                item.openInput = false;
              }
            );
            const lenderInfofibe =
              data.payload.caseDetails.lenderActivityHolder.lenderActivityDetails.filter(
                (item) => {
                  return item.lenderName.toLowerCase().includes("fibe");
                }
              );
            setLenderDetailFibe(lenderInfofibe);
            setSelectedLenderList(
              data.payload.caseDetails.lenderActivityHolder
                .lenderActivityDetails
            );
          }
          if (setStatus) {
            const caseStatus = data?.payload?.caseDetails?.caseStatus;
            setCurrentState(caseStatus);
            const upperCaseFirstLetter = `${caseStatus
              .slice(0, 1)
              .toLowerCase()}${caseStatus.slice(1)}`;
            let stage = upperCaseFirstLetter?.replace(" ", "");
            stage = stage === "logged" ? "login" : stage;
            setCurrentStageIndex(stage);
          }

          const payoutType =
            data?.payload?.caseDetails?.connectorDetails?.payoutType ?? "";
          const payoutPercentage =
            data?.payload?.caseDetails?.connectorDetails?.payoutPercentage ?? 0;
          const interestRate =
            data?.payload?.caseDetails?.loanDetails?.expectedInterestRate ?? 0;
          const tmpPayout =
            payoutType === "FIXED" ? payoutPercentage : interestRate;

          setPayoutType(payoutType);
          setTentativePayout(tmpPayout);
        }
        if (data?.payload?.productVersion == "V2") {
          if (data?.payload?.processInstanceId) {
            setShowV2LeadDetails(true);
          } else {
            notification.error({
              message: "Process Instance Id Not Available",
            });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setCurrentState = (status) => {
    
    const currentStageCopy = { ...currentStage } as any;
    

    if (status === "Lead") {
      setLenderSelection(true);
      setCurrentStage({
        ...currentStageCopy,
        prospect: true,
      });
    }
    if (status === "Visit") {
      setCurrentStage({
        ...currentStageCopy,
        visit: true,
        prospect: false,
      });
      setActivePanel("1");
    }
    if (status === "Documentation") {
      setLanderDecisionActive(true);
      setActiveLogin(true);
      setLenderSelectionActive(true);
      setCurrentStage({
        ...currentStageCopy,
        visit: false,
        prospect: false,
        documentation: true,
      });
      setActivePanel("2");
    }
    if (status === "Lender Selection") {
      // setActiveLogin(true);
      setLenderSelectionActive(true);
      setCurrentStage({
        ...currentStageCopy,
        prospect: false,
        lenderSelection: true,
      });
    }
    // if (status === "Lender Selection") {
    //   setLanderDecisionActive(true);
    //   setLenderSelectionActive(true);
    //   setActiveLogin(true);
    //   setCurrentStage({
    //     ...currentStageCopy,
    //     prospect: false,
    //     lenderSelection: true,
    //   });
    // }
    if (status === "Logged") {
      setActiveLogin(true);
      // setLanderDecisionActive(true);
      setLenderSelectionActive(true);
      setCurrentStage({
        ...currentStageCopy,
        prospect: false,
        login: true,
      });
    }
    if (status === "Lender Decision") {
      // setDisbursedActive(true);
      setLanderDecisionActive(true);
      setLenderSelectionActive(true);
      setActiveLogin(true);
      setCurrentStage({
        ...currentStageCopy,
        prospect: false,
        lenderDecision: true,
      });
    }
    if (status === "Disbursed") {
      setDisbursedActive(true);
      setLanderDecisionActive(true);
      setLenderSelectionActive(true);
      setActiveLogin(true);
      setCurrentStage({
        ...currentStageCopy,
        prospect: false,
        disbursed: true,
      });
    }
    if (status === "Prospect") {
      setCurrentStage({
        ...currentStageCopy,
        visit: false,
        prospect: true,
      });
    }
  };

  const getLeadStage = () => {
    const strStage = leadData?.caseDetails?.caseStatus ?? "";
    let stage: any = {
      name: strStage,
      firstChar: strStage[0],
    };
    switch (strStage) {
      case "Prospect":
        stage["id"] = 1;
        break;

      case "Lead":
        stage["id"] = 2;
        break;

      case "Visit":
        stage["id"] = 3;
        break;

      case "Documentation":
        stage["id"] = 4;
        break;

      case "Lender Selection":
        stage["id"] = 5;
        break;

      case "Logged":
        stage["id"] = 6;
        break;

      case "Lender Decision":
        stage["id"] = 7;
        break;

      case "Disbursed":
        stage["id"] = 8;
        break;

      case "Closed":
        stage["id"] = 9;
        break;

      default:
        break;
    }

    return stage;
  };

  const createGoToMenu = () => {
    let menus: any[] = [
      {
        key: "prospect",
        label: "Prospect",
      },
      {
        key: "visit",
        label: "Visit",
      },
      {
        key: "documentation",
        label: "Documentation",
      },
    ];

    const { id } = getLeadStage();

    if (id >= 5) {
      menus.push({
        key: "lenderSelection",
        label: "Lender Selection",
      });
    }
    if (id >= 6) {
      menus.push({
        key: "login",
        label: "Log in",
      });
    }
    if (id >= 7) {
      menus.push({
        key: "lenderDecision",
        label: "Lender Decision",
      });
    }
    if (id >= 8) {
      menus.push({
        key: "disbursed",
        label: "Disbursed",
      });
    }

    //     currentStage.lenderSelection
    // currentStage.login
    // currentStage.lenderDecision
    // currentStage.disbursed
    // currentStage.documentation

    // {
    //   key: "login",
    //   label: "Log in",
    //   disabled: !activeLogin,
    // },
    // {
    //   key: "lenderDecision",
    //   label: "Lender Decision",
    //   disabled: !landerDecisionActive,
    // },
    // {
    //   key: "disbursed",
    //   label: "Disbursed",
    //   disabled: !disbursedActive,
    // },

    return menus;
  };

  const onClickMenu = (ev: any) => {
    setSelectedCurrentStage(currentStage);
    setCurrentStageIndex(ev.key);

    if (ev.key === "documentation") {
      const currentStageCopy = { ...currentStage } as any;
      for (var key in currentStageCopy) {
        if (currentStageCopy.hasOwnProperty(key)) {
          currentStageCopy[key] = false;
        }
      }
      setCurrentStage({
        ...currentStageCopy,
        [ev.key]: true,
      });
      setActivePanel("2");
    } else {
      const currentStageCopy = { ...currentStage } as any;
      for (var key in currentStageCopy) {
        if (currentStageCopy.hasOwnProperty(key)) {
          currentStageCopy[key] = false;
        }
      }
      setCurrentStage({
        ...currentStageCopy,
        [ev.key]: true,
      });
      setActivePanel("1");
    }
  };
  
  const menuList = (
    <Menu
      selectable
      selectedKeys={[currentStageIndex]}
      className="menu-list"
      onClick={onClickMenu}
      items={createGoToMenu()}
    // items={[
    //   {
    //     key: "prospect",
    //     label: "Prospect",
    //   },
    //   {
    //     key: "visit",
    //     label: "Visit",
    //   },
    //   {
    //     key: "documentation",
    //     label: "Documentation",
    //     disabled: false,
    //   },
    //   {
    //     key: "lenderSelection",
    //     label: "Lender Selection",
    //     disabled: !lenderSelectionActive,
    //   },
    //   {
    //     key: "login",
    //     label: "Log in",
    //     disabled: !activeLogin,
    //   },
    //   {
    //     key: "lenderDecision",
    //     label: "Lender Decision",
    //     disabled: !landerDecisionActive,
    //   },
    //   {
    //     key: "disbursed",
    //     label: "Disbursed",
    //     disabled: !disbursedActive,
    //   },
    // ]}
    />
  );

  const nextStage = (stageName) => {
    setCurrentStageIndex(stageName.toString());
    if (stageName === "VISIT") {
      setCurrentStageIndex(stageName.toLowerCase().toString());
      setActivePanel("2");
      setCurrentState("Visit");
      return;
    }
    if (stageName === "DOCUMENTATION") {
      setCurrentStageIndex(stageName.toLowerCase().toString());
      setActivePanel("2");
      setCurrentState("Documentation");
      return;
    }
    if (stageName === "DISBURSED") {
      history.push("/lead");
      return;
    }

    const currentStageCopy = { ...currentStage } as any;
    for (var key in currentStageCopy) {
      if (currentStageCopy.hasOwnProperty(key)) {
        currentStageCopy[key] = false;
      }
    }
    setTimeout(() => {
      setCurrentStage({
        ...currentStageCopy,
        [stageName]: true,
      });
    }, 1000);
  };

  const visitNextHandler = () => {
    let data = {
      comments:
        userComment ??
        leadData?.caseDetails?.caseHistoryHolder?.visitState[0]?.comments,
      status: "VISIT",
    };
    setIsLoading(true)
    API_SERVICE.closeCase(leadData.caseDetails.caseId, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getLeadById();
          setUserComment(null);
          setTimeout(() => {
            nextStage("DOCUMENTATION");
          });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const prospectNextStageHandler = () => {
    let data = {
      comments: userProspectComment,
      status: "PROSPECT",
    };

    if (!userProspectComment) {
      setUserProspectComment(
        leadData?.caseDetails?.caseHistoryHolder?.prospectState[0]?.comments
      );
      nextStage("VISIT");
      return;
    }

    if (
      leadData?.caseDetails?.caseHistoryHolder?.prospectState[0]?.comments ===
      userProspectComment
    ) {
      nextStage("VISIT");
      return;
    }
    setIsLoading(true)
    API_SERVICE.closeCase(leadData.caseDetails.caseId, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getLeadById();
          setTimeout(() => {
            nextStage("VISIT");
          });
          setUserProspectComment(null);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const allStagesSubmitHandler = (comment: string, status: string) => {
    let data = {
      caseStatusAndComment: {
        comments: comment,
        status: status,
      },
    };
    setIsLoading(true)
    API_SERVICE.patchCase(leadData?.caseDetails?.caseId, data)
      .then(({ data }) => {
        if (data) {
          getLeadById();
          notification.success({ message: data.message });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const submitHandler = () => {
    allStagesSubmitHandler(userProspectComment, "PROSPECT");
  };

  const submitVisitHandler = () => {
    allStagesSubmitHandler(
      userComment ??
      leadData?.caseDetails?.caseHistoryHolder?.visitState[0]?.comments,
      "VISIT"
    );
  };

  const lenderNextHandler = (lenderData) => {
    let cStatus = caseStatus.keyAndDisplayValueList.find(
      (item) => item.key === "LENDER_SELECTION"
    );

    const paylodForSubmit = {
      caseStatusAndComment: {
        comments: lenderData.comment,
        status: cStatus.key,
      },
    };

    submitLenderSelectionHandler(paylodForSubmit, cStatus);
    setActiveLogin(true);
  };

  const updateCase = (payload, status, nextStep?) => {
    setIsLoading(true)
    API_SERVICE.patchCase(leadData?.caseDetails?.caseId, payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getLeadById();
          // setTimeout(() => {
          //   nextStage(nextStep);
          //   setLoading(false);
          // });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const submitLenderSelectionHandler = (payload, staus) => {
    setIsLoading(true)
    API_SERVICE.patchCase(leadData?.caseDetails?.caseId, payload)
      .then(({ data }) => {
        if (data) {
          getLeadById();
          notification.success({ message: data.message });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const lenderLoginHandler = (data: any) => {
    setIsLoading(true);
    let cStatus = caseStatus.keyAndDisplayValueList.find(
      (item) => item.key === "LOGGED"
    );
    const payload = {
      caseStatusAndComment: { comments: data.comment, status: cStatus.key },
    };
    updateCase(payload, cStatus, "lenderDecision");
    setLanderDecisionActive(true);
  };

  const lenderDecisionHandler = (params: any) => {
    const { comment } = params;
    let cStatus = caseStatus.keyAndDisplayValueList.find(
      (item) => item.key === "LENDER_DECISION"
    );

    const payload = {
      caseStatusAndComment: { comments: comment, status: cStatus.key },
    };
    submitLenderSelectionHandler(payload, cStatus);
    setDisbursedActive(true);
  };

  const lenderDisbursedHandler = (values: any) => {
    allStagesSubmitHandler(values?.comment ?? "", "DISBURSED");
  };

  const lenderDocumentHandler = (comment: any) => {
    let data = {
      comments: comment,
      status: "DOCUMENTATION",
    };
    setIsLoading(true)
    API_SERVICE.closeCase(leadData.caseDetails.caseId, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getLeadById();
          setLanderDecisionActive(true);
          // setTimeout(() => {
          //   nextStage("lenderSelection");
          //   setCurrentStageIndex("lenderSelection");
          //   setActivePanel("1");
          // });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));
      
  };

  const lenderDocumentSubmitHadler = (comment: any) => {
    allStagesSubmitHandler(comment, "DOCUMENTATION");
    setCurrentState("Documentation");
  };

  const changeStage = (key) => {
    setActivePanel(key);
  };

  const handleClick = () => {
   // setIsLoading(true)
    API_SERVICE.getWhatsAppDocumentValidate(leadData?.caseDetails?.caseId)
      .then(({ data }) => {

        if (data.payload.validToSend) {
          setIsModalWhatsappVisible(true);
        }
        else {
          setIsModalWhatsappVisible(false);
          setSuccessMessage(data.payload.message);
          setIsSuccessModalVisible(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
     // .finally(()=>setIsLoading(false));;
  }
  const handleModalOk = () => {
    setIsModalWhatsappVisible(false);
    //setIsLoading(true)
    API_SERVICE.sendWhatsAppMessage(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        setIsModalWhatsappVisible(false);
        if (data.payload) {
          notification.success({ message: data.payload })
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      //.finally(()=>setIsLoading(false));;
  };

  const handleModalCancel = () => {
    setIsModalWhatsappVisible(false);
  };
  const handleModalmessage = () => {
    setIsSuccessModalVisible(false)
  }

  const whatsapp = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isModalWhatsappVisible}
        footer={null}
        closable={false}
      >
        <div
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "35px",
              marginLeft: "5px",
              fontSize: "medium",
            }}
          >
            Is {leadData?.contactDetails?.mobileNumber} the valid contact number? <br />
            if not, please update lead details.
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              className="dynamic-btn-primary-default"  
              style={{ '--bgColor': appColors?.appPrimaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px" }as any} 
              type="primary"
              onClick={handleModalOk}
            >
              Confirm
            </Button>
            <Button
              type="default"
              className="dynamic-btn-default" 
              style={{'--borderColor': appColors?.appSecondaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px" }as any}
              onClick={handleModalCancel}
            >
              Back
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const message = () => {
    return (<Modal
      centered
      width={450}
      visible={isSuccessModalVisible}

      footer={null}
      closable={false}
    > <p
      style={{
        textAlign: "center",
        marginTop: "30px",
        marginBottom: "15px",
        marginLeft: "5px",
        fontSize: "medium",
      }}
    >{successMessage}
      </p>
      <Button
        type="default"
         className="dynamic-btn-default" 
              style={{'--borderColor': appColors?.appSecondaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px", marginLeft:"123px" }as any}
        onClick={handleModalmessage}
      >
        OK
      </Button>
    </Modal>
    )
  }
  const getShareLinkId = (item: any) => {
  //  setIsLoading(true)
    API_SERVICE.getShareLinkId(item?.caseDetails?.caseId)
      .then(({ data }) => {
        navigator.clipboard.writeText(data?.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
    //  .finally(()=>setIsLoading(false));
  };

  const topView = () => {
    const { name } = getLeadStage();
    return (
      <div className="top-view">
        <img src={homeBlack} alt="" className="home-black-img" />
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text>Loan Type</Text>
            <Text style={{ fontSize: "small" }}>{name}</Text>
          </div>
          <Text className="lead-data-loan-type">{leadData?.loanType}</Text>
        </div>
      </div>
    );
  };

  const salerUserHandler = (item, id) => {
    let data = {
      assignToUserId: id?.id,
    };
    setIsLoading(true)
    API_SERVICE.patchCase(leadData?.caseDetails?.caseId, data)
      .then(({ data }) => {
        if (data) {
          getLeadById();
          notification.success({ message: data.message });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(()=>setIsLoading(false));;
  };

  const renderAssignToSalesUser = () => {
    return (
      <div className="assign-to-sales">
        <Text style={{ alignSelf: "center" }}>Assign To Sales User:</Text>
        {userSameAsAssigned ? (
          <>
            <Select
              placeholder="Select Sales User"
              className="custom-select"
              defaultValue={assignedToUserName}
              onChange={salerUserHandler}
            >
              <Option value={assignedToUserName} id={assignedToUserid}>
                {assignedToUserName}
              </Option>
              <Option value={basicDetails?.srmName} id={basicDetails?.srmId}>
                {basicDetails?.srmName}
              </Option>
            </Select>
          </>
        ) : (
          assignToSalesUser
        )}
      </div>
    );
  };

  const genExtra = () => (
    <>
      <Button
        style={{ border: "none" }}
        size="small"
        onClick={(event) => {
          getShareLinkId(leadData);
          event.stopPropagation();
        }}
        icon={<img src={mobileShareIcon} alt="" width={24} height={24} />}
      />
    </>
  );

  const caseInfoPanel = () => {
    return (
      <div
        className={"lead-detail-box"}
        style={{ height: "calc(100% - 72px)" }}
      >
        {!isMobile ? (
          <>
            <div className="heading">
              <Title level={5}>Case Information</Title>
              <Space
                className="action-button"
                size="middle"
                style={{ marginRight: "-3px" }}
              >
                <Button
                  size="small"
                  hidden={!leadData?.isWhatsAppEnabled}
                  onClick={() => {
                    handleClick();
                  }}
                  icon={
                    <img
                      src={whatsappIcon}
                      alt=""
                      width={24}
                      height={24}
                      style={{ marginTop: "-4px" }}
                    />
                  }
                />
                <Button
                  size="small"
                  onClick={() => getShareLinkId(leadData)}
                  icon={
                    <img
                      src={shareIcon}
                      alt=""
                      width={24}
                      height={24}
                      style={{ marginTop: "-4px" }}
                    />
                  }
                />
              </Space>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="lead-details">
          <Row gutter={[16, 16]} className="custom-row">
            <Col className="custom-row">
              <Text className="title">Applicant Type</Text>
              <br />
              <Text>{leadData?.applicationType}</Text>
            </Col>
            <Col>
              <Text className="title">
                {leadData?.applicationType != null
                  ? leadData?.applicationType + " Type"
                  : null}
              </Text>
              <br />
              <Text>{leadData?.applicationSubType}</Text>
            </Col>
            <Col>
              <Text className="title">Gender</Text>
              <br />
              <Text>{leadData?.contactDetails?.gender}</Text>
            </Col>
          </Row>

          <Row className="custom-row">
            <Col className="custom-row">
              <Space direction="vertical" className="gap">
                <Text className="title">Applicant Details</Text>
                <Text>{leadData?.contactDetails?.firstName}</Text>
                {(leadData?.contactDetails?.dateOfBirth?.length ?? 0) !== 0 && (
                  <Text>
                    {moment(leadData?.contactDetails.dateOfBirth)
                      .locale("en")
                      .format("DD MMM YYYY")}
                  </Text>
                )}
                <Text>{leadData?.contactDetails?.emailId}</Text>
                <Text>
                  {leadData?.contactDetails?.mobileNumber.startsWith("1")
                    ? ""
                    : leadData?.contactDetails?.mobileNumber}
                </Text>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical" className="gap">
                <Text className="title">Current Address</Text>
                <Text>{leadData?.address?.pinCode}</Text>
                <Text>{leadData?.address?.address}</Text>
                <Text>{leadData?.address?.city}</Text>
                <Text>{leadData?.address?.state}</Text>
                <Text>{leadData?.address?.country}</Text>
              </Space>
            </Col>
          </Row>
        </div>
        <div className="lead-details">
          <Row className="custom-row">
            {leadData?.loanType == "Education Loan" ? (
              <Space direction="vertical" className="gap">
                <Text className="title">Additional Details</Text>
                <Text>
                  PAN:{" "}
                  {leadData?.contactDetails?.pan === "PENDING"
                    ? ""
                    : leadData?.contactDetails?.pan}
                </Text>
              </Space>
            ) : (
              <Space direction="vertical" className="gap">
                <Text className="title">Additional Details</Text>
                <Text>
                  PAN:{" "}
                  {leadData?.contactDetails?.pan === "PENDING"
                    ? ""
                    : leadData?.contactDetails?.pan}
                </Text>
                <Text>Company: {leadData?.entityOrEmployerName || "NA"}</Text>

                {leadData?.loanType === "Personal Loan" ||
                  (leadData?.loanType?.toLowerCase() === "home loan" &&
                    leadData?.applicationSubType == "Salaried") ? (
                  <>
                    <Text>
                      Monthly In-hand Salary: Rs.
                      {numberWithCommas(
                        leadData?.caseDetails?.loanDetails?.monthlyInHandSalary
                      )}
                    </Text>
                    <Text>
                      Current EMIs: Rs.
                      {numberWithCommas(
                        leadData?.caseDetails?.loanDetails?.currentEmis
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text>
                      Business Profile:{" "}
                      {getEnumValue(
                        "BUSINESS_PROFILE",
                        leadData?.businessProfile ?? ""
                      )}
                    </Text>
                    <Text>
                      No of Years In Business:{" "}
                      {leadData?.businessVintage
                        ? leadData?.businessVintage
                        : 0}
                    </Text>
                    <Text>
                      Business Turnover (Yearly): Rs.
                      {leadData?.businessTurnOver
                        ? numberWithCommas(leadData?.businessTurnOver)
                        : 0}
                    </Text>
                    <Text>
                      Monthly Obligations (EMIs): Rs.
                      {leadData?.monthlyEmi
                        ? numberWithCommas(leadData?.monthlyEmi)
                        : 0}
                    </Text>
                    <Text>
                      Ownership:{" "}
                      {getEnumValue(
                        "PROPERTY_OWNERSHIP",
                        leadData?.caseDetails?.propertyOwnership ?? ""
                      )}
                    </Text>
                  </>
                )}
              </Space>
            )}
          </Row>
        </div>
        {leadData?.loanType == "Education Loan" && (
          <div className="lead-details">
            <Row className="custom-row">{renderEducationDetails()}</Row>
          </div>
        )}
        {Object.keys(basicOfferDetails).length > 0 &&
          lenderDetailFibe.length === 0 ? (
          <div className="lead-details">
            <Row className="custom-row">
              <Space direction="vertical" className="gap">
                <Text className="title">Basic Offer Details</Text>
                <Text>
                  Offered Loan Amount: Rs.{" "}
                  {numberWithCommas(basicOfferDetails?.loanAmount)}
                </Text>
                <Text>
                  ROI: {basicOfferDetails?.interest ?? basicOfferDetails?.roi}%
                </Text>
                <Text>
                  EMI: Rs.{" "}
                  {numberWithCommas(
                    basicOfferDetails?.totalEMI ?? basicOfferDetails?.emi
                  )}
                </Text>
                <Text>
                  Tenure:{" "}
                  {basicOfferDetails?.tenureInMonths ??
                    basicOfferDetails?.tenure}
                </Text>
              </Space>
            </Row>
          </div>
        ) : null}
        <div className="lead-details">
          <Row className="custom-row" gutter={[16, 16]}>
            <Col>
              <Space direction="vertical" className="gap">
                <Text className="title">Loan Details</Text>
                <Text>
                  Loan Amount: Rs.
                  {leadData?.caseDetails?.loanDetails?.loanAmount &&
                    numberWithCommas(
                      leadData?.caseDetails?.loanDetails?.loanAmount
                    )}{" "}
                </Text>
                <Text>
                  Loan Tenure:{" "}
                  {leadData?.caseDetails?.loanDetails?.loanTenureInYears} Years
                </Text>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical" className="gap">
                <Text className="title">Loan Type</Text>
                <Text>{leadData?.loanType}</Text>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const historyPanel = () => {
    return (
      <div className={"lead-detail-box"}>
        {!isMobile ? (
          <div className="heading">
            <Title level={5}>History</Title>
          </div>
        ) : (
          ""
        )}

        <Row className={"timeline"}>
          <Col span={24}>
            <Timeline>
              {leadHistory?.map((item: any, i: number) => (
                <Timeline.Item className="timeline-box" key={i} color={appColors?.appSecondaryColor ?? "#c71c8e"} style={{'--color': appColors?.appSecondaryColor ?? ""}as any}>
                  <Title level={4} style={{display: isMobile ? "grid" : "flex"}}>
                    {item?.currentStatus && <span>{item?.currentStatus}</span>}
                    {item.statusChangedOn && (
                      <span className="date">
                        {moment(item.statusChangedOn)
                          .locale("en")
                          .format("DD MMM YYYY hh:mm A")}
                      </span>
                    )}
                  </Title>
                  <Title level={5} style={{fontSize:'12px' ,fontWeight:'400'}}>{item?.changedByUser?.firstName}</Title>
                  {(item?.comments || item?.statusUpdate) && (
                    <>
                      <Text className="title">Comment:</Text>
                      <br />
                      <Text>{item?.comments}</Text>
                      {item?.statusUpdate &&
                        renderStatusupdate(item?.statusUpdate)}
                    </>
                  )}
                </Timeline.Item>
              ))}
            </Timeline>
          </Col>
        </Row>
      </div>
    );
  };

  const renderDocumentationStage = () => {
    return (
      <DocumentationList
        leadData={leadData}
        lenderDocumentHandler={lenderDocumentHandler}
        isNextStage={!isNextStage}
        lenderDocumentSubmitHadler={lenderDocumentSubmitHadler}
        assignToSalesUser={assignToSalesUser}
        assignedToUserName={assignedToUserName}
        userSameAsAssigned={userSameAsAssigned}
      />
    );
  };

  const renderValue = (key: string, value: any) => {
    if (value != null && typeof value === "object") {
      return renderObj(key, value);
    }

    if (value == null) {
      return <></>;
    }

    return (
      <div>
        <Text>{`${key}: ${value}`}</Text>
      </div>
    );
  };

  const renderObj = (title, res) => {
    if (res == null) {
      return <></>;
    }

    if (typeof res === "string") {
      const tmpTitle = (title?.length ?? 0) == 0 ? "Response" : title;
      return <div>{renderValue(tmpTitle, res)}</div>;
    }

    const data = Object.keys(res);
    return (
      <div>
        <Text style={{ fontWeight: "bold", color: "black" }}> {title} </Text>
        {data?.map((key: any) => {
          let value = res[key];
          if (Array.isArray(value)) {
            value = res[key]?.join(", ");
          }
          return renderValue(key, value);
        })}
      </div>
    );
  };

  const renderStatusupdate = (statusUpdate: any) => {
    let obj = statusUpdate;
    if (typeof statusUpdate === "string") {
      obj = JSON.parse(statusUpdate ?? "{}");
    }

    
    // if (obj?.response) {
    //   let res = obj?.response;
    //   try {
    //     res = JSON.parse(obj?.response ?? "{}");
    //   } catch (error) { }
    //   console.log("res: ", res);
    //   return <div style={{ marginTop: '0px' }}> {renderObj("", res)}  </div>
    // } else {
    const data = Object.keys(obj);
    return (
      <div style={{ marginTop: "0px" }}>
        {data.map((key: any) => {
          
          let res = obj[key];
          try {
            res = JSON.parse(obj?.[key] ?? "{}");
          } catch (error) { }
          return <div> {renderObj(key, res)} </div>;
        })}
      </div>
    );
    // }

    return <></>;
  };

  const renderEducationDetails = () => {
    return (
      <Space direction="vertical" className="gap">
        <Text className="title">Education Details</Text>
        <Text>
          {"Time of study (Starts from): "}
          {
            leadData?.caseDetails?.loanDetails?.educationLoanDetails
              ?.timeOfStudy
          }
        </Text>
        <Text>
          {"Preferred place of study: "}
          {getEnumValue(
            "PLACE_STUDY",
            leadData?.caseDetails?.loanDetails?.educationLoanDetails
              ?.placeOfStudy ?? ""
          )}
        </Text>
        <Text>
          {"Admission Status: "}
          {getEnumValue(
            "ADMISSION_STATUS",
            leadData?.caseDetails?.loanDetails?.educationLoanDetails
              ?.admissionStatus ?? ""
          )}
        </Text>
      </Space>
    );
  };

  const getCashETriggerSMSLink = (lenderID) => {
    const caseId = leadData.caseDetails.caseId;
   // setIsLoading(true)
    API_SERVICE.sendCashESMSLink(lenderID, caseId)
      .then(({ data }) => {
        
        if (data) {
          if (data?.payload) {
            
            // const url = data?.payload;
            // navigator.clipboard.writeText(data?.payload)
            // if (!copied) {
            //   window.open(url, '_blank')?.focus();
            // }
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        // setCheckedLeader(true, lenderInfoId);
      })
     // .finally(()=>setIsLoading(false));;
  };

  const getRedirectURLLink = (
    lenderInfoId,
    loanProductID,
    lenderID,
    copied = false
  ) => {
    const caseId = leadData.caseDetails.caseId;
    //setIsLoading(true)
    API_SERVICE.getCredilioURL(lenderInfoId, loanProductID, lenderID, caseId)
      .then(({ data }) => {
        
        if (data) {
          if (data?.payload) {
            const url = data?.payload;
            navigator.clipboard.writeText(data?.payload);
            if (!copied) {
              window.open(url, "_blank")?.focus();
            }
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        // setCheckedLeader(true, lenderInfoId);
      })
      //.finally(()=>setIsLoading(false));;
  };

  const getDownloadLetterForICICI = (lenderID) => {
    const caseId = leadData.caseDetails.caseId;
   // setIsLoading(true)
    API_SERVICE.getSanctionLetterForICICI(lenderID, caseId)
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            if (data.payload.pdfFile) {
              var a = document.createElement("a") as any;
              document.body.appendChild(a);
              const url = `data:application/pdf;base64,${data.payload.pdfFile}`;
              a.href = url;
              a.download = "ICICISanctionLetter.pdf";
              a.click();
            } else {
              notification.error({ message: data.payload.resDesc });
            }
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        // setCheckedLeader(true, lenderInfoId);
      })
     // .finally(()=>setIsLoading(false));
  };
  const customBtnPrimary: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const customSecondry: any = { '--bgColor': appColors?.appSecondaryLightColor ?? "", marginTop: "0px", '--color': appColors?.appSecondaryColor ?? "" };

  return showV2LeadDetails ? (
    <LeadDetailNew
      leadData={leadData}
      getLeadById={getLeadById}
      setLeadData={setLeadData}
      getCashETriggerSMSLink={getCashETriggerSMSLink}
      getRedirectURLLink={getRedirectURLLink}
      getDownloadLetterForICICI={getDownloadLetterForICICI}
      lenderLoginHandler={lenderLoginHandler}
      lenderDecisionHandler={lenderDecisionHandler}
      loading = {setIsLoading}
      //isConnectorEditForm={isConnectorEditForm}
    />
  ) : (
    <div className="content-box">
      {isMobile ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Button
            style={{ border: "none" }}
            size="small"
            onClick={() => history.goBack()}
            icon={<img src={AppImages.back} />}
          />
          <Text style={{ marginTop: "5px" }}>Lead {id}</Text>
          <div style={{ width: "25px" }} />
        </div>
      ) : (
        <Title level={4}>
          <Link className="link" to={"/lead"} style={{'--color': appColors?.appPrimaryColor ?? ""} as any}>
            Manage Leads
          </Link>
          {/* &nbsp; &gt; &nbsp;Lead  {id} */}
        </Title>
      )}
      <Row gutter={[16, 16]} style={{ marginBottom: "50px" }}>
        <Col xs={24} xl={8} span={8}>
          {topView()}
          {isMobile ? (
            <Collapse
              className="payout-collapse remove-bottom"
              expandIconPosition={"end"}
              defaultActiveKey={["1"]}
            >
              <Panel
                header={
                  <Title style={{ marginBottom: "0px" }} level={5}>
                    Case Information
                  </Title>
                }
                key="1"
                extra={genExtra()}
              >
                {caseInfoPanel()}
              </Panel>
            </Collapse>
          ) : (
            caseInfoPanel()
          )}
        </Col>
        <Col xs={24} xl={8} span={8}>
          {isMobile ? (
            <Collapse
              className="payout-collapse remove-bottom"
              expandIconPosition={"end"}
              defaultActiveKey={["1"]}
            >
              <Panel
                header={
                  <Title style={{ marginBottom: "0px" }} level={5}>
                    History
                  </Title>
                }
                key="1"
              >
                {historyPanel()}
              </Panel>
            </Collapse>
          ) : (
            historyPanel()
          )}
        </Col>
        <Col xs={24} xl={8} span={8}>
          <div className="lead-detail-box">
            <div className="heading">
              <Title level={5}>Current Stage</Title>
              {isGoTo ? (
                <div className="menu-list">
                  <Dropdown overlay={menuList} placement="topRight" arrow>
                    <Space>
                      Go To
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="details-item">
              <Tabs
                className="custom-tab"
                defaultActiveKey="1"
                onChange={changeStage}
                style={customSecondry}
                activeKey={activePanel}
              >
                <Tabs.TabPane tab="Current Stage" key="1">
                  {currentStage.lenderSelection && selectedLenderList && (
                    <LenderSelection
                      leadData={leadData}
                      lenderNextHandler={lenderNextHandler}
                    />
                  )}
                  {currentStage.login && selectedLenderList && (
                    <LenderLogin
                      loading={loading}
                      assignedLenders={selectedLenderList}
                      lenderLoginHandler={lenderLoginHandler}
                      loginComment={
                        leadData?.caseDetails?.caseHistoryHolder?.logInState?.at(
                          0
                        )?.comments
                      }
                    />
                  )}
                  {currentStage.lenderDecision && (
                    <LenderDecision
                      leadData={leadData}
                      assignedLenders={selectedLenderList}
                      lenderDecisionHandler={lenderDecisionHandler}
                    />
                  )}
                  {currentStage.disbursed && (
                    <LenderDisbursed
                      leadData={leadData}
                      assignedLenders={selectedLenderList}
                      lenderDisbursedHandler={lenderDisbursedHandler}
                    />
                  )}
                  {currentStage.visit && (
                    <>
                      <Title level={5}>Visit</Title>
                      {renderAssignToSalesUser()}

                      <Title level={5}>Comment:</Title>
                      <Form.Item
                        name="visitComment"
                        style={{ marginTop: "5px" }}
                      >
                        <TextArea
                          defaultValue={
                            leadData?.caseDetails?.caseHistoryHolder
                              ?.visitState[0]?.comments
                          }
                          placeholder="Comment"
                          onChange={(e) => setUserComment(e.target.value)}
                          rows={4}
                        />
                      </Form.Item>

                      <Space className="btn-space">
                        <Button
                          onClick={() => {
                            nextStage("DOCUMENTATION");
                          }}   
                          className="dynamic-btn-default"  
                          style={btnDefaultBorderStyle}                 
                          type="default"
                        >
                          Skip
                        </Button>
                        <Button
                          onClick={() => {
                            isNextStage
                              ? visitNextHandler()
                              : submitVisitHandler();
                          }}
                          className="dynamic-btn-primary-default"  
                          style={customBtnPrimary} 
                          type="primary"
                        >
                          {isNextStage ? "Next Step" : "Submit"}
                        </Button>
                      </Space>
                    </>
                  )}
                  {currentStage.prospect && (
                    <>
                      <Title level={5}>Prospect</Title>
                      {renderAssignToSalesUser()}

                      <Title level={5}>Comment:</Title>
                      <TextArea
                        placeholder="Comment"
                        value={userProspectComment}
                        onChange={(e) => setUserProspectComment(e.target.value)}
                        rows={4}
                        style={{ marginTop: "5px" }}
                      />
                      <Space className="btn-space"></Space>
                      <Space className="btn-space">
                        <Form.Item name="cancel">
                          <Button
                            onClick={() => {
                              history.push("/lead");
                            }}
                            type="default"
                            className="dynamic-btn-default"
                            style={{'--borderColor': appColors?.appSecondaryColor ?? ""}as any}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item name="submit">
                          <Button
                            className="dynamic-btn-primary-default"
                            style={customBtnPrimary}
                            onClick={() => {
                              isNextStage
                                ? prospectNextStageHandler()
                                : submitHandler();
                            }}
                            type="primary"
                          >
                            {isNextStage ? "Next Stage" : "Submit"}
                          </Button>
                        </Form.Item>
                      </Space>
                    </>
                  )}
                  {currentStage.documentation && (
                    <>
                      <Title level={5}>Documentation</Title>
                      {renderAssignToSalesUser()}

                      <Title level={5}>Comment:</Title>
                      <TextArea
                        placeholder="Comment"
                        value={documentComment}
                        onChange={(e) => setDocumentComment(e.target.value)}
                        rows={4}
                        style={{ marginTop: "5px" }}
                      />
                      <Button
                       className="next-btn dynamic-btn-primary-default"
                       style={customBtnPrimary}
                        onClick={() => {
                          // lenderDocumentSubmitHadler(documentComment);
                          lenderDocumentHandler(documentComment);
                        }}
                        type="primary"
                      >
                        {"Submit"}
                      </Button>
                    </>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Documents" key="2">
                  {renderDocumentationStage()}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
      {whatsapp()}
      {message()}
      {/* {isLoading && <AppLoading />} */}
    </div>
  );
};

export default LeadDetails;
