import React from "react";
import { Button, Col, Form, Row, Typography, notification, Card, List, Popconfirm } from 'antd';
import "./notification.scss"
import { ReactComponent as IconNotification } from "../../assets/images/notification_bell_red.svg"
import API_SERVICE from "shared/services/api-service";
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { RootState } from "shared/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationPageNo, setNotificationPageSize, setNotificationStatus } from "shared/redux/notification-reducer";
import { setNotificationCount } from "shared/redux/notificationCount-reducer";

const { Title } = Typography;
const { Meta } = Card;

const NotificationBar: React.FunctionComponent = () => {
  const history = useHistory();
  const [notificationData, setNotificationData] = useState([] as any)
  const [totalRecords, setTotalRecords] = useState(0);
  const [notificationReadStatus, setNotificationReadStatus] = useState(null as any);
  const notifications: any = useSelector((state: RootState) => state.notification);
  const { notificationCount } = useSelector((state: RootState) => state.notificationCount);
  const { appColors, isMobile }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    getNotificationList(null, notifications.pageNo, notifications.pageSize);
    dispatch(setNotificationStatus("ALL"));
  }, []);

  const [listCase, setListCase] = useState([
    {
      "key": "ALL",
      "label": "All",
      "total": "",
      "hasPermission": true
    },
    {
      "key": "UNREAD",
      "label": "Unread",
      "total": "",
      "hasPermission": true
    },
    {
      "key": "READ",
      "label": "Read",
      "total": "",
      "hasPermission": true
    },
  ]);


  const getNotificationList = (notificationReadStatus: any = null, pageNo: number = 1, pageSize: any = null, sortBy: any = null, sortDir: any = null) => {
    const params = { notificationReadStatus: notificationReadStatus, pageNo: pageNo, pageSize: pageSize, sortBy: sortBy, sortDir: sortDir };
    API_SERVICE.getNotificationList(params)
      .then(({ data }) => {
        setNotificationData(data?.payload?.content ?? []);
        setTotalRecords(data?.payload?.totalElements);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateNotificationStatus = (notificationId, notificationReadStatus) => {
    API_SERVICE.updateNotificationStatus(notificationId)
      .then(({ data }) => {
        const landingPage = data?.payload?.eventShortDetails?.landingPage
        const caseId = data?.payload?.additionalData?.caseId
        landingPageCondistions(landingPage, caseId)

        if (notificationReadStatus === "UNREAD" && notificationCount !== 0) {
          dispatch(setNotificationCount(notificationCount - 1));
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const landingPageCondistions = (landingPage, caseId) => {
    if (landingPage === "VIEW_LEAD") {
      history.push(`/lead/details/${caseId}`);
    } else if (landingPage === "LEAD_DASHBOARD") {
      history.push(`/lead`);
    } else if (landingPage === "CONNECTOR_HOME_PAGE") {
      history.push(`/dashboard`);
    }
  }

  const clearAllNotifications = () => {
    API_SERVICE.clearAllNotifications()
      .then(({ data }) => {
        getNotificationList(null, notifications.pageNo, notifications.pageSize);
        dispatch(setNotificationCount(0));
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleNotificationTagChange = (i: number, value: string) => {
    dispatch(setNotificationPageNo(1));
    dispatch(setNotificationStatus(value));
    setNotificationReadStatus(value)

    if (value === "READ" || value === "UNREAD") {
      getNotificationList(value, 1, notifications.pageSize);
    } else if (value === "ALL") {
      getNotificationList(null, 1, notifications.pageSize);
    }
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setNotificationPageNo(current))
    dispatch(setNotificationPageSize(pageSize))

    if (notificationReadStatus === "READ" || notificationReadStatus === "UNREAD") {
      getNotificationList(notifications.status, current, pageSize)
    } else {
      getNotificationList(null, current, pageSize);
    }
    window.scrollTo(0, 0);
  };

  const customStyles: any = { '--iconColor': appColors?.appPrimaryColor ?? "" };
  const tabBtnStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
  const btnDefaultBorderStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };

  return (
    <div className="content-box">
      <Row gutter={[24, 24]}>
        <Col span={9} xs={10} xl={9}><Title level={3}>Notification</Title></Col>
        <Col offset={4}>
          <Form.Item>
            <Popconfirm
              title="Are you sure you want to clear all notifications?"
              onConfirm={clearAllNotifications}
              okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
              cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
            >
              <Button className="dynamic-btn-default" style={btnDefaultBorderStyle}>Clear all</Button>
            </Popconfirm>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="btn-box">
          {
            listCase.map((item: any, i: number) => (
              <>
                {item.hasPermission ?
                  <CheckableTag className="custom-button-checkable" style={tabBtnStyles} key={i} checked={item.key === notifications.status} onChange={(checked) => handleNotificationTagChange(i, item.key)}>
                    {item.label}
                  </CheckableTag>
                  :
                  null
                }
              </>
            ))
          }
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} xl={15} span={15}>
          <List
            // style={{ width: "100%" }}
            className="dynamic-pagination-checkbox"
            style={tabBtnStyles}
            grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}

            pagination={{
              onChange: (page, pageSize) => {
                handlePaginationChange(page, pageSize);
              },
              current: notifications.pageNo,
              pageSize: notifications.pageSize,
              total: totalRecords
            }}
            itemLayout="horizontal"
            dataSource={notificationData}
            renderItem={(item: any) => (

              <List.Item
                onClick={() => updateNotificationStatus(item.notificationId, item.notificationReadStatus)}
              >
                <Card style={{ backgroundColor: item.notificationReadStatus === "READ" ? "#E5E8F5" : "" }} >
                  <Row>
                    <Col span={2} xs={4} xl={2} className="notification-img" style={customStyles}>
                      <IconNotification
                        style={{ "--appPrimary": appColors?.appPrimaryColor ?? "" } as any}
                      />
                    </Col>
                    <Col span={18}>
                      <Meta
                        title={item.title}
                        className={item.notificationReadStatus === "READ" ? "read-notification" : "unread-notification"}
                        description={item.content} />
                    </Col>
                    <Col span={4} xs={24} xl={4} style={{ marginTop: isMobile ? "10px" : "35px", color: "gray", fontWeight: "300",textAlign:"right", display:"grid", alignItems:"end" }}>
                      {(item.daysOpen)}
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>

  );
};

export default NotificationBar;
