import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export const offerSlice = createSlice({
    name: "offerReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setOfferPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setOfferPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setOfferSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const {setOfferPageNo, setOfferPageSize, setOfferSearchParam} = offerSlice.actions;

export default offerSlice.reducer;