import React, { useEffect, useState } from "react";
import "../LenderPrivacyPolicy/LenderPrivacyPolicy.scss";
import PrivacyPolicyPanel from "../LenderPrivacyPolicy/PrivacyPolicyPanel";

interface ILenderPrivacyPolicy {
  leadData?: any;
}

const LenderPrivacyPolicy: React.FC<ILenderPrivacyPolicy> = (
  props: ILenderPrivacyPolicy
) => {
  const { leadData } = props;
  const [selectedLenderData, setSelectedLenderData] = useState([] as any);

  useEffect(() => {
    const tmpAssignedLenders =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;
    setSelectedLenderData(tmpAssignedLenders);
  }, []);

  return (
    <div>
      <div
        className="leadDisburse-Detail-scroll"
        style={{ maxHeight: "400px" }}
      >
        <ul style={{ listStyle: "none", padding: "0px" }}>
          {selectedLenderData?.map((lender: any, i: any) => {
            const iDetails = lender?.insuranceDetails?.insuranceDetails;
            if ((iDetails?.length ?? 0) === 0) {
              return <PrivacyPolicyPanel lender={lender} lenderIndex={i} />;
            } else {
              return iDetails?.map((detail: any) => {
                const tmpLender = { ...lender };
                tmpLender.insuranceDetails = detail;
                return (
                  <PrivacyPolicyPanel lender={tmpLender} lenderIndex={i} />
                );
              });
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default LenderPrivacyPolicy;
