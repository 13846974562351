import { Button, Col, Divider, List, Modal, notification, Popconfirm, Radio, Row, Select, Space, Typography, Upload, UploadProps } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { useState, useEffect, useCallback } from "react";
import API_SERVICE from "shared/services/api-service";
import "./ShareLink.scss"
import addfile from "../../../assets/images/payouts/addFile.svg";
import { ReactComponent as FileBorder } from "../../../assets/images/file-border.svg";
import logoIcon from "../../../assets/images/logo.png";
import deleteIcon from "../../../assets/images/delete.svg";
import { FaDownload, FaFileMedical } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setAppColors } from "shared/redux/common-reducer";
import FormIcons from "shared/components/FormIcons/FormIcons";

const { Title, Text } = Typography;
type Props = {};

const ShareLink: React.FunctionComponent<Props> = () => {

    const [hierarchyList, setHierarchyList] = useState([]);
    //const [hierarchyListTag, setHierarchyListTag] = useState([]);
    // const [leadDetail, setLeadDetail] = useState([] as any);
    const [documentList, setDocumentList] = useState([] as any);
    const [documentSubTypeList, setDocumentSubTypeList] = useState([] as any);
    const [fileList, setFileList] = useState([] as any);
    const [uploadedFile, setUploadedFile] = useState(null as any);
    const [imageUrl, setImageUrl] = useState(null as any);
    const [displayDocumentList, setDisplayDocumentList] = useState([] as any);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isOpenVerify, setIsOpenVerify] = useState(false);
    const [leadShareDetails, setLeadShareDetails] = useState([] as any);
    const isMobile = width <= 768;

    const [oldDocumentType, setOldDocumentType] = useState(null as any);
    const [documentTypesParent, setDocumentTypesParent] = useState(null as any);
    const [selectedDocument, setSelectedDocument] = useState(null as any);
    const [selectedDocumentType, setSelectedDocumentType] = useState(null as any);
    const [allowedAttechmentTypeZip] = useState(["application/x-zip-compressed"]);
    const [isSelectMultipleFile, setIsSelectMultipleFile] = useState(false);
    const [filePayload, setFilePayload] = useState([] as any);
    const { appColors } = useSelector((state: RootState) => state.common);
    const [isUploadLoader, setIsUploadLoader] = useState(false);
    const dispatch = useDispatch();

    const { id } = useParams() as any;

    useEffect(() => {
        getCaseDetailsByShareLinkId();
    }, []);

    useEffect(() => {
        if (oldDocumentType && documentList) {
            filterDocumentList(oldDocumentType, documentList);
        }
    }, [oldDocumentType, documentList, leadShareDetails, documentTypesParent]);

    let leadSourceName = leadShareDetails?.connectorName ?? ""
    let leadSourceId = leadShareDetails?.connectorId ?? ""
    let leadSourceCity = leadShareDetails?.address?.state ?? ""
    let gender = leadShareDetails?.applicant?.gender === "M" ? "Male" : leadShareDetails?.applicant?.gender === "F" ? "Female" : ""
    gender = (gender?.length ?? 0) === 0 ? leadShareDetails?.applicant?.gender : gender
    gender = (gender?.length ?? 0) === 0 ? "" : gender

    const leadDetails = [
        <Text className="lead-deatils-text"><h5>{`Lead Source`}</h5></Text>,
        <Text className="lead-deatils-text-highlight">{leadSourceName}</Text>,
        <Text className="lead-deatils-text-highlight">{leadSourceId}</Text>,
        <Text className="lead-deatils-text">{`${leadSourceCity ?? ""}`}</Text>,
    ];

    const personDetails = [
        <Text className="lead-deatils-text"><h5>Applicant Name</h5>{`${leadShareDetails?.applicant?.applicantName ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Individual Type</h5>{`${leadShareDetails?.applicant?.applicantSubType ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Gender</h5>{`${gender}`}</Text>,
        <Text className="lead-deatils-text"><h5>Applicant Type</h5>{` ${leadShareDetails?.applicant?.applicantType ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Email</h5>{` ${leadShareDetails?.applicant?.email ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Contact No.</h5>{` ${leadShareDetails?.applicant?.phoneNo ?? ""}`}</Text>,
    ];

    const loanDetail = [
        <Text className="lead-deatils-text"><h5>{`Loan Details`}</h5></Text>,
        <Text className="lead-deatils-text"><h5>Loan Type</h5>{` ${leadShareDetails?.loanDetails?.loanType ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Loan Amount</h5>{` ${leadShareDetails?.loanDetails?.loanAmount ?? ""}`}</Text>,
        <Text className="lead-deatils-text"><h5>Loan Tenure</h5>{` ${leadShareDetails?.loanDetails?.loanTenureInYears ?? ""}`}</Text>,
    ];

    const getCaseDetailsByShareLinkId = () => {
        API_SERVICE.getCaseDetailsByShareLinkId(id)
            .then(({ data }) => {
                if (data?.payload) {
                    setLeadShareDetails(data?.payload)
                    getDocumentHierarchyList(data?.payload?.loanProductId);
                    getDocumentList(data?.payload?.documentSetId);
                    let obj = {} as any;
                    data?.payload?.tenantShortDetails?.tenantColorConfig?.map((item: any) => {
                        obj[item.element] = item.hexCode;
                    })
                    const tmpObj = {
                        appPrimaryColor: obj?.primary_color,
                        appSecondaryColor: obj?.secondary_color,
                        appSecondaryLightColor: obj?.secondary_color ? `${obj?.secondary_color}33` : "",
                        appPrimaryLightColor: obj?.primary_color ? `${obj?.primary_color}33` : "",
                    }
                    dispatch(setAppColors(tmpObj));
                } else {
                    setLeadShareDetails([]);
                }
            })
            .catch(function (e) {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
            });
    }

    const getDocumentHierarchyList = (loanProductId: any) => {

        let applicationType = "APPLICANT"
        let employmentType = "SELF_EMPLOYED"

        const params = {
            applicationType,
            loanProductId,
            employmentType,
        };

        API_SERVICE.getDocumentHierarchyList(params).then(({ data }) => {
            if (data) {
                let uploadObject = {
                    documentCategoryKey: "ZIP",
                    documentCategoryName: "Zip"
                }
                let dataObject = data?.payload;
                dataObject.push(uploadObject);
                setHierarchyList(dataObject)
                // setHierarchyListTag(dataObject);
                if ((data?.payload?.length ?? 0) > 0) {
                    setOldDocumentType(data?.payload[0]?.documentCategoryKey);
                    setDocumentTypesParent(data?.payload[0]?.documentCategoryKey);
                    if (documentList?.length > 0) {
                        filterDocumentList(data?.payload[0]?.documentCategoryKey, documentList);
                    }
                }

                if (data?.payload[0]?.documentCategoryKey === "ZIP") {
                    setIsSelectMultipleFile(true);
                } else {
                    setIsSelectMultipleFile(false);
                }
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    const getDocumentList = (documentId: any) => {
        const documentSetId = documentId;
        if (documentSetId) {
            API_SERVICE.getDocumentList(documentSetId).then(({ data }) => {
                if (data) {
                    setDocumentList(data?.payload ?? []);
                    if (data?.payload?.length > 0) {
                        // setDocumentList(data.payload);
                        // setDocumentTypesParent(data?.payload[0]?.documentType);
                        let tempArray = [] as any;
                        tempArray.push(data?.payload[0])
                        filterDocumentList(data?.payload[0]?.documentType, tempArray)
                    }

                }
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    }

    const generatDocumentType = (documentSubTypeList: any) => {
        return <div>
            {documentSubTypeList &&
                <Select
                    style={{ width: "93%", borderRadius: "20px", marginLeft: "25px" }}
                    value={selectedDocumentType}
                    placeholder="Document Type"
                    onChange={(e) => setSelectedDocumentType(e)}
                    options={documentSubTypeList}
                />
            }
        </div>
    }

    const uploadProps: UploadProps = {
        accept: documentTypesParent === "ZIP" ? "application/x-zip-compressed" : "image/png, image/jpeg, application/pdf",
        // accept: "image/png, image/jpeg, application/pdf",
        listType: 'text',
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            const newFilePayload = filePayload.slice();
            newFileList.splice(index, 1);
            newFilePayload.splice(index, 1);

            if (newFileList?.length >= 0) {
                setFileList(newFileList);
            }
            if (newFilePayload?.length >= 0) {
                setFilePayload(newFilePayload);
            }

            if (newFileList?.length === 0 && newFilePayload?.length === 0) {
                setUploadedFile(null);
            }
        },
        beforeUpload: file => {
            if (documentTypesParent === "ZIP" && allowedAttechmentTypeZip.indexOf(file.type) === -1) {
                notification.error({ message: "Please attach only ZIP file." });
                return;
            } else {
                if (documentTypesParent === "ZIP") {
                    return false;
                    // setFileList([file]);

                } else {
                    setFileList([file]);
                    return false;
                }
            }
        },
        fileList,
    };

    const onUpload = useCallback((acceptedFiles: any) => {

        let fileLength = acceptedFiles?.fileList?.length;
        if (acceptedFiles?.file?.status === "removed" && acceptedFiles?.fileList?.length === 0) {
            setUploadedFile(null);
        } else {
            if (acceptedFiles?.file?.size > 9437184) {
                notification.error({ message: "Please attach less than 10MB file" });
                setFileList([]);
                return;
            } else {
                setFileList(acceptedFiles?.fileList);
                setUploadedFile(acceptedFiles.file);
                uploadDocumentHandler(acceptedFiles.file, fileLength)
            }
        }
    }, []);

    const uploadDocumentHandler = (file: any, fileLength: number) => {
        const formData = new FormData();
        formData.append('file', file);
        API_SERVICE.fileUpload(formData).then(({ data }) => {
            if (data) {
                if (file?.type === "application/x-zip-compressed") {
                    let filePayloadObject = {
                        documentURL: data,
                        documentName: file.name,
                        documentType: "ZIP",
                        documentSubType: selectedDocumentType
                    }

                    if (fileLength > 0) {
                        setFilePayload(filePayload => [...filePayload, filePayloadObject])
                        fileLength--
                    }
                } else {
                    if (imageUrl || imageUrl === null) {
                        setImageUrl(data);
                    }
                }
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    const uploadDocumentFile = () => {
        setIsUploadLoader(true);
        const allowedAttechmentTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];

        if (documentTypesParent !== "ZIP" && allowedAttechmentTypes.indexOf(uploadedFile?.type) === -1) {
            notification.error({ message: "Please attach only image(jpg/jpeg/png) or pdf file." });
            return;
        }

        if (documentTypesParent === "ZIP" && allowedAttechmentTypeZip?.indexOf(uploadedFile?.type) === -1) {
            notification.error({ message: "Please attach only ZIP file." });
            return;
        }

        if (documentTypesParent === "ZIP") {
            if (filePayload?.length > 0) {

                filePayload?.map((file: any) => {
                    const payload = file

                    const documentSetId = leadShareDetails?.documentSetId;
                    if (file?.documentURL?.includes(".zip")) {
                        API_SERVICE.addDocument(payload, documentSetId).then(({ data }) => {
                            if (data) {
                                notification.success({ message: data?.message });
                                setFileList([]);
                                setFilePayload([]);
                                setSelectedDocumentType(null);
                                getDocumentList(documentSetId);
                                getDocumentHierarchyList(leadShareDetails?.loanProductId)
                                filterDocumentList(selectedDocument, documentList);
                                setUploadedFile(null);
                                setIsOpenVerify(false)
                            }
                        }).catch((e: any) => {               
                          notification.error({ message: API_SERVICE.handleErrors(e) });
                        })
                        .finally(() => setIsUploadLoader(false));
                    }
                })
            }
        } else {
            const payload = {
                documentURL: imageUrl,
                documentName: uploadedFile.name,
                documentType: documentTypesParent,
                documentSubType: selectedDocumentType
            }
            const documentSetId = leadShareDetails?.documentSetId;
            API_SERVICE.addDocument(payload, documentSetId).then(({ data }) => {           
                if (data) {
                    notification.success({ message: data?.message });
                    setFilePayload([]);
                    if (fileList?.length > 0) {
                        setFileList([]);
                    }
                    setSelectedDocumentType(null);
                    getDocumentList(documentSetId);
                    getDocumentHierarchyList(leadShareDetails?.loanProductId)
                    filterDocumentList(selectedDocument, documentList);
                    setUploadedFile(null);
                    setIsOpenVerify(false)
                }
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => setIsUploadLoader(false));
        }

    }

    const filterDocumentList = (value: any, documents: any) => {
        if (value && documents) {
            let data = documents?.filter((doc: any) => {
                if (doc.documentType === value.toUpperCase().replace(" ", "_")) {
                    return doc
                }
            })
            if (data?.length > 0) {
                setDisplayDocumentList(data)
            } else {
                setDisplayDocumentList([])
            }
        }
    }

    const getType = (type: any) => {
        const filteredEmp = documentSubTypeList?.filter((item: { key: any; }) => item.key === type);
        if ((filteredEmp?.length ?? 0) > 0) {
            return filteredEmp[0].value;
        }
        return type
    }

    const handleConnectorTagChange = (i: number, value: string) => {
        setOldDocumentType(value);
        setSelectedDocument(value);
        filterDocumentList(value, documentList);
    };

    const downloadDocument = (businessId: String, documentId: String) => {
        API_SERVICE.downloadDocument(businessId, documentId)
            .then(({ data }) => {
                let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
                saveByteArray([bytesData], data.payload.documentName);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const base64ToArrayBuffer = (base64: string) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    const saveByteArray = (function () {
        var a = document.createElement("a") as any;
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data: BlobPart[] | undefined, name: any) {
            var blob = new Blob(data, { type: "octet/stream" }),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    })();

    const onDelete = (doc: any) => {
        API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
            if (data) {
                getDocumentList(leadShareDetails?.documentSetId);
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
    }, [width]);

    const renderVerifyModel = () => {
        return (
            <Modal
                title=""
                visible={isOpenVerify}
                footer={[
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingInline: "15px",
                        }}
                    >
                        <Button
                            key="back"
                            style={{ paddingInline: "30px", width: "100%" }}
                            onClick={() => setIsOpenVerify(false)}
                        >
                            Cancel
                        </Button>
                    </div>,
                ]}
                width={300}
                centered
                closable={false}
                onCancel={() => {
                    setIsOpenVerify(false);
                }}
            >
                {renderTypeOfDocument()}
            </Modal>
        );
    };

    const renderTypeOfDocument = () => {
        return (
            <>
                <div style={{ padding: "20px", }}>
                    <Title level={5} style={{ marginLeft: "20px", marginTop: "10px" }}>
                        Type of Document
                    </Title>
                    <div className="upload-area">
                        <>
                            <Radio.Group
                                className="types-document dynamic-radio"
                                style={{ '--borderColor': appColors?.appPrimaryColor ?? "" } as any}
                                name="document"
                                onChange={(ev) => {
                                    setDocumentTypesParent(ev.target.value);
                                    if (fileList?.length > 0) {
                                        setFileList([]);
                                        setFilePayload([]);
                                    }
                                    if (ev.target.value === "ZIP") {
                                        if (!isSelectMultipleFile) {
                                            setIsSelectMultipleFile(true);
                                        }
                                    } else {
                                        if (isSelectMultipleFile) {
                                            setIsSelectMultipleFile(false);
                                        }
                                    }
                                    setSelectedDocumentType(null);
                                }}
                                value={documentTypesParent}
                            >
                                {hierarchyList?.map((documentType: any) => {
                                    const value = documentType?.documentCategoryKey;
                                    return (
                                        <>
                                            {value === "ZIP" && (
                                                <Divider orientation="center">Or</Divider>
                                            )}
                                            <Radio value={value}>
                                                {documentType?.documentCategoryName}
                                            </Radio>
                                            {documentTypesParent === value &&
                                                generatDocumentType(documentType?.documentList)}
                                        </>
                                    );
                                })}
                            </Radio.Group>
                        </>
                        <Upload
                            className="upload-wrapper"
                            onChange={onUpload}
                            {...uploadProps}
                            multiple={isSelectMultipleFile}
                        >
                            <FaFileMedical />
                            Upload New Document
                        </Upload>
                    </div>


                    <div
                        className="upload-btns"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Button
                            loading={isUploadLoader}
                            className="dynamic-btn-primary"
                            style={
                                { "--btnColor": appColors?.appPrimaryColor ?? "", paddingLeft: "42%", paddingRight: "42%" } as any
                            }
                            type="primary"
                            disabled={documentTypesParent === "ZIP" ? !(uploadedFile) : !(selectedDocumentType && uploadedFile)}
                            onClick={uploadDocumentFile}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div style={{ background: "#E5E5E5", paddingBottom: "5%" }}>
                {isMobile && renderVerifyModel()}
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "5%", }}>
                    <Space>
                        <img src={logoIcon} alt="" width={100} className=" start-import-upload-icon " />
                    </Space>
                </div>
                <div style={{ justifyContent: "center", marginLeft: "15%", marginRight: "15%", marginTop: "2%" }}>
                    <div className="mobile-view-container" style={{ background: `${isMobile ? "" : "white"} `, }}>
                        <div className="mobile-view-list">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} xl={5} span={8}>
                                    <div className="lead-detail-box-mobile" style={{
                                        height: 'calc(auto - 116px)',
                                        background: `${isMobile ? "white" : ""} `,
                                        borderRadius: `${isMobile ? "8px" : "0px"} `,
                                        boxShadow: `${isMobile ? "0px 4px 14px rgba(0, 0, 0, 0.08)" : "0px 0px 0px rgba(0, 0, 0, 0.08)"}`
                                    }} >
                                        <List
                                            style={{ padding: "10px" }}
                                            grid={{ gutter: 4, column: 1, xs: 1, sm: 1, md: 1, lg: 3 }}
                                            dataSource={leadDetails}
                                            renderItem={(item) => <List.Item>{item}</List.Item>}
                                        />
                                    </div>
                                </Col>
                                {!isMobile &&
                                    <Col span={1}>
                                        <Divider type="vertical" style={{ height: "100%" }} />
                                    </Col>
                                }
                                <Col xs={24} xl={9} span={8}>
                                    <div className="lead-detail-box-mobile" style={{
                                        height: 'calc(auto - 116px)',
                                        background: `${isMobile ? "white" : ""} `,
                                        borderRadius: `${isMobile ? "8px" : "0px"} `,
                                        boxShadow: `${isMobile ? "0px 4px 14px rgba(0, 0, 0, 0.08)" : "0px 0px 0px rgba(0, 0, 0, 0.08)"}`
                                    }}>
                                        <List
                                            style={{ padding: "10px" }}
                                            grid={{ gutter: 6, column: 3, xs: 1, sm: 1, md: 1, lg: 3 }}
                                            dataSource={personDetails}
                                            renderItem={(item) => <List.Item>{item}</List.Item>}
                                        />
                                    </div>
                                </Col>
                                {!isMobile &&
                                    <Col span={1}>
                                        <Divider type="vertical" style={{ height: "100%" }} />
                                    </Col>
                                }
                                <Col xs={24} xl={8} span={8}>
                                    <div className="lead-detail-box-mobile" style={{
                                        height: 'calc(auto - 116px)',
                                        background: `${isMobile ? "white" : ""} `,
                                        borderRadius: `${isMobile ? "8px" : "0px"} `,
                                        boxShadow: `${isMobile ? "0px 4px 14px rgba(0, 0, 0, 0.08)" : "0px 0px 0px rgba(0, 0, 0, 0.08)"}`
                                    }}>
                                        <List
                                            style={{ padding: "10px" }}
                                            grid={{ gutter: 6, column: 2, xs: 1, sm: 1, md: 1, lg: 3 }}
                                            dataSource={loanDetail}
                                            renderItem={(item) => <List.Item>{item}</List.Item>}
                                        />
                                    </div></Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: "15%", marginRight: "15%", marginBottom: "5%" }}>
                    <div style={{ marginTop: "30px", background: `${isMobile ? "white" : "white"}`, borderRadius: "20px" }}>
                        <Row>
                            {!isMobile && (
                                <Col span={11}>
                                    <div style={{ marginTop: "-20px" }}>
                                        {renderTypeOfDocument()}
                                    </div>
                                </Col>
                            )}
                            {!isMobile && (
                                <Col
                                    span={1}
                                    style={{ display: "flex", justifyContent: "end" }}
                                >
                                    <Divider
                                        type="vertical"
                                        style={{ height: "100%", width: "30px" }}
                                    />
                                </Col>
                            )}
                            <Col span={isMobile ? 24 : 12}>
                                <div style={{ marginTop: "-20px" }}>

                                    <div style={{ paddingLeft: "00px", marginTop: "30px" }}>
                                        <Title level={5} style={{ marginLeft: "00px" }}>
                                            Uploaded Documents
                                        </Title>
                                        {/* <div className="btn-box">
                                            {hierarchyList?.map((item: any, i: number) => (
                                                <>
                                                    {item?.documentCategoryName ? (
                                                        <CheckableTag
                                                            className="custom-checkable"
                                                            style={{ '--bgColor': appColors?.appPrimaryColor ?? "" } as any}
                                                            key={item?.documentCategoryName}
                                                            checked={
                                                                item?.documentCategoryKey ===
                                                                oldDocumentType?.toUpperCase().replace(" ", "_")
                                                            }
                                                            onChange={(checked) =>
                                                                handleConnectorTagChange(
                                                                    i,
                                                                    item.documentCategoryName
                                                                )
                                                            }
                                                        >
                                                            {item?.documentCategoryName}
                                                        </CheckableTag>
                                                    ) : null}
                                                </>
                                            ))}
                                        </div> */}
                                    </div>
                                    <div className="doc-list-mobile" style={{ marginLeft: "0px" }}>
                                        <ul>
                                            {documentList?.length > 0 &&
                                                documentList?.map((doc: any, i: number) => (
                                                    <li key={i}>
                                                        <div className="doc-details">
                                                            <Space>
                                                                <FileBorder style={{ '--fileIconColor': appColors?.appPrimaryColor ?? "" } as any} className="icon-border" />
                                                            </Space>
                                                            <span>
                                                                <span>
                                                                    {doc?.documentName}
                                                                    <br />
                                                                </span>
                                                                <span className="type">
                                                                    {getType(
                                                                        doc?.documentSubType ?? doc?.documentType
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </div>
                                                        {/* <div className="doc-action">
                                                            <Space>
                                                                <span
                                                                    onClick={() =>
                                                                        downloadDocument(
                                                                            doc.businessId,
                                                                            doc.businessDocumentId
                                                                        )
                                                                    }
                                                                    className="remove"
                                                                >
                                                                    <span onClick={() => downloadDocument(doc.businessId, doc.businessDocumentId)} className='remove'>
                                                                        <FormIcons type="download" color={appColors?.appSecondaryColor ?? ""} isDynamicIcon />
                                                                    </span>
                                                                </span>

                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="Are you sure you want to delete this document?"
                                                                    onConfirm={() => onDelete(doc)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    className="remove"
                                                                    okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border: "none" } }}
                                                                    cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
                                                                >
                                                                    <span className='remove'>
                                                                        <img src={deleteIcon} alt="" width={16} className=" start-import-upload-icon " />
                                                                    </span>
                                                                </Popconfirm>
                                                            </Space>
                                                        </div> */}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    {isMobile && (
                                        <div
                                            className="upload-btns"
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Button
                                                style={{ paddingLeft: "42%", paddingRight: "42%" }}
                                                className=""
                                                type="primary"
                                                onClick={() => {
                                                    setIsOpenVerify(true);
                                                }}
                                            >
                                                Upload
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareLink