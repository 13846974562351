import { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import "./i18n";
import "antd/dist/antd.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import en_US from "antd/es/locale/en_US";
import { AuthProvider } from "shared/contexts/AuthContext";
import LoaderOverlay from "shared/LoaderOverlay";
// Don't move below css
import "./App.scss";
import "./responsive.scss";
import ProtectedRoute from "shared/ProtectedRoute";
import ShareLink from "screens/Lead/ShareLink/ShareLink";
import Layout from "screens/layout/Layout";
import SignIn from "screens/Authentication/sign-in";
import ForgetPassword from "screens/Authentication/ForgetPassword";
import SignUp from "screens/Authentication/sign-up/sign-up";
import { useDispatch } from "react-redux";
import { setIsMobile } from "shared/redux/common-reducer";
import MobileRunTime from "screens/LeadNew/LeadDetail/MobileRuntime";
import CheckExtranalSource from "screens/CheckExtranalSource/CheckExtranalSource";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    const isMobile = window.innerWidth <= 768;
    dispatch(setIsMobile(isMobile));
  }

  return (
    <ConfigProvider locale={en_US}>
      <AuthProvider>
        {loading ? (
          <LoaderOverlay size={"large"} />
        ) : (
          <Router>
            <Switch>
              <Route exact path={"/mobile-runtype"} component={MobileRunTime} />
              <Route exact path="/shareLink/:id" component={ShareLink} />
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/sign-in" component={SignIn} />
              <Route exact path="/check-extranal-source" component={CheckExtranalSource} />
              <Route exact path="/forgot-password" component={ForgetPassword} />
              <Route exact path="/change-password" component={ForgetPassword} />
              <ProtectedRoute path="/" component={Layout} />
            </Switch>
          </Router>
        )}
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
