import { Button, Space, Typography } from "antd";
import successimage from "../../../../../src/assets/images/payouts/payoutSuccess.svg";
import { useHistory } from "react-router-dom";

const { Text } = Typography;
type Props = {};

const SuccessImportBulkUpload: React.FunctionComponent<{
  bulkUploadEcecuteData: any;
  setActiveStepKey: any;
}> = (props) => {
  const { bulkUploadEcecuteData } = props;
  const history = useHistory();

  return (
    <>
      <div className="maindiv">
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text>
              <h4
                style={{
                  color:
                    bulkUploadEcecuteData?.status === "FAILED"
                      ? "red"
                      : "green",
                }}
              >
                {bulkUploadEcecuteData?.status.replace("_", " ")}
              </h4>
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <Text>{"Your data is " + bulkUploadEcecuteData?.status.replace('_', " ")}</Text> */}
            <Text style={{ color: "#C71C8E" }}>
              Click on “Return to Bulk Upload Summary Page” button below to
              check the status of your bulk upload request.
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Space>
              <img
                src={successimage}
                alt=""
                className=" start-import-upload-icon "
              />
            </Space>
          </div>

          {/* <div style={{ marginTop: "20px", }}>
                        <Row><Col><h2>Summary</h2></Col></Row>
                        <div style={{ background: "white", height: "150px", }}>
                            <div style={{ paddingTop: "50px" }}>
                                <Row style={{ textAlign: "center", }}>
                                    <Col span={6}>Import Rows</Col>
                                    <Col span={6}>New Records</Col>
                                    <Col span={6}>Updated Records</Col>
                                    <Col span={6}>New Association</Col>
                                </Row>
                                <Row style={{ textAlign: "center" }}>
                                    <Col span={6} style={{ marginTop: "10px" }}>{bulkUploadEcecuteData?.uploadedFileName}</Col>
                                    <Col span={6} style={{ marginTop: "10px" }}>{bulkUploadEcecuteData?.createdDate}</Col>
                                    <Col span={6} style={{ marginTop: "10px" }}>{bulkUploadEcecuteData?.successRecords}</Col>
                                    <Col span={6} style={{ marginTop: "10px" }}>{bulkUploadEcecuteData?.failedRecords}</Col>
                                </Row>
                            </div>
                        </div>
                    </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <Button
              size="large"
              className="bulk-upload-btn"
              style={{ width: "300px", padding: "5px" }}
              onClick={() => {
                history.goBack();
              }}
            >
              Return to Bulk Upload Summary Page
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessImportBulkUpload;
