import { useEffect, useState } from "react";
import { Button, Card, Form, notification } from "antd";
import { Link } from "react-router-dom";
import FormInput from "shared/components/FormInput/FormInput";
import { signUpStages } from "./sign-up";
import "../authentication.scss";
import API_SERVICE from "shared/services/api-service";

interface ISignUpReferral {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  saveUserData: Function;
}

const SignUpReferral: React.FunctionComponent<ISignUpReferral> = (
  props: ISignUpReferral
) => {
  const { setSelectedSignUpStage, saveUserData } = props;

  const [fields, setFields] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [formSignIn] = Form.useForm();
  const [isSlateId, setIsSlateId] = useState(false);
  const [referralSource, setReferralSource] = useState("");
  const [dsaBusinessId, setDsaBusinessId] = useState("");

  useEffect(() => {
    let searchParams = new URL(window.location.href).searchParams as any;
    getURLParams(searchParams);
  }, []);

  const getURLParams = (searchParams) => {
    if (searchParams?.size > 0) {
      const urlParams = {} as any;
      searchParams?.forEach((value, key) => {
        urlParams[key] = value;
      });

      console.log("urlParams: ", urlParams);
      if (Object.keys(urlParams).length != 0) {
        console.log("urlParams set: ", urlParams);
        formSignIn.setFieldsValue({
          referralConnectorId: urlParams?.connID ?? "",
          referralManagerId: urlParams?.salesID ?? "",
        });
        setReferralSource(urlParams?.referralSource ?? "");
        onCheckStateUser(urlParams?.salesID ?? "");
        setDsaBusinessId(urlParams?.dsaBusinessID ?? "");
      }
    }
  };

  const skipNow = () => {
    setSelectedSignUpStage("selectType");
  };

  const onCheckStateUser = (connectorId: any) => {
    if ((connectorId?.length ?? 0) > 0) {
      API_SERVICE.checkStateUser(connectorId)
        .then(({ data }) => {
          setIsSlateId(data?.payload);
        })
        .catch((e: any) => {
          // notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const onSumbitRefferalCode = (values) => {
    const referralConnectorId = values?.referralConnectorId ?? "";
    const referralManagerId = values?.referralManagerId ?? "";
    const slateEmployeeID = values?.slateEmployeeID ?? "";

    if (
      (referralConnectorId?.length ?? 0) == 0 &&
      (referralManagerId?.length ?? 0) == 0 &&
      (slateEmployeeID?.length ?? 0) == 0
    ) {
      skipNow();
      return;
    }

    let payload = [referralConnectorId, referralManagerId, slateEmployeeID];
    console.log(values);
    API_SERVICE.submitReferralCode(payload)
      .then(({ data }) => {
        let isError = false;

        if (referralConnectorId) {
          let connectorIdFromAPI = data?.payload?.find(
            (element) => element.userId === referralConnectorId
          );
          if (!connectorIdFromAPI) {
            isError = true;
            formSignIn.setFields([
              {
                name: "referralConnectorId",
                errors: ["Invalid ID"],
              },
            ]);
          }
        }

        if (referralManagerId) {
          let connectorIdFromAPI = data?.payload?.find(
            (element) => element.userId === referralManagerId
          );
          if (!connectorIdFromAPI) {
            isError = true;
            formSignIn.setFields([
              {
                name: "referralManagerId",
                errors: ["Invalid ID"],
              },
            ]);
          }
        }

        if (slateEmployeeID) {
          let connectorIdFromAPI = data?.payload?.find(
            (element) => element.userId === slateEmployeeID
          );
          if (!connectorIdFromAPI) {
            isError = true;
            formSignIn.setFields([
              {
                name: "slateEmployeeID",
                errors: ["Invalid ID"],
              },
            ]);
          }
        }

        if (!isError) {
          saveUserData({
            referralId: values?.referralConnectorId,
            managerId: values?.referralManagerId,
            slateEmployeeId: values?.slateEmployeeID,
            referralSource: referralSource,
            dsaBusinessId: dsaBusinessId
          });
          setSelectedSignUpStage("selectType");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderForm = () => {
    return (
      <Form
        size="large"
        form={formSignIn}
        fields={fields}
        onFieldsChange={(changedFields, allFields) => {
          setFields(allFields);
        }}
        onFinish={(values: string) => {
          onSumbitRefferalCode(values);
        }}
        layout="vertical"
        requiredMark={false}
      >
        <FormInput
          name="referralConnectorId"
          classNameForm="oneflo-bottom-space"
          label="Do you have referral Connector ID?"
          placeholder="Enter your Referral ID"
          requiredLabel="referral connector ID"
          isBorder
        />

        <FormInput
          name="referralManagerId"
          classNameForm="oneflo-bottom-space"
          label="Do you have a Referral Sales Relationship Manager ID?"
          placeholder="Enter your Referral ID"
          requiredLabel="referral Sales Relationship Manager ID"
          isBorder
          onBlur={(e) => onCheckStateUser(e?.target?.value)}
        />

        {isSlateId && (
          <FormInput
            name="slateEmployeeID"
            classNameForm="oneflo-bottom-space"
            label="Do you have a referral Slate Employee ID?"
            placeholder="Referral ID"
            isBorder
          />
        )}

        <Form.Item className="oneflo">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size={"large"}
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>

        <Form.Item className="oneflo">
          <Button
            type="default"
            loading={loading}
            size={"large"}
            className="oneflo-full-default-btn"
            onClick={skipNow}
          >
            Skip Now
          </Button>
        </Form.Item>

        <Form.Item>
          <p className="already-a-user text-center">
            Already a User?{" "}
            <Link to="/sign-in" className="sign-in-link">
              Sign In
            </Link>
          </p>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card className="authentication-box">
      <h3 className="welcome-to-oneflo">
        Welcome to
        <span className="oneflo">ONEFLO</span>
      </h3>
      <h3 className="authentication-header-small-no-bottom-space">
        Do You Have Referral Code?
      </h3>
      <p className="authentication-header-addtional-details">
        Please enter your referral code to get additional benefits
      </p>
      <br />
      <div className="authentication-form">{renderForm()}</div>
    </Card>
  );
};

export default SignUpReferral;