import Title from "antd/lib/typography/Title";
import { Button, Col, DatePicker, Form, Input, Row, Table, Tooltip } from "antd";
import { RiSearchLine } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from 'antd/es/table';
import { numberWithCommas } from "shared/services/Utility";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setIsAppFooterShow, setIsAppHeaderShow } from "shared/redux/common-reducer";

interface DataType {
  key: React.Key;
  connectorName: string;
  employeeName: string;
  totalLeads: number;
  login: number;
  sanctioned: number;
  sanctionedAmount: number;
  disbursed: number;
  disbursedAmount: number;
  withdrawn: number;
  closed: number;
}
const columns: ColumnsType<DataType> = [
  {
    title: 'Connector Name',
    dataIndex: 'connectorName',
    width: 170,
    ellipsis: true
  },
  {
    title: 'Employee Name',
    dataIndex: 'employeeName',
    width: 170,
    ellipsis: true
  },
  {
    title: 'Total Leads',
    dataIndex: 'totalLeads',
    width: 80,
    ellipsis: true,
    align: "right"
  },
  {
    title: 'Login',
    dataIndex: 'login',
    width: 55,
    ellipsis: true,
    align: "right"
  },
  {
    title: 'Sanctioned',
    dataIndex: 'sanctioned',
    width: 80,
    ellipsis: true,
    align: "right"
  },
  {
    title: 'Sanctioned Amount',
    dataIndex: 'sanctionedAmount',
    width: 125,
    ellipsis: true,
    align: "right",
    render: (_: any, record: any) => (
      numberWithCommas(record?.sanctionedAmount)
    )
  },
  {
    title: 'Disbursed',
    dataIndex: 'disbursed',
    width: 75,
    ellipsis: true,
    align: "right"
  },
  {
    title: 'Disbursed Amount',
    dataIndex: 'disbursedAmount',
    width: 120,
    ellipsis: true,
    align: "right",
    render: (_: any, record: any) => (
      numberWithCommas(record?.sanctionedAmount)
    )
  },
  {
    title: 'Withdrawn',
    dataIndex: 'withdrawn',
    width: 80,
    ellipsis: true,
    align: "right"
  },
  {
    title: 'Closed',
    dataIndex: 'closed',
    width: 55,
    ellipsis: true,
    align: "right"
  },
];

const data: DataType[] = []
for (let i = 0; i < 100; i++) {
  data.push({
    key: '1',
    connectorName: 'Chirag Kothiya',
    employeeName: 'Amit Kumar',
    totalLeads: 10,
    login: 2,
    sanctioned: 2,
    sanctionedAmount: 300000,
    disbursed: 2,
    disbursedAmount: 250000,
    withdrawn: 1,
    closed: 1,
  })
};

const Partners = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(false));
  }, []);

  return (
    <>
      <div className="content-box">
        <Title level={3}>Partners</Title>
        <Form form={form}>
          <Row gutter={24} className="data-filter-bar">
            <Col xs={24} sm={14} md={16} lg={18} className="pd-lr-10">
              <Form.Item name="searchBox">
                <Input size="large" className="search-box" placeholder="Search by Connector Name" allowClear prefix={<RiSearchLine />} />
              </Form.Item>
            </Col>
            <Col xs={9} sm={5} md={4} lg={3} className="pd-lr-10">
              <Tooltip title="Filter">
                <Button type="primary" className="filter-btn" icon={<FaFilter />} onClick={() => { form.setFieldsValue({ searchBox: "" }) }}></Button>
              </Tooltip>
              <Tooltip title="Refresh">
                <Button type="primary" className="refresh-btn" onClick={() => { form.setFieldsValue({ searchBox: "" }) }} icon={<ReloadOutlined />}></Button>
              </Tooltip>
            </Col>
            <Col xs={8} sm={5} md={4} lg={3}>
              <Button type="primary" className="share-link-btn">Share Link</Button>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={18} sm={16} md={13} lg={10}>
              <Form.Item name='SelectDate' label='Select Date Range' className="label-pt">
                <RangePicker className="date-range" />
              </Form.Item>
            </Col>
            <Col xs={6} sm={4} md={4} lg={3} className="pd-lr-10 text-center">
              <Button type="primary" className="export-btn full-width responsive-export-btn">Export</Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={24}>
          <Col span={24}>
            <Table size="small" scroll={{ x: 'max-content' }} columns={columns} dataSource={data} />
          </Col>
        </Row>
      </div >
    </>
  );
};

export default Partners;
