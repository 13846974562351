import { Button, Col, Form, Input, Row, Space, Switch, Table, Typography, notification } from "antd";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { setDesignationPageNo, setDesignationPageSize, setDesignationSearchParam } from "shared/redux/designation-reducer";
import API_SERVICE from "shared/services/api-service";
import { setIsAppFooterShow, setIsAppHeaderShow, setShowProfielVerification } from "shared/redux/common-reducer";
import { useHistory } from "react-router-dom";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import { isUserVerified } from "shared/services/Utility";
const { Title } = Typography;

interface Idesignation {
};
const Designation: React.FunctionComponent<Idesignation> = (
  props: Idesignation
) => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [allDesignation, setAllDesignation] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const designation: any = useSelector((state: RootState) => state.designation);
  const { appColors } = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);

  useEffect(() => {
    dispatch(setIsAppHeaderShow(true));
    dispatch(setIsAppFooterShow(true));
    getAllDesignation();
  }, []);

  const userVerified = () => {
    if (!isUserVerified()) {
      dispatch(setShowProfielVerification(true))
      notification.error({ message: "Please verify your profile to proceed" });
      return false
    }
    return true
  }

  const goToEdit = (item: any) => {
    if (userVerified()) {
      history.push({
        pathname: `/designation/edit/${item.id}`,
        state: { name: item.name },
      });
    }
  };
  const goToAdd = () => {
    if (userVerified()) {
      history.push("/designation/add-designation")
    }
  }
  
  const columns: ColumnsType<any> = [

    {
      title: "Designation",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
            onClick={() => goToEdit(record)}
          />
        </Space>
      ),
    },
  ];

  const getAllDesignation = (
    pageNo: number = 1, pageSize: any = null, searchVal: any = null
  ) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getDesignation(params)
      .then(({ data }) => {
        mapLeadsData(data);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllDesignation(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const handleSearch = (event: any) => {
    dispatch(setDesignationPageNo(1));
    dispatch(setDesignationSearchParam(event.target.value));
    getAllDesignation(1, designation.pageSize, event.target.value);
  };


  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDesignationPageNo(current));
    dispatch(setDesignationPageSize(pageSize));
    getAllDesignation(current, pageSize, designation.searchParam);
  };

  return (
    <div className="content-box">
      <Title level={3}>Manage Designations</Title>
      <Form form={form}>
        <Row gutter={24} className="data-filter-bar">
          <Col xs={24} sm={18} md={16} lg={16}>
            <Form.Item name="searchBox">
              <Input
                size="large"
                className="searchBar"
                placeholder="Search"
                allowClear
                prefix={<FaSearch />}
                onChange={debounce(handleSearch, 1000)}
              />
            </Form.Item>
          </Col>
          <Col xs={10} sm={5} md={5} lg={4}>
            <Button type="primary" 
              className="add-btn dynamic-btn-primary" 
              style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
              onClick={goToAdd}
            >
              Add Designation
            </Button>
          </Col>
        </Row>
      </Form>
      <Row></Row>
      <Row gutter={24} style={{ marginTop: isMobile ? "20px" : "0px" }}>
        <Col span={isMobile ? 24 : 10}>
          <Table
            className="dynamic-pagination-checkbox"
            style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
            loading={loading}
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={allDesignation}
            size="small"
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              current: designation.pageNo,
              pageSize: designation.pageSize,
              total: totalCount, // total count returned from backend
            }}
          />
        </Col>
        {/* )} */}
      </Row>
    </div>


  )
}

export default Designation;