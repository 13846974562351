import {
  Button,
  Modal,
  notification,
  Select,
  Typography,
  Spin,
  Checkbox,
  Row,
  Col,
  Space,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";
import UploadDocument from "../UploadDocument/UploadDocument";
import "./index.scss";
import DocCategory from "./DocCategory";
import ReplaceDocument from "./ReplaceDocument";
import RenameDocument from "./RenameDocument";
import SkipDocument from "./SkipDocument";

const { Title } = Typography;
const { Option } = Select;
const RequiredDocumentFlowable: React.FunctionComponent<any> = ({
  leadData,
  handleFormSubmit,
  setFinalJSONSchema,
  // documentList,
  setDocumentList,
  finalJSONSchema,
  selectedLander,
  loadingDisplay,
  isDocumentUploadResponse,
  skippedLoginStatus,
  setShowAdditionalFields,
  failedCount,
  getLenderDocumentUploadDetails,
}) => {
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [masterParentIDs, setMasterParentIDs] = useState<any>(undefined);
  const [masterChildIDs, setMasterChildIDs] = useState<any>(undefined);
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [mappedList, setMappedList] = useState([] as any);
  const [documentMappingList, setDocumentMappingList] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [documentList1, setDocumentList1] = useState([finalJSONSchema] as any);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [showReplace, setShowReplace] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [isAllDocChecked, setIsAllDocChecked] = useState<any>(false);
  const [checkedDocuments, setCheckedDocuments] = useState<any>([]);
  // const [sampleDataSet, setSampleDataSet] = useState([] as any);
  // console.log(documentList);
  const [SelectedChild, setSelectedChild] = useState([] as any);
  const [showSkipDoc, setShowSkipDoc] = useState(false);

  const [tmpdocumentList1, setTmpdocumentList1] = useState([] as any);

  const [tableCurrentPageNo, setTableCurrentPageNo] = useState(0);
  const [isConflictClick, setIsConflictClick] = useState(false);
  const [allDocumentList, setAllDocumentList] = useState([] as any);
  const smapleNewData1: any[] = [];
  console.log("RequiredDocumentFlowable calling")
  const getAllDocumentList = () => {
    API_SERVICE.getLenderDocumentlist(
      selectedLander.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          updateDocs(data.payload);
        }
      })
      .catch((e: any) => {
        console.log(newArray1);
        // updateDocs();
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
    // checkedNexBtn();
    const updateDocs = (json) => {
      const tmpLendersData = Array.from(
        new Set(json.map((item) => item.parentDocId))
      );
      json.filter((item, indexx) => {
        smapleNewData1.push({
          isVisible: true,
          isUpdatable: true,
          isRemovable: false,
          childDocIdOrg: item?.childDocId,
          // require: item?.childRequired === 'yes',
          // indexValue: `${updateIndex}`,
          ...item,
          // documentCategory: item?.docName.split("~").at(0),
        });
      });
      // setSampleDataSet(smapleNewData1);
      generateCategoryList(smapleNewData1);
      setTmpdocumentList1(smapleNewData1);
      // const json = tmpdocumentList1;
      setAllDocumentList(json);
    }
  };
  useEffect(() => {
    // let json = documentList;
    // let json = dummyData;
    let datas = [] as any;
    let paggination = 10;
    const smapleNewData1: any[] = [];
    let sampleIndex = 0;
    let sampleSecondID = 1;
    let ind = 0;
    getAllDocumentList()
    getLenderDocumentUploadDetails();
  }, []);
  const errorCallback = () => {
    getAllDocumentList();
    setIsAllDocChecked(false);
    setCheckedDocuments([]);
  };
  const checkNextBtnStatus = useEffect(() => {
    console.log('::allDocumentList', allDocumentList);
    if (allDocumentList?.length > 0) {
      const tmpMasterParentData = Array.from(
        new Set(
          allDocumentList.map((item: any) => {
            if (item.childRequired.toUpperCase() === "YES_OR_NO" && item.lenderDocumentStatus !== "UPLOADED") {
              return item.parentDocId;
            }
          })
        )
      );
      const tmpMasterChildData = Array.from(
        new Set(
          allDocumentList.map((item: any) => {
            if (item.childRequired.toUpperCase() === "YES" && item.lenderDocumentStatus !== "UPLOADED") {
              return item.childDocId;
            }
          })
        )
      );
      allDocumentList?.map((doc) => {
        if (doc?.documentDetails) {
          const indexA = tmpMasterParentData.findIndex(
            (val) => val === doc?.parentDocId
          );
          const indexChild = tmpMasterChildData.findIndex(
            (val) => val === doc?.childDocId
          );
  
          if (doc?.childRequired?.toUpperCase() === "YES" && indexChild !== -1) {
            tmpMasterChildData.splice(indexChild, 1);
          }
          // if (doc?.childRequired !== "yes") {
          //   setSelectedChild([...SelectedChild, doc?.parentDocId]);
          // }
          if (indexA !== -1 && doc?.childRequired?.toUpperCase() !== "YES") {
            tmpMasterParentData.splice(indexA, 1);
            // setSelectedChild([...SelectedChild, ...a]);
          }
        }
      });
      const indexChild = tmpMasterChildData.findIndex(
        (val) => val === undefined
      );
      const indexParent = tmpMasterParentData.findIndex(
        (val) => val === undefined
      );
      if (indexChild !== -1) {
        tmpMasterChildData.splice(indexChild, 1);
      }
      if (indexParent !== -1) {
        tmpMasterParentData.splice(indexParent, 1);
      }
      console.log('Doc>>P', tmpMasterParentData);
      console.log('Doc>>C', tmpMasterChildData);
      setMasterParentIDs(tmpMasterParentData);
      setMasterChildIDs(tmpMasterChildData);
    }
  }, [allDocumentList, allDocumentList.length])
  const downloadDocument = (doc: any) => {
    console.log(doc);
    API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        console.log(data);
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  console.log(finalJSONSchema);
  const onDelete = (doc: any) => {
    console.log(doc.businessId);
    API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        if (data) {
          // getDocumentList();
          // console.log(documentList);
          let tmpChilData: any[] = [];
          let tmpParentData: any[] = [];
          const tmpDocumentList = tmpdocumentList1?.map((docItem) => {
            console.log(docItem?.documentDetails?.businessDocumentId);

            if (
              docItem?.documentDetails?.businessDocumentId ===
              doc.businessDocumentId
            ) {
              if (docItem.childRequired?.toUpperCase() !== "YES") {
                const indexParent = SelectedChild.findIndex(
                  (val) => val === docItem?.parentDocId
                );
                SelectedChild.splice(indexParent, 1);
                if (!SelectedChild.includes(docItem.parentDocId)) {
                  tmpParentData = [docItem?.parentDocId];
                }
              }
              if (docItem.childRequired?.toUpperCase() === "YES") {
                tmpChilData = [docItem.parentDocId];
              }
              // const tmpMasterParentData = Array.from(new Set(json.map((item: any) => { if (item.childRequired.toUpperCase() !== "YES") { return item.parentDocId } } )));
              // const tmpMasterChildData = Array.from(new Set(json.map((item: any) => { if (item.childRequired.toUpperCase() === "YES") { return item.childDocId } } )));
              return { ...docItem, documentDetails: null };
            }
            return docItem;
          });
          setFinalJSONSchema(tmpDocumentList);
          setTmpdocumentList1(tmpDocumentList);

          // setMasterParentIDs((prev) => [...prev, ...tmpParentData]);
          setMasterParentIDs((prev) =>
            Array.from(new Set([...prev, ...tmpParentData].map((x) => x)))
          );
          // setMasterChildIDs((prev) => [...prev, ...tmpChilData]);
          setMasterChildIDs((prev) =>
            Array.from(new Set([...prev, ...tmpChilData].map((x) => x)))
          );
          // setMasterChildIDs((prev) => [...prev, ...tmpChilData]);
          // checkedNexBtn();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  // useEffect(() => {
  //   checkedNexBtn();
  // }, [masterParentIDs?.length, masterChildIDs?.length]);
  // const getNextBtnDisable = (arr: any) => {
  //   const data = arr;
  //   const datas = JSON.stringify(data.map((item: any) => item.parentDocId));
  //   const updateData = JSON.parse(datas);
  //   const updatedArr = data.map((item) => item.parentDocId);
  // };
  const newArray1 = [] as any;
  const onChangeAddNewDocument = (item: any, index: any) => {
    console.log("items?.documentType", item);
    const tmplDoclist = tmpdocumentList1.map((items) => {
      console.log("items?.documentType +++", items);

      if (items?.childDocId === item?.childDocId) {
        items.isUpdatable = false;
        return items;
      }
      return items;
    });
    const tmoDocLists = tmplDoclist.filter(
      (val) => val?.childDocIdOrg === item?.childDocIdOrg
    );
    const indexDocumnet = tmplDoclist.findIndex(
      (lstItem) => lstItem?.childDocId === item?.childDocId,
      index
    );
    if (indexDocumnet !== -1) {
      tmplDoclist.splice(index + 1, 0, {
        ...item,
        isUpdatable: true,
        documentDetails: null,
        isRemovable: true,
        childDocId: `${item?.childDocIdOrg}~${tmoDocLists?.length - 1}`,
      });
    }
    setDocumentList(tmplDoclist);
    setTmpdocumentList1(tmplDoclist);
  };

  const onRemoveRecord = (item: any) => {
    let updateTmpLst = tmpdocumentList1.filter(
      (items) => items.childDocId !== item.childDocId
    );
    const lstName: any[] = [];
    updateTmpLst.filter((value) => {
      if (value.childDocIdOrg === item.childDocIdOrg) {
        lstName.push(value.childDocId);
      }
    });
    if (lstName?.length > 0) {
      updateTmpLst = updateTmpLst.map((value) => {
        console.log("value", value);
        if (
          value.childDocId === lstName[lstName.length - 1] &&
          !value.headers
        ) {
          return {
            ...value,
            isUpdatable: true,
            isRemovable: lstName?.length !== 1,
          };
        }
        return value;
      });
    }
    const updatedDocumentMappingList = documentMappingList?.filter(
      (items) => items.childDocId !== item.childDocId
    );
    if (item?.documentDetails) {
      API_SERVICE.deleteDocument(
        item?.documentDetails?.businessId,
        item?.documentDetails?.businessDocumentId
      ).then((data) => {
        console.log("Document Delete and removed", data);
      });
    }
    setDocumentMappingList(updatedDocumentMappingList);
    setDocumentList(updateTmpLst);
    setTmpdocumentList1(updateTmpLst);
  };

  let tempArray = [{}];
  let tempObj = {};
  const onClickNext = (documentList) => {
    console.log('checkedDocuments>>>', checkedDocuments);
    // console.log(documentList)
    //   if((typeof(documentList.data))=='string'){
    //     console.log(JSON.parse(documentList.data));
    //     JSON.parse(documentList.data).map((item)=>{
    //      if(item?.documentDetails?.documentName !== undefined && item?.documentDetails?.documentType !== undefined){
    //           tempObj={
    //          "userId":"11142",
    //          "docType":item?.documentDetails?.documentType,
    //          "docName":item?.documentDetails?.documentName,
    //         };
    //        tempArray.push(tempObj);
    //      }
    //    })
    //    setDocumentDetails(tempArray);
    //    console.log(documentDetails)
    //  }

    documentList.map((item) => {
      if (
        item?.documentDetails?.documentName !== undefined &&
        item?.documentDetails?.documentType !== undefined
      ) {
        tempObj = {
          ...item,
          userId: "11142",
          // docType: item?.documentDetails?.documentType,
          // docName: item?.documentDetails?.documentName,
        };
        tempArray.push(tempObj);
      }
    });
    let allData: any = [];
    documentListCat.map((items) => {
      allData = [...allData, ...items?.docs];
    });
    const tmp1 = allData.filter((items) => items?.documentDetails && items?.lenderDocumentStatus !== 'UPLOADED');
    const tmp2 = tmp1.filter((docs) => checkedDocuments.includes(docs?.identifier))
    const data = tmp2.map((item) => {
      return  {...item, lenderDocumentValues: item?.lenderDocumentValues?.split('~').at(0), identifier: item?.identifier.split('~').at(0) }
    });
    if (checkedDocuments?.length > 0) {
      handleFormSubmit({ data: data }, errorCallback);

    } else {
      notification.error({ message: "Please select Document before upload" });
    }
  };
  
  // const checkedNexBtn = useCallback(() => {
  //   if (!selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
  //     if (masterParentIDs?.length === 0 && masterChildIDs?.length === 0) {
  //       setDisableNextBtn(false);
  //     } else {
  //       setDisableNextBtn(true);
  //     }
  //   } else {
  //     setDisableNextBtn(false);
  //   }
  //   getDisableNextBtn();
  //   console.log('::masterParentIDs', masterParentIDs);
  //   console.log('::masterChildIDs', masterChildIDs);
  // }, [masterParentIDs?.length, masterChildIDs?.length]);


  const onTableChane = (paggination) => {
    const currentPage = paggination?.current - 1;
    setTableCurrentPageNo(currentPage);
  };
  //multiple lender document changes.
  const generateCategoryList = (docs: any) => {
    const tmpLendersData = Array.from(
      new Set(docs.map((item) => item.documentCategory))
    ).sort((a: any, b: any) => a > b ? 1 : -1 );

    console.log("tmpLendersData: ", tmpLendersData);

    const tmp = tmpLendersData?.map((category) => {
      const filter = docs.filter((item) => item.documentCategory == category);
    


      // const f1 = filter?.map((item) => {
      //   return {
      //     ...item,
      //     isUpdatable: true,
      //     isRemovable: false,
      //     documentOrgType: item?.documentType,
      //   };
      // });
      const f1 = filter?.map((item) => {
        let docType = "";
        if(item?.documentDetails != null) {
          docType = item?.documentType+"~"+item?.documentDetails?.docPage
        }
        let lenderValue = "";
        if (item?.documentDetails != null && item?.documentDetails?.docPage > 0) {
          lenderValue =
            item?.identifier +
            "~" +
            item?.documentDetails?.docPage;
        }
        return {
          ...item,
          isUpdatable: true,
          isRemovable: false,
          documentOrgType: item?.documentType,
          documentType: (docType?.length ?? 0) > 1 ? docType : item?.documentType,
          lenderDocumentValuesOrg: item?.lenderDocumentValues,
          identifierOrg: item?.identifier,
          identifier: (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.identifier,
          // lenderDocumentValues:
          //   (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.lenderDocumentValues,
        };
      });
      console.log("mandatory2: ", f1);
      const uploadedDoc = f1?.filter((items) => items.documentDetails);
      const mandatoryDocs = f1?.filter((items) => items.require && items.documentDetails);
      const mandatoryTotalDocs = f1?.filter((items) => items.require);
      const requiredYes = f1?.some((item) => item.childRequired.toUpperCase().trim() === 'YES');
      console.log('requiredYes', requiredYes);
      const requiredYesOrNO = f1?.some((item) => item.childRequired.toUpperCase().trim() === 'YES_OR_NO');
      console.log('requiredYesOrNO', requiredYesOrNO);
      const mandatoryU = requiredYes ? 1 : requiredYesOrNO ? 2 : 0;
      console.log();
      console.log("mandatory3: ", mandatoryDocs);
      console.log("mandatory4: ", uploadedDoc.length);
      console.log("mandatory5: ", mandatoryTotalDocs);
      return { category: category, docs: f1, mandatory: mandatoryU,  totalDocs: mandatoryTotalDocs?.length, uploadedDocs: uploadedDoc?.length, mandatoryCount: mandatoryDocs?.length  };
    });

    console.log("tmp: ", tmp);
    // if (isDocumentUploadResponse) {
    //   if (isDocumentUploadResponse?.documentsSuccessfullySubmitted > 0) {
    //     const submittedData =  isDocumentUploadResponse?.submissionDetails;
    //     const tmp1: any = [];
    //     const tmpFailed: any = [];
    //     const tmpFailedDocIDs: any = [];
    //     submittedData.map((items) =>{
    //       if (items?.status === 'UPLOADED') {
    //         tmp1.push(`${items?.docSubType}~${items?.docOrder}`);
    //       } else if (items?.status === 'FAILED') {
            
    //         tmpFailedDocIDs.push(items?.documentId);
    //         tmpFailed.push(`${items?.docSubType}~${items?.docOrder}`);
    //       }
    //     });
    //     // const tmp1 = submittedData.map((items) => {
    //     const subDocs = tmp.map((document) => {
    //           const docs = document.docs.map((docs) => {
    //             const docNames = docs?.documentType.split('~');
    //            if (tmp1?.includes(`${docNames[0]}~${docNames[1]}`) && docs?.documentDetails && !docs?.documentType?.includes(','))  {
    //             return {
    //               ...docs, lenderDocumentStatus: 'UPLOADED'
    //             }
    //            } else if (tmpFailed?.includes(`${docNames[0]}~${docNames[1]}`) && docs?.documentDetails && !docs?.documentType?.includes(',')) {
    //             return {
    //               ...docs, lenderDocumentStatus: 'FAILED'
    //             }
    //            } else {
    //             return docs
    //            }
    //           });
    //           return { category: document.category, docs: docs };
    //       })
    //     //   return subDocs;
    //     // });
    //     console.log('subDocs', subDocs);
    //     console.log('tmp1', tmp1);
    //     console.log('tmpFailed', tmpFailed);
    //     setDocumentListCat(subDocs);
    //   }
    // } else {
      setDocumentListCat(tmp);
    // }

  };

  // const actionOnDelete = (doc: any) => {
  //   API_SERVICE.deleteDocument(
  //     doc?.documentDetails?.businessId,
  //     doc?.documentDetails?.businessDocumentId
  //   )
  //     .then(({ data }) => {
  //       API_SERVICE.deleteDocReference(leadData?.caseDetails?.caseId, doc?.documentDetails?.businessDocumentId).then((data1) =>
  //       {
  //         if (data1) {
  //           changeDocumentDetail(doc, null);
  //         }
  //       });
  //     })
  //     .catch((e: any) => {
  //       notification.error({ message: API_SERVICE.handleErrors(e) });
  //     });
  // };
  const actionOnDelete = (doc: any, isRemove: any = false, isChangeDoc: any = true) => {
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        API_SERVICE.deleteDocReference(leadData?.caseDetails?.caseId, doc.documentDetails.businessDocumentId).then((data1) =>
        {
          if (data1) {
            if (isRemove) {
              actionRemove(doc);
            }
            if (isChangeDoc) {
              changeDocumentDetail(doc, null);
            }
          }
        });
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const actionAdd = (doc, index) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        const tmplDoclist = document?.docs.map((items) => {
          if (items?.identifier === doc?.identifier) {
            items.isUpdatable = false;
            return items;
          }
          return items;
        });
                const tmoDocLists = tmplDoclist.filter(
          (val) => val?.identifierOrg === doc?.identifierOrg
        );
                const indexDocumnet = tmplDoclist.findIndex(
          (lstItem) => lstItem?.identifier === doc?.identifier,
          index
        );
        if (indexDocumnet !== -1) {
          console.log('::doc', doc)
                    tmplDoclist.splice(index + 1, 0, {
            ...doc,
            isUpdatable: true,
            documentDetails: null,
            isRemovable: true,
            isAddedCase: true,
            lenderDocumentStatus: '',
            documentType: doc?.documentType,
            lenderDocumentValues: `${doc?.lenderDocumentValuesOrg}~${tmoDocLists?.length}`,
            identifierOrg : doc?.identifier,
            identifier:`${doc?.identifier}~${tmoDocLists?.length}`
          });
        }
        const uploadedDoc = tmplDoclist?.filter((items) => items.documentDetails);
        const mandatoryDocs = tmplDoclist?.filter((items) => items.require && items.documentDetails);
        const mandatoryTotalDocs = tmplDoclist?.filter((items) => items.require);
        return { category: document.category, docs: tmplDoclist,  totalDocs: mandatoryTotalDocs?.length, uploadedDocs: uploadedDoc?.length, mandatoryCount: mandatoryDocs?.length  };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };
  const actionRemove = (doc) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        let updateTmpLst = document?.docs?.filter(
          (items) => items.identifier !== doc.identifier
        );
        const lstName: any[] = [];
        updateTmpLst.filter((value) => {
          if (value.identifierOrg === doc.identifierOrg) {
            lstName.push(value.identifier);
          }
        });
        if (lstName?.length > 0) {
          updateTmpLst = updateTmpLst.map((value) => {
            if (value.identifier === lstName[lstName.length - 1]) {
              return {
                ...value,
                isUpdatable: true,
                isRemovable: lstName?.length !== 1,
              };
            }
            return value;
          });
        }
        const updatedDocumentMappingList = documentMappingList?.filter(
          (items) => items.identifier !== doc.identifier
        );
        if (doc?.documentDetails) {
          API_SERVICE.deleteDocument(
            doc?.documentDetails?.businessId,
            doc?.documentDetails?.businessDocumentId
          ).then((data) => {
            console.log("Document Delete and removed", data);
          });
        }
        console.log('::updatedDocumentMappingList', updatedDocumentMappingList);
        setDocumentMappingList(updatedDocumentMappingList);
        const uploadedDoc = updateTmpLst?.filter((items) => items.documentDetails);
        const mandatoryDocs = updateTmpLst?.filter((items) => items.require && items.documentDetails);
        const mandatoryTotalDocs = updateTmpLst?.filter((items) => items.require);
        return { category: document.category, docs: updateTmpLst,  totalDocs: mandatoryTotalDocs?.length, uploadedDocs: uploadedDoc?.length, mandatoryCount: mandatoryDocs?.length  };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };
  const docReplaceDocs = (docID) => {
    const tmp = documentListCat.map((documents) => {
      const updatedDocs = documents.docs.filter((item) => {
        if (item?.documentDetails) {
          if(item?.documentDetails?.businessDocumentId === docID) {
            item.documentDetails= null;
            item.conflict = true;
                 return { ...item };     
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
      const uploadedDoc = updatedDocs?.filter((items) => items.documentDetails);
      const mandatoryDocs = updatedDocs?.filter((items) => items.require && items.documentDetails);
      const mandatoryTotalDocs = updatedDocs?.filter((items) => items.require);
      return { category: documents.category, docs: updatedDocs,  totalDocs: mandatoryTotalDocs?.length, uploadedDocs: uploadedDoc?.length, mandatoryCount: mandatoryDocs?.length  };
    })
    setDocumentListCat(tmp);
  };
  // const changeDocumentDetail = (doc, docDetail) => {
  //   // const tmpDocumentList2 = { ...doc };
  //   // tmpDocumentList2.documentDetails = docDetail;
  //   let allData: any = [];
    
  //   const tmp1 = documentListCat?.map((document) => {
  //     const docs = document?.docs?.map((item) => {
  //       // if (
  //       //   item?.documentDetails
  //       //   ?
  //       //   (doc?.documentDetails?.businessDocumentId?.length ?? 0) > 0 && item?.documentDetails?.businessDocumentId === doc?.documentDetails?.businessDocumentId: item?.documentType === doc?.documentType) {
  //       //   return {...item, documentDetails: docDetail};
  //       // }
  //       if (item?.lenderDocumentValues === doc?.lenderDocumentValues || item.documentType === doc.documentType) {
  //         console.log('::||::', item);
  //         console.log('::||::', doc);
  //         return {...item, documentDetails: docDetail};
  //       }
  //       return item;
  //     });
  //     allData = [...allData, ...docs];
  //     const uploadedDoc = docs?.filter((items) => items.documentDetails);
  //     const mandatoryDocs = docs?.filter((items) => items.require && items.documentDetails);
  //     const mandatoryTotalDocs = docs?.filter((items) => items.require);
  //     return { category: document.category, docs: docs,  totalDocs: mandatoryTotalDocs?.length, uploadedDocs: uploadedDoc?.length, mandatoryCount: mandatoryDocs?.length  };
  //   });
  //   setAllDocumentList(allData);
  //   checkedNexBtn();
  //   setDocumentListCat(tmp1);
  // };
  const changeDocumentDetail = (doc, docDetail) => {
    getAllDocumentList();
  };
  useEffect(() => {
    if (isDocumentUploadResponse) {
      setIsAllDocChecked(false);
      setCheckedDocuments([]);
      if (isDocumentUploadResponse?.documentsSuccessfullySubmitted > 0 || isDocumentUploadResponse?.failure > 0) {
        const submittedData =  isDocumentUploadResponse?.submissionDetails;
        const tmp1: any = [];
        const tmpFailed: any = [];
        const tmpFailedDocIDs: any = [];
        const failedErrorMsg: any = [];
        submittedData.map((items) =>{
          if (items?.status === 'UPLOADED') {
            tmp1.push(`${items?.docType}~${items?.docOrder}`);
          } else if (items?.status === 'FAILED') {
            
            tmpFailedDocIDs.push(items?.documentId);
            tmpFailed.push(`${items?.docType}~${items?.docOrder}`);
            failedErrorMsg[`${items?.docType}~${items?.docOrder}`]= { error: items.error }
          }
        });
        // const tmp1 = submittedData.map((items) => {
        const subDocs = documentListCat.map((document) => {
              const docs = document.docs.map((docs) => {
                const docNames = docs?.documentType.split('~');
               if (tmp1?.includes(`${docNames[0]}~${docNames[1]}`) && docs?.documentDetails && !docs?.documentType?.includes(','))  {
                return {
                  ...docs, lenderDocumentStatus: 'UPLOADED'
                }
               } else if (tmpFailed?.includes(`${docNames[0]}~${docNames[1]}`) && docs?.documentDetails && !docs?.documentType?.includes(',')) {
                return {
                  ...docs, lenderDocumentStatus: 'FAILED', error: failedErrorMsg[`${docNames[0]}~${docNames[1]}`].error
                }
               } else {
                return docs
               }
              });
              return { category: document.category, docs: docs };
          })
        //   return subDocs;
        // });
        // setCheckedDocuments(tmpFailedDocIDs);
        console.log('subDocs', subDocs);
        console.log('tmp1', tmp1);
        console.log('tmpFailed', tmpFailed);
        setDocumentListCat(subDocs);
      }
    }
  }, [isDocumentUploadResponse]);
  
  const actionReplaceDoc = (doc) => {
    setSelectedDocument(doc);
    setShowReplace(true);
  };
  const actionRenameDoc = (doc) => {
    setSelectedDocument(doc);
    setShowRename(true);
  };

  const onChangeCheckbox = (value, doc, isDelete = false) => {
    const id = doc?.identifier;
    let tmp = [...checkedDocuments];
    if (tmp.includes(id)) {
      setIsAllDocChecked(false);
      const filtered = tmp.filter((lstItem) => lstItem !== id);
      tmp = filtered;
    } else {
      if(!isDelete) {
        tmp.push(id);
      }
    }
    setCheckedDocuments(tmp);
  };
  const onChangeCheckboxAll = (value) => {
    const checked = value?.target?.checked;
    setIsAllDocChecked(checked);
    let tmp = [...checkedDocuments];
    if (checked) {
      documentListCat?.map((document) => {
        document?.docs?.map((item) => {
          if (item?.lenderDocumentStatus !== "UPLOADED" && item?.documentDetails) {
            tmp.push(item?.identifier ?? "");
          }
        });
      });
    } else {
      tmp = [];
    }
    setCheckedDocuments(tmp);
  };

  const getDocPage = () => {
    console.log('::', selectedDocument);
    const docPageValues = selectedDocument?.identifier?.split("~");
    const docPage = docPageValues.length > 1 ? docPageValues?.at(-1) : 0;
    console.log('::docPage', docPage);
    return (docPage?.length ?? 0) == 0 ? 0 : docPage;
  }
    const getDisableNextBtn = () => {
    let isDisable = false;
    tmpdocumentList1?.map((document) => {
      if (!isDisable) {
        isDisable = (document?.documentDetails && document?.maxSize < document?.documentDetails?.fileSize)
      }
    });
    console.log(":isDisable: ", isDisable)
    if (isDisable) {
      setDisableNextBtn(true);
    }
  }
  const getDisableBtn = () => {
    let isDisable = false;
    if (!selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
      // TOOD: Enable next button without upload any document
      if (masterParentIDs?.length === 0 && masterChildIDs?.length === 0) {
        isDisable = false;
      } else {
        isDisable = true;
      }
    } else {
      isDisable = false;
    }

    tmpdocumentList1?.map((document) => {
      if (!isDisable) {
        isDisable = (document?.documentDetails && document?.maxSize < document?.documentDetails?.fileSize) || ((document?.require && document?.lenderDocumentStatus !== "UPLOADED") && !checkedDocuments.includes(document?.identifier));
      }
    });
    return isDisable;
  };

  // multilender document
  return (
    <>
      {/* <div>
        <Table
          columns={columns}
          dataSource={tmpdocumentList1}
          size="small"
          onChange={(paggination) => onTableChane(paggination)}
          // pagination={false}
        />
      </div> */}
      {showReplace && (
        <>
        <ReplaceDocument
        documentSetId={selectedLander?.documentSetId}
        showReplaceDoc={showReplace}
        setShowReplaceDoc={setShowReplace}
        selectedDocument={selectedDocument}
        isConflict={isConflictClick}
        leadData={leadData}
        getDocPage={getDocPage}
        docReplaceDocs={docReplaceDocs}
        getAllDocumentList={getAllDocumentList}
        selectedLander={selectedLander}
        actionAdd={() => {
          setShowReplace(false);
          setShowUploadDocument(true);
        }}
        callbackSuccess={(docDetail) => {
          changeDocumentDetail(selectedDocument, docDetail);
          setShowReplace(false);
        }}
        callbackDocUploaded={(isUpload, tmpDoc) => {
          if(isUpload) {
            selectedDocument.documentDetails = tmpDoc;
            changeDocumentDetail(selectedDocument, tmpDoc)
          }
          setShowUploadDocument(false);
          setShowReplace(false);
          setIsDocumentUploaded(true);
          // checkedNexBtn();
          // if (isUpload) {
          //   console.log("tmpDoc", tmpDoc);
          //   const tmpDocumentList = tmpdocumentList1?.map((doc) => {
          //     if (
          //       doc?.parentDocId === tmpDoc?.parentDocId &&
          //       doc?.childDocId === tmpDoc?.childDocId && !doc?.headers
          //     ) {
          //       const indexA = masterParentIDs.findIndex(
          //         (val) => val === doc?.parentDocId
          //       );
          //       const indexChild = masterChildIDs.findIndex(
          //         (val) => val === doc?.childDocId
          //       );

          //       if (doc?.childRequired === "yes" && indexChild !== -1) {
          //         masterChildIDs.splice(indexChild, 1);
          //       }
          //       if (doc?.childRequired !== "yes") {
          //         setSelectedChild([...SelectedChild, doc?.parentDocId]);
          //       }
          //       if (indexA !== -1 && doc?.childRequired !== "yes") {
          //         masterParentIDs.splice(indexA, 1);
          //         // setSelectedChild([...SelectedChild, ...a]);
          //       }

          //       return { ...doc, documentDetails: tmpDoc };
          //     }
          //     return doc;
          //   });
          //   checkedNexBtn();
          //   setDocumentList(tmpDocumentList);
          //   setTmpdocumentList1(tmpDocumentList);

          //   let tmpDocumentMappingList = [...documentMappingList];

          //   documentMappingList?.map((item) => {
          //     if (item.documentType == tmpDoc?.documentType) {
          //       const index = tmpDocumentMappingList.findIndex(
          //         (obj) => obj.documentType === tmpDoc?.documentType
          //       );
          //       tmpDocumentMappingList.splice(index, 1);
          //     }
          //     return "";
          //   });

          //   tmpDocumentMappingList.push({
          //     documentId: tmpDoc?.businessDocumentId,
          //     documentType: tmpDoc?.documentType,
          //     mapDocumentType: tmpDoc?.documentName,
          //   });
          //   setDocumentMappingList(tmpDocumentMappingList);
          //   console.log("map list", tmpDocumentMappingList);
          //   console.log("documnet", tmpDoc);
          //   // const tmpArr = [] as any;
          //   // tmpDocumentList?.map((item: any) => {
          //   //   if (item.documentDetails != null) {
          //   //     tmpArr.push(item?.documentDetails?.documentName);
          //   //     // if (item?.require) {
          //   //     // }
          //   //   }
          //   //   return "";
          //   // });
          //   // const uniqueNames = Array.from(new Set(tmpArr));
          //   // setMappedList(uniqueNames);
          // }
        }}
        actionOnDelete={actionOnDelete}
      />
        </>
      )}
      {showRename && (
        <RenameDocument
          showRenameDoc={showRename}
          setShowRenameDoc={setShowRename}
          selectedDocument={selectedDocument}
          callbackSuccess={(docDetail) => {
            changeDocumentDetail(selectedDocument, docDetail);
            setShowRename(false);
          }}
        />
      )}

      {showSkipDoc && (
        <SkipDocument
          showSkipDoc={showSkipDoc}
          setShowSkipDoc={setShowSkipDoc}
          documentListCat={documentListCat}
          lenderId={selectedLander.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          callbackSuccess={() => {
            setShowSkipDoc(false);
            setShowAdditionalFields(false);
            skippedLoginStatus()
          }}
        />
      )}
      {showUploadDocument && (
        <Modal
          title="Upload New Document:"
          centered
          width={400}
          onCancel={() => {
            setShowUploadDocument(false);
          }}
          footer={null}
          visible={showUploadDocument}
        >
          <UploadDocument
            // selectedType={"SIX_MONTH_SALARY_SLIP"}
            isCustomUI={true}
            isLenderDocs={true}
            // selectedType={selectedDocument?.internalDocumentType ? selectedDocument?.internalDocumentType : selectedDocument?.docName}
            selectedType={selectedDocument?.documentType ?? ""}
            parentDocId={selectedDocument?.parentDocId}
            childDocId={selectedDocument?.childDocId}
            documentSetId={selectedLander?.documentSetId}
            docOrder={selectedDocument?.documentDetails?.docOrder ?? selectedDocument?.documentOrgType?.split("~")?.at(-1) ?? 0}
            docPage={getDocPage()}
            formatDocument={selectedDocument?.format ?? ""}
            maxSize={selectedDocument?.maxSize ?? 5}
            selectedDocument={selectedDocument}
            individualType={leadData.applicationSubType}
            loanProductId={leadData.loanProductId}
            callBack={(isUpload, tmpDoc) => {
              selectedDocument.documentDetails = tmpDoc;
              setShowUploadDocument(false);
              setIsDocumentUploaded(true);
              if (isUpload) {
                console.log("tmpDoc", tmpDoc);
                const tmpDocumentList = tmpdocumentList1?.map((doc) => {
                  if (
                    doc?.parentDocId === tmpDoc?.parentDocId &&
                    doc?.childDocId === tmpDoc?.childDocId && !doc?.headers
                  ) {
                    const indexA = masterParentIDs.findIndex(
                      (val) => val === doc?.parentDocId
                    );
                    const indexChild = masterChildIDs.findIndex(
                      (val) => val === doc?.childDocId
                    );

                    if (doc?.childRequired?.toUpperCase() === "YES" && indexChild !== -1) {
                      masterChildIDs.splice(indexChild, 1);
                    }
                    if (doc?.childRequired?.toUpperCase() !== "YES") {
                      setSelectedChild([...SelectedChild, doc?.parentDocId]);
                    }
                    if (indexA !== -1 && doc?.childRequired?.toUpperCase() !== "YES") {
                      masterParentIDs.splice(indexA, 1);
                      // setSelectedChild([...SelectedChild, ...a]);
                    }

                    return { ...doc, documentDetails: tmpDoc };
                  }
                  return doc;
                });
                changeDocumentDetail(selectedDocument, tmpDoc);
                // checkedNexBtn();
                setDocumentList(tmpDocumentList);
                setTmpdocumentList1(tmpDocumentList);

                let tmpDocumentMappingList = [...documentMappingList];

                documentMappingList?.map((item) => {
                  if (item.documentType == tmpDoc?.documentType) {
                    const index = tmpDocumentMappingList.findIndex(
                      (obj) => obj.documentType === tmpDoc?.documentType
                    );
                    tmpDocumentMappingList.splice(index, 1);
                  }
                  return "";
                });

                tmpDocumentMappingList.push({
                  documentId: tmpDoc?.businessDocumentId,
                  documentType: tmpDoc?.documentType,
                  mapDocumentType: tmpDoc?.documentName,
                });
                setDocumentMappingList(tmpDocumentMappingList);
                console.log("map list", tmpDocumentMappingList);
                console.log("documnet", tmpDoc);
                // const tmpArr = [] as any;
                // tmpDocumentList?.map((item: any) => {
                //   if (item.documentDetails != null) {
                //     tmpArr.push(item?.documentDetails?.documentName);
                //     // if (item?.require) {
                //     // }
                //   }
                //   return "";
                // });
                // const uniqueNames = Array.from(new Set(tmpArr));
                // setMappedList(uniqueNames);
              }
            }}
          />
          {loadingDisplay && (
            <div className="loader-view-flowable">
              <Spin
                tip="We are processing your request, please wait"
                size="large"
              ></Spin>
            </div>
          )}
        </Modal>
      )}
      <div>
          <Row>
            <Col span={2} style={{ paddingInlineStart: "41px" }}>
            <Checkbox
                checked={isAllDocChecked}
                onChange={onChangeCheckboxAll}
              />
            </Col>
            <Col span={6}>{"File Name"}</Col>
            <Col span={5}>{"Lender Docu Name"}</Col>
            <Col span={3} style={{display:'flex', justifyContent:'center'}}>{"Format"}</Col>
            <Col span={2}>{"Max Size"}</Col>
            <Col span={6} style={{ textAlign: "center" }}>
              {"Actions"}
            </Col>
          </Row>
          {documentListCat?.map((item) => {
            return (
              <DocCategory
                isFlowable={true}
                documentCategoryList={item}
                actionOnDelete={actionOnDelete}
                actionOnUpload={(doc) => {
                  setSelectedDocument(doc);
                  setShowUploadDocument(true);
                }}
                actionAdd={actionAdd}
                actionRemove={actionRemove}
                actionReplaceDoc={actionReplaceDoc}
                actionRenameDoc={actionRenameDoc}
                onChangeCheckbox={onChangeCheckbox}
                checkedDocuments={checkedDocuments}
                selectedLander={selectedLander}
                setIsConflictClick={setIsConflictClick}
                callbackUpdateDoc={(documents) => {
                  const tmp = [...documentListCat];
                  const findExistingItem = tmp.find((item) => {
                    return item.category === documents.category;
                  });
                  if (findExistingItem) {
                    findExistingItem.docs = documents.docs;
                  }
                  setDocumentListCat(tmp);
                }}
              />
            );
          })}
      </div>
      <div
        style={{ display: "flex", justifyContent: "right", marginBlock: "10px" }}
      >
        <Space className="">
          <Button
            size="middle"
            style={{ paddingInline: "30px" }}
            htmlType="button"
            disabled={failedCount < 3}
            onClick={() => {
              setShowSkipDoc(true);
            }}
          >
            Skip
          </Button>
          <Button
            size="middle"
            style={{ paddingInline: "30px" }}
            loading={loading}
            disabled={getDisableBtn()}
            type="primary"
            htmlType="button"
            onClick={() => onClickNext(tmpdocumentList1)}
          >
            Next
          </Button>
        </Space>
        {/* <Button
          disabled={disableNextBtn}
          onClick={() => onClickNext(tmpdocumentList1)}
        >
          Next
        </Button> */}
      </div>
    </>
  );
};

export default RequiredDocumentFlowable;
