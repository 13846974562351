import { useEffect, useState } from "react";
import { Button, Card, Form, notification } from "antd";
import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "shared/services/api-service";
import { getUser, getUserDetails } from "shared/services/Utility";
import AppLoading from "shared/components/AppLoading/AppLoading";
import iconError from "../../assets/images/error-msg.svg";
import iconCopy from "../../assets/images/copy.svg";
import { Redirect } from "react-router-dom";

const CheckExtranalSource = () => {
  const [loading, setLoading] = useState(false);
  const [connectorData, seConnectorData] = useState(undefined as any);
  const [error, setError] = useState(undefined as any);

  useEffect(() => {
    let searchParams = new URL(window.location.href).searchParams;
    const params: any = {};
    searchParams?.forEach((value, key) => {
      params[key] = value;
    });
    if ((params?.ldata?.length ?? 0) > 0) {
      checkValidateConnector(params);
    }
  }, []);

  const getAllTypes = async () => {
    const { data } = await API_SERVICE.getTypes()
    if (data) {
      localStorage.setItem("enumValues", JSON.stringify(data));
    }
  };

  const getUsersPermissions = async () => {
    const { data } = await API_SERVICE.getUsersPermission(
      getUserDetails()?.userId
    );
    if (data) {
      localStorage.setItem(
        "userPermission",
        JSON.stringify(data.payload.roles)
      );
      window.location.reload();
    }
  };

  const getConnector = async () => {
    const { data } = await API_SERVICE.getConnectorById(
      getUserDetails()?.userId
    );
    localStorage.setItem(
      "basicDetails",
      JSON.stringify(data?.payload?.basicDetails)
    );
  };

  const getVerifyUser = async (userId) => {
    const { data } = await API_SERVICE.getVerifyUser(userId);
    localStorage.setItem("isUserVerified", data?.payload);
  };

  async function checkValidateConnector(params: any) {
    setLoading(true);
    try {
      const { data } = await API_SERVICE.checkValidateConnector({
        data: params?.ldata,
        externalSource: params?.externalSource ?? null,
      });

      const dataTmp = {
        payload: {
          ...data?.payload,
          userId: data?.payload?.connectorId,
          userDetails: {
            userId: data?.payload?.connectorId,
          },
        },
      };

      localStorage.setItem("user", JSON.stringify(dataTmp?.payload));
      await getAllTypes();
      await getVerifyUser(dataTmp?.payload?.userDetails?.userId);
      await getConnector();
      await getUsersPermissions();
      seConnectorData(dataTmp);
    } catch (e) {
      setError(API_SERVICE.handleErrors(e));
      setLoading(false);
    }
  }

  const renderExtranalSource = (updateAPICreds: any) => {
    if (connectorData) {
      updateAPICreds(connectorData);
    }
    if ((error?.length ?? 0) > 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            padding:'20px'
          }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              maxWidth: "600px",
              flexDirection: "column",
              flexWrap: "wrap",
              alignContent: "center",
              backgroundColor: "#CE111133",
              borderRadius: "10px",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={iconError} height={50} />
            </div>
            <span style={{ textAlign: "center" }}>
              {error}{" "}
              <img
                src={iconCopy}
                height={20}
                onClick={() => {
                  navigator.clipboard.writeText(error);
                }}
                style={{cursor:"pointer"}}
              />
            </span>
          </div>
        </div>
      );
    }
    return <>{loading && <AppLoading />}</>;
  };

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, apiCreds }) => {
        const user = localStorage.getItem("user");
        return isAuth && (user?.length ?? 0) > 0 ? (
          <Redirect
            to={{ pathname: "/" + (getUser()?.redirectUrl ?? "lead") }}
          />
        ) : (
          renderExtranalSource(updateAPICreds)
        );
      }}
    </AuthConsumer>
  );
};

export default CheckExtranalSource;
