import { useEffect } from "react";
import { Card, Form, Radio, RadioChangeEvent } from "antd";
import { signUpStages } from "./sign-up";
import SignTypeIndividual from "assets/images/sign-type-individual.svg";
import SignTypeBusiness from "assets/images/sign-type-business.svg";
import API_SERVICE from "shared/services/api-service";
import "../authentication.scss";
import { Link } from "react-router-dom";

interface ISignUpType {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  setBusinessType: React.Dispatch<React.SetStateAction<string>>;
}

const SignUpType: React.FunctionComponent<ISignUpType> = (
  props: ISignUpType
) => {
  const { setSelectedSignUpStage, setBusinessType } = props;

  useEffect(() => {
    getAllTypesFree();
  }, []);

  const getAllTypesFree = () => {
    API_SERVICE.getTypesFree().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
      }
    });
  };

  const onSignUpTypeChanged = (e: RadioChangeEvent) => {
    if ("INDIVIDUAL" === e.target.value) {
      setBusinessType("individual");
    } else if ("BUSINESS" === e.target.value) {
      setBusinessType("business");
    }
    setSelectedSignUpStage("yourDetail");
  };

  const renderForm = () => {
    return (
      <Form size="large" layout="vertical" requiredMark={false}>
        <Form.Item className="oneflo oneflo-bottom-space">
          <Radio.Group
            name="signUpType"
            className="full-width text-center"
            onChange={onSignUpTypeChanged}
          >
            <Radio value={"INDIVIDUAL"} className="lg">
              <img src={SignTypeIndividual} width={223} />
              <p className="text-center no-mr">INDIVIDUAL</p>
            </Radio>
            <div className="sign-up-type-divider"></div>
            <Radio value={"BUSINESS"} className="lg">
              <img src={SignTypeBusiness} width={223} />
              <p className="text-center no-mr">BUSINESS</p>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Card className="authentication-box">
        <div className="authentication-form">{renderForm()}</div>
      </Card>
      <div className="already-a-user-main">
        <p className="already-a-user text-center">
          Already a User?{" "}
          <Link to="/sign-in" className="sign-in-link">
            Sign In
          </Link>
        </p>
      </div>
    </>
  );
};

export default SignUpType;
