import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const designationSlice = createSlice({
  name: "DesignationReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setDesignationPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setDesignationPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setDesignationSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDesignationPageNo, setDesignationPageSize, setDesignationSearchParam } = designationSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default designationSlice.reducer;