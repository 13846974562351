import { Button, Col, notification, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { AppImages } from "shared/constants/AppImages";
import "./index.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setIsAppHeaderShow } from "shared/redux/common-reducer";


const { Title, Text } = Typography;
const ViewOffer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [leadData, setLeadData] = useState(null as any);
  const [webOffer, setWebOfferDocumentDetails] = useState(false);
  const [title, setTitle] = useState('');
  const [time, setTime] = useState('');
  const [discrption, setDescrption] = useState('');
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  const { appColors } = useSelector((state: RootState) => state.common);

  let { id } = useParams() as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    getLeadById();
    dispatch(setIsAppHeaderShow(false));
  }, []);

  const getLeadById = () => {
    API_SERVICE.getBusinessid(id)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data.payload);
          if (data.payload) {
            setTitle(data.payload.title);
            setDescrption(data.payload.description);
            setTime(moment(data.payload.lastUpdatedDate).locale("en").format("MMM, DD, YYYY HH:mm"));
          }
          if (data.payload.webOfferDocumentDetails.fileExtn !== "") {
            setWebOfferDocumentDetails(true);
          }

          if (data?.payload?.caseDetails?.caseHistoryHolder) {
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => { });
  };

  const documentWeb = (leadData?.webOfferDocumentDetails?.length ?? 0) > 0 && !isMobile ? leadData.webOfferDocumentDetails[0] : leadData?.mobileOfferDocumentDetails[0];

  return (
    <div className="content-box">
      {isMobile ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px', paddingBottom: '10px' }}>
          <Button
            style={{ border: 'none' }}
            size="small"
            onClick={() => history.goBack()}
            icon={<img src={AppImages.back} />}
          />
          <Text style={{ marginTop: '5px' }}>Offer</Text>
          <div style={{ width: '25px' }} />
        </div>
      ) : (

        <Title level={3}>
          <Link className="link" to={"/offers"} style={{'--color': appColors?.appPrimaryColor ?? ""} as any}>
            Offers
          </Link>{" "}
          &nbsp; &gt; &nbsp; Offer Detail
        </Title>
      )}

      <Row gutter={16}>
        <Col span={16}>
          {webOffer && (
            <>
              <img
                style={{ borderRadius: '10px', marginTop: '25px', maxWidth: "300px", minWidth: "240px" }}
                src={
                  "data:image/" +
                  documentWeb?.fileExtn +
                  ";base64," +
                  documentWeb?.documentDetails
                }
                width="80%"
                height="150px"
                alt="banner"
              />
            </>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ "marginTop": "3%" }}>
        <Col span={16}>
          <h4>{title}</h4>
          <p style={{ color: "grey" }}>{time}</p>
          <p className="discrption">{discrption}</p>
        </Col>
      </Row>
    </div >
  );
};

export default ViewOffer;
