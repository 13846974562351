import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const tenantSlice = createSlice({
  name: "TenantReducer",
  initialState: { tenantInfo:{} as any },
  reducers: {
    setTenantInfo : (state,action: PayloadAction<Object>) =>{
      state.tenantInfo = action?.payload;
    }
  },
});

export const { setTenantInfo } = tenantSlice.actions;
export default tenantSlice.reducer;
