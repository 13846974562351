import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  InputNumber,
  Space,
  Slider,
  Radio,
  Modal,
} from "antd";
import type { SliderMarks } from "antd/es/slider";
import { numberWithCommas } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";

interface IiciciOfferSreen {
  responseJSONSchema: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
}
const ICICIOfferAcceptance: React.FunctionComponent<IiciciOfferSreen> = (
  props: IiciciOfferSreen
) => {
  const [loanData, setLoanData] = useState();
  const [loanId, setLoanID] = useState();

  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(100);

  const [maxTenure, setMaxTenure] = useState(0);
  const [minTenure, setMinTenure] = useState(100);

  const [requestedAmount, setRequestedAmount] = useState(0);
  const [requestedTenure, setRequestedTenure] = useState(0);

  const [monthlyEmi, setMonthlyEmi] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loginFees, setLoginFees] = useState(0);

  const [offeredLoanAmount, setOfferedLoanAmount] = useState(0);
  const [loanStatus, setLoanStatus] = useState();
  const [loanEligiblityDetails, setLoanEligiblityDetails] = useState();
  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  const [isOfferDeclined, setIsOfferDeclined] = useState(false);

  const [isValuesUpdated, setIsValuesUpdated] =  useState(false);

  //
  useEffect(() => {
    API_SERVICE.updateRetriveOfferAcceptance(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          console.log("data", JSON.parse(data?.payload));
          const updateData = JSON.parse(data?.payload);
          if (updateData) {
            setLoanID(updateData.loanID);
            setLoanEligiblityDetails(updateData);
            setLoanStatus(updateData.eligibilityDtls[0].status);
            setIsOfferAccepted(updateData.eligibilityDtls[0].status === "Y");
            // setApplicantData(data?.payload?.applicants);
            setLoanData(updateData);
            setRequestedAmount(
              parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
            );
            setRequestedTenure(
              parseInt(updateData.eligibilityDtls[0].expectedTenure)
            );

            setMaxTenure(parseInt(updateData.eligibilityDtls[0].maxTenure));
            setMinTenure(parseInt(updateData.eligibilityDtls[0].minTenure));
            if (
              updateData.eligibilityDtls[0].minLoanAmt
                .toLowerCase()
                .includes("e")
            ) {
              const updatedData =
                updateData.eligibilityDtls[0].minLoanAmt.toLocaleString(
                  "fullwide",
                  { useGrouping: false }
                );
              setMinAmount(parseInt(updatedData));
            } else {
              setMinAmount(parseInt(updateData.eligibilityDtls[0].minLoanAmt));
            }
            if (
              updateData.eligibilityDtls[0].maxLoanAmt
                .toLowerCase()
                .includes("e")
            ) {
              const updatedData = Number(
                updateData.eligibilityDtls[0].maxLoanAmt
              ).toLocaleString("fullwide", { useGrouping: false });
              setMaxAmount(parseInt(updatedData));
            } else {
              setMaxAmount(parseInt(updateData.eligibilityDtls[0].maxLoanAmt));
            }

            //
            setMonthlyEmi(parseInt(updateData.eligibilityDtls[0].emi));
            setInterestRate(parseFloat(updateData.eligibilityDtls[0].rate));
            setLoginFees(parseInt(updateData.eligibilityDtls[0].initialFee));
            setOfferedLoanAmount(
              parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
            );
            //
          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  }, [props]);
  //
  const silderMarkRequestedAmount = {
    [minAmount]: {
      style: {
        transform: "translateX(0%)",
        width: "max-content",
      },
      label: <strong>Min: ₹ {numberWithCommas(minAmount)} </strong>,
    },
    [maxAmount]: {
      style: {
        transform: "translateX(-100%)",
        width: "max-content",
      },
      label: <strong>Max: ₹ {numberWithCommas(maxAmount)}</strong>,
    },
  };
  const silderMarkRequestedTenure = {
    [minTenure]: {
      style: {
        transform: "translateX(0%)",
        width: "max-content",
      },
      disable: true,
      label: <strong>Min: {numberWithCommas(minTenure)}</strong>,
    },
    [maxTenure]: {
      style: {
        transform: "translateX(-100%)",
        width: "max-content",
      },
      disable: true,
      label: <strong>Max: {numberWithCommas(maxTenure)}</strong>,
    },
  };
  const onChangeRequestedtenur = (value) => {
    setIsValuesUpdated(true);
    setRequestedTenure(value);
  };
  const onChangeRequestedAmt = (value) => {
    setIsValuesUpdated(true);
    setRequestedAmount(value);
  };
  const onChangeEmiCalculations = () => {
    setIsOfferLoading(true);
    const payload = {
      reqAmount: requestedAmount,
      reqTenure: requestedTenure,
      stLoanId: loanId,
    };
    API_SERVICE.getUpdatedICICIEmiCal(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId,
      payload
    )
      .then(({ data }) => {
        if (data) {
          console.log("data", JSON.parse(data?.payload));
          const updateData = JSON.parse(data?.payload);
          if (updateData) {
            setLoanEligiblityDetails(updateData);
            setLoanStatus(updateData.eligibilityDtls[0].status);
            // setApplicantData(data?.payload?.applicants);
            setLoanData(props.responseJSONSchema);
            setRequestedAmount(
              parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
            );
            setRequestedTenure(
              parseInt(updateData.eligibilityDtls[0].expectedTenure)
            );

            setMaxTenure(parseInt(updateData.eligibilityDtls[0].maxTenure));
            setMinTenure(parseInt(updateData.eligibilityDtls[0].minTenure));

            if (
              updateData.eligibilityDtls[0].minLoanAmt
                .toLowerCase()
                .includes("e")
            ) {
              const updatedData = Number(
                updateData.eligibilityDtls[0].minLoanAmt
              ).toLocaleString("fullwide", { useGrouping: false });
              setMinAmount(parseInt(updatedData));
            } else {
              setMinAmount(parseInt(updateData.eligibilityDtls[0].minLoanAmt));
            }
            if (
              updateData.eligibilityDtls[0].maxLoanAmt
                .toLowerCase()
                .includes("e")
            ) {
              const updatedData = Number(
                updateData.eligibilityDtls[0].maxLoanAmt
              ).toLocaleString("fullwide", { useGrouping: false });
              setMaxAmount(parseInt(updatedData));
            } else {
              setMaxAmount(parseInt(updateData.eligibilityDtls[0].maxLoanAmt));
            }

            //
            setMonthlyEmi(parseInt(updateData.eligibilityDtls[0].emi));
            setInterestRate(parseFloat(updateData.eligibilityDtls[0].rate));
            setLoginFees(parseInt(updateData.eligibilityDtls[0].initialFee));
            setOfferedLoanAmount(
              parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
            );
            //
          }
          setIsValuesUpdated(false);
          setIsOfferLoading(false);
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  //
  const onChangeOfferAccept = (value) => {
    setIsOfferAccepted(value);
  };
  ///
  const handleSubmitReq = (value) => {
    setIsOfferDeclined(false);
    const payload = {
      response: loanEligiblityDetails,
      isOfferAccepted: loanStatus === "Y" && value,
    };
    props.handleFormSubmit({ data: payload });
  };
  return (
    <div>
      {loanData ? (
        <>
          {loanStatus === "N" && (
            <div
              className="py-3 mt-2"
              style={{
                backgroundColor: "rgb(251 209 209)",
              }}
            >
              <Row>
                <div className="ml-2" style={{ marginLeft: "10px" }}>
                  You are not eligible for this offer. Please update amount and
                  tenure to get valid Offer.
                </div>
              </Row>
            </div>
          )}
          <Row>
            <Col span={12}>
              <div style={{ padding: "5px" }}>
                <Row style={{ alignItems: "center", margin: "0px 6px" }}>
                  <Col span={12}>
                    <span>Requested Amount</span>
                  </Col>
                  {/* <Col span={2} /> */}
                  <Col span={12}>
                    <InputNumber
                      className="custom-input"
                      value={requestedAmount}
                      formatter={(value) => numberWithCommas(value)}
                      style={{ color: "black" }}
                      min={minAmount}
                      max={maxAmount}
                      controls={false}
                      //   disabled={items.verified}
                      onChange={(value) => onChangeRequestedAmt(value)}
                      placeholder="Requested Amount"
                    />
                  </Col>
                </Row>
                <Slider
                  marks={silderMarkRequestedAmount}
                  min={minAmount}
                  max={maxAmount}
                  defaultValue={requestedAmount}
                  value={requestedAmount}
                  trackStyle={{ backgroundColor: "#273896" }}
                  handleStyle={{ border: "solid 2px #273896" }}
                  onChange={(val) => onChangeRequestedAmt(val)}
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: "5px" }}>
                <Row style={{ alignItems: "center", margin: "0px 6px" }}>
                  <Col span={10}>
                    <span>Requested Tenure (Months)</span>
                  </Col>
                  <Col span={2} />
                  <Col span={12}>
                    <InputNumber
                      className="custom-input"
                      style={{ color: "black" }}
                      formatter={(value) => numberWithCommas(value)}
                      value={requestedTenure}
                      max={maxTenure}
                      min={minTenure}
                      controls={false}
                      //   disabled={items.verified}
                      onChange={(value) => onChangeRequestedtenur(value)}
                      placeholder="Requested Tenure"
                    />
                  </Col>
                </Row>
                <Slider
                  marks={silderMarkRequestedTenure}
                  min={minTenure}
                  max={maxTenure}
                  defaultValue={requestedTenure}
                  value={requestedTenure}
                  onChange={(value) => onChangeRequestedtenur(value)}
                  trackStyle={{ backgroundColor: "#273896" }}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  handleStyle={{ border: "solid 2px #273896" }}
                />
              </div>
            </Col>
          </Row>
          {/* buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "10px",
            }}
          >
            <Space className="">
              <Button
                size="middle"
                loading={isOfferLoading}
                style={{ paddingInline: "30px" }}
                // loading={loading}
                // disabled={
                //   applicantData.filter((item: any) => !item?.verified).length > 0
                // }
                type="primary"
                htmlType="button"
                disabled={!isValuesUpdated}
                onClick={() => onChangeEmiCalculations()}
              >
                Check Loan Offer
              </Button>
            </Space>
            {/* <Button
          disabled={disableNextBtn}
          onClick={() => onClickNext(tmpdocumentList1)}
        >
          Next
        </Button> */}
          </div>
          <div
            className="py-3 mt-2"
            style={{
              backgroundColor: "#F5F5F5",
            }}
          >
            <Row>
              <Col span={24}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    style={{
                      color: "#273896",
                      textAlign: "center",
                      fontFamily: "Avenir LT Std",
                      fontSize: "17px",
                      fontStyle: "normal",
                      fontWeight: "750",
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Your Final Loan Offer: ₹
                    {numberWithCommas(offeredLoanAmount)}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div
                  className="mb-1 mt-2"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      fontFamily: "Avenir LT Std",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Dear Customer you are eligible upto above amount based on
                    your income details
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="mt-3">
                  <Row>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Monthly EMI: ₹
                      {isNaN(monthlyEmi) ? " -" : numberWithCommas(monthlyEmi)}
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Interest Rate: {interestRate.toFixed(2)} %
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Login Fee: ₹
                      {isNaN(loginFees) ? " -" : numberWithCommas(loginFees)}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        "Loading"
      )}
      {
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: 'auto'
         }}>
        <Modal
          width={600}
          visible={isOfferDeclined}
          // onCancel={() => onCancelPopup()}
          closable={false}
          footer={null}
          title=""
          maskClosable={true}
        >
          {/* <Row> */}
          <div style={{ margin: "10px", textAlign: "center" }}>
            Are you sure, You do not want to avail the Offer Generated?
          </div>
          {/* </Row> */}
          {/* <Row> */}
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: '10px' }}>
              <Button
                htmlType="button"
                onClick={() => {
                  // setShowAdditionalFields(false);
                  setIsOfferDeclined(false);
                }}
              >
                Back to edit
              </Button>
            </div>

            <div style={{ padding: '10px' }}>
              <Button
                size="middle"
                style={{ paddingInline: "30px" }}
                type="primary"
                htmlType="button"
                onClick={() => handleSubmitReq(false)}
              >
                I Confirm
              </Button>
            </div>
          </div>
          {/* </Row> */}
        </Modal>
        </div>
      }

      {/* buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBlock: "10px",
        }}
      >
        <Space className="">
          <Button
            htmlType="button"
            onClick={() => {
              setIsOfferDeclined(true);
            }}
          >
            Offer Decline
          </Button>
          <Button
            size="middle"
            style={{ paddingInline: "30px" }}
            type="primary"
            disabled={loanStatus !== 'Y' || isValuesUpdated}
            htmlType="button"
            onClick={() => handleSubmitReq(true)}
          >
            Offer Accept
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default ICICIOfferAcceptance;
