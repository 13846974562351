import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from 'shared/contexts/AuthContext';

export default function ProtectedRoute({ component: Component, ...rest }: any) {

    let searchParams = new URL(window.location.href).searchParams;
    const params: any = {};
    searchParams?.forEach((value, key) => {
      params[key] = value;
    });

    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthConsumer>
                    {({ isAuth, apiCreds }) => {
                        if((params?.ldata?.length ?? 0) > 0) {
                            localStorage.clear();
                            return <Redirect
                                        to={{
                                            pathname: '/check-extranal-source',
                                            state: {data: true},
                                            search: new URL(window.location.href).searchParams.toString(),
                                        }}
                                    />;
                        }
                        if (apiCreds?.userDetails?.changePasswordNextLogin) {
                            return <Redirect
                                to={{
                                    pathname: '/change-password',
                                    state: {data: true}
                                }}
                            />;
                        }
                        return isAuth ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/sign-in',
                                    state: { from: rest.location },
                                }}
                            />
                        );
                    }
                    }
                </AuthConsumer>
            )}
        />
    );
}
