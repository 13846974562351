import { Button, Card, Col, Divider, Row, Typography } from "antd";
import React, { useState } from "react";
import {ReactComponent as IconProfileEdit} from "../../assets/images/profileEdit.svg";
import VerifyEmail from "./verify-email";
import EditProfile from "./edit-profile";
import "./profile.scss";
import { getUserDetails } from "shared/services/Utility";
import EditBusinessDetail from "./edit-business-details";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import FormIcons from "shared/components/FormIcons/FormIcons";
import FormButton from "shared/components/FormButton/FormButton";
const { Text } = Typography;

interface IProfileTab {
  updateConnector: any;
  basicDetails: any;
  setOpenEditProfileDetail: any;
  openEditProfileDetail: any;
  setOpenEditBusinessDetail: any;
  openEditBusinessDetail: any;
  getConnector: any;
}

const ProfileTab: React.FC<IProfileTab> = (props: IProfileTab) => {
  const {
    getConnector,
    updateConnector,
    basicDetails,
    openEditProfileDetail,
    setOpenEditProfileDetail,
    openEditBusinessDetail,
    setOpenEditBusinessDetail,
  } = props as any;
  const userDetails = getUserDetails();
  const [openEmail, setOpenEmail] = useState(false);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const verifyEmail = () => {
    setOpenEmail(true);
  };
  const OpenEditData = () => {
    setOpenEditProfileDetail(true);
  };

  const OpenEditBusinessData = () => {
    setOpenEditBusinessDetail(true);
  };

  const iconPrimaryColor = appColors?.appPrimaryColor ?? "";
  const iconSecondaryColor = appColors?.appSecondaryColor ?? "#C71C8E";

  return (
    <>
      {(isMobile
        ? !openEditBusinessDetail && !openEditProfileDetail && !openEmail
        : !openEmail && !openEditProfileDetail) && (
        <Card
          className="lead-details"
          style={{ height: "840px", width: "100%", marginTop: "5px" }}
        >
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Connector ID
                <p style={{ fontWeight: "350" }}>{userDetails?.userId}</p>
              </p>
            </Col>
            <Col span={9} className="profile-icon-mobile-view">
              <FormButton
                className="remove-border"
                icon={<FormIcons type="copy"/>}
              />
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Phone
                <p style={{ fontWeight: "350" }} prefix="+91">
                  {basicDetails?.phoneNumber}
                </p>
              </p>
            </Col>
            <Col span={9} className="profile-icon-mobile-view">
              <div style={{ marginLeft: "17px", marginRight: "17px" }}>
                <FormIcons type="apporvedBorder"/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Email
                <span>
                  {basicDetails?.emailId !== undefined ||
                  basicDetails?.emailId !== null ? (
                    basicDetails?.emailVerified == true ? null : (
                      <label className="label_verified label_verficiation_pending">
                        Verification Pending
                      </label>
                    )
                  ) : null}
                </span>
                <p style={{ fontWeight: "350" }}>{basicDetails?.emailId}</p>
              </p>
            </Col>
            <Col span={9} className="profile-icon-mobile-view">
              {basicDetails?.emailId !== undefined ||
              basicDetails?.emailId !== null ? (
                basicDetails?.emailVerified == true ? (
                  <div style={{ marginLeft: "17px", marginRight: "17px" }}>
                    <FormIcons type={"apporvedBorder"} />
                  </div>
                ) : (
                  <Button className="btn-verify" onClick={verifyEmail}>
                    Verify
                  </Button>
                )
              ) : null}
            </Col>
          </Row>
          <p style={{ fontWeight: "350" }}>
            Contact / Brand Name
            <p style={{ fontWeight: "350" }}>{basicDetails?.connectorContactBrandName ?? " "}</p>
          </p>
          <Divider />
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Date Of Birth
                <p style={{ fontWeight: "350" }}>{basicDetails?.dateOfBirth}</p>
              </p>
            </Col>
            <Col span={9} className="profile-icon-mobile-view">
              <FormButton
                className="remove-border custome-btn-icon-play"
                style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                icon={<IconProfileEdit style={{width:"20px", height:"20px"}}/>}
                onclick={OpenEditData}
              />
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Gender
                <p style={{ fontWeight: "350" }}>{basicDetails?.gender}</p>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <p style={{ fontWeight: "350" }}>
                Current Address
                <p style={{ fontWeight: "350" }}>
                  {basicDetails?.address?.pinCode}
                  <br />
                  {basicDetails?.address?.address}
                  <br />
                  {basicDetails?.address?.city} <br />
                  {basicDetails?.address?.state}
                </p>
              </p>
            </Col>
          </Row>
          {isMobile && basicDetails?.connectorType === "BUSINESS" && (
            <div className="tab-details">
              <Divider />
              {(!openEditProfileDetail || !openEditBusinessDetail) && (
                <>
                  <Row>
                    <Col span={20}>
                      <Text style={{ fontWeight: "350" }}>
                        Business Type
                        <p style={{ fontWeight: "350" }}>
                          {basicDetails?.businessType}
                        </p>
                      </Text>
                    </Col>
                    {basicDetails?.connectorRole === "CONNECTOR_ADMIN" && (
                      <Col>
                        <Button
                          type="text"
                          size="small"
                          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                          icon={<IconProfileEdit />}
                          onClick={OpenEditBusinessData}
                          className="remove-border custome-btn-icon-play"
                        />
                      </Col>
                    )}
                  </Row>
                  <p style={{ fontWeight: "350" }}>
                    Business Name
                    <p style={{ fontWeight: "350" }}>
                      {basicDetails?.businessName}
                    </p>
                  </p>
                  <p style={{ fontWeight: "350" }}>
                    Authorised Signatory Name
                    <p style={{ fontWeight: "350" }}>
                      {basicDetails?.authorizedSignatory?.name}
                    </p>
                  </p>
                  <p style={{ fontWeight: "350" }}>
                    {" "}
                    Authorised Signatory Phone
                    <p style={{ fontWeight: "350" }}>
                      {basicDetails?.authorizedSignatory?.phoneNumber}
                    </p>
                  </p>
                  <p style={{ fontWeight: "350" }}>
                    Authorised Signatory Email
                    <p style={{ fontWeight: "350" }}>
                      {basicDetails?.authorizedSignatory?.email}
                    </p>
                  </p>
                </>
              )}
              <Divider />
              <p style={{ fontWeight: "350" }}>
                Referral Connector Detail <br />
                <span style={{ fontWeight: "350" }}>
                  {basicDetails?.connectorName}
                </span>
                <span style={{ fontWeight: "350", float: "right" }}>
                  {basicDetails?.connectorMobileNumber}
                </span>
              </p>
              <p style={{ fontWeight: "350" }}>
                Referral Sales Relationship Manager Detail <br />
                <span style={{ fontWeight: "350" }}>
                  {basicDetails?.srmName}
                </span>
                <span style={{ fontWeight: "350", float: "right" }}>
                  {basicDetails?.srmMobileNumber}
                </span>
              </p>
            </div>
          )}
        </Card>
      )}

      {openEmail && (
        <VerifyEmail setOpenEmail={setOpenEmail} getConnector={getConnector} />
      )}
      {openEditProfileDetail && (
        <EditProfile
          setOpenEditProfileDetail={setOpenEditProfileDetail}
          updateConnector={updateConnector}
          basicDetails={basicDetails}
        />
      )}
      {openEditBusinessDetail && isMobile && (
        <EditBusinessDetail
          setOpenEditBusinessDetail={setOpenEditBusinessDetail}
          updateConnector={updateConnector}
          basicDetails={basicDetails}
        />
      )}
    </>
  );
};
export default ProfileTab;
