import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export const noticesSlice = createSlice({
    name: "NoticesSlice",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setNoticesPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setNoticesPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setNoticesSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setNoticesPageNo, setNoticesPageSize, setNoticesSearchParam } = noticesSlice.actions;
export default noticesSlice.reducer;