import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { DefaultOptionType } from "antd/es/select";
import "../FormInput/FormInput.scss"

interface IOption {
  value: string;
  label: string;
}

interface IFormSelect {
  name: any;
  label?: string;
  placeholder?: string;
  style?: any;
  isRequired?: boolean;
  requiredLabel?: string;
  disabled?: boolean;
  rules?: Rule[] | undefined;
  options?: IOption[] | undefined;
  defaultValue?: string;
  isBorder?: boolean;
  classNameForm?: string;
  mode?: "multiple" | "tags";
  maxTagCount?: "responsive";
  value?: string;
  optionFilterProp?: string;
  onFocus?: undefined | any;
  onChange?:
    | ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
  onSearch?: any;
  removeDefaultCss?: boolean;
  onSelect?: any;
}

const FormSelect = (props: IFormSelect) => {
  const {
    name,
    label,
    isRequired,
    requiredLabel,
    disabled,
    placeholder,
    style,
    rules,
    isBorder,
    classNameForm,
    options,
    defaultValue,
    mode,
    maxTagCount,
    onChange,
    onSearch,
    optionFilterProp,
    value,
    onFocus,
    removeDefaultCss,
    onSelect
  } = props;

  return (
    <Form.Item
      className={removeDefaultCss ? "" : "field-bg"}
      name={name}
      label={label}
      style={style}
      rules={[
        {
          required: isRequired,
          message: `Please select ${
            requiredLabel ?? label ?? placeholder
          }`,
        },
        ...(rules ?? []),
      ]}
      initialValue={defaultValue}
    >
      <Select
        size="large"
        className="custom-select"
        defaultValue={defaultValue}
        showSearch
        allowClear
        disabled={disabled}
        onChange={onChange}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={placeholder}
        value={value}
        mode={mode}
        onFocus={onFocus}
        optionFilterProp={optionFilterProp}
        maxTagCount={maxTagCount}
        style={style}
      >
        {options?.map((option: any, i: number) => (
          <Select.Option key={i} value={option.key}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormSelect;

/*
interface IFormSelect {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  rules?: Rule[] | undefined;
  options: IOption[] | undefined;
  defaultValue?: string;
  isBorder?: boolean;
  value?: string;
  onChange?:
  | ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void)
  | undefined;
  onSelect?: any;
  removeDefaultCss?: boolean;
}

const FormSelect = (props: IFormSelect) => {
  const {
    name,
    label,
    isRequired,
    disabled,
    placeholder,
    rules,
    isBorder,
    options,
    defaultValue,
    onChange,
    value,
    onSelect,
    removeDefaultCss
  } = props;

  return (
    <Form.Item
      //className={`field-bg ${isBorder ? "field-border" : ""} `}
      className={removeDefaultCss ? "" : "field-bg"}
      name={name}
      label={label}
      rules={[
        {
          required: isRequired,
          message: `Please select your ${label ?? placeholder}`,
        },
        ...(rules ?? []),
      ]}
      initialValue={defaultValue}
    >
      <Select
        size="large"
        defaultValue={defaultValue}
        showSearch
        disabled={disabled}
        onChange={onChange}
        onSelect={onSelect}
        placeholder={placeholder}
        value={value}
      >
        {options?.map((option: any, i: number) => (
            <Select.Option key={i} value={option.key}>
              {option.value}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default FormSelect;
*/