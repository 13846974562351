import { Button, Card, Checkbox, Form, notification } from "antd";
import FormInput from "shared/components/FormInput/FormInput";
import { signUpStages } from "./sign-up";
import "../authentication.scss";
import { useState } from "react";
import API_SERVICE from "shared/services/api-service";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";

interface ISignUpSignatoryDetails {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  userDetails: any;
}

const SignUpSignatoryDetails: React.FunctionComponent<
  ISignUpSignatoryDetails
> = (props: ISignUpSignatoryDetails) => {
  const { setSelectedSignUpStage, userDetails } = props;

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm() as any;

  const handleSubmit = async (values: any) => {
    const params = {
      businessName: userDetails?.businessName ?? "",
      businessType: userDetails?.businessType ?? "",
      active: userDetails?.active ?? true,
      driverLicenseNo: "",
      email: values.email,
      managerId: "",
      name: values.fullName,
      pan: "",
      phoneNumber: values.mobileNumber,
      primaryRole: "",
      referralId: "",
      userId: userDetails?.userId ?? "",
    };

    console.log("params: ", params);
    ///////////////////////////////
    // setSelectedSignUpStage("setPassword");
    // return;
    ////////////////////////////////

    setLoading(true);
    API_SERVICE.updateAuthSignatory(userDetails?.userId ?? "", params)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          setSelectedSignUpStage("setPassword");
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        fullName: userDetails?.name ?? userDetails?.fullName ?? "",
        email: userDetails?.email ?? userDetails?.loginName ?? "",
        mobileNumber: userDetails?.phoneNumber || "",
      });
    } else {
      form.resetFields();
    }
  };

  const renderForm = () => {
    return (
      <Form form={form} size="large" layout="vertical" onFinish={handleSubmit}>
        <Checkbox
          defaultChecked={false}
          style={{ marginTop: "10px" }}
          onChange={onChangeCheckbox}
        >
          Authorised Signatory Same As Sign Up User{" "}
        </Checkbox>

        <FormInput
          label="Full Name (As Per PAN Card)"
          name={"fullName"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Enter your name"}
          isRequired={true}
          requiredLabel="full name (As Per PAN Card)"
        />

        <FormInput
          label="Mobile No"
          name={"mobileNumber"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Enter your mobile number"}
          isRequired={true}
          requiredLabel="mobile Number"
          inputMode={"numeric"}
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid number",
            },
          ]}
        />

        <FormInput
          label="Email ID "
          name={"email"}
          classNameForm="oneflo-bottom-space"
          placeholder={"Enter your email id"}
          isRequired={true}
          requiredLabel="email"
          inputMode={"email"}
          rules={[{ type: "email", message: "Please enter valid email" }]}
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card className="authentication-box">
      <h3 className="welcome-to-oneflo">
        Enter Your Business Authorised Signatory Details
      </h3>
      <div className="authentication-form">{renderForm()}</div>
    </Card>
  );
};

export default SignUpSignatoryDetails;
