import {
  Row,
  Typography,
  Col,
  InputNumber,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import Disbursed from "./Disbursed";

import "./index.scss";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title, Text } = Typography;

const LenderDisbursed: React.FunctionComponent<any> = ({
  leadData,
  assignedLenders,
  lenderDisbursedHandler,
}) => {
  const [selectedLenders, setSelectedLenders] = useState([] as any);
  const [totalSanctionedAmount, setTotalSanctionedAmount] = useState(
    0 as number
  );
  const [payoutType, setPayoutType] = useState("");
  const [comment, setComment] = useState(null as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    const type = leadData?.caseDetails?.connectorDetails?.payoutType ?? ""
    setPayoutType(type);
    setComment(leadData?.caseDetails?.caseHistoryHolder?.disbursedState?.at(0)?.comments);
  }, []);

  useEffect(() => {
    let sanctionedAmount = 0;
    const tmpAssignedLenders = assignedLenders.filter(
      (item) =>
        item.status != "REJECTED" &&
        item.status != "ASSIGNED" &&
        item.status != "LOGGED" &&
        item.sanctionedAmount > 0
    );

    // const lenders = tmpAssignedLenders?.map((lender) => {
    //   if (lender.disbursalLenderInfoDetails == null || (lender.disbursalLenderInfoDetails ?? 0) == 0) {
    //     lender.disbursalLenderInfoDetails = [
    //       {
    //         disbursedId: null,
    //         lenderAmount: {
    //           disbursedAmount: 0,
    //           insuranceAmount: 0,
    //           payoutPercentage: leadData?.caseDetails?.loanDetails?.expectedInterestRate
    //         }
    //       }
    //     ];
    //   }
    //   return lender;
    // });

    setTotalSanctionedAmount(sanctionedAmount);
    setSelectedLenders(tmpAssignedLenders);
  }, [assignedLenders]);



  return (
    <div className="lender-disbursed">
      <Title level={5}>Disbursed</Title>
      <div>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Text style={{ fontSize: '14px' }}>Total Tentative Loan Amount:</Text>
            <InputNumber
              readOnly
              className="custom-input-number"
              name="tentativeLoanAmount"
              value={leadData?.caseDetails?.loanDetails?.loanAmount || 0}
              formatter={(value) =>
                numberWithCommas(value)
              }
              placeholder="Total Tentative Loan Amount"
            />
          </Col>
          <Col span={24}>
            <Text style={{ fontSize: '14px' }}>Total Sanctioned Loan Amount:</Text>
            <InputNumber
              readOnly
              className="custom-input-number"
              name="tentativePayoutAmount"
              value={totalSanctionedAmount}
              formatter={(value) =>
                numberWithCommas(value)
              }
              placeholder="Total Sanctioned Loan Amount"
            />
          </Col>

          <Col span={24}></Col>
        </Row>

        {selectedLenders?.map((lender, lenderIndex) => {
          return <Disbursed
            lender={lender}
            leadData={leadData}
            lenderIndex={lenderIndex}
            payoutType={payoutType}
            onActionSubmit={(comment) => {
              lenderDisbursedHandler({ comment: comment });
            }} />
        })}

        <TextArea
          name="comment"
          showCount
          value={comment}
          maxLength={500}
          className="custom-textarea"
          placeholder="Comment"
          onChange={(e) => setComment(e.target.value)}
        />
        <Button
          type="primary"
          htmlType="submit"
          className="next-btn dynamic-btn-primary"
          style={{ '--btnColor': appColors?.appPrimaryColor ?? "" } as any}
          onClick={() => {
            lenderDisbursedHandler({ comment: comment });
          }}
        >
          Submit
        </Button>

      </div>
    </div>
  );
};

export default LenderDisbursed;
