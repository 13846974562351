export const DASHBOARD_ROUTE = '/dashboard';
export const LEAD = '/lead';
export const OFFERS = '/offers';
export const NOTICES = '/notices';
export const EMPLOYEES = '/employees';
export const DESIGNATION = '/designation';
export const REPORT = '/report';
export const HELP = '/help';
export const PAYOUT_REQUEST = '/payout-request';
export const PARTNERS = '/partners';
export const TOOLS = '/tools';
