import React, { useEffect, useState } from 'react';
import { Button, Form, List, notification } from 'antd';
import { PHONE_NUMBER_REGEX, PINCODE_REGAX, STRING_ONLY } from 'shared/constants/AppConstants';
import moment from 'moment';
import { disableDate } from 'shared/services/Utility';

import API_SERVICE from 'shared/services/api-service';
import FormCheckbox from 'shared/components/FormCheckbox/FormCheckbox';
import FormDOB from 'shared/components/FormDOB/FormDOB';
import FormInput from 'shared/components/FormInput/FormInput';
import FormInputPan from 'shared/components/FormInput/FormInputPan';

interface IApplicantInformation {
    setShowApplicantInfo: any;
    setIsApplicantModal: any;
    leadData: any;
    getBureauScore: any;
    genrateBureauScore: any;
    getLeadById: any;
}

const ApplicantInformation: React.FC<IApplicantInformation> = (props: IApplicantInformation) => {

    const { setShowApplicantInfo, setIsApplicantModal, leadData, getBureauScore, genrateBureauScore, getLeadById } = props;

    const [pan, setPan] = useState("");
    const [panData, setPanData] = useState({}) as any;
    const [applicantInfoForm] = Form.useForm() as any;

    useEffect(() => {
        const { contactDetails, address } = leadData;
        console.log("leadData ----->", leadData)
        applicantInfoForm.setFieldsValue({
            pan: contactDetails?.pan == "PENDING" ? "" : contactDetails?.pan,
            name: contactDetails?.firstName ?? "",
            mobileNumber: contactDetails?.mobileNumber.startsWith("1") ? "" : contactDetails?.mobileNumber,
            dateOfBirth: contactDetails?.dateOfBirth && moment(contactDetails.dateOfBirth),
            email: contactDetails?.emailId ?? "",
            pinCode: address?.pinCode ?? "",
            addressLine1: address?.address ?? "",
            city: address?.city ?? "",
            state: address?.state ?? "",
        });
        setPan(contactDetails?.pan == "PENDING" ? "" : contactDetails?.pan);
        console.log("pandata", panData)
    }, []);

    const checkPincode = (e: any) => {
        const { value: nextValue } = e.target;
        const params = {
            pin_code: nextValue,
        };
        if (nextValue?.length == 6) {
            API_SERVICE.checkPincode(params).then(({ data }) => {
                if (data) {
                    applicantInfoForm.setFieldsValue({
                        city: data?.payload?.city ?? "",
                        state: data?.payload?.state ?? "",
                    });
                }
            });
        }
    };

    const handleGetConsetUserInfo = (formData: any) => {
        const payload = {
            address: formData?.addressLine1,
            caseId: leadData?.caseDetails?.caseId,
            city: formData?.city,
            dob: formData?.dateOfBirth,
            email: formData?.email,
            mobileNumber: formData?.mobileNumber,
            name: formData?.name,
            panId: formData?.pan,
            pincode: formData?.pinCode,
            state: formData?.state,
        }
        console.log("applicant ----", payload)
        API_SERVICE.getConsentInfo(payload).then(({ data }) => {
            if (data) {
                setShowApplicantInfo(false);
                setIsApplicantModal(false);
                if (data?.payload) {
                    genrateBureauScore(formData?.pan);
                }
                getBureauScore(formData?.pan);
                getLeadById();
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })

    }

    const onFinish = (values: any) => {
        if (panData?.payload?.result?.name == applicantInfoForm.getFieldValue("name").trim()) {
            handleGetConsetUserInfo(values);
        } else {
            notification.error({ message: "Please Enter correct details" })
        }
    };

    const column = [
        <FormInputPan
            name={'pan'}
            label='PAN'
            placeholder='Enter Your Pan'
            showVerification
            value={pan}
            callBack={(panData?: any) => {
                setPanData(panData)
                if ((panData?.payload?.result?.name?.length ?? 0) != 0) {
                    applicantInfoForm.setFieldsValue({
                        name: panData?.payload?.result?.name,
                    })
                }
            }}
        />,
        <FormInput
            name='name'
            placeholder='Enter Your Name'
            label='Name'
            rules={[
                { required: true, message: "Please enter name" },
                {
                    pattern: STRING_ONLY,
                    message: "Please enter valid name",
                },
            ]}
        />,
        <FormInput
            name='mobileNumber'
            label='Mobile Number'
            placeholder='Enter Your Mobile Number'
            prefix='+91'
            rules={[
                {
                    required: true,
                    message: "Please enter mobile number"
                },
                {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please enter valid Mobile number",
                },
            ]}
        />,
        <FormInput
            name='email'
            label='Email'
            placeholder='Enter Your Email'
            rules={[
                {
                    required: true,
                    message: "Please enter email",
                },
                { type: "email", message: "Please enter valid email" },
            ]}
        />,
        <FormDOB
            form={applicantInfoForm}
            disableDate={disableDate}
            label="Date of Birth (YYYY-MM-DD)"
            placeholder="Date of Birth"
            name="dateOfBirth"
            isRequired={true}
        />,
        <FormInput
            name='pinCode'
            label='Pin Code'
            placeholder='Enter Pincode'
            onChange={(e: any) => checkPincode(e)}
            rules={[
                {
                    required: true,
                    message: "Please enter pin code",
                },
                {
                    pattern: PINCODE_REGAX,
                    message: "Please enter valid pincode",
                },
            ]}
        />,
        <FormInput
            name='addressLine1'
            label='Address Line 1'
            placeholder='Enter Your Address Line 1'
            rules={[
                {
                    required: true,
                    message: "Please enter address line 1",
                },
            ]}
        />,
        <FormInput
            name='city'
            label='City'
            placeholder='Enter Your City'
            rules={[
                {
                    required: true,
                    message: "Please enter city",
                },
            ]}
        />,
        <FormInput
            name='state'
            label='State'
            placeholder='Enter Your State'
            rules={[
                {
                    required: true,
                    message: "Please enter state",
                },
                {
                    pattern: STRING_ONLY,
                    message: "Please enter valid state",
                },
            ]}
        />
    ];

    return (
        <div style={{ width: "90%" }} className='content-box'>
            <p style={{ fontWeight: "300" }}>*Required For Bureau Request</p>
            <Form
                form={applicantInfoForm}
                layout="vertical"
                onFinish={onFinish}
                name='applicantInfoForm'
            >
                <List
                    grid={{ gutter: 5, column: 2, xs: 1 }}
                    dataSource={column}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
                <FormCheckbox
                    name={"applicant terms"}
                    isRequired
                    msgRequired="Please Check applicant information terms"
                    textElement={
                        <div>
                            I confirm that the Applicant has been informed about the following credit Bureau Disclaimer.
                            “I hereby appoint Oneinfinity as my authorised representative to receive my credit information from
                            Experian or such credit bureaus.” I have also ensured that the details entered in the Borrower
                            Case are actual details of the borrower associated with the Credit Bureau records.
                        </div>
                    }
                />
                <div style={{ width: "50%" }}>
                    <Form.Item>
                        <Button className='next-btn' type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
};

export default ApplicantInformation;